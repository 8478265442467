import React, {useEffect, useState} from "react";
import { ResponsivePieCanvas } from '@nivo/pie'
import s from "../../CallRecords/Analytics/Analytics.module.scss";
import {useTheme} from "../../../../../hooks/useTheme";

export const pieTemplate = {
    "id": "Нет данных",
    "target": "lost",
    "label": "Пропущенный",
    "value": 1,
    "color": "hsl(0,70%,50%)"
}

export const PieChart = ({data, label, show_legend=true, w="550px", clickEvent}) => {
    const {theme} = useTheme()
    const [cnt, setCnt] = useState(0)

    useEffect(() => {
        if (!data) return

        let sum = 0
        data.map(e => sum += e.value ? e.value : 0)

        setCnt(sum)
    }, [data])

    return(
        <div style={{width: w, height: "250px", marginLeft: ""}}>
            <div style={theme === 'light' ? {color: 'var(--light-color)'} : {}}
                 className={s.chartLabel}>{label} ({cnt} шт.)</div>
            <ResponsivePieCanvas
                data={data}
                margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                onClick={clickEvent && clickEvent}
                innerRadius={0.3}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                // colors={{ scheme: 'paired' }}
                colors={{ scheme: 'set2' }}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.6
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor={theme === 'light' ? 'var(--light-color)' : "#333333"}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor="#333333"
                legends={
                    show_legend ?
                    [{
                        anchor: 'bottom-left',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 42,
                        itemsSpacing: 20,
                        itemWidth: 100,
                        itemHeight: 14,
                        itemTextColor: theme === 'light' ? 'var(--light-color)' : "#333333",
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle'
                    }]
                    : []
                }
            />
        </div>
    )
}