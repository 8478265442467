
export function getCurDate() {
    let now = new Date();
    let date = now.getDate().toString()
    let month = (now.getMonth()+1).toString()
    let year = now.getFullYear().toString()

    if (date.length === 1) date = "0"+date
    if (month.length === 1) month = "0"+month

    return date+"."+month+"."+year;
    //return "11.03.2022";
}

export function convertDateToUcdr(date_str, add_day=0) {
    date_str = date_str.split(".")
    let day = date_str[0]
    let month = date_str[1]
    let year = date_str[2]

    let dt = new Date();
    dt.setFullYear(parseInt(year))
    dt.setMonth(parseInt(month) - 1)

    dt.setDate(parseInt(day) + add_day)
    dt.setHours(0)
    dt.setMinutes(0)
    dt.setSeconds(0)

    day = dt.getDate().toString()
    month = (dt.getMonth() + 1).toString()
    year = dt.getFullYear().toString()

    if (day.toString().length === 1) day = "0"+day
    if (month.toString().length === 1) month = "0"+month

    return year+"-"+month+"-"+day
}

export function getDateTimeFromStr(date_str, add_day=0) {
    date_str = date_str.split(".")
    let day = date_str[0]
    let month = date_str[1]
    let year = date_str[2]

    let dt = new Date();
    dt.setFullYear(parseInt(year))
    dt.setMonth(parseInt(month))
    dt.setDate(parseInt(day) + add_day)
    dt.setHours(0)
    dt.setMinutes(0)
    dt.setSeconds(0)

    return dt
}

export function getDay(date_str) {
    date_str = date_str.split(".")
    return parseInt(date_str[0])
}

export function getDiap(dates_list) {
    console.log(dates_list)
    let start = dates_list[0]
    let end =  dates_list[1]

    // console.log("getDiap", start, end)

    let i = 1
    let cur_date = convertDateToUcdr(start)

    let result = []
    while (cur_date !== convertDateToUcdr(end)) {

        result.push(cur_date)
        cur_date = convertDateToUcdr(start, i++)
    }
    result.push(convertDateToUcdr(end))

    return result
}


export function changeDateTime(oldDate) {
    // TODO: корректировка даты с поправкой на часовой пояс
    let newDate = oldDate

    if (newDate.includes('T')) {
        newDate = newDate.split('T')
        let date = newDate[0]
        let time = newDate[1]
        time = time.split('.')[0]
        newDate = [date, time].join(' ')
    }


    return newDate
}

export const getTDate = (elem) => {
    let result = elem.split("T")
    return result[0]
}

export const getDates = (data, key="call_start_stamp") => {

    let dates = []
    for (const elem of data) {
        let date = elem[key]

        let day = ""
        if (date.includes("T")) {
            day = getTDate(date)
            if (!dates.includes(day)) dates.push(day)
        }
    }

    return dates
}

export const diffDate = (dt, years=0, month=0,days=0, hours=0, minutes=0, seconds=0) => {
    let newDt = new Date()

    newDt.setFullYear(dt.getFullYear() + years)
    newDt.setMonth(dt.getMonth() + month)
    newDt.setDate(dt.getDate() + days)
    newDt.setHours(dt.getHours() + hours)
    newDt.setMinutes(dt.getMinutes() + minutes)
    newDt.setSeconds(dt.getSeconds() + seconds)
    return newDt

}

export const getDashedDate = (date_str, only_day=false) => {
    let data_str = ""
    let time_str = ""
    if (date_str.includes("T")) {
        data_str = date_str.split("T")[0]
        time_str = date_str.split("T")[1]
    } else if (date_str.includes(" ")) {
        data_str = date_str.split(" ")[0]
        time_str = date_str.split(" ")[1]
    } else {
        return ""
    }

    if (time_str.includes(".")) {
        time_str = time_str.split(".")[0]
    }

    let day = data_str.split("-")[2]
    let month = data_str.split("-")[1]
    let year = data_str.split("-")[0]
    let hour = time_str.split(":")[0]
    let minutes = time_str.split(":")[1]
    let seconds = time_str.split(":")[2]

    let dt = new Date();
    dt.setFullYear(parseInt(year))
    dt.setMonth(parseInt(month) - 1)
    dt.setDate(parseInt(day))

    dt.setHours(parseInt(hour))
    dt.setMinutes(parseInt(minutes))
    dt.setSeconds(parseInt(seconds))

    day = dt.getDate().toString()
    month = (dt.getMonth() + 1).toString()
    year = dt.getFullYear().toString()

    if (day.toString().length === 1) day = "0"+day
    if (month.toString().length === 1) month = "0"+month

    if (only_day) return year+"-"+month+"-"+day
    return year+"-"+month+"-"+day+" "+hour+":"+minutes+":"+seconds
}


export const TDateToDate = (date_str) => {
    return new Date(date_str)
}