import React, {useState} from 'react'
import s from './PopupChangePass.module.scss'
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useTheme } from '../../hooks/useTheme'
import {yupResolver} from "@hookform/resolvers/yup";
import {changeOldPasswd, changePasswd, userAuth} from "../../api";

const schema = yup
    .object({
        password: yup.string().required("Password is required"),
        newPassword: yup.string().min(8).max(32).required("Password is required"),
        newPasswordRep: yup.string().min(8).max(32).required("Password is required"),
    })

const PopupChangePass= ({ popup, setPopup, message, abacs, userData, status}) => {
    const theme = useTheme()

    const [errorElem, setErrorElem] = useState("")
    const [errorMes, setErrorMes] = useState("")

    function clearErrors() {
        setErrorElem("")
        setErrorMes("")
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    function saveData(data, auth) {
        if (auth.error) {
            setErrorElem("password")
            setErrorMes("Некорректный пароль")
            return
        }

        // Сохраняем новый пароль
        if (abacs && userData && userData["login"]) {
            changeOldPasswd({
                oldPassword: data["oldPassword"],
                password: data["password"],
                login: userData["login"],
                type: "abacs"
            })
                .then((r) => {setPopup("settingsSuccess"); status(r)})
                .catch((e) => console.log(e.response.data))

        } else {
            changePasswd({
                login: theme.contacts["login"],
                token: auth["token"],
                password: data["newPassword"],
            }).then(() => setPopup("settingsSuccess"))
        }
    }

    const onSubmit = (e) => {
        // Валидируем новые пароли
        if (e.newPassword !== e.newPasswordRep) {
            setErrorElem("newPassword")
            setErrorMes("Пароли не совпадают")
            return
        }

        let passRegExp = /(?=.*[A-Z])(?=.*[_$!])(?=.*[0-9])(?=.*[a-z]).{8,21}/;

        if (!passRegExp.exec(e.newPassword)) {
            setErrorElem("incorrectNewPass")
        } else {
            setErrorElem("")
        }

        // // Проверяем пароль
        if (!abacs)
        userAuth({
            login: theme.contacts["login"],
            password: e["password"]
        }).then(r => saveData(e, r))
        else
            saveData({
                password: e.newPassword,
                oldPassword: e["password"],
            }, {})
    }

  return (
    <div className={popup === "change_password" ? `${s.popup} ${s.active}` : s.popup}>
      <div className={s.blur} onClick={() => setPopup("")}/>
      <div className={s.popupWrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
              <p className={s.subTitle}>Изменение пароля</p>

              <div className={s.formBlock}>
                  {
                      errorElem === "password" ?
                          <div className={s.errorMes}>{errorMes}</div> : null
                  }

                  <p className={s.blockTitle}>Текущий пароль</p>
                  <input
                      type="password"
                      onClick={errorElem === "password" ? clearErrors : null}
                      className={
                          errors.password ? `${s.defaultInput} ${s.error}` : s.defaultInput
                      }
                      {...register('password')}
                  />

              </div>
              <div className={s.formBlock}>
                  {
                      errorElem === "newPassword" ?
                          <div className={s.errorMes}>{errorMes}</div> : null
                  }

                  <p className={s.blockTitle}>Новый пароль</p>
                  <input
                      type="password"
                      onClick={errorElem === "newPassword" ? clearErrors : null}
                      className={
                          errors.newPassword ? `${s.defaultInput} ${s.error}` : s.defaultInput
                      }
                      {...register('newPassword')}
                  />
              </div>
              <div className={s.formBlock}>
                  {
                      errorElem === "newPassword" ?
                          <div className={s.errorMes}>{errorMes}</div> : null
                  }
                  <p className={s.blockTitle}>Повтор пароля</p>
                  <input
                      type="password"
                      onClick={errorElem === "newPassword" ? clearErrors : null}
                      className={
                          errors.newPasswordRep ? `${s.defaultInput} ${s.error}` : s.defaultInput
                      }
                      {...register('newPasswordRep')}
                  />
              </div>
              {
                  errorElem === "incorrectNewPass" ?
                      <div className={s.mainErrorMes}>
                          Пароль должен быть длиной от 8 до 20 символов и состоять из, по крайней мере,
                          одной цифры, одной заглавной буквы, одной строчной буквы и одного символа _$!
                      </div> : null
              }
              <button className={s.button}>Сохранить</button>
          </form>

          {message &&
          <div className={s.AddMessage}>
              {message}
          </div>
          }
      </div>
    </div>
  )
}

export default PopupChangePass
