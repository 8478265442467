import React, {useEffect, useState} from 'react'
import {useTheme} from '../../../../../../../../hooks/useTheme'
import Tippy from '@tippyjs/react'
import 'tippy.js/animations/scale.css'
import s from './NumberBacket.module.scss'
import { useLocation } from 'react-router-dom'

const NumberBacket = ({removeActiveNumber, activeNumbers}) => {
  const {helpText, isHelperOpen, phoneNumers} = useTheme()
  const [localTooltip, setLocalTooltip] = useState(false)
  const [paySize, setPaySize] = useState(0)
  const [abPaySize, setAbPaySize] = useState(0)
    const location = useLocation();

  useEffect(() => {
    if (!helpText) {
      setLocalTooltip(false)
    }
  }, [helpText])

  useEffect(() => {
      let ap = 0;
      let price = 0;

      // if (activeNumbers.length === 1) {
      //     if (activeNumbers[0].CATEGORY && activeNumbers[0].CATEGORY === "Без категории") {
      //         ap = 100
      //         price = 0
      //     }
      // }

      if (location.pathname === "/autocalling" && phoneNumers.length === 0) {
          activeNumbers.map((elem, id) => {ap += (id === 0 && elem.CATEGORY === "Без категории") ? 100 : elem["PRICE"]})
          activeNumbers.map((elem, id) => {price += (id === 0 && elem.CATEGORY === "Без категории") ? 0 : elem["PRICE"]})
      } else {
          activeNumbers.map((elem, id) => {ap += elem["AP"]; price += elem["PRICE"]})
      }
      setAbPaySize(ap)
      setPaySize(price)

  }, [activeNumbers])

  return (
    <div className={s.wrapper}>
      <div className={s.window}>
        {activeNumbers.map((number, index) => {
          return (
            <p className={s.number} key={index}>
              <span className={s.plus} onClick={() => removeActiveNumber(number.NUMER)}>
                -
              </span>
              <span className={s.paragraph} onDoubleClick={() => removeActiveNumber(number.NUMER)}>
                {number.NUMER}
              </span>
            </p>
          )
        })}
      </div>
      <div>
        <div className={s.abonentPayment}>
          <p className={s.abonentParagraph}>
            Абонентская плата - {abPaySize}
            <span className="ruble"> ₽</span> в месяц
          </p>

          <Tippy
            visible={localTooltip}
            animation={'scale'}
            content={
              <div
                style={{
                  background: 'var(--background-gradient-st)',
                  color: 'var(--main-font-color)',
                  padding: '12px',
                  borderRadius: '10px',
                  border: '1px solid var(--border-default)',
                  boxShadow: '0 0 20px 0 var(--border-default)',
                }}
              >
                  Ежемесячная абонентская плата рассчитывается в соответствии с выбранным количеством номеров той
                  или иной категории на основании подписанного договора.
              </div>
            }
          >
            <p
              className={s.mark}
              onClick={() => {
                if (document.body.clientWidth < 1250) {
                  setLocalTooltip(true)
                  isHelperOpen(!helpText)
                }
              }}
              onMouseOver={() => {
                setLocalTooltip(true)
              }}
              onMouseLeave={() => {
                setLocalTooltip(false)
              }}
            >
              ?
            </p>
          </Tippy>
        </div>
        <p>
          Разовый платеж - {paySize} <span className="ruble"> ₽</span>
        </p>
      </div>
    </div>
  )
}

export default NumberBacket
