import React, {useEffect, useState} from 'react'
import DefaultTitle from '../../../../../ui/DefaultTitle/DefaultTitle'
import s from './Numbers.module.scss'
import Cities from './Cities/Cities'
import NumberBlock from './NumberBlock/NumberBlock'
import {useTheme} from "../../../../../hooks/useTheme";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import {CollapseB} from "../../../../../ui/Button/CollapseB";

const Numbers = ({
                     setActiveNumbers,
                     activeNumbers,
                     removeLocalNumber,
                 }) => {
    const {numbersShopPrice, phoneNumers} = useTheme()
    const [activeCities, setActiveCities] = useState([]) // активные города
    const [needNewNumbers, setNeedNewNumbers] = useState(true)

    useEffect(() => {
        if (phoneNumers.length > 0) {
            setNeedNewNumbers(false)
        }
    }, [phoneNumers])

    return (
        <div className={s.numberBlock}>

            <div style={{margin: "25px 0 0 0", display: "flex"}}>
                <DefaultTitle computeResult={numbersShopPrice} title={"Покупка телефонных номеров"} />

                {/*{phoneNumers.length > 0 &&*/}
                {/*<div style={{display: "inline-block", marginLeft: "50px"}}>*/}
                {/*    <CheckBox styleParams="inline"*/}
                {/*              status={needNewNumbers}*/}
                {/*              chStatus={() => setNeedNewNumbers(!needNewNumbers)}/>*/}
                {/*</div>*/}
                {/*}*/}
            </div>
            {phoneNumers.length > 0 && <CollapseB expanded={needNewNumbers} changeStatus={setNeedNewNumbers}/>}

            {/*Если есть номера, мы не заситавляем пользователя купить ещё*/}
            { needNewNumbers &&
            <div>
                <Cities activeCities={activeCities} setActiveCities={setActiveCities}/>
                <NumberBlock
                    setActiveNumbers={setActiveNumbers}
                    activeNumbers={activeNumbers}
                    activeCities={activeCities}
                    removeLocalNumber={removeLocalNumber}
                />
            </div>

            }


        </div>
    )
}

export default Numbers
