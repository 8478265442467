import React, { useEffect, useState } from 'react'
import { useTheme } from '../../hooks/useTheme'
import GlobalSvgSelector from '../GlobalSvgSelector/GlobalSvgSelector'
import { changeVariables } from './changeTheme'
import s from './Header.module.scss'
import Menu from './Menu/Menu'
import MobileMenu from './MobileMenu/MobileMenu'
import UserInfo from './UserInfo/UserInfo'
import {headerMenu} from "../../provider/variables";
import {Link, useSearchParams} from "react-router-dom";
import {setPayStatus} from "../../api";

const Header = ({ activePopup, setActivePopup }) => {
    // theme's storage
    const {logoutFunc, userAccount, contacts} = useTheme()
    const theme = useTheme()
    const [activeMenu, setActiveMenu] = useState(false)
    const [searchParams, ] = useSearchParams();
    const [payEnd, setPayEnd] = useState(false)

    const handleMenu = () => {
        setActiveMenu(!activeMenu)
    }

    const savePayStatus = (orderId, secret_code) => {
        console.log("savePayStatus: ",orderId,secret_code)
        const jsonRequest = {
            "orderId": orderId,
            "status": true,
            "secret_code": secret_code,
        }

        setPayStatus(jsonRequest)
            .then((response) => {
                setActivePopup("successPay")
            })
            .catch((error) => {
                setActivePopup("failPay")
            })
    }
    console.log(payEnd);
    if (searchParams.get("successPay") && searchParams.get("orderId") && !payEnd) {
        console.log("succespay ")
        savePayStatus(searchParams.get("orderId"), searchParams.get("successPay"))
        setPayEnd(true)
    }

    if (searchParams.get("failPay") && !payEnd) {
        setActivePopup("failPay")
        setPayEnd(true)
    }

    const changeTheme = () => {
        theme.setTheme(theme.theme === 'light' ? 'dark' : 'light')
    }

    useEffect(() => {
        if (activeMenu) {
            document.body.classList.add('hidden')
        } else {
            document.body.classList.remove('hidden')
        }
    }, [activeMenu])

    // change theme
    useEffect(() => {
        localStorage.setItem('theme', theme.theme)

        const root = document.querySelector(':root')

        changeVariables(root, theme.theme)
    }, [theme.theme])
    return (
        <>
            <header className={s.header}>
                <div className={s.svg}>
                    <a href="/account">
                        {theme.theme !== 'light' ? (
                            <GlobalSvgSelector id="logo-dark" />
                        ) : (
                            <GlobalSvgSelector id="logo" />
                        )}
                    </a>
                </div>
                <ul className={s.menu}>
                    <Menu items={headerMenu} />
                </ul>
                <div className={s.rightSide}>
                    <div className={s.features}>
                        {userAccount.manager &&
                        <Link to={"/mgrs"} className={theme.theme !== 'dark' ? s.darkTheme : ''}>
                            {theme.theme !== 'light' ? (
                                <GlobalSvgSelector id='gear-ico' />
                            ) : (
                                <GlobalSvgSelector id='gear-ico-dark' />
                            )}
                        </Link>
                        }

                        {userAccount.manager &&
                        <p
                            onClick={() => setActivePopup("change_user")}
                            className={theme.theme !== 'dark' ? s.darkTheme : ''}
                        >
                            {theme.theme !== 'light' ? (
                                <GlobalSvgSelector id="cross-user-dark" />
                            ) : (
                                <GlobalSvgSelector id="cross-user-light" />
                            )}
                        </p>
                        }

                        <p
                            onClick={changeTheme}
                            className={theme.theme !== 'dark' ? s.darkTheme : ''}
                        >
                            {theme.theme !== 'light' ? (
                                <GlobalSvgSelector id="theme-switcher" />
                            ) : (
                                <GlobalSvgSelector id="theme-switcher-dark" />
                            )}
                        </p>

                        {!userAccount.virtual && !userAccount.fusion_auth &&
                        <Link to={"/settings"} className={theme.theme !== 'dark' ? s.darkTheme : ''}>
                            {theme.theme !== 'light' ? (
                                <GlobalSvgSelector id="profile" />
                            ) : (
                                <GlobalSvgSelector id="profile-dark" />
                            )}
                        </Link>
                        }


                        <Link to={"/faq"} className={theme.theme !== 'dark' ? s.darkTheme : ''}>
                            {theme.theme !== 'light' ? (
                                <GlobalSvgSelector id="faq-ico" />
                            ) : (
                                <GlobalSvgSelector id="faq-ico-dark" />
                            )}
                        </Link>
                    </div>
                    <a href="/#" className={s.help} onClick={logoutFunc}>
                        Выйти
                    </a>
                </div>

                {/* Mobile */}
                <div className={s.menuBurger} onClick={handleMenu}>
                    {theme.theme !== 'light' ? (
                        <GlobalSvgSelector id="burger" />
                    ) : (
                        <GlobalSvgSelector id="burger-dark" />
                    )}
                </div>
                <MobileMenu
                    items={headerMenu}
                    handleMenu={handleMenu}
                    changeTheme={changeTheme}
                    activeMenu={activeMenu}
                />
            </header>
            <main>
                <section>
                    {
                        theme.contacts ?
                            <div className={s.titleBar}>
                                <h1>Добро пожаловать, {theme.contacts["name"]}</h1>
                                <p>(ПИН {theme.contacts["login"]})</p>
                            </div>: null
                    }
                    <UserInfo popup={activePopup} setPopup={setActivePopup} />
                </section>
            </main>
        </>
    )
}

export default Header
