import React from 'react'
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'
import Checkbox from './Checkbox/Checkbox'
import s from './Filters.module.scss'

const Filters = ({ filters, handleChangeFilters, activeFilters }) => {
  const handleCheck = (id) => {
    if (activeFilters.indexOf(id) > -1) {
      let array = [...activeFilters]
      array.splice(activeFilters.indexOf(id), 1)
      handleChangeFilters(array)
    } else {
      handleChangeFilters([...activeFilters, id])
    }
  }

  const isActive = (id) => {
    return activeFilters.indexOf(id) > -1
  }

  return (
    <div className={s.filters}>
      <div className={s.flex}>
        <div className={s.title}>
          <DefaultTitle title="Фильтры:" />
        </div>
      </div>
      <div className={s.filterWrapper}>
        {filters.map((filter) => {
          return (
            <Checkbox
              {...filter} key={filter.id} handleClick={handleCheck}
              isChecked={activeFilters.length && isActive(filter.title)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Filters
