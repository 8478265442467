import React, {useEffect, useState} from 'react'
import GlobalSvgSelector from '../../components/GlobalSvgSelector/GlobalSvgSelector'
import s from './PopupDeposit.module.scss'
import { useTheme } from '../../hooks/useTheme'
import {thisUrl} from "../../provider/variables";
import {getPayUrlApi} from "../../api";

const PopupDeposit = ({ popup, setPopup }) => {
    const { existsDrives, shopPhonePrice, shopVoipPrice, settings, balance, shopList } = useTheme()
    const [curUsl, setCurUsl] = useState("")
    const [amount, setAmount] = useState(100)
    const [verified, setVerified] = useState(false)

    const {userAccount} = useTheme()

    useEffect(() => {
        // setVerified(settings?.verified || false)
        setVerified(settings?.verified || true)
    }, [settings])

    useEffect(() => {
        if (!existsDrives || existsDrives.length === 0) return
        let allowDrive = ["voip", "phone", "internet", "b-seti"]
        for (const elem of allowDrive) {
            if (existsDrives.includes(elem)) {
                setCurUsl(elem)
                return
            }
        }
    }, [existsDrives])

    useEffect(() => {
        if (amount.toString().includes(".")) {
            setAmount(Math.ceil(amount))
        }
    }, [amount])


    const openPay = (val) => {
        console.log("Функция openPay вызвана. Переданные данные:", val);
        // const confirmationUrl = val.confirmation.confirmation_url;

        console.log(val);
        // Проверяем, есть ли formUrl в объекте val
        // if (!confirmationUrl) {
        if (!val["formUrl"]) {
            console.warn("formUrl отсутствует в переданных данных. Ничего не делаем.");
            window.open(val["formUrl"]);
            return;
        }

        console.log("formUrl найден. Открываем URL:", val["formUrl"]);

        // Попытка открытия URL в новом окне
        try {
            window.open(val["formUrl"]);
            console.log("Окно с formUrl успешно открыто.");
        } catch (error) {
            console.error("Ошибка при попытке открыть formUrl:", error);
        }
    };

    const getUrl = (e) => {
        e.preventDefault()

        if (!userAccount) return;
        const jsonRequest = {
            login: userAccount.login,
            token: userAccount.token,
            service: curUsl,
            amount: amount,
            payService: "sber",
            returnUrl: thisUrl+"?successPay=",
            failUrl: thisUrl+"?failPay=true",

            shopAmounts: {
                "voip": shopVoipPrice,
                "phone": shopPhonePrice
            }

        }

        getPayUrlApi(jsonRequest)
            .then(result => openPay(result))
            .catch(error => console.log("error", error))
    }

    useEffect(() => {
        if (popup.includes("shop"))
            setCurUsl("shop")
    }, [popup])


    useEffect(() => {

        let val = 100;
        if (curUsl === "voip") val = balance?.balance_v || 100
        else if (curUsl === "phone") val = balance?.balance_p || 100
        else if (curUsl === "b-seti") val = balance?.balance_s || 100
        else if (curUsl === "internet") val = balance?.balance_i || 100

        if (val > 0) {
            val = 100
        } else {
            val *= -1
        }

        if (curUsl === "shop" && shopPhonePrice && shopVoipPrice) val = shopPhonePrice + shopVoipPrice
        setAmount(val)

    }, [curUsl])

    return (
        <div className={popup.includes("payments") ? `${s.popup} ${s.active}` : s.popup}>
            <div className={s.blur} onClick={() => setPopup("")}/>
            <div className={s.popupWrapper}>
                <p className={s.title}>Выберите услугу</p>
                <div className={s.flex}>
                    {existsDrives.includes("phone") && !popup.includes("shop") &&
                        <div className={s.item}>
                            <span> <GlobalSvgSelector id="telephonia" /> </span>
                            <label className={s.text} htmlFor="usl">
                                <div>
                                    <p className={s.textTitle}>Телефония</p>
                                    <p className={s.textDescription}>(Приложение к договору ЮТФ)</p>
                                </div>
                                <input type="radio" name="usl" id="usl" className={s.radio}
                                       checked={curUsl === "phone"}
                                       onChange={() => setCurUsl("phone")}/>
                            </label>
                        </div>
                    }

                    {existsDrives.includes("voip") && !popup.includes("shop") &&
                            <div className={s.item}>
                                <span><GlobalSvgSelector id="intercity" /></span>
                                <label className={s.text} htmlFor="usl1">
                                    <div>
                                        <p className={s.textTitle}>Межгород</p>
                                        <p className={s.textDescription}>(Приложение к договору ЮТЛ)</p>
                                    </div>
                                    <input type="radio" name="usl" id="usl1" className={s.radio}
                                           checked={curUsl === "voip"}
                                           onChange={() => setCurUsl("voip")}/>
                                </label>
                            </div>
                    }

                    {((existsDrives.includes("internet") && !popup.includes("shop")) || balance?.balance_i > 0) &&
                            <div className={s.item}>
                                <span><GlobalSvgSelector id="internet" /></span>
                                <label className={s.text} htmlFor="usl2">
                                    <div>
                                        <p className={s.textTitle}>Интернет</p>
                                        <p className={s.textDescription}>(Приложение к договору ЮИН)</p>
                                    </div>
                                    <input type="radio" name="usl" id="usl2" className={s.radio}
                                           checked={curUsl === "internet"}
                                           onChange={() => setCurUsl("internet")}/>
                                </label>
                            </div>
                    }

                    {existsDrives.includes("b-seti") && !popup.includes("shop") &&
                            <div className={s.item}>
                                <span><GlobalSvgSelector id="b-seti" /></span>
                                <label className={s.text} htmlFor="usl3">
                                    <div>
                                        <p className={s.textTitle}>Каналы связи</p>
                                        <p className={s.textDescription}>(Приложение к договору ЮМС)</p>
                                    </div>
                                    <input type="radio" name="usl" id="usl3" className={s.radio}
                                           checked={curUsl === "b-seti"}
                                           onChange={() => setCurUsl("b-seti")}/>
                                </label>
                            </div>
                    }

                    {shopList.fullPrice > 0 &&
                        <div className={s.item}>
                            <span><GlobalSvgSelector id="intercity" /></span>
                            <label className={s.text} htmlFor="shop">
                                <div style={{minWidth: "197px"}}>
                                    <p className={s.textTitle}>Магазин Telezon</p>
                                </div>
                                <input type="radio" name="usl" id="usl3" className={s.radio}
                                       checked={curUsl === "shop"}
                                       onChange={() => setCurUsl("shop")}/>
                            </label>
                        </div>
                    }

                </div>
                <p className={s.title}>Выберите сумму</p>
                <input className={s.input} type="text" placeholder="100" value={amount ? amount : 0}
                       readOnly={curUsl === "shop"}
                       onChange={(e) => setAmount(parseFloat(e.target.value))}/>
                {curUsl && verified &&
                    <div className={s.btn}>
                        <button><a href="/#" onClick={getUrl}>Пополнить</a></button>
                    </div>
                }

                {!verified &&
                <div className={s.wrongText}>
                    <p>Оплата по банковской карте недоступна для неверифицированных абонентов.
                        Для верификации аккаунта обратитесь к персональному менеджеру или в техническую поддержку
                    </p>
                </div>
                }
                <p className={s.link}>
                    {/*<a href="/#">Описание процесса оплаты</a>*/}
                </p>
                <div className={s.icons}>
          <span className={s.visa}>
            <GlobalSvgSelector id="visa" />
          </span>
                    <span className={s.mastercard}>
            <GlobalSvgSelector id="mastercard" />
          </span>
                    <span className={s.mir}>
            <GlobalSvgSelector id="mir" />
          </span>
                </div>
            </div>
        </div>
    )
}

export default PopupDeposit
