import React, {useEffect, useState} from 'react'
import { Controller, useForm } from 'react-hook-form'
import s from './Form.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTheme } from '../../../../hooks/useTheme'
import Select from 'react-select'
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'

import {saveSettings, saveMailingPlans} from "../../../../api"
import CheckBox from "../../../../ui/CheckBox/CheckBox";

const schema = yup
    .object({
        balance_i: yup.number(),
        balance_v: yup.number(),
    })
    .required()

export const MailingsForm = ({ setActivePopup }) => {
    const theme = useTheme()
    const [activeMailings, setActiveMailings] = useState()
    const [mailingsMenuIsOpen, openMailingsMenu] = useState(false)
    const [isMailingsActive, setMailingsActive] = useState(false)

    const customStyles = {
        control: () => ({
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            cursor: 'pointer',
        }),
        placeholder: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            opacity: '.3',
        }),
        menuList: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            position: 'absolute',
            zIndex: 1000,
            width: '100%',
            borderRadius: '10px',
            top: 'calc(100% + 12px)',
            // overflow: 'hidden',
            background:
                theme === 'light' ? 'var(--night-color)' : 'var(--light-color)',
        }),
        menu: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        option: () => ({
            background: 'none',
            padding: '5px',
            fontSize: '16px',
            cursor: 'pointer',

            '&:hover': {
                background: '#3a3a3a',
                color: 'var(--light-color)',
            },
        }),
        singleValue: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--main-font-color)',
            backgroundColor: 'var(--main-font-color)',
        }),
        input: () => ({
            display: 'none',
        }),
    }

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            balance_i: "",
            balance_v: "",
        }
    })

    const onSubmit = (data) => {
        // Сохраняем настройки
        saveSettings({
            login: theme.userAccount.login,
            token: theme.userAccount.token,
            mail_v: data.balance_v,
            mail_i: data.balance_i,
            mailings_active: isMailingsActive,
        })
            .then(() => setActivePopup("settingsSuccess"))
            .catch(() => setActivePopup("failSave"))

        // Сохраняем периоды рассылки
        if (activeMailings) {
            saveMailingPlans({
                login: theme.userAccount["login"],
                token: theme.userAccount.token,
                mailPlans: activeMailings.map(v => v.value)
            })
                .then((r) => console.log("success save mailing plans", r))
                .catch(e => console.log("error save mailing plans", e))
        }
    }

    useEffect(() => {
        if (!theme || !theme.mailingsPlans || !theme.mailings) return

        setActiveMailings(
            theme.mailingsPlans.filter(v => theme.mailings.map(v => v["target"]).includes(v["value"]))
        )
    }, [theme.mailingsPlans, theme.mailings])

    useEffect(() => {
        if (!theme.settings) return

        setMailingsActive(theme.settings.mailings_active)
        if (theme.settings) {
            reset({
                balance_i: theme.settings.mail_i,
                balance_v: theme.settings.mail_v,
            })
        }

        }, [theme.settings]
    )

    return (
        <>
            <div className={s.header}>
                <div className={s.title}>
                    <DefaultTitle title="Настройка рассылок" />
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={s.formBlock}>
                    <p className={s.blockTitle} style={{fontWeight: "bold"}}>Активность рассылок</p>
                    <div style={{display: "inline-block"}}>
                        <CheckBox styleParams="inline"
                                  checked={isMailingsActive}
                                  chStatus={(status) => setMailingsActive(status)}/>
                    </div>
                </div>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>
                        Сумма баланса для оповещения по услуге интернет
                    </p>
                    <input
                        // defaultValue={theme.settings ? theme.settings["mail_i"] : ""}
                        // value={theme.settings["mail_i"]}
                        type="text"
                        className={
                            errors.balance_i
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        {...register('balance_i')}
                    />
                </div>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>
                        Сумма баланса для оповещения по услуге межгород
                    </p>
                    <input
                        // defaultValue={theme.settings ? theme.settings["mail_v"] : ""}
                        type="text"
                        className={
                            errors.balance_v
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        {...register('balance_v')}
                    />
                </div>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Период рассылки</p>
                    <div
                        className={`${s.defaultInput} ${s.noPadding}`}
                        onClick={() => {
                            openMailingsMenu(!mailingsMenuIsOpen)
                        }}
                    >
                        <Controller
                            control={control}
                            values={theme.mailingsPlans}
                            name="mailings"
                            rules={{required: true}}
                            render={({field: {onChange}}) => (
                                <Select
                                    onChange={setActiveMailings}
                                    options={theme.mailingsPlans}
                                    styles={customStyles}
                                    placeholder={'Ежедневно'}
                                    isClearable
                                    isMulti
                                    isSearchable
                                    menuIsOpen={mailingsMenuIsOpen}
                                    value={activeMailings}
                                />
                            )}
                        />
                    </div>
                </div>

                <button className={`${s.button} ${s.floatR}`} style={{marginTop: "25px"}}>Сохранить</button>
            </form>
        </>
    )
}
