import {useTheme} from "../../hooks/useTheme";
import React, {useEffect, useState} from "react";
import s from "./SmsHistoryPopup.module.scss"
import {getDashedDate, TDateToDate} from "../../provider/dates";
import DefaultButton, {DownloadButton} from "../Button/Button";
import {smsHistoryHeaders, smsHistoryHeadersAero} from "../../provider/smsVariables";
import {getSmsAeroHistory, getSmsHistory} from "../../api-sms";
import Loading from "../Loading/Loading";


const SmsHistoryStatisticsAero = (props) => {
    const [statuses, setStatuses] = useState([])

    useEffect(() => {
        // console.log(props?.statistic)
        let statusesList = [{name: "Все", count: props?.statistic?.length}]
        for (const elem of props?.statistic || []){
            // console.log(elem)
            if (!statusesList.find(e => e?.name === elem?.status)) {
                // statusesList.push(elem?.status)
                statusesList.push(
                    {name: elem?.status, count: 1}
                )
            } else {
                let elemInList = statusesList.find(e => e?.name === elem?.status)
                elemInList.count += 1
            }
            // break
        }
        setStatuses([...statusesList])
    }, [props?.statistic])

    return(
        <div className={s.statistics}>
            {statuses.map(status =>
                <span onClick={() => props.filter(status?.name)}
                      className={props.filters.find(e => e === status?.name) ? s.active : null}>
                    {status?.name}: {status?.count}
                </span>
            )}
        </div>
    )
}

const SmsHistoryStatistics = (props) => {
    return(
        <div className={s.statistics}>
            <span onClick={() => props.filter("all")} className={props.filters.includes("all") ? s.active : null}>
                Всего: {props.statistic.all}
            </span>
            <span onClick={() => props.filter("delivered")} className={props.filters.includes("delivered") ? s.active : null}>
                Доставлено: {props.statistic.delivered}
            </span>
            <span onClick={() => props.filter("undelivered")} className={props.filters.includes("undelivered") ? s.active : null}>
                Недоставлено: {props.statistic.undelivered}
            </span>
            <span onClick={() => props.filter("error")} className={props.filters.includes("error") ? s.active : null}>
                Ошибка отправки: {props.statistic.error}
            </span>
            <span onClick={() => props.filter("rejected")} className={props.filters.includes("rejected") ? s.active : null}>
                Отклонено: {props.statistic.rejected}
            </span>
            <span onClick={() => props.filter("other")} className={props.filters.includes("other") ? s.active : null}>
                Другое: {props.statistic.other}
            </span>
        </div>
    )
}

const SmsHistoryListAero = (props) => {
    const [startElem, setStartElem] = useState(0)
    const [expandedText, setExpandedText] = useState(null)
    const textSliced = 40
    const sliceSize = 10

    return(
        <div className={s.smsList}>
            <table>
                <thead>
                <tr>
                    {smsHistoryHeadersAero.map(h => <th key={h.key}>{h.label}</th>)}
                </tr>
                </thead>
                <tbody>
                {props.data.slice(startElem, startElem + sliceSize).map((elem, index) =>
                    <tr key={elem?.sms_id}>
                        {smsHistoryHeadersAero.map(h =>
                            (
                                <td key={`${elem?.sms_id}-${h.key}`} className={`${h.data_type === "dt" ? s.datetime : s.text}`} style={{
                                    width: h?.key === "text" ? "500px" : "inherit"
                                }}
                                >
                                    {(h.data_type === "text" && elem[h.key]) &&
                                    <span>
                                        {expandedText === `${elem?.sms_id}-${h.key}`
                                            ? elem[h.key]
                                            : elem[h.key].toString()?.length > 0 && elem[h.key].toString().slice(0, textSliced)
                                        }
                                    </span>
                                    }

                                    {(h.data_type === "text" && elem[h.key]
                                        && elem[h.key].toString()?.length > textSliced
                                        && expandedText !== `${elem?.sms_id}-${h.key}`
                                    ) &&
                                    <span className={s.aMore}
                                       onClick={() => setExpandedText(`${elem?.sms_id}-${h.key}`)}>
                                        ... ещё
                                    </span>
                                    }

                                    {h.data_type === "dt" && <span>{getDashedDate(elem[h.key])}</span>}
                                </td>
                            )
                        )}
                    </tr>
                )}
                </tbody>
            </table>

            <div>Сообщения с {startElem + 1}&nbsp;
                по {startElem + 1 + sliceSize <= props.data.length ? startElem + sliceSize : props.data.length}.
                Всего: {props.data.length}</div>

            <div className={s.buttons}>
                {startElem > 0 &&
                <DefaultButton text={"Назад"} clickEvent={() => setStartElem(startElem - sliceSize)}/>
                }

                {(startElem + sliceSize < props.data.length) &&
                <DefaultButton text={"Ещё"} clickEvent={() => setStartElem(startElem + sliceSize)}/>
                }
            </div>

        </div>
    )
}

const SmsHistoryList = (props) => {
    const [startElem, setStartElem] = useState(0)
    const sliceSize = 10

    return(
        <div className={s.smsList}>
            <table>
                <thead>
                    <tr>
                        {smsHistoryHeaders.map(h => <th key={h.key}>{h.label}</th>)}
                    </tr>
                </thead>
                <tbody>
                {props.data.slice(startElem, startElem + sliceSize).map(elem =>
                    <tr key={elem.id}>
                        {smsHistoryHeaders.map(h =>
                            <td className={
                                h.data_type === "dt"
                                    ? s.datetime
                                    : s.text
                            } key={h.key}>
                                {h.data_type === "text" && <span>{elem[h.key]}</span>}
                                {h.data_type === "dt" && <span>{getDashedDate(elem[h.key])}</span>}
                                {h.data_type === "dict" && <span>{elem[h.key][h.dict_key]}</span>}
                            </td>
                        )}
                    </tr>
                )}
                </tbody>
            </table>

            <div>Сообщения с {startElem + 1}&nbsp;
                по {startElem + 1 + sliceSize <= props.data.length ? startElem + sliceSize : props.data.length}.
                Всего: {props.data.length}</div>

            <div className={s.buttons}>
                {startElem > 0 &&
                <DefaultButton text={"Назад"} clickEvent={() => setStartElem(startElem - sliceSize)}/>
                }

                {startElem + sliceSize < props.data.length &&
                <DefaultButton text={"Ещё"} clickEvent={() => setStartElem(startElem + sliceSize)}/>
                }
            </div>

        </div>
    )
}

export const SmsHistoryPopup = (props) => {
    const theme = useTheme()
    const [smsData, setSmsData] = useState({})

    const [loadingStatus, setLoadingStatus] = useState(true)
    const [filters, setFilters] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        if (!theme.userAccount || !theme.userAccount.login || !props.show) return
        setLoadingStatus(true)
        if (theme.smsSettings?.service === "telezon") {
            getSmsHistory({...theme.userAccount})
                .then(r => {
                    setSmsData(r);
                    setLoadingStatus(false)
                })
                .catch(e => {
                    console.log(e)
                    setLoadingStatus(false)
                })
        } else if (theme.smsSettings?.service === "aero") {
            let newData = []
            let lastSmsId = -1
            const parseAeroData = (r) => {
                let nextPage = r?.next
                let d = []
                for (const dataElem of r?.results) {
                    let e = {...dataElem}
                    if (!dataElem?.sms_id) {
                        e.sms_id = lastSmsId
                        lastSmsId -= 1
                    }
                    d.push(e)
                }
                newData = [...newData, ...d]

                // console.log(r)
                // console.log(newData?.length)
                if (nextPage?.length > 0) {
                    getSmsAeroHistory({...theme.userAccount, url: nextPage})
                        .then(r => parseAeroData(r))
                        .catch(e => {
                            console.log(e)
                            setLoadingStatus(false)
                            setSmsData([...newData])
                        })
                } else {
                    setLoadingStatus(false)
                    setSmsData([...newData])
                }
            }
            getSmsAeroHistory({...theme.userAccount})
                .then(r => parseAeroData(r))
                .catch(e => {
                    console.log(e)
                    setLoadingStatus(false)
                })
        }


    }, [theme.userAccount, props.show, theme.smsSettings])

    useEffect(() => {
        if (filters?.length === 0) {
            let sortedData = []
            if (theme.smsSettings?.service === "telezon") {
                sortedData = smsData?.data?.results || []
                sortedData = sortedData?.sort(
                    (a, b) => TDateToDate(a?.created_at) < TDateToDate(b?.created_at) ? 1 : -1
                )

            } else if (theme.smsSettings?.service === "aero") {
                sortedData = smsData || []
                sortedData = sortedData?.sort(
                    (a, b) => TDateToDate(a?.date_created) < TDateToDate(b?.date_created) ? 1 : -1
                )
            }
            setFilteredData([...sortedData])

        } else if (theme.smsSettings?.service === "telezon") {
            let fData = smsData?.data?.results.filter(e => filters.includes(e?.status?.id))
            fData = fData.sort(
                (a, b) => TDateToDate(a?.created_at) < TDateToDate(b?.created_at) ? 1 : -1
            )
            if (filters?.includes("other")) {
                let statuses = ["delivered", "undelivered", "error", "rejected"]
                fData = [...fData, ...smsData?.data?.results.filter(e => !statuses.includes(e?.status?.id))]
            }
            setFilteredData([...fData])
        } else if (theme.smsSettings?.service === "aero") {
            let sortedData = smsData || []
            sortedData = sortedData.sort(
                (a, b) => TDateToDate(a?.date_created) < TDateToDate(b?.date_created) ? 1 : -1
                )
            if (filters?.includes("Все")) {
                setFilteredData([...sortedData])
            } else {
                setFilteredData([...sortedData?.filter(e => filters.includes(e?.status))])
            }
        }
    }, [filters, smsData])

    const filterRaws = (col) => {
        if (col === "all" || col === "Все") {
            setFilters([])
            return
        }
        if (!filters.includes(col)) {
            setFilters([...filters, col])
        } else {
            setFilters([...filters.filter(c => c !== col)])
        }
    }

    return (
        <div style={{
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.5)",
            display: props.show ? "block" : "none",
        }} onClick={(v) => props.hide(!props.show)}>
            <div style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                color: "#555",
                backgroundColor: "white",
                minWidth: smsData?.data?.results?.length > 0 ? "700px"  : "550px",
                minHeight: smsData?.data?.results?.length > 0 ? "420px" : "50px",
                maxWidth: "700px",
                padding: "25px",
                textAlign: "center",
                borderBottom: "1px solid #dbdbdb",
                borderRadius: "10px",
                boxShadow: "unset 0 4px 0 rgb(0 0 0 / 8%)",
            }} onClick={(e) => e.stopPropagation()} className={s.animation}>

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "35px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                    color: props.error ? "red": "rgb(85, 85, 85)"
                }}>
                    История SMS-сообщений
                    <div className={s.buttonsTop}>
                        {/*<DefaultButton text={"⇓"} clickEvent={() => props.hide()} alter={"Скачать в Excel"}/>*/}
                        {(filteredData?.length > 0
                            && ["aero", "telezon"].includes(theme.smsSettings?.service)) &&
                        <DownloadButton data={filteredData}
                                        columns={
                                            theme.smsSettings?.service === "aero"
                                                ? smsHistoryHeadersAero
                                            : theme.smsSettings?.service === "telezon"
                                                ? smsHistoryHeaders
                                            : null
                                        }
                                        alter={"Скачать в Excel"}
                                        file={"история SMS.csv"}/>
                        }

                        <div>
                            <DefaultButton text={"╳"} clickEvent={() => props.hide()} alter={"Закрыть окно"}/>
                        </div>

                    </div>

                </div>
                <div className={loadingStatus ? `${s.flexCenter} ${s.h250}` : null}>
                    {loadingStatus &&
                        <Loading />
                    }

                    {(smsData?.data?.results?.length > 0 && props.show && !loadingStatus) &&
                        <div>
                            {(smsData?.data?.statistic && theme.smsSettings?.service === "telezon") &&
                            <SmsHistoryStatistics
                                statistic={smsData.data.statistic} filter={filterRaws} filters={filters}/>
                            }

                            {(smsData?.data?.statistic && theme.smsSettings?.service === "adero") &&
                            <SmsHistoryStatisticsAero
                                statistic={smsData.data.statistic} filter={filterRaws} filters={filters}/>
                            }

                            <SmsHistoryList data={filteredData} filters={filters}/>
                        </div>
                    }

                    {(smsData?.length > 0 && theme.smsSettings?.service === "aero" && !loadingStatus) &&
                    <div>
                        <SmsHistoryStatisticsAero
                            statistic={smsData} filter={filterRaws} filters={filters}/>

                        <SmsHistoryListAero data={filteredData} filters={filters}/>
                    </div>
                    }

                    {(smsData?.data?.results?.length === 0 && props.show && !loadingStatus) &&
                    <div>В истории нет сообщений</div>
                    }

                </div>

            </div>

        </div>

    )
}