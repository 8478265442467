import React, {useEffect, useState} from 'react'
import s from "./VoipDetailPage.module.scss"
import {phoneHeader, voipHeader} from "../../../../../provider/variables";
import GlobalSvgSelector from "../../../../GlobalSvgSelector/GlobalSvgSelector";
import {TableRows} from "../TableRow/TableRow";
import DefaultButton from "../../../../../ui/Button/Button";
import {
    addDayToDate,
    getDateFromStr, getDateFromStrDetail,
    getDateStrFromDMYHMstr,
} from "../../../../../sortFunction";
import PaginationBlock from "../../../../../ui/PaginationBlock/PaginationBlock";
import Loading from "../../../../../ui/Loading/Loading";
import {CSVLink} from "react-csv";
import {GetApp} from "@mui/icons-material";



export const VoipDetailPage = ({loadData, callData, paginationSize, valueEnd, valueStart, status}) => {
    const [filteredRows, setFilteredRows] = useState([])
    const [activeRows, setActiveRows] = useState([])

    const [sortStatus, setSortStatus] = useState(false)
    const [sortKey, setSortKey] = useState("date")
    const [curPage, setCurPage] = useState(1)
    const [pageSize, setPageSize] = useState(1)
    const [filters, setFilters] = useState({})
    const [allDuration, setAllDuration] = useState(0)
    const [allSum, setAllSum] = useState(0)
    const [exportData, setExportData] = useState([])

    useEffect(() => {
        if (!paginationSize || !paginationSize.value) return

        setPageSize(paginationSize.value)
    }, [paginationSize])

    const [curFilterSequence, setCurFilterSequence] = useState([])

    const sortData = (key) => {
        setSortKey(key)
        setSortStatus(!sortStatus)
    }

    useEffect(() => {
        // Сортируем
        if (sortStatus) {
            if (sortKey === "date")
                filteredRows.sort((a, b) =>
                    getDateFromStr(a[sortKey]) > getDateFromStr(b[sortKey]) ? 1 : -1)
            else
                filteredRows.sort((a, b) =>
                    a[sortKey] > b[sortKey] ? 1 : -1)
        } else {
            if (sortKey === "date")
                filteredRows.sort((a, b) =>
                    getDateFromStr(a[sortKey]) < getDateFromStr(b[sortKey]) ? 1 : -1)
            else
                filteredRows.sort((a, b) =>
                    a[sortKey] < b[sortKey] ? 1 : -1)
        }

        // пагинируем
        setActiveRows([...filteredRows.slice(
            pageSize * curPage - pageSize,
            pageSize * curPage,
        )])
    }, [filteredRows, sortKey, sortStatus])

    useEffect(() => {
        if (!callData) return

        setFilteredRows([...filterData(callData)])
    }, [curPage, pageSize, filters, callData])

    const getFilters = (e) => {
        setCurFilterSequence([])
        let f = [{label: "Нет", value: "Нет", k: e}]
        let prev = []
        let names = {}
        for (const [_, elem] of Object.entries(filteredRows)) {
            let label = elem[e]
            let val = elem[e]

            if (e === "CONNECT_TIME") {
                label = getDateStrFromDMYHMstr(label)
                val = getDateStrFromDMYHMstr(val)
            }
            if (!prev.includes(val)) prev.push(val)
            names[val] = label
        }
        prev.sort()

        setCurFilterSequence(
            [...f, ...prev.map(elem => ({label: elem, value: names[elem], k: e}))])
    }

    const addFilter = (val) => {
        setCurPage(1)

        let new_f = filters
        // фильтры таблицы
        if (val.k && val.label) {
            if (!Object.keys(new_f).includes(val.k)) {
                new_f[val.k] = []
            }

            if (val.value === "Нет") {
                new_f[val.k] = []
            } else if (new_f[val.k].includes(val.label)) {

            } else {
                new_f[val.k] = val.label
            }

            if (new_f[val.k].length === 0) {
                delete new_f[val.k]
            }
        }

        if (val.k.includes("DURATION")) {
            let key = val.k
            let value = val.value

            if (!value.length) delete new_f[key]
            else {
                new_f[key] = value
            }
        }

        setFilters({...new_f})
    }

    const filterData = (data) => {
        let endDate = new Date(valueEnd)
        endDate = addDayToDate(endDate, 1)
        let startDate = valueStart
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)

        let activeSequence = [...data]

        // Фильтрация по дате
        // console.log(startDate, endDate, activeSequence.length)
        // activeSequence.map(e => console.log(e.date, valueStart, getDateFromStr(e.date) >= valueStart))
        activeSequence = activeSequence.filter(
            e => ((getDateFromStrDetail(e.CONNECT_TIME) >= startDate) && (getDateFromStrDetail(e.CONNECT_TIME) <= endDate))
        )

        // Фильтрация по полям таблицы
        //const f2 = {status: 'Обработан', comment: 'ЛС'}
        // console.log(filters)
        for (const key of Object.keys(filters)) {
            let val = filters[key]

            if (key === "CONNECT_TIME") {
                activeSequence = activeSequence.filter(
                    e => e[key].toLowerCase().includes(val.toLowerCase()))
            } else if (key.includes("DURATION")) {
                if (key.includes("from")) {
                    activeSequence = activeSequence.filter(
                        e => e["DURATION"] >= val)
                } else if (key.includes("to")) {
                    activeSequence = activeSequence.filter(
                        e => e["DURATION"] <= val)
                }

            } else {
                activeSequence = activeSequence.filter(
                    e => e[key] && e[key].toString().toLowerCase().includes(val.toString().toLowerCase()))
            }
        }

        let dur = 0
        let sum = 0
        activeSequence.map(e => dur += e.DURATION)
        activeSequence.map(e => sum += e.PRICE)

        setAllDuration((dur / 60).toFixed(2))
        setAllSum(sum.toFixed(2))
        setExportData([...formatData(activeSequence)])

        return activeSequence
    }

    const formatData = (rows) => {
        let data = []
        let headers = voipHeader.map(elem => elem["key"])

        for (const [_, elem] of Object.entries(rows)) {
            let sub_data = []

            for (const [_, key] of Object.entries(headers)) {
                let val = ""
                if (elem[key]) {
                    val = elem[key]
                }

                if (key === "PRICE") {
                    val = val.toString()
                    val = val.replace(".", ",")
                }
                if (key === "TARIFF") {
                    val = val.toString()
                    val = val.replace(".", ",")
                }

                sub_data.push(val)
            }

            data.push(sub_data)
        }

        return data
    }


    return(
        <div className={s.main}>
            <div className={s.flexButtons} style={{marginBottom: "10px"}}>
                {activeRows.length > 0 &&
                <CSVLink data={exportData} className={s.csvLink}
                         headers={phoneHeader.map(elem => elem["label"])}
                         separator=";"
                         filename={"detail.csv"}
                         data-tooltip="Скачать в csv">
                    <button className={[s.btn, s.btnDownload].join(" ")}>
                        <GetApp className={s.SvgElem}/> в csv
                    </button>
                </CSVLink>
                }

                <DefaultButton text={"Загрузить"} clickEvent={loadData}/>
            </div>

            <table className={s.table}>
                <thead>
                <tr className={s.tableHead}>
                    {voipHeader.map(
                        string =>
                            <th key={string.key} onClick={() => sortData(string.key)} >
                                <span style={{maxWidth: "100px"}}>{string.label}</span>


                                {sortStatus && sortKey === string.key &&
                                <span>
                                    <GlobalSvgSelector id="select-arrow" />
                                </span>
                                }

                                {!sortStatus && sortKey === string.key &&
                                <span className={s.rotate}>
                                    <GlobalSvgSelector id="select-arrow" />
                                </span>
                                }
                            </th>
                    )}

                </tr>
                </thead>
                <tbody className={s.tbody}>
                    <TableRows
                        data={activeRows}
                        getFilters={getFilters}
                        addFilter={addFilter}
                        curFilterSequence={curFilterSequence}
                        headers={voipHeader}
                    />
                </tbody>
            </table>

            {status === false &&
            <div className={s.loadingDiv}>
                <Loading />
            </div>
            }

            {activeRows && activeRows.length > 0 && status &&
            <div className={s.summaryValues}>
                <div>Общая длительность: {allDuration} мин., Общая сумма: {allSum} руб.</div>
            </div>
            }


            <div className={s.flexFloatLeft}>
                <PaginationBlock curPage={curPage} setCurPage={setCurPage}
                                 callData={filteredRows} paginationSize={pageSize}/>
            </div>

        </div>
    )
}
