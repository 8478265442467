import React from 'react'
import s from './Finances.module.scss'

const Finances = ({ balance, packet }) => {
  return (
    <div className={s.finances}>
      <p className={s.title}>Финансы</p>
      <p className={s.text}>
        Баланс для звонков вне пакета:{' '}
        <span>
          {balance.balance_v.toFixed(2)} <b className={s.ruble}>₽</b>
        </span>
      </p>

      <p className={s.text}>
        В этом месяце потрачено:{' '}
        <span>
          0 <b className={s.ruble}>₽</b>
        </span>
      </p>
      <p className={s.text}>
        Активен до: <span>{packet.last_day}</span>
      </p>
    </div>
  )
}

export default Finances
