import React, {useState} from 'react'
import s from './NumberBlock.module.scss'
import NumberHead from './NumberHead/NumberHead'
import NumberTable from './NumberTable/NumberTable'

const NumberBlock = ({
  setActiveNumbers,
  activeNumbers,
  activeCities,
  removeLocalNumber
}) => {
  const [search, setSearch] = useState('')
  const [activeCategory, setActiveCategory] = useState({id: 0, name: 'Без категории'}) // активная категория
  const [activeSort, setActiveSort] = useState({id: 1, name: 'Городские'}) // типы номеров

  return (
    <div className={s.wrapper}>
      <NumberHead
        search={search}
        setSearch={setSearch}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <NumberTable
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        setActiveSort={setActiveSort}
        activeSort={activeSort}
        search={search}
        setActiveNumbers={setActiveNumbers}
        activeNumbers={activeNumbers}
        activeCities={activeCities}
        removeLocalNumber={removeLocalNumber}
      />
    </div>
  )
}

export default NumberBlock
