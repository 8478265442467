import React, {useEffect, useState} from 'react'
import s from './Card.module.scss'
import {HelpElemTippy} from "../../HelpElemTippy/HelpElemTippy";
import {useTheme} from "../../../hooks/useTheme";

const Card = ({
                data, service, selectedPacket, setSelectedPacket,
                activeMinutes, activatePacket, setPacketPrice, activatable=true}) => {
  const {shopVoipPrice, setShopVoipPrice} = useTheme()
  const [price, setPrice] = useState(0)
  const [label, setLabel] = useState("")
  const [text, setText] = useState("")
  const [longText, setLongText] = useState("")
  const [isCurrent, setCurrent] = useState(false)

  useEffect(() => {
    if (!data || !data.prices) return

    const price = data.prices.find(elem => elem.minutes === activeMinutes)
    if (price) {
      setPrice(price.price)

      if (selectedPacket && (data.id === selectedPacket.id)) {
        setPacketPrice(price.price)
      }
    }

    // Определяем, текущая ли услуга
    if (service && service.context && service.context.packet_size && service.usl_name && data.wTitle) {
      const ps = service.context.packet_size
      setCurrent(ps === activeMinutes && service.usl_name.includes(data.wTitle))

    }

  },[data, service, activeMinutes, selectedPacket])


  useEffect(() => {
    if (!data) return
    if (data.name) setLabel(data.name)
    if (data.description) setText(data.description)
    if (data.description_long) setLongText(data.description_long)

  }, [data])

  const selectPacket = () => {
    setSelectedPacket(data)
  }

  return (
    <div
      className={`${!isCurrent ? s.card : s.cardCurrent} 
                  ${selectedPacket && selectedPacket.id === data.id && s.active}`}
      onClick={selectPacket}
    >

      {longText &&
      <div style={{align: "right"}}>
        <HelpElemTippy text={longText} white={selectedPacket && selectedPacket.id === data.id}/>
      </div>

      }

      <div className={s.wrapper}>
        {isCurrent
            ? <p className={s.title}>{label} (активный пакет)</p>
            : <p className={s.title}>{label}</p>
        }


        <div className={s.price}>
          <p className={s.cost}>{price}</p>
          <p className={s.text}>за 30 дней</p>
        </div>

      </div>
      <div>
        <div className={s.paragraph}>{text}</div>
      </div>

      {activatable &&
      <button className={s.btn} onClick={() => activatePacket(data)} disabled={isCurrent}>Подключить</button>
      }
    </div>
  )
}

export default Card
