import React, {useEffect, useState} from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import MainBlock from './MainBlock/MainBlock'
import s from './Autocalling.module.scss'
import {useTheme} from "../../../hooks/useTheme";
import {cbcUrl} from "../../../provider/variables";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";

const Autocalling = ({setActivePopup}) => {
    const theme = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [cbcFullUrl, setCbcUrl] = useState("")

    const {userAccount} = useTheme()

    useEffect(() => {
        if (userAccount.fusion_auth) return
        if (!cbcFullUrl) {
            let url = [cbcUrl, userAccount["token"]].join("?token=")
            url = [url, userAccount["login"]].join("&pin=")
            setCbcUrl(url)
        }
    }, [cbcFullUrl])

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    return (
        <div className={`${s.mainPart} ${theme.designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                {/*{(cbcServices && cbcServices.length > 0)*/}
                {/*    ? // cbcServices.length > 0*/}
                {/*    // Если услуга подключена*/}
                {/*    <div className={s.vatsFrame}>*/}
                {/*        <iframe src={cbcFullUrl} allowFullScreen />*/}
                {/*    </div>*/}
                {/*    :*/}
                {/*    <MainBlock*/}
                {/*        activeBurger={activeBurger}*/}
                {/*      setActiveBurger={setActiveBurger}*/}
                {/*      url={cbcFullUrl}*/}
                {/*      setActivePopup={setActivePopup}*/}
                {/*    />*/}
                {/*}*/}
                {cbcFullUrl.length > 0
                    ? <div className={s.vatsFrame}><iframe src={cbcFullUrl} allowFullScreen /></div>
                    : <DefaultTitle title={"Услуга не подключена, либо недоступна"} />
                }
            </div>
        </div>
    )

}

export default Autocalling
