import React, {useEffect, useState} from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import MainBlock from './MainBlock/MainBlock'
import s from './FaqPage.module.scss'
import {AddFeaturePopup} from "../../../ui/Popup/Popups";
import {createBrowserHistory} from "history";
import {useTheme} from "../../../hooks/useTheme";

const history = createBrowserHistory();

const FaqPage = (props) => {
    const {userAccount, designType} = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [popup, setPopup] = useState("")



    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

      const updatePage = () => {
          history.push("/faq")
          history.go(0)

      }

  return (
      <div className={`${s.mainPart} ${designType === "minimal" && s.minimalDesign}`}>
          <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
          <div className={s.flex}>
              <MainBlock setPopup={setPopup}/>

              {userAccount.manager &&
              <AddFeaturePopup show={popup === "AddFeature"} hide={() => setPopup("")} success={updatePage}/>
              }
          </div>
      </div>

  )
}

export default FaqPage
