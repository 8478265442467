import React, {Component, useEffect, useState} from 'react';
import s from "./VideoPlayer.module.scss"
import ReactPlayer from 'react-player';

export const VideoPlayer = ({uri}) => {
    const [videoUrl, setVideoUrl] = useState("")

    useEffect(() => {
        if (uri.length > 0)
            setVideoUrl(uri)
    }, [uri])

    return(
        <div>
            {videoUrl.length > 0
             ? <ReactPlayer url={uri} controls/>
             : <div className={s.noVideo}>Отсутствует ссылка на видео</div>
            }
        </div>
    )
}