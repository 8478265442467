import axios from 'axios'

export const getSmsHistory = async (info) => {
    // let url = "http://10.248.0.173:8770/billing/";
    let url = "https://api.telezon.ru/gw_sms/billing/";

    return await axios
        .get(url, {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                PIN: info.login,
            },
        }, )
        .then((response) => response.data)
}

export const getSmsSettings = async (info) => {
    let url = "https://api.telezon.ru/gw_sms/user/";
    // let url = "http://10.248.0.173:8770/user/";

    return await axios
        .get(url, {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                PIN: info.login,
            },
        }, )
        .then((response) => response.data)
}

export const saveSmsSettings = async (info) => {
    let url = "https://api.telezon.ru/gw_sms/user/";
    // let url = "http://10.248.0.173:8770/user/";

    console.log(info?.payload)
    return await axios
        .put(url, info.payload,
            {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
        .catch(error => error.data)
}

export const getSmsAeroSettings = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/user/";

    return await axios
        .get(url, {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                PIN: info.login,
            },
        })
        .then((response) => response.data)
        .catch(error => error.data)
}

export const getSmsAeroHistory = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/storage/";
    if (info?.url) {
        url = info?.url
    }

    return await axios
        .get(url, {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                PIN: info.login,
            },
        })
        .then((response) => response.data)
        .catch(error => error.data)
}

export const saveSmsAeroSettings = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/user/";

    return await axios
        .put(url, info.payload,
            {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
}

