import React, {useEffect, useState} from "react";
import s from "./ShopBLock.module.scss"
import {useTheme} from "../../hooks/useTheme";
import {Link} from 'react-router-dom'
import DefaultTitle from "../DefaultTitle/DefaultTitle";
import DefaultButton from "../Button/Button";
import DoneIcon from "@mui/icons-material/Done";
import {byServices, byUslRequest} from "../../api";
import {TippyElement} from "../TippyElement/TipyElemet";



const EmptyBasket = ({hide}) => {
    return(
        <div className={s.messageBlock}>
            <p>В корзине нет товаров</p>
            <p>Найдите то, что вам нужно в разделе <Link to="/telephony" onClick={() => hide()}>Телефония</Link></p>

            <div className={s.closeButton}>
                <DefaultButton text={"Закрыть"} clickEvent={() => hide()}/>
            </div>

        </div>
    )
}

const BuyingProcess = ({hide}) => {

    return(
        <div className={s.messageBlock}>
            <p>Спасибо за покупку!</p>
            <p>Когда все услуги и номера будут зарегистрированы, вы получите сообщение на электронную почту
            </p>
            <DoneIcon fontSize="large" color="action" />

            <div className={s.closeButton}>
                <DefaultButton text={"Закрыть"} clickEvent={() => hide()}/>
            </div>

        </div>
    )
}

const NoEmptyBasket = ({hide, startBye}) => {
    const {setShopList, shopList, changeShopListLocal, phoneNumers} = useTheme()

    const removeElem = (e) => {
        let newPrice = shopList.fullPrice
        let newElements = shopList.elements

        newPrice = newPrice - e.data.price
        newElements = newElements.filter(elem => elem.name !== e.name)
        setShopList({fullPrice: newPrice, elements: newElements})
        changeShopListLocal({fullPrice: newPrice, elements: newElements})
    }

    return(
        <div className={s.basketBlock}>
            <DefaultTitle title={"Корзина"} />
            {shopList.elements.map(elem =>
                <div key={elem.name} className={s.basketElem}>
                    <div className={s.title}>
                        {elem.name}
                        {elem?.data?.service === "voip" && elem?.data?.param?.newOptions?.length > 0 &&
                        elem.data.param.newOptions.map(option =>
                            <span key={option}>Опция "{option}"</span>
                        )}

                    </div>
                    {elem.data.price > 0
                        ? <div className={s.price}>
                                { elem.data.price} руб.
                                {elem.data.fullPrice && (elem.data.price < elem.data.fullPrice) &&
                                    <>&nbsp;
                                        <TippyElement>
                                            <div style={{color: "black"}}>
                                                Стоимость текущей оплаты уменьшена на сумму абонентской платы действующего пакета
                                            </div>
                                        </TippyElement>
                                    </>
                                }
                            </div>
                        : <div className={s.priceIndivid}>индивидуально</div>
                    }

                    <div className={s.deleteB} onClick={() => removeElem(elem)}>Удалить</div>
                </div>
            )}

            {phoneNumers.length === 0 && !shopList.elements.find(e => e.data.service === "phoneNumber") &&
            <div className={s.noNumbers}>*Для полноценной работы виртуальной АТС необходимо наличие внешнего номера</div>
            }


            <div>
                <span>Стоимость: {shopList.fullPrice}</span>
                {phoneNumers.length === 0 && !shopList.elements.find(e => e.data.service === "phoneNumber") &&
                <DefaultButton text={"Купить"} style={s.grey}/>
                }

                {(phoneNumers.length > 0 || shopList.elements.find(e => e.data.service === "phoneNumber")) &&
                <DefaultButton text={"Купить"} clickEvent={startBye}/>
                }

                <DefaultButton text={"Закрыть"} clickEvent={() => hide()}/>
            </div>

        </div>
    )
}

export const ShopBLock = ({show, hide}) => {
    const {shopList, setShopList, balance, phoneNumers, setPhoneNumers, reloadData,
        setCurrentVatsPacket, setCrIsActive, setVzIsActive, setCrmIntegrIsActive,
        setActivePopupGlobal, userAccount, changeShopListLocal} = useTheme()
    const [starBuy, setStartBuy] = useState(false)

    useEffect(() => {
        // setStartBuy(false)
    }, [shopList])

    const setShopListActive = () => {
        for (const elem of shopList.elements) {
            if (elem.data.service === "vats") {
                setCurrentVatsPacket(
                    {
                        usl_name: "Виртуальная АТС <" + elem.data.param.toString() + ">",
                        price: elem.data.price,
                    })
            } else if (elem.data.service === "callRecords") {
                setCrIsActive(true)
            } else if (elem.data.service === "vamZvonily") {
                setVzIsActive(true)
            } else if (elem.data.service === "crmIntegration") {
                setCrmIntegrIsActive(true)
            } else if (elem.data.service === "phoneNumber") {
                let numberData = elem.data.param
                setPhoneNumers(
                    [...phoneNumers, {label: numberData.NUMER, value: numberData.NUMER, idSetDrive: 0}])
            } else {
                console.log(elem.data.service, elem.data.param, elem.data.price)
            }
        }
        setShopList({fullPrice: 0, elements: []})
        changeShopListLocal({fullPrice: 0, elements: []})
    }

    const runBuyProcess = () => {
        // setShopListActive()
        //
        // return
        const successPay = (response) => {
            console.log(response)
            setStartBuy(true)

            byServices({auth: userAccount, buyUuid: response.uuid})
                .then(r => reloadData())
                .catch(r => console.log(r))

            setShopListActive()
        }


        let balanceValue = balance && balance.balance_v ? balance.balance_v : 0
        console.log("покупаем", balanceValue, shopList.fullPrice)
        // console.log("покупаем", shopList)
        // return

        if (balanceValue >= shopList.fullPrice) {
            byUslRequest({...userAccount, summ: shopList.fullPrice, services: shopList.elements})
                .then(r => r.result
                    ? successPay(r)
                    : setActivePopupGlobal("noBalance")
                )
                .catch(() => setActivePopupGlobal("oups"))
        } else if (balance.max_credit && balance.max_credit > 0) {
            console.log("Берём кредит")
            setActivePopupGlobal("noBalanceWCredit")
        } else { // обработка, если баланса недостаточно
            console.log("баланса недостаточно")
            setActivePopupGlobal("noBalance")
        }
    }

    return(
        <div className={`${s.fullSizedWrap} ${show ? s.show : s.hide}`} onClick={() => hide()}>
            <div className={`${s.modalWindow}`} onClick={e => e.stopPropagation()}>
                {shopList.fullPrice === 0 && shopList.elements.length === 0 && !starBuy &&
                <EmptyBasket hide={hide}/>
                }

                {shopList.elements.length > 0 && !starBuy &&
                <NoEmptyBasket hide={hide} startBye={runBuyProcess}/>
                }

                {starBuy &&
                <BuyingProcess hide={() => {setStartBuy(false); hide()}}/>
                }
            </div>
        </div>
    )
}
