import React, { useState } from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './VamZvonili.module.scss'
import Form from './Form/Form'
import {useTheme} from "../../../hooks/useTheme";

const VamZvonili = (props) => {
    const theme = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    return (
        <div className={`${s.mainPart} ${theme.designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                <Form setActivePopup={props.setActivePopup}/>
            </div>
        </div>
    )

}

export default VamZvonili
