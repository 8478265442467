import React, {useEffect, useState} from 'react'
import { Controller, useForm } from 'react-hook-form'
import s from './Form.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTheme } from '../../../../hooks/useTheme'
import Select from 'react-select'
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'

import {saveSettings, saveIvrData} from "../../../../api"

const schema = yup
    .object({
        defaultNumber: yup.string(),
        redirectNumber: yup.number(),
        ivrList: yup.array()
    })
    .required()

export const IncommingCalsForm = ({ setActivePopup }) => {
    const theme = useTheme()
    const [ivrMenuIsOpen, openIvrMenu] = useState(false)
    const [ivrMenuList, setIvrMenuList] = useState([]) // список доступных меню
    const [selectedIvrMenuList, setSelectedIvrMenuList] = useState([]) // список выбранных меню
    const [redirectNumberIvr, setRedirectNumberIvr] = useState(0) // список выбранных меню

    useEffect(() => {
        if (!theme.ivrData || !theme.ivrData.data || !theme.ivrData.data || ivrMenuList.length > 0) return
        const menuData = theme.ivrData.data.filter(e => e.name === "Голосовое меню")
        if (menuData.length === 0) return
        // menuData.map((e, index) => console.log(e.objects))

        let elements = []
        for (const e of menuData) {
            for (const object of e.objects) {
                if (!object.descr) continue
                elements.push({id:object.number, value: object.name, label: object.descr})
            }
        }
        setIvrMenuList([...elements])
    }, [theme.ivrData])

    useEffect(() => {
        if (!theme.savedIvrData || !theme.savedIvrData.sniff_nums) return
        if (ivrMenuList.length === 0) return

        const numbers = theme.savedIvrData.sniff_nums
        const ivr = ivrMenuList.filter(e => numbers.includes(e.id))
        setSelectedIvrMenuList([...ivr])

        if (theme.savedIvrData) {
            setRedirectNumberIvr(theme.savedIvrData.redirect_num)
        }


    }, [ivrMenuList, theme.savedIvrData])

    const customStyles = {
        control: () => ({
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            cursor: 'pointer',

        }),
        placeholder: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            opacity: '.3',
        }),
        menuList: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            position: 'absolute',
            zIndex: 1000,
            width: '100%',
            borderRadius: '10px',
            top: 'calc(100% + 12px)',
            // overflow: 'hidden',
            background:
                theme === 'light' ? 'var(--night-color)' : 'var(--light-color)',
        }),
        menu: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        option: () => ({
            background: 'none',
            padding: '5px',
            fontSize: '16px',
            cursor: 'pointer',

            '&:hover': {
                background: '#3a3a3a',
                color: 'var(--light-color)',
            },
        }),
        singleValue: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        input: () => ({
            display: 'none',
        }),
    }

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            defaultNumber: "",
            redirectNumber: "",
            ivrList: [],
        }
    })

    const onSubmit = (data) => {
        // сохраняем IVR-меню
        saveIvrData({
            login: theme.userAccount.login,
            token: theme.userAccount.token,
            numbers: data.ivrList.map(e => e.id),
            redirectNumber: data.redirectNumber
        })
            .then(() => setActivePopup("settingsSuccess"))
            .catch(() => setActivePopup("failSave"))

        // Сохраняем настройки
        saveSettings({
            login: theme.userAccount.login,
            token: theme.userAccount.token,
            default_number: data.defaultNumber,
        })
            .then((r) => console.log("success save default_number", r))
            .catch(() => setActivePopup("failSave"))
    }

    useEffect(() => {
        if (!theme.settings) return

        if (theme.settings) {
            reset({
                defaultNumber: theme.settings["default_number"],
                redirectNumber: redirectNumberIvr,
                ivrList: selectedIvrMenuList
            })
        }

        }, [theme.settings, redirectNumberIvr, selectedIvrMenuList]
    )

    return (
        <>
            <div className={s.header}>
                <div className={s.title}>
                    <DefaultTitle title="Обработка входящих запросов" />
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Внутренний номер для click2call</p>
                    <input
                        // placeholder={theme.settings ? theme.settings["default_number"] : ""}
                        // defaultValue={theme.settings ? theme.settings["default_number"] : ""}
                        type="text"
                        className={
                            errors.phone ? `${s.defaultInput} ${s.error}` : s.defaultInput
                        }
                        {...register('defaultNumber')}
                    />
                </div>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Внутренний номер для переадресации</p>
                    <input
                        // placeholder={redirectNumberIvr}
                        // defaultValue={redirectNumberIvr}
                        type="text"
                        className={
                            errors.phone ? `${s.defaultInput} ${s.error}` : s.defaultInput
                        }
                        {...register('redirectNumber')}
                    />
                </div>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Выбор IVR для целевых запросов</p>
                    <div
                        style={{width: "220px"}}
                        className={`${s.defaultInput} ${s.noPadding}`}
                        onClick={() => {
                            openIvrMenu(!ivrMenuIsOpen)
                        }}
                    >
                        <Controller
                            control={control}
                            values={selectedIvrMenuList}
                            name="ivrList"
                            rules={{required: true}}
                            render={({field: {onChange}}) => (
                                <Select
                                    onChange={r => {setSelectedIvrMenuList(r); onChange(r)}}
                                    options={ivrMenuList}
                                    styles={customStyles}
                                    placeholder={'Нет'}
                                    isClearable
                                    isMulti
                                    isSearchable
                                    maxMenuHeight={175}
                                    aria-rowcount={10}
                                    menuIsOpen={ivrMenuIsOpen}
                                    value={selectedIvrMenuList}
                                />
                            )}
                        />
                    </div>
                </div>

                <button className={[s.button, s.floatR].join(" ")} style={{marginTop: "25px"}}>Сохранить</button>
            </form>
        </>
    )
}
