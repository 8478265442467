import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import s from './Form.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTheme } from '../../../../hooks/useTheme'
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'

import {userAuth, changePasswd} from "../../../../api"

const schema = yup
    .object({
        password: yup.string().required("Password is required"),
        newPassword: yup.string().min(8).max(32).required("Password is required"),
        newPasswordRep: yup.string().min(8).max(32).required("Password is required"),
    })

export const SecurityForm = ({ setActivePopup }) => {
    const theme = useTheme()
    const [errorElem, setErrorElem] = useState("")
    const [errorMes, setErrorMes] = useState("")

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            password: "",
            newPassword: "",
            newPasswordRep: "",
        }
    })

    function saveData(data, auth) {
        if (auth.error) return

        changePasswd({
            login: theme.contacts["login"],
            token: auth["token"],
            password: data.newPassword,
        })
            .then(() => setActivePopup("settingsSuccess"))
            .catch(() => setActivePopup("failSave"))
    }

    const onSubmit = (data) => {

        // Валидируем новые пароли
        if (data.newPassword !== data.newPasswordRep) {
            setErrorElem("newPassword")
            setErrorMes("Пароли не совпадают")
            return
        }

        let passRegExp = /(?=.*[A-Z])(?=.*[_$!])(?=.*[0-9])(?=.*[a-z]).{8,21}/;

        if (!passRegExp.exec(data.newPassword)) {
            setErrorElem("incorrectNewPass")
        } else {
            setErrorElem("")
        }

        // Проверяем пароль
        userAuth({
            login: theme.contacts["login"],
            password: data["password"]
        })
            .then(r => saveData(data, r))
            .catch(() => setActivePopup("failChangePassword"))
    }

    return (
        <>
            <div className={s.header}>
                <div className={s.title}>
                    <DefaultTitle title="Безопасность" />
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>
                        Логин
                    </p>
                    <input
                        defaultValue={theme.userAccount ? theme.userAccount["login"] : ""}
                        type="text"
                        className={
                            s.defaultInputReadOnly
                        }
                        readOnly={true}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Текущий пароль</p>
                    <input
                        placeholder="Актуальный пароль"
                        type="password"
                        style={{maxWidth: "200px"}}
                        className={
                            errors.password || errorElem === "password" ? `${s.defaultInput} ${s.error}` : s.defaultInput
                        }
                        {...register('password')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Новый пароль</p>

                    {
                        errorElem === "newPassword" ?
                            <div className={s.errorMes}>{errorMes}</div> : null
                    }
                    <input
                        placeholder="Новый пароль"
                        type="password"
                        style={{maxWidth: "200px"}}
                        className={
                            errors.password || errorElem === "newPassword" ? `${s.defaultInput} ${s.error}` : s.defaultInput
                        }
                        {...register('newPassword')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Повторите пароль</p>
                    {
                        errorElem === "newPasswordRep" ?
                            <div className={s.errorMes}>{errorMes}</div> : null
                    }
                    <input
                        placeholder="Повторите пароль"
                        type="password"
                        style={{maxWidth: "200px"}}
                        className={
                            errors.password || errorElem === "newPasswordRep" ? `${s.defaultInput} ${s.error}` : s.defaultInput
                        }
                        {...register('newPasswordRep')}
                    />
                </div>
                <button className={`${s.button} ${s.floatR}`}>Сменить пароль</button>
            </form>
        </>
    )
}
