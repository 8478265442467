import React, {useEffect, useState} from 'react'
import Card from './Card/Card'
import s from './Cards.module.scss'
import {useTheme} from "../../../../../hooks/useTheme";
import {cards, noShowCards} from "../../../../../provider/variables";
import MinutesSlider from "../../../NumersShop/MainBlock/OutgoingCall/MinutesSlider/MinutesSlider";
import RangeSelect from "../../../NumersShop/MainBlock/OutgoingCall/RangeSelect/RangeSelect";
import ModalMessage from "../../../../../ui/Popup/ModalMessage";
import DefaultButton from "../../../../../ui/Button/Button";
import {addVoip} from "../../../../../api";

const Cards = ({usl, cards, setActivePopup, activatable=true}) => {
    const {userAccount} = useTheme()
    const [clientWidth, setClientWidth] = useState(window.innerWidth)

    const [selectedPacket, setSelectedPacket] = useState()
    const [activeMinutes, setActiveMinutes] = useState(
        localStorage.getItem("activeMinutes") ?
            parseInt(localStorage.getItem("activeMinutes")) : 1000) // активное кол-во минут

    const [minutesPrice, setMinutesPrice] = useState(0) // цена зависящая от кол-ва минут
    const [newPacket, setPacket] = useState() // новый пакет
    const [popupTarget, setPopupTarget] = useState("")


    useEffect(() => {
        if (cards && cards.length > 0)
            setSelectedPacket(cards.filter(c => c.is_active)[0])
    }, [cards])

    const activatePacket = (service, confirmed=false) => {

        const setErrorMsg = (r) => {
            if (r.error) {
                setActivePopup(r.error)
            }
        }

        if (!confirmed) {
            setPopupTarget("confirm")
            setPacket(service)
        } else if (newPacket) {
            setPopupTarget("")
            addVoip({...userAccount, packet: newPacket, packet_size: activeMinutes})
                .then(() => setActivePopup("changeTariffSuccess"))
                .catch(error => setErrorMsg(JSON.parse(error.response.data)))
        }
    }

    return (
        <div>

            {clientWidth >= 768 ? (
                <MinutesSlider
                    activeMinutes={activeMinutes}
                    setActiveMinutes={setActiveMinutes}

                    minutesPrice={minutesPrice}
                    setMinutesPrice={setMinutesPrice}
                />
            ) : (
                <RangeSelect setMinutesPrice={setMinutesPrice} />
            )}

            <div className={s.cards}>
                {cards.map(card => (
                    card.is_active && !noShowCards.includes(card.id) &&
                    <Card
                        key={card.id}
                        data={card}
                        service={usl}
                        selectedPacket={selectedPacket}
                        setSelectedPacket={setSelectedPacket}
                        activatePacket={activatePacket}
                        activeMinutes={activeMinutes}
                        activatable={activatable}
                    />
                ))}
            </div>

            <div className={s.additionalDescription}>
                * Городская связь при наборе 7-значного номера 2хх-хх-хх в рамках пакета минут бесплатно.
                При наборе 10-значного федерального номера в формате 391 2хх-хх-хх по междугороднему тарифу для
                г. Красноярск в ценовом диапазоне от 1,62-1,79 руб/мин, в зависимости от количества минут в пакете

                {/*<br />*/}
                {/*Цена минуты вне пакета (звонки на все направления мобильных и городских номеров РФ, не*/}
                {/*включая международную связь) 2,88 ₽.*/}
                <br />
                <br />
                ** Список деловых центров: Москва, Красноярск, Санкт-Петербург, Владивосток, Екатеринбург,
                Ростов-на-Дону, Тюмень, Хабаровск, Челябинск, Астрахань, Белгород, Брянск, Волгоград, Воронеж,
                Ижевск, Иркутск, Казань, Калининград, Пенза, Киров, Краснодар, Курск, Липецк, Махачкала,
                Набережные Челны, Нижний Новгород, Новосибирск, Омск, Тула, Оренбург, Пермь, Самара, Саратов,
                Сочи, Севастополь, Сургут, Тверь, Тольятти, Томск, Улан-Удэ, Ульяновск, Уфа, Чита, Рязань, Барнаул,
                Кемерово, Новокузнецк, Ярославль
            </div>

            <ModalMessage message={"Вы уверены, что хотите подключить новый тарифный план?"}
                          label={"Смена тарифного плана"}
                          show={popupTarget === "confirm"}
                          hide={() => {activatePacket({}, true)}}>
                <div>
                    <div className="white-button margin-center w20"
                         style={{marginTop: "10px"}}
                         onClick={() => {setPopupTarget("")}}>
                        Отмена
                    </div>
                </div>
            </ModalMessage>
        </div>
    )
}

export default Cards
