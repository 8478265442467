import React from 'react'
import s from './Checkbox.module.scss'

const Checkbox = ({ id, title, isChecked, handleClick }) => {
  return (
    <div className={s.label}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => handleClick(title.toLowerCase())}
        className={s.checkbox}
        id={`sort${id}`}
      />
      <label htmlFor={`sort${id}`} className={s.label}>
        {title}
      </label>
    </div>
  )
}

export default Checkbox
