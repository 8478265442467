import React, {useEffect, useState} from 'react'
import s from "./Tabs.module.scss"
// import {useTheme} from "../../hooks/useTheme";


export const Tabs = ({activeTab, setActiveTab, tabs, noMargin}) => {
    // const {vatsNames, userAccount} = useTheme()
    // const [allowedTabs, setAllowedTabs] = useState([])
    //
    // useEffect(() => {
    //     let to_allowedTabs = []
    //     for (const t of tabs) {
    //         if ((t.needVats && vatsNames.length > 0) || !t.needVats) {
    //             if (!t.showVirtual && userAccount.virtual) continue
    //             to_allowedTabs.push(t)
    //         }
    //     }
    //
    //     if (to_allowedTabs.length > 0) setActiveTab(to_allowedTabs[0])
    //     setAllowedTabs([...to_allowedTabs])
    //
    // }, [vatsNames])

    useEffect(() => {
        if (!tabs) return

        setActiveTab(tabs[0])
    }, [tabs])

    return(
        <div className={`${s.tabs} ${noMargin && s.noMargin}`}>
            {tabs.map(elem =>
                <div className={`${s.tab} ${activeTab.id === elem.id && s.activeTab}`}
                     key={elem.id}
                     onClick={() => setActiveTab(elem)}>
                    {elem.label}
                </div>
            )}
        </div>
    )
}

export const TabsNoColored = ({activeTab, setActiveTab, tabs}) => {
    return(
        <div className={s.tabs}>
            {tabs.map(elem =>
                <div className={`${s.tabNc} ${activeTab === elem.id && s.activeTabNc}`}
                     key={elem.id}
                     onClick={() => setActiveTab(elem.id)}>
                    {elem.label}
                </div>
            )}
        </div>
    )
}

export const SmallTabs = (props) => {
    const [tabs, setTabs] = useState([])
    const [currentTab, setCurrentTab] = useState("")

    useEffect(() => {
        if (!props.data) return

        setTabs(props.data)
        // if (props.data.length > 0) {
        //     setCurrentTab(props.data[0].value)
        // }

    }, [props.data])

    useEffect(() => {
        props.onChange(currentTab)

        if (props.notSeletable)
            setCurrentTab("")

    }, [currentTab])

    return(
        <div className={s.selector}>
            {tabs.map(
                type_elem =>
                    <div onClick={() => setCurrentTab(type_elem.value)} key={type_elem.id}
                         className={type_elem.value === currentTab ? s.selected : s.noSelected}>
                        {type_elem.label}
                    </div>
            )}
        </div>
    )
}