import React, { useState } from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import MainBlock from './MainBlock/MainBlock'
import s from './DdosPage.module.scss'
import {useTheme} from "../../../hooks/useTheme";

const DdosPage = (props) => {
    const theme = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    return (
        <div className={`${s.mainPart} ${theme.designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                {theme.ipAddresses.length > 0 ?
                    <MainBlock /> : null
                }

            </div>
        </div>
    )

}

export default DdosPage
