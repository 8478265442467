import React, {useEffect, useState} from 'react'
import { useTheme } from '../../../../hooks/useTheme'
import s from './MainBlock.module.scss'
import Settings from './Settings/Settings'
import SvgDiagram from './SvgDiagram/SvgDiagram'
import Finances from "./Finances/Finances";
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";


const PacketUsl = ({usl, packageData}) => {
    const { balance } = useTheme()
    const [serviceName, setServiceName] = useState("")

    const packetRubStartsWith = "Услуга_ПР"
    useEffect(() => {
        if (!usl?.usl_name) return

        let tmpServiceName = ""
        if (usl?.usl_name?.startsWith(packetRubStartsWith)) {
            let splittedName = usl?.usl_name.split("_")
            tmpServiceName = `Пакет ${splittedName?.[2]} рублей`
        }
        setServiceName(tmpServiceName)
    }, [usl])

    return(
        <div key={usl?.id_set_usl}>
            <div className={s.feature}>
                <p className={s.title}>Информация о состоянии услуги</p>

                <h1 className={s.name}>
                    <span>{serviceName}</span>
                </h1>

                { // Рисуем график, если пакет есть и он соответствует нашему тарифу
                    (parseInt(packageData?.package_size) || 0  > 0 && parseInt(packageData?.package_used) || 0  > 0) &&
                    <div className={s.diagramWrapper}>
                        <SvgDiagram packet={packageData} />
                        <div className={s.infoBlock}>
                            <Finances balance={balance} packet={packageData}/>
                            <Settings usl={usl}/>
                        </div>
                    </div>

                }
            </div>
        </div>
    )
}


const NoExistsPackageBlock = () => {
    return(
        <div style={{margin: "25px 0"}}>
            <DefaultTitle title="Нет подключенных пакетных услуг исходящей связи" />
        </div>
    )
}

const ExistsPackageBlock = ({props}) => {
    const {currentVoipPacket, userAccount, packageData, setPackageData} = useTheme()

    // const [cards, setCards] = useState([])
    //
    // useEffect(() => {
    //     if (!userAccount.login || !userAccount.token) return
    //     voipCards(userAccount)
    //         .then((r) => setCards(r))
    // }, [userAccount])

    return(
        <>
            <div style={{borderBottom: "1px solid black"}}/>
            <div style={{margin: "25px 0"}}>
                <PacketUsl usl={currentVoipPacket} packageData={packageData}/>
            </div>


            {/*<div className={s.cards}>*/}
            {/*    <Cards usl={currentVoipPacket} cards={cards} {...props}/>*/}
            {/*</div>*/}
        </>
    )
}

const MainBlock = (props) => {
    const {currentVoipPacket, userAccount, packageData, setPackageData} = useTheme()

    // useEffect(() => {
    //     if (!currentVoipPacket || !packageData?.package_name) return
    //     // console.log(currentVoipPacket)
    //     // console.log(packageData)
    //
    // }, [currentVoipPacket, packageData])

    return (
        <div>
            <DefaultTitle title="Пакетные тарифы исходящей связи" />

            {!packageData?.package_name &&
                <NoExistsPackageBlock props={props}/>
            }

            {packageData?.package_name &&
                <ExistsPackageBlock props={props}/>
            }
        </div>
    )

}

export default MainBlock
