import React, {useEffect, useState} from 'react'
import NumberAssortiment from './NumberAssortiment/NumberAssortiment'
import NumberBacket from './NumberBacket/NumberBacket'
import NumberTypes from './NumberTypes/NumberTypes'
import {useTheme} from '../../../../../../../hooks/useTheme'
import s from './NumberTable.module.scss'

const NumberTable = ({
                         search, setActiveSort, activeSort, activeCategory,
                         setActiveNumbers, activeNumbers,
                         activeCities, removeLocalNumber
                     }) => {
    const {numbers} = useTheme()

    const [filteredNumbers, setFilteredNumbers] = useState([])
    const [searchNumbers, setSearchNumbers] = useState([])

    const [loadedStorage, setLoadedStorage] = useState(false)

    const sortNumersByType = value => {
        if (activeSort.name !== '8800') {
            return value.filter(numb => {
                return numb.NUMER.replace(' ', '').slice(0, 3) !== '800'
            })
        } else if (activeSort.name === '8800') {
            return value.filter(numb => {
                return numb.NUMER.replace(' ', '').slice(0, 3) === '800'
            })
        }

        return []
    }

    useEffect(() => {
        if (filteredNumbers.length === 0) return
        if (loadedStorage) return

        // Загружаем из локального хранилища номера в магазин
        let exists_numbers = activeNumbers.map(e => e.NUMER)
        if (localStorage.getItem("selectedNumersCount") && localStorage.getItem("selectedNumersCount") > 0) {
            const cnt = localStorage.getItem("selectedNumersCount")
            let local_numbers = []
            for (let i = 0; i < cnt; i++) {
                const number_str = localStorage.getItem("selectedNumer"+i.toString())
                let number = numbers.filter(number => number.NUMER === number_str)
                if (number.length > 0 && !exists_numbers.includes(number_str)) {
                    exists_numbers.push(number_str)
                    local_numbers = [...local_numbers, ...number]
                }
            }

            setActiveNumbers([...activeNumbers, ...local_numbers])
        }
        setLoadedStorage(true)

        // localStorage.setItem("selectedNumersCount", "0")
        // for (let i = 0; i < 10; i++) {
        //   localStorage.removeItem("selectedNumer"+i.toString())
        // }

    }, [filteredNumbers])

    useEffect(() => {
        setFilteredNumbers(checkSort(numbers))
    }, [numbers, activeCategory, activeCities])

    const checkSort = nums => {
        // отбираем номера по категории
        let temporary_numbers = nums.filter(numb => numb.CATEGORY === activeCategory.name)

        if (activeCities.length > 0) {
            // отбираем номера по городу
            const cities = activeCities.map(city => city.name)
            temporary_numbers = temporary_numbers.filter(numb => cities.includes(numb.CITY))
        }

        // удаляем все номера которые пользователь уже выбрал
        temporary_numbers = temporary_numbers.filter(numb => {
            return activeNumbers.filter(activeNumb => activeNumb.NUMER === numb.NUMER).length <= 0;
        })

        // выбираем номера по типам
        return sortNumersByType(temporary_numbers)
    }

    const searchNumber = () => {
        return filteredNumbers.filter(numb => numb.NUMER.includes(search))
    }

    useEffect(() => {
        setFilteredNumbers(checkSort(numbers))
    }, [activeSort, activeCategory, activeNumbers])

    useEffect(() => {
        if (search !== '') setSearchNumbers(searchNumber())
    }, [search])

    const addLocalNumber = number_str => {
        // Получаем номера из локального хранилища
        let cnt = 0;
        let saved_numbers = []
        if (localStorage.getItem("selectedNumersCount") &&
            localStorage.getItem("selectedNumersCount") > 0) {
            cnt = localStorage.getItem("selectedNumersCount");
            for (let i = 0; i < cnt; i++) {
                saved_numbers.push(localStorage.getItem("selectedNumer"+i.toString()))
            }
        }

        if (!saved_numbers.includes(number_str)) saved_numbers.push(number_str)
        localStorage.setItem("selectedNumersCount", saved_numbers.length.toString())
        saved_numbers.map((num, index) => localStorage.setItem("selectedNumer"+index, num.toString()))

    }

    const addActiveNumber = number_str => {
        let number = filteredNumbers.splice(
            filteredNumbers.findIndex(numb => numb.NUMER === number_str), 1
        )
        setActiveNumbers([...activeNumbers, ...number])

        // Сохраняем выбранный номер локально
        if (number.length > 0) addLocalNumber(number_str)
    }

    return (
        <div className={s.flex}>
            <NumberTypes setActiveSort={setActiveSort} activeSort={activeSort} />
            <NumberAssortiment
                numbers={filteredNumbers}
                addActiveNumber={addActiveNumber}
                searchNumbers={searchNumbers}
                search={search}
            />
            <NumberBacket
                activeNumbers={activeNumbers}
                removeActiveNumber={removeLocalNumber}
            />
        </div>
    )
}

export default NumberTable
