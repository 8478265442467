import React, {useState} from 'react'
import s from "./LoginPage.scss"
import {useSearchParams} from 'react-router-dom'

import base64 from 'base-64'
import utf8 from "utf8"
import LoginMain from "./LoginMain/LoginMain";
import RegisterPageESIA from "../RegisterPage/RegisterPageESIA";

const LoginPage = (props) => {
  const [searchParams, ] = useSearchParams();
  const [registerData, setRegisterData] = useState();

  // Проверяем, есть ли регистрационные данные из сервиса esia
  if (searchParams.get("esia-data")) {
    if (!registerData) {
      let data = searchParams.get("esia-data")
      try {
        let decodedData = base64.decode(data);
        let text = utf8.decode(decodedData)
        text = text.replaceAll("True", "true")
        text = text.replaceAll("False", "false")
        let result = JSON.parse(text)
        result["source"] = "esia"
        setRegisterData(result)
      }
      catch (e) {
        console.log("errror", e)
        console.log("errror", data)
      }
    }
  }

  // return (
  //     registerData ?
  //         <RegisterPageESIA registerData={registerData} /> :
  //         <LoginMain setRedirectTo={props.setRedirectTo}/>
  // )
  return(
      <LoginMain setRedirectTo={props.setRedirectTo}/>
  )
}

export default LoginPage
