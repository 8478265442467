import React, {useEffect, useState} from 'react'
import s from "./AnalyticsBlock.module.scss"
import {PieChart, pieTemplate} from "./Charts/PieChart";
import {BarChart, barTemplate, HistogramChart} from "./Charts/BarChart";
import {OrangeSelect} from "./Charts/Charts";
import {LostClientsChart} from "../LostClients/Charts/Charts";
import Loading from "../../../../ui/Loading/Loading";
import {successed_maps} from "../../../../provider/variables";
import {useTheme} from "../../../../hooks/useTheme";

const allowedFilters = ["outbound", "inbound"]

const main_types = [
    {id: 0, label: "Все", value: "calls", groups: ["inbound", "outbound", "local"]},
    {id: 1, label: "По сотрудникам", value: "workers", groups: ["inbound", "outbound", "local"]},
    // {id: 2, label: "Потерянные вызовы", value: "lost", groups: ["inbound"]},
]

const charts_types = [
    {id: 0, label: "Диаграмма кольцевая", value: "pie"},
    {id: 1, label: "Гистограмма горизонтальная", value: "gisth"},
    {id: 2, label: "Гистограмма вертикальная", value: "gistv"},
]

const normalizeName = (name) => {
    if (name != null && name.length > 0) {
        while (name.includes("  ")) name = name.replace("  ", " ")

        if (name.includes(" ")) {
            let name_data = name.trim()
            name_data = name_data.split(" ")
            if (name_data.length === 3) {
                name = [name_data[0], name_data[1][0] + "." + name_data[2][0] + "."].join(" ")
            } else if (name_data.length === 2) {
                name = [name_data[0], name_data[1][0] + "."].join(" ")
            }
        }
    }

    return name
}

export const AnalyticsBlock = (props) => {
    const {theme} = useTheme()
    const [currentData, setCurrentData] = useState([pieTemplate])
    const [lastData, setLastData] = useState([pieTemplate])
    const [curFilter, setCurFilter] = useState({})

    const [chartType, setChartType] = useState("calls")
    const [fields, setFields] = useState([{key: "successful", name: "Успешные"}, {key: "unsuccessful", name: "Неуспешные"}])

    const parseDataPie = (rawData, type) => {
        let successful = rawData.filter(e => successed_maps.includes(e.hangup_cause)).length
        let unsuccessful = rawData.filter(e => !successed_maps.includes(e.hangup_cause)).length

        let success = {
            id: "Успешные",
            target: "successful",
            label: "Успешные",
            value: successful,
            toCkick: successful,
        }
        let fail = {
            id: "Неуспешные",
            target: "unsuccessful",
            label: "Неуспешные",
            value: unsuccessful,
            toCkick: unsuccessful,
        }

        return [
            success,
            fail
        ]
    }

    const parseDataBar = (rawData, type) => {
        let result = []
        let names = {}
        for (const row of rawData) {
            if ("direction" in row && row.direction !== curFilter.label) continue

            let src_field = "src_name"
            if (curFilter.label === "inbound") src_field = "dst_name"

            let name = normalizeName(row[src_field])
            if (name === null) name = "Noname"
            if (!(name in names))
                names[name] = {
                    id: name,
                    status: name,
                    target: 0,
                    successful: 0,
                    unsuccessful: 0,
                    fullName: row[src_field]
                }

            if (successed_maps.includes(row.hangup_cause)) names[name].successful += 1
            else names[name].unsuccessful += 1
        }

        for (const name of Object.keys(names)) result.push(names[name])
        result.sort((a, b) => a.successful + a.unsuccessful > b.successful + b.unsuccessful ? 1 : -1)
        result.push({
            id: "",
            status: "",
            target: "0",
            successful: 0,
            unsuccessful: 0,
        })

        // console.log(curFilter)
        // console.log(names)
        return result
    }


    const generatePie = (rawData) => {
        let resultData = []
        for (const field of  fields) {
            let template_elem = {...pieTemplate}

            template_elem.id = field.name
            template_elem.target = field.key
            template_elem.label = field.name
            template_elem.value = rawData[field.key]
            template_elem.toCkick = rawData[field.key]

            if (rawData[field.key] > 0)
                resultData.push(template_elem)

        }
        resultData = resultData.sort((a, b) => a.label.length > b.label.length ? 1 : -1)

        return resultData
    }

    const generateBar = (rawData, target, direction) => {
        let d = {}
        if (rawData && rawData[direction] && rawData[direction][target]) {
            d = rawData[direction][target]
        }

        if (!Object.keys(d).length) return pieTemplate

        let resultData = []
        for (let key of Object.keys(d)) {
            let current_elem = d[key] ? d[key] : {}
            let name = normalizeName(current_elem.name)
            if (name === null) name = key

            let template_elem = {}
            template_elem.id = name
            template_elem.status = name
            template_elem.target = key
            template_elem.successful = current_elem.successful
            template_elem.unsuccessful = current_elem.unsuccessful
            template_elem.fullName = current_elem.name

            if (template_elem.successful > 0 || template_elem.unsuccessful > 0)
                resultData.push(template_elem)
        }
        resultData.sort((a, b) => a.successful + a.unsuccessful > b.successful + b.unsuccessful ? 1 : -1)
        resultData.push({
            id: "",
            status: "",
            target: "0",
            successful: 0,
            unsuccessful: 0,
        })

        // resultData = resultData.slice(0, resultData.length)

        return resultData
    }

    const parseData = (rawData, type) => {
        if (type === "calls") {
            // if ("foreignStatistics" in props && !props.foreignStatistics && props.filteredData) {
            //     setCurrentData(parseDataPie(props.filteredData, type))
            // } else {
            //     setCurrentData(generatePie(rawData.current[curFilter.value]))
            // }
            setCurrentData(parseDataPie(props.filteredData, type))

            setLastData(generatePie(rawData?.last[curFilter.value] || pieTemplate))
        } else if (type === "workers") {
            // if ("foreignStatistics" in props && !props.foreignStatistics && props.filteredData) {
            //     setCurrentData(parseDataBar(props.filteredData, type))
            // } else {
            //     setCurrentData(generateBar(rawData.current, type, curFilter.value))
            // }
            setCurrentData(parseDataBar(props.filteredData, type))

            setLastData(generateBar(rawData?.last, type, curFilter?.value))
        } else if (type === "lost" && props.lostCallsStatistics && props.lostCallsStatistics.calls) {
            // setCurrentData([{id: "noData"}])
            // setLastData([{id: "noData"}])
        } else {
            console.log(type)
        }

    }

    useEffect(() => {

        setCurFilter(props.filter)
        setCurrentData([])
        setLastData([])
        setChartType(main_types[0].value)

        if (props.filter && props.filter.value && props.filter.value === "lost")
            setChartType("lost")

    }, [props.filter])

    useEffect(() => {
        if (!props.data.current || !props.data.last) {
            return
        }
        if (!curFilter.value || !allowedFilters.includes(curFilter.value)) {
            return
        }

        props.setCurChart(chartType)

        parseData(props.data, chartType)
    }, [props.data, curFilter, chartType, props.foreignStatistics, props.filteredData])

    const clickEvent = (e) => {
        console.log(e)
    }




    return(
        currentData.length > 0 || lastData.length > 0 || chartType === "lost" ?
        <div style={{margin: "25px 0 0 0"}}>
            <div className={`${s.selector} ${theme === "light" && s.selectorDarkTheme}`}>
                {main_types.map(
                    type_elem =>
                        type_elem.groups.includes(curFilter.label) &&
                        <div onClick={() => setChartType(type_elem.value)} key={type_elem.id}
                             className={type_elem.value === chartType ? s.selected : s.noSelected}>
                            {type_elem.label}
                        </div>
                )}

                {/*<OrangeSelect data={charts_types} changeValue={(e) => setChartType(e.value)}/>*/}
            </div>

            {chartType === "calls" &&
            <div className={`${s.charts} ${s.chartsSize}`}>
                {currentData?.length > 0 &&
                <PieChart data={currentData} label={"Текущий период"}
                          clickEvent={props.clickEvent}
                          show_legend={false} w={"600px"}/>
                }

                {lastData?.length > 0 &&
                <PieChart data={lastData} label={"Прошлый период"} show_legend={true} w={"600px"}/>
                }
            </div>
            }

            {chartType === "workers" &&
            <div className={s.charts}>
                {currentData?.length > 0 &&
                <HistogramChart data={currentData} clickEvent={props.clickEvent} keys={fields} label={"Текущий период"}/>
                }

                {lastData?.length > 0 &&
                <HistogramChart data={lastData} clickEvent={props.clickEvent} keys={fields} label={"Прошлый период"}/>
                }
            </div>
            }

            {chartType === "lost" && props.lostCallsStatistics && props.lostCallsStatistics.calls &&
            <LostClientsChart data={props.lostCallsStatistics} clickEvent={props.clickEvent}/>
            }

        </div>
            :
            <div className={`${s.spinner} ${s.chartsSize}`}>
                <Loading />
            </div>
    )
}
