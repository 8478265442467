import React, { useEffect } from 'react'
import s from './MultipleSelect.module.scss'

const MultipleSelect = ({
  value,
  setValue,
  activeCities,
  setActiveCities,
  isOpen,
  setIsOpen,
  localOpen,
  setLocalOpen,
}) => {
  const handleChange = (e) => {
    const activeValue = value.filter((val) => val.id !== +e.target.value)

    if (!activeValue.length) {
      setLocalOpen(!localOpen)
      setIsOpen(!isOpen)
    }

    setValue(activeValue)
    setActiveCities([
      ...activeCities,
      ...value.filter((val) => val.id === +e.target.value),
    ])
  }

  useEffect(() => {
    if (!isOpen && localOpen) setLocalOpen(false)
  }, [isOpen])

  return (
    <>
      <div className={s.width}/>
      <select
        className={`${s.select} ${!localOpen && s.none}`}
        onChange={handleChange}
        multiple
      >
        {value.map((val) => (
          <option key={val.id} value={val.id}>
            {val.name}
          </option>
        ))}
      </select>
    </>
  )
}

export default MultipleSelect
