import React, {useEffect, useState} from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './ManagersPage.module.scss'
import {useTheme} from "../../../hooks/useTheme";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import Cards from "./Cards/Cards"
import {saveVoipCard, userAuth, voipCards} from "../../../api";
import DefaultButton, {SubmitButton} from "../../../ui/Button/Button";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {minutesMarks} from "../../../provider/variables";
import Card from "./Cards/Card/Card"
import CheckBox from "../../../ui/CheckBox/CheckBox";


const UnlimitedTarifs = ({setActivePopup}) => {
    const {services, packetsVoipNumers, userAccount} = useTheme()
    const [service, setService] = useState()
    const [cards, setCards] = useState([])
    const [showAddNew, setShowAddNew] = useState(false)

    const schema = yup
        .object({
            name: yup.string().required(),
            wTitle: yup.string().required(),
            description: yup.string().required(),
            minutes: yup.object().required(),
        })
        .required()

    const {
        register, handleSubmit, reset, watch, setError, formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            name: "Название тарифа",
            wTitle: "",
            description: "Описание тарифа",
            minutes: ""
        }
    })

    useEffect(() => {
        let minutes = {};
        Object.entries(minutesMarks).map(val => minutes[val[1].label] = '100')

        reset({
            name: "Название тарифа",
            wTitle: "",
            description: "Описание тарифа",
            minutes: {...minutes}
        })

    } ,[minutesMarks])

    const watchFields = watch(["name", "description"]);

    useEffect(() => {
        if (!userAccount.login || !userAccount.token) return
        voipCards(userAccount)
            .then((r) => setCards(r))
    }, [userAccount])

    useEffect(() => {
        if (!services || !services.active_usl || !packetsVoipNumers || service) return

        const s = services.active_usl.find(usl => usl.usl_id && packetsVoipNumers.includes(usl.usl_id))
        setService(s)
        // console.log(s)

    }, [services, packetsVoipNumers])

    const onSubmit = (e) => {
        for (const [item] of Object.entries(e.minutes)) {
            if (e.minutes[item].length === 0) {
                setError(item, { minLength: 1 })
                return
            }
        }

        saveVoipCard({...e, ...userAccount})
            .then(() => setActivePopup("successSave"))
            .catch(e => setActivePopup("wrongTarif"))

    }

    return(
        <div>
            <div style={{marginTop: "15px"}}>
                <DefaultButton text={!showAddNew ? "Добавить новый" : "Скрыть"} clickEvent={() => setShowAddNew(!showAddNew)}/>
            </div>


            {showAddNew &&
            <div style={{marginTop: "15px"}}>
                <form onSubmit={handleSubmit(onSubmit)} className={s.formMain}>
                    <div>
                        <div className={s.formBlock}>
                            <p className={s.blockTitle}>Название тарифа</p>
                            <input
                                type="text"
                                className={
                                    errors.name
                                        ? `${s.defaultInput} ${s.error}`
                                        : s.defaultInput
                                }
                                {...register('name')}
                            />
                        </div>

                        <div className={s.formBlock}>
                            <p className={s.blockTitle}>Префикс из Абакса</p>
                            <input
                                type="text"
                                className={
                                    errors.wTitle
                                        ? `${s.defaultInput} ${s.error}`
                                        : s.defaultInput
                                }
                                {...register('wTitle')}
                            />
                        </div>

                        <div className={s.formBlock}>
                            <p className={s.blockTitle}>Описание</p>
                            <input
                                type="text"
                                className={
                                    errors.description
                                        ? `${s.defaultInput} ${s.error}`
                                        : s.defaultInput
                                }
                                {...register('description')}
                            />
                        </div>

                        <div className={s.formBlockLine}>
                            {Object.entries(minutesMarks).map((val, index) =>
                                <div key={val[1].label}>
                                    <p className={s.blockTitle}>{val[1].label} минут</p>
                                    <input type="text" size="5"
                                           className={
                                               errors[val[1].label]
                                                   ? `${s.defaultInput} ${s.error}`
                                                   : s.defaultInput
                                           }
                                           {...register(`minutes.${val[1].label}`)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={s.cards}>
                        Как это будет выглядеть
                        <Card data={{id: 0, name: watchFields[0], description: watchFields[1]}} activatable={false}/>
                    </div>

                    <div style={{alignSelf: "end"}}>
                        <SubmitButton text={"Сохранить"} />
                    </div>
                </form>
            </div>
            }

            <div>

                <Cards usl={service} cards={cards} activatable={true} setActivePopup={setActivePopup}/>
            </div>
        </div>
    )
}

const ManagersPage = (props) => {
    const {designType} = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [section, setSection] = useState("unlp")

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    return (
        <div className={`${s.mainPart} ${designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                {/*{*/}
                {/*    props.other_voip*/}
                {/*        ? <MainBlockOther />*/}
                {/*        : <MainBlock />*/}
                {/*}*/}
                <div style={{display: "flex"}}>
                    <DefaultTitle title={"Тарифы безграничной телефонии"}/>
                    <div style={{display: "inline-block", marginLeft: "50px"}}>
                        <CheckBox styleParams="inline"
                                  checked={section === "unlp"}
                                  chStatus={() => {section === "unlp" ? setSection("") : setSection("unlp")}}/>
                    </div>
                </div>

                {section === "unlp" &&
                <UnlimitedTarifs {...props}/>
                }


            </div>
        </div>
    )
}

export default ManagersPage
