import React from 'react'
import s from './Pagination.module.scss'

const Pagination = ({ pages, setActivePage, activePage }) => {
  const changeActivePage = (e) => setActivePage(+e.target.innerHTML)
  let links = []

  for (let i = 1; i <= pages; i++) {
    links.push(i)
  }

  return (
    <>
      {links.length > 1 && (
        <div className={s.pages}>
          {links.map((link) => (
            <span
              key={link}
              className={activePage === link ? `${s.page} ${s.active}` : s.page}
              onClick={changeActivePage}
            >
              {link}
            </span>
          ))}
        </div>
      )}
    </>
  )
}

export default Pagination
