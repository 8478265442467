import {useTheme} from "../../../../../hooks/useTheme";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CdrsDirections, CdrsHangupCause, minCallDuration, successed_maps} from "../../../../../provider/variables";
import {getAudioFilePath, normalizeDateDMHMS} from "../../../../../sortFunction";
import s from "./CallsTable.module.scss";
import Loading from "../../../../../ui/Loading/Loading";
import {CallDialog} from "../../../../../ui/Popup/CallDialog";
import SmsSenderPopup from "../../../../../ui/Popup/smsSenderPopup";
import PaginationBlock from "../../../../../ui/PaginationBlock/PaginationBlock";
import ReactSelect from "react-select";
import GlobalSvgSelector from "../../../../GlobalSvgSelector/GlobalSvgSelector";
import {GetApp} from "@mui/icons-material";
import Tippy from "@tippyjs/react";
import {createBrowserHistory} from "history";
import {AddCommentPopup} from "../../../../../ui/Popup/AddCommentPoppup";
import {changeCallRecordStatus, changeFeedbackStatus} from "../../../../../api";

const history = createBrowserHistory();

export const CallsHeader = ({columns, sortFunc, sortStatus, sortKey}) => {
    const {theme} = useTheme()

    return(
        <thead className={`${s.tableHead} ${theme === 'light' && s.darkHeadTheme}`}>
        <tr>
            {columns &&
            Object.keys(columns).map(key =>
                <th key={key+"_column"} onClick={() => sortFunc(key)} >
                    {columns[key]}

                    {sortStatus && sortKey === key &&
                    <span className={s.sortArrow}>
                        <GlobalSvgSelector id="select-arrow" />
                    </span>
                    }

                    {!sortStatus && sortKey === key &&
                    <span className={`${s.sortArrow} ${s.rotate}`}>
                        <GlobalSvgSelector id="select-arrow" />
                    </span>
                    }
                </th>
            )}
        </tr>
        </thead>
    )
}

export const CallsFilters = ({columns, getFilters, curFilterSequence, addFilter, activeFilters, setActiveFilters}) => {
    const theme = useTheme()
    const [searchParams, setSearchParams] = useSearchParams();
    // useEffect(() => {console.log(activeFilters)}, [activeFilters])

    const addDurFilter = (event) => {
        let f = activeFilters;

        let d = f["duration"] ? f["duration"] : {}
        let key = event.target.id === "from" ? "from" : "to";
        d[key] = event.target.value
        f["duration"] = d

        f["changes"] = f["changes"] ? f["changes"] + 1 : 1

        setActiveFilters({...f})
    }

    // https://react-select.com/styles
    const filterSelectStyle = {
        control: () => ({
            height: "30px",
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        menu: (provided, state) => ({
            ...provided,
            minWidth: "150px"
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            backgroundColor: state.isSelected
                ? "rgba(41,165,61,1)"
                : state.isFocused
                    ? "rgb(254,144,26)"
                    : "white",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: state.data && Array.isArray(state.data.label) ? "bold" : "normal",
            color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--main-font-color)'
        }),
    }

    return(
        <tr className={`${s.tableFilter} ${theme.theme === 'light' && s.tableFilterDarkTheme}`}>
            {Object.keys(columns).map(
                key => key !== "record_file"  && key !== "duration"?
                    <td onClick={() => getFilters(key)} key={key+"_filter"} className={s.reactSelect}>
                        <ReactSelect
                            options={curFilterSequence}
                            defaultValue={{label: "Нет", value: "Нет", k: key}}
                            value={
                                searchParams.get(key)
                                    ? {label: searchParams.get(key), value: searchParams.get(key), k: key}
                                    : activeFilters[key] ? {label: activeFilters[key], value: activeFilters[key], k: key}
                                    : {label: "Нет", value: "Нет", k: key}

                            }
                            onChange={addFilter}
                            styles={filterSelectStyle}
                            maxMenuHeight={175}
                            aria-rowcount={10}
                            // menuIsOpen={isOpenSelect2}
                        />
                    </td>
                    : key === "duration" ?
                        <td key={key+"_filter"}>
                            <input style={{
                                width: "35px",
                                borderRadius: "5px",
                                backgroundColor: "white !important",
                                padding: "0"}}
                                   id={"from"} onChange={addDurFilter}/>
                            -
                            <input style={{
                                width: "35px",
                                borderRadius: "5px",
                                backgroundColor: "white !important",
                                padding: "0"}}
                                   id={"to"} onChange={addDurFilter}/>
                        </td>
                        :
                        <td key={key+"_filter"}>&nbsp;</td>
            )}
        </tr>
    )
}

const AudioElem = ({t, playAudio, curAudio}) => {
    const { callrecords, isHelperOpen, helpText } = useTheme()
    const [thrdTooltip, setThrdTooltip] = useState(false)
    const [existsRecord, setExistsRecord] = useState(false)

    useEffect(() => {
        setExistsRecord(
            playAudio && t?.record_file && (t?.duration || 0) > minCallDuration
        )
    }, [callrecords])

    if (callrecords && existsRecord) return (
        <div className={s.record} >
            <div style={{display: "inline"}}
                 onClick={() => playAudio({
                     file: t?.record_file,
                     datetime: normalizeDateDMHMS(t?.call_start_stamp.replace("T", " ")),
                     numerA: t?.src_num,
                     numerB: t?.dst_num,
                 })}
            >
                {curAudio && t["record_file"] && curAudio === t["record_file"]
                    ?
                    <GlobalSvgSelector id="audio-played" />
                    :
                    <GlobalSvgSelector id="audio-button" />
                }
            </div>
        </div>
    )

    if (callrecords &&!existsRecord) return (
        <td className={s.record}/>
    )

    if (!callrecords) return (
        <div>
            <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                <Tippy
                    visible={thrdTooltip}
                    animation={'scale'}
                    content={
                        <div
                            style={{
                                background: 'var(--background-gradient-st)',
                                color: 'var(--main-font-color)',
                                padding: '12px',
                                borderRadius: '10px',
                                border: '1px solid var(--border-default)',
                                boxShadow: '0 0 20px 0 var(--border-default)',
                            }}
                        >
                            Услуга записи телефонных разговоров не подключена.
                            <br/>
                            Для подключения обратитесь к вашему менеджеру
                        </div>
                    }
                >
                    <p className={s.mark}
                        onClick={() => {
                            if (document.body.clientWidth < 1250) {
                                setThrdTooltip(true)
                                isHelperOpen(!helpText)
                            }
                        }}
                        onMouseOver={() => setThrdTooltip(true)}
                        onMouseLeave={() => setThrdTooltip(false)}
                    >
                        ?
                    </p>
                </Tippy>
            </div>
        </div>
    )
}

export const CallMenuItem = (props) => {

    const clickEvent = (e) => {
        e.stopPropagation()

        if (props.clickEvent) props.clickEvent()
    }

    return(
        <div className={s.callMenuItems}
             onClick={clickEvent}
        >
            <GlobalSvgSelector id={props.ico}/>
            <div className={s.toolTip}
                 style={{
                     background: 'var(--background-gradient-st)',
                     color: 'var(--main-font-color)',
                     padding: '12px',
                     borderRadius: '10px',
                     border: '1px solid var(--border-default)',
                     boxShadow: '0 0 20px 0 var(--border-default)',
                     position: "absolute",
                     display: "none",
                 }}
            >
                {props.text}
            </div>
        </div>
    )
}

const CallMenu = ({string, setActions, linkToCr}) => {
    const [existsSms, setExistsSms] = useState(false)
    const [srcNum, setSrcNum] = useState("Введите номер")
    const [dstNum, setDstNum] = useState("Введите номер")

    useEffect(() => {
        if (CdrsDirections[string.direction] === "Входящий") {
            setDstNum(string.src_num ? string.src_num : 0)

            let numberA = ""
            if (string.dst_num && parseInt(string.dst_num) < 1000) {
                numberA = string.dst_num
            }

            setSrcNum(numberA)

        } else if (CdrsDirections[string.direction] === "Исходящий") {
            setDstNum(string.dst_num ? string.dst_num : 0)
            setSrcNum(string.src_num ? string.src_num : 0)

        } else if (CdrsDirections[string.direction] === "Внутренний") {
            setDstNum(string.dst_num ? string.dst_num : 0)
            setSrcNum(string.src_num ? string.src_num : 0)
        }

    }, [string])

    // useEffect(() => {
    //     if (theme.smsSettings && Object.keys(theme.smsSettings) > 0) setExistsSms(true)
    //
    // } , [theme.smsSettings])

    const goToCr = (url) => {
        history.push(url)
        history.go(0)
    }

    const setComment = (id, status) => {
    }

    return(
        <div className={s.callMenu}>
            <CallMenuItem text={"Комментарий"} ico={string.comment ? "set-comment-one" : "set-comment"}
                          clickEvent={() => setActions("changeComment", string)}/>
            <CallMenuItem text={"Позвонить"} ico={"call-ico"}
                          clickEvent={() => setActions("call", {
                              dst: dstNum,
                              src: srcNum,
                          })}/>

            {existsSms &&
            <CallMenuItem text={"Отправить SMS"} ico={"sms-ico"}
                          clickEvent={() => setActions("sms", {
                              dst: dstNum,
                              src: 0
                          })}/>
            }

            {string.lost_status === "Клиент перезвонил сам" && linkToCr &&
            <CallMenuItem text={"Перейти к записям разговоров"} ico={"audio-svg"}
                          clickEvent={() => goToCr("/calllog?page=cr&src_num="+string[linkToCr])}/>
            }

            {string.lost_status !== "Клиент перезвонил сам" && linkToCr &&
            <CallMenuItem text={"Перейти к записям разговоров"} ico={"audio-svg"}
                          clickEvent={() => goToCr("/calllog?page=cr&dst_num="+string[linkToCr])}/>
            }
        </div>
    )
}

const DirectionElem = ({entry, callData, setFilter}) => {
    return(
        <td key={[entry?.[0], callData?.id?.toString()].join("_")} className={s.pointer}
            onClick={() => setFilter(entry[0], CdrsDirections[callData["direction"]]
                ? CdrsDirections[callData?.direction] : callData?.direction)}
        >
            {CdrsDirections[callData?.direction] ? CdrsDirections[callData?.direction] : callData?.direction}
        </td>
    )
}


const HangupCauseElem = ({entry, callData, setFilter}) => {
    return(
        <td key={[entry[0], callData.id.toString()].join("_")} className={s.pointer}
            onClick={() => setFilter(entry[0], CdrsHangupCause[callData["hangup_cause"]] ? CdrsHangupCause[callData["hangup_cause"]] : callData["hangup_cause"])}
        >
            {CdrsHangupCause[callData["hangup_cause"]] ? CdrsHangupCause[callData["hangup_cause"]] : callData["hangup_cause"]}
        </td>
    )
}


const CallDateElem = ({entry, callData, setFilter}) => {
    return(
        <td key={[entry[0], callData.id.toString()].join("_")}>
            {/*{normalizeDateDMHMS(string["call_start_stamp"].replace("T", " "))}*/}
            {normalizeDateDMHMS(callData["call_start_stamp"])}
        </td>
    )
}

const DurationElem = ({entry, callData, setFilter}) => {
    return(
        <td key={[entry[0], callData.id.toString()].join("_")} style={{width: "80px"}}>
            {callData?.duration}
        </td>
    )
}


const DstTypeElem = ({entry, callData, setFilter}) => {
    return(
        <td key={[entry[0], callData.id.toString()].join("_")} className={s.hovered}
            onClick={() => setFilter(entry[0], callData[entry[0]])}
        >

            {callData[entry[0]]}
        </td>
    )
}


const WorkerElem = ({entry, callData, setFilter}) => {
    return(
        <td key={[entry[0], callData.id.toString()].join("_")} className={s.hovered}
            onClick={() => setFilter(entry[0], callData[entry[0]])}
            style={{width: "60px"}}
        >
            {callData.direction === "inbound" && callData.dst_name &&
                callData.dst_name
            }

            {callData.direction === "outbound" && callData.src_name &&
                callData.src_name
            }
        </td>
    )
}


const SrcNumElem = ({entry, callData, setFilter, setActions, linkToCr}) => {
    return(
        <td key={[entry[0], callData.id.toString()].join("_")} className={s.hovered}
            onClick={() => setFilter(entry[0], callData[entry[0]])}
            style={{width: "60px"}}
        >
            {callData[entry[0]]}

            <CallMenu string={callData} setActions={setActions} linkToCr={linkToCr}/>
        </td>
    )
}

const DefaultValue = ({entry, callData, setFilter}) => {
    return(
        <td key={[entry[0], callData.id.toString()].join("_")} className={s.pointer}
            onClick={() => setFilter(entry[0], callData[entry[0]])}
        >
            {callData[entry[0]]}
            {/*{console.log(entry)}*/}
        </td>
    )
}


const RecordFileElem = ({entry, callData, playAudio, curAudio}) => {
    const theme = useTheme()
    const { callrecords } = useTheme()

    return(
        <td key={[entry[0], callData.id.toString()].join("_")}
            style={{width: "80px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <AudioElem t={callData} playAudio={playAudio} crIsActive={theme?.crIsActive} curAudio={curAudio}/>

            {(callData?.record_file?.length > 0 && callData?.duration > 0 && callrecords) &&
                <a href={getAudioFilePath(callData?.record_file)} className={s.audioDownload} target={"__blank"} >
                    <GetApp />
                </a>
            }
        </td>
    )
}

const CallElem = (props) => {
    const {columns, playAudio, curAudio, setFilter, setActions, linkToCr, callData} = props

    // useEffect(() => {
    //     // console.log(callData?.uuid)
    //
    // }, [callData.record_file])

    return(
        <tr key={callData.id.toString()}
            style={callData.theme === 'light' && callData.comment ? {color: 'var(--light-color)'} : {}}
            className={`${s.hovered} ${callData.comment ? s.withComment : ""}`}>

            {Object.entries(columns).map(entry =>
                entry?.[0] === "direction" ? <DirectionElem entry={entry} callData={callData} setFilter={setFilter} key={`${callData?.uuid}_${entry?.[0]}`}/>
                :
                entry?.[0] === "hangup_cause" ? <HangupCauseElem entry={entry} callData={callData} setFilter={setFilter} key={`${callData?.uuid}_${entry?.[0]}`}/>
                :
                entry?.[0] === "call_start_stamp" ? <CallDateElem entry={entry} callData={callData} setFilter={setFilter} key={`${callData?.uuid}_${entry?.[0]}`}/>
                :
                entry?.[0] === "duration" ? <DurationElem entry={entry} callData={callData} setFilter={setFilter} key={`${callData?.uuid}_${entry?.[0]}`}/>
                :
                entry?.[0] === "record_file" ? <RecordFileElem entry={entry} callData={callData} setFilter={setFilter} playAudio={playAudio} curAudio={curAudio} key={`${callData?.uuid}_${entry?.[0]}`}/>
                :
                entry?.[0] === "dst_type" ? <DstTypeElem entry={entry} callData={callData} setFilter={setFilter} key={`${callData?.uuid}_${entry?.[0]}`}/>
                :
                entry?.[0] === "worker" ? <WorkerElem entry={entry} callData={callData} setFilter={setFilter} key={`${callData?.uuid}_${entry?.[0]}`}/>
                :
                entry?.[0] === "src_num" ? <SrcNumElem entry={entry} callData={callData} setFilter={setFilter} setActions={setActions} linkToCr={linkToCr} key={`${callData?.uuid}_${entry?.[0]}`}/>
                : <DefaultValue entry={entry} callData={callData} setFilter={setFilter} key={`${callData?.uuid}_${entry?.[0]}`}/>
            )}
        </tr>
    )
}

export const CallsBody = ({data, columns, playAudio, curAudio, setFilter, setActions, linkToCr}) => {

    const props = {data, columns, playAudio, curAudio, setFilter, setActions, linkToCr}
    return(
        data && data.length > 0 && columns &&
        data.map(callData =>
            <CallElem callData={callData} {...props} key={callData?.uuid}/>
        )
    )
}


export const CallsTable = (props) => {
    const theme = useTheme()
    const [searchParams, setSearchParams] = useSearchParams();
    const [filteredData, setFilteredData] = useState([])
    const [paginatedData, setPaginatedData] = useState([])
    const [sortStatus, setSortStatus] = useState(false)
    const [sortKey, setSortKey] = useState("call_start_stamp")

    const [curPage, setCurPage] = useState(1)
    const [paginationSize, setPaginationSize] = useState({ value: 20, label: '20' })

    const [curFilterSequence, setCurFilterSequence] = useState([])
    const [activeFilters, setActiveFilters] = useState({"changes": 0})
    const [dialogs, setDialog] = useState("")
    const [dialogData, setDialogData] = useState({})

    useEffect(() => {
        if (!props.pageSize) return

        setPaginationSize(props.pageSize)
    }, [props.pageSize])

    useEffect(() => {
        if (!props.filters) return
        addFilter(props.filters)

    }, [props.filters])


    useEffect(() => {
        if (!props.callsData || !props.callsData.results) return

        const d = [...filterRows(props.callsData.results)]
        setFilteredData(d)

        if (props.setActiveSequence) {
            props.setActiveSequence(d)
        }

    }, [props.callsData, activeFilters.changes])

    const sortData = (data) => {
        if (sortStatus) return data.sort((a, b) => a[sortKey] > b[sortKey] ? 1 : -1)
        else return data.sort((a, b) => a[sortKey] < b[sortKey] ? 1 : -1)
    }

    useEffect(() => {
        setPaginatedData([...paginateData(sortData(filteredData))])
    }, [filteredData, curPage, paginationSize, sortKey, sortStatus])

    const filterRows = (data) => {
        if (!data) return

        let fusionNumber = theme.userAccount && theme.userAccount.fusion_auth ?
            theme.userAccount.fusion_auth : ""
        // fusionNumber = "610"

        let filtered = data
        if (fusionNumber.length > 0) {
            filtered = filtered.filter(val => val.src_num === fusionNumber || val.dst_num === fusionNumber)
        }

        for (const [key, element] of Object.entries(activeFilters)) {
            if (key === "changes") continue
            let elem = element

            if (key === "duration") {
                if (elem["from"]) {
                    filtered = filtered.filter(val => val[key] >= elem["from"])

                    if (elem["from"] > 0)
                        filtered = filtered.filter(val => !!val["record_file"])
                    else {
                        filtered = filtered.filter(val => !val["record_file"])
                    }
                }
                //console.log(filtered)

                if (elem["to"]) {
                    filtered = filtered.filter(val => val[key] <= elem["to"])

                    if (elem["to"] > 0)
                        filtered = filtered.filter(val => !!val["record_file"])
                    else {
                        filtered = filtered.filter(val => !val["record_file"])
                    }
                }

                if (props.durationFilter) props.durationFilter([key, element])
                continue
            } else if (key === "direction") {
                if (Object.keys(CdrsDirections).find(k => CdrsDirections[k].includes(elem))) {
                    elem = Object.keys(CdrsDirections).find(k => CdrsDirections[k].includes(elem))
                } else {
                    // console.log(key, elem)

                }
            } else if (key === "hangup_cause") {
                if (Object.keys(CdrsHangupCause).find(k => CdrsHangupCause[k].includes(elem))) {
                    elem = Object.keys(CdrsHangupCause).find(k => CdrsHangupCause[k].includes(elem))
                } else {
                    if (elem.includes("Успешные")) {
                        filtered = filtered.filter(val => successed_maps.includes(val[key]))
                        continue
                    } else if (elem.includes("Неуспешные")) {
                        filtered = filtered.filter(val => !successed_maps.includes(val[key]))
                        continue
                    } else {
                        // console.log(key, elem)
                    }
                }
            } else if (key === "worker") {
                filtered = filtered.filter(val => (val.direction === "inbound" && elem.includes(val.dst_name)) || (val.direction === "outbound" && elem.includes(val.src_name)))
                continue

            }
            // console.log(elem)


            filtered = filtered.filter(val => elem.includes(val[key]))
        }

        setCurPage(1)
        if (props.setFilteredData) props.setFilteredData(filtered)
        return filtered
    }

    const paginateData = (data) => {
        const pageSize = paginationSize.value

        return data.slice(
            pageSize * curPage - pageSize,
            pageSize * curPage,
        )
    }

    const addSortParams = (key) => {
        setSortStatus(!sortStatus)
        setSortKey(key)
    }

    const getFilters = (e) => {
        setCurFilterSequence([])

        let f = [{label: "Нет", value: "Нет", k: e}]
        let prev = []
        let names = {}
        for (const [_, elem] of Object.entries(filteredData)) {
            let label = elem[e]
            let val = elem[e]


            if (e === "direction") val = CdrsDirections[val] ? CdrsDirections[val] : val
            else if (e === "hangup_cause") val = CdrsHangupCause[val] ? CdrsHangupCause[val] : val
            else if (e === "call_start_stamp") val = normalizeDateDMHMS(val.replace("T", " "))
            else if (e === "worker") {
                if (elem.direction === "inbound" && elem.dst_name) {
                    label = elem.dst_name
                    val = elem.dst_name
                } else if (elem.direction === "outbound" && elem.src_name) {
                    label = elem.src_name
                    val = elem.src_name
                }
            }

            if (!prev.includes(val)) prev.push(val)
            names[val] = label

        }
        prev.sort()
        setCurFilterSequence(
            [...f, ...prev.map(elem => ({label: elem, value: names[elem], k: e}))])
    }

    const removeFilter = (val) => {
        let f = activeFilters;

        if(f[val.k]) {

            delete f[val.k]
        }

        f["changes"] = f["changes"] ? f["changes"] + 1 : 0
        setActiveFilters({...f})
    }

    const addFilter = (val) => {
        if (!val["label"]) return
        if (val.k && val.k === "clearAll") {
            setActiveFilters({"changes": 0})
            return
        }

        if (val["label"] === "Нет") {
            removeFilter(val)

            if (searchParams.get(val.k)) {
                setSearchParams("")
            }

            return
        }

        let f = activeFilters;
        if (!f[val["k"]]) {
            f[val["k"]] = []
        }

        // if (f[val["k"]].includes(val["value"])) return
        if (f[val["k"]]) {
            f[val["k"]] = []
        }

        f[val["k"]].push(val["value"])

        f["changes"] = f["changes"] ? f["changes"] + 1 : 1
        setActiveFilters({...f})
    }

    const addFilterFromClickValue = (key, val) => {
        addFilter({label: val, value: val, k: key})
    }

    const callActions = (target, elem) => {
        console.log(target, elem)

        // if (elem) set
        if (!elem) return


        setDialog(target)
        setDialogData(elem)
    }

    const changeData = (uuid, key, val) => {
        let d = [...filteredData]
        d.map(elem => elem.uuid === uuid && key in elem ? elem[key] = val : null)

        setFilteredData([...d])
    }

    return(
        <div className={s.callsTable} id={"callstable"}>
            {paginatedData && paginatedData.length > 0 &&
            <div style={theme.theme === 'light' ? {color: 'var(--light-color)'} : {}}
                className={s.counterBlock} >Всего вызовов: {filteredData.length}</div>
            }

            {props.showLoader &&
            <Loading />
            }

            <table className={s.table}>
                <CallsHeader columns={props.columns} sortFunc={addSortParams}
                             sortStatus={sortStatus} sortKey={sortKey}
                />

                <tbody className={s.tableBody}>
                <CallsFilters columns={props.columns} getFilters={getFilters}
                              curFilterSequence={curFilterSequence} addFilter={addFilter}
                              activeFilters={activeFilters} setActiveFilters={setActiveFilters}/>
                <CallsBody data={paginatedData} columns={props.columns}
                           curAudio={props.curAudio}
                           playAudio={props.playAudio}
                           setFilter={addFilterFromClickValue}
                           setActions={callActions}
                           linkToCr={props.linkToCr}
                />
                </tbody>
            </table>
            <CallDialog show={dialogs === "call"} hide={() => setDialog("")} data={dialogData}/>
            <SmsSenderPopup show={dialogs === "sms"} hide={() => setDialog("")} number={dialogData.dst} />
            <AddCommentPopup show={dialogs === "changeComment"} changeData={changeData}
                             elem={dialogData} hide={() => {setDialog(""); setDialogData({})}}/>

            <PaginationBlock curPage={curPage} setCurPage={setCurPage}
                             callData={filteredData} paginationSize={paginationSize.value}/>
        </div>
    )
}
