import React, {useEffect, useState} from "react";
import {
    callRecordsColls,
    callsCards, callsCardsWGraphics,
    CdrsDirections, CdrsHangupCause, inbound_maps, lostClientsCols, outbound_maps, phoneHeader, prices, successed_maps,
    uuidRe,
    virtualUsersCrMinutesDif
} from "../../../../provider/variables";
import s from "./Calls.module.scss"
import {
    addDayToDate,
    changeDateTime,
    convertDateToUcdr,
    convertDateToUcdrFull,
    getAudioFilePath,
    sleep
} from "../../../../sortFunction";
import {
    findCallRecordsByNumber,
    getCallRecords,
    getCallRecordsLost,
    getLostCallsStatistics,
    getUcdrStatistics
} from "../../../../api";
import {useSearchParams} from "react-router-dom";
import {useTheme} from "../../../../hooks/useTheme";
import {DateBubbleSmall} from "../../../../ui/Bubbles/DateBubble/DateBubble";
import {DeviceBubble} from "../../../../ui/Bubbles/DeviceBubble/DeviceBubble";
import DefaultButton from "../../../../ui/Button/Button";
import {CallsTable} from "./Table/CallsTable";
import {AudioPlayer} from "../../../../ui/AudioPlayer/AudioPlayer";
import CallingRecords from "../../NumersShop/MainBlock/VirtualATS/CallingRecords/CallingRecords";
import {AnalyticsBlock} from "../Analytics/AnalyticsBlock";
import {CSVLink} from "react-csv";
import {GetApp} from "@mui/icons-material";
import {WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";
import {SearchBubble} from "../../../../ui/Bubbles/SeacrhBubble/SearchBubble";


const FilterCard = ({data, current, select, count}) => {
    const {theme} = useTheme()

    useEffect(() => {}, [data, current])
    return(
        <div className={`${s.filterCard} ${current.id === data.id && s.selectedCard}`}
             style={theme === 'light' ? {color: 'var(--light-color)'} : {}}
             onClick={() => select(data)}
        >
            {data.label} ({count})
        </div>
    )
}

const DownloadButton = (props) => {
    const [exportData, setExportData] = useState([])

    const parse_cr_data = (elem) => {
        let sub_data = []
        for (const key of Object.keys(callRecordsColls)) {
            let val = ""
            if (key in elem) val = elem[key]

            if (key === "direction") {
                val = CdrsDirections[elem["direction"]] ? CdrsDirections[elem["direction"]] : val
            } else if (key === "hangup_cause") {
                val = CdrsHangupCause[elem["hangup_cause"]] ? CdrsHangupCause[elem["hangup_cause"]] : val
            } else if (key === "call_start_stamp") {
                val = changeDateTime(elem["call_start_stamp"])
            } else if (key === "worker") {
                if (elem.direction === "inbound") val = elem.dst_name
                if (elem.direction === "outbound") val = elem.src_name
            }

            if (key === "record_file") {
                val = getAudioFilePath(val)
            }

            sub_data.push(val)
        }
        return sub_data
    }

    const parse_lost_data = (elem) => {
        let sub_data = []
        for (const key of Object.keys(lostClientsCols)) {
            let val = ""
            if (key in elem) val = elem[key]

            sub_data.push(val)
        }
        return sub_data
    }

    useEffect(() => {
        setExportData([])
        if (!props.data || !props.data.results || !props.columns) return

        let data = []
        for (const [_, elem] of Object.entries(props.data.results)) {
            // ААА: если звонок пришел с шлюза вместо None записывается ууид шлюза
            if (uuidRe.exec(elem.dst_num)) {
                elem.dst_num = ""
            }
            if (uuidRe.exec(elem.src_num)) {
                elem.src_num = ""
            }

            if (uuidRe.exec(elem.external_num)) {
                elem.external_num = ""
            }

            if (props.columns === callRecordsColls) {
                data.push(parse_cr_data(elem))
            } else if (props.columns === lostClientsCols) {
                data.push(parse_lost_data(elem))
            }
        }
        setExportData(data)

        // console.log(data)
    }, [props.data, props.columns])

    return(
        exportData.length > 0 && props.columns ?
        <CSVLink data={exportData} className={s.csvLink}
                 headers={Object.values(props.columns)}
                 separator=";"
                 filename={"detail.csv"}
                 data-tooltip="Скачать в csv">
            <button className={[s.btn, s.btnDownload].join(" ")}>
                <GetApp className={s.SvgElem}/> в csv
            </button>
        </CSVLink>
            : ""
    )
}


const FilterCards = (props) => {
    const [cards, setCards] = useState([])
    const [selected, setSelected] = useState({})

    useEffect(() => {
        if (!selected.filter) return

        if (CdrsDirections[selected.filter]) {
            props.selectFilterCard({label: selected.filter, value: selected.filter, k: "direction"})
        } else if (selected.filter === "all") {
            props.selectFilterCard({label: "Нет", value: "Нет", k: "direction"})
        } else {
            props.selectFilterCard({label: selected.filter, value: selected.filter, k: "notFilter"})
            // console.log("No filter settings for ", selected)
        }

        //{label: value, value: value, k: target}


    }, [selected])

    useEffect(() => {
        if (!callsCards) return

        setCards(callsCards)
        setSelected(callsCards[0])

    }, [callsCards])

    return(
        <div className={s.filterCards}>
            {cards.map(elem =>
                <FilterCard data={elem} current={selected} select={setSelected}
                            key={elem.id} count={props.counts && props.counts[elem.filter] ? props.counts[elem.filter] : 0}/>
            )}
        </div>
    )
}


export const Calls = (props) => {
    const {vatsNames, userAccount, vatsDomain} = useTheme()
    const [counts, setCounts] = useState({})

    // Head bubbles
    const [selectedDevice, selectDevice] = useState()
    const [paginationSize, setPaginationSize] = useState({ value: 20, label: '20' })
    const [crData, setCrData] = useState([])
    const [lostData, setLostData] = useState([])
    const [lostCallsStatistics, setLostCallsStatistics] = useState({})
    const [statisticsData, setStatisticsData] = useState({})
    const [foreignStatistics, setForeignStatistics] = useState(true)
    const [isRtu, setRtu] = useState(false)
    const [durationFilter, setDurationFilter] = useState([])
    const [filteredData, setFilteredData] = useState([])

    const [valueStart, setValueStart] = useState(new Date())
    const [valueEnd, setValueEnd] = useState(new Date())

    const [searchParams, setSearchParams] = useSearchParams();

    const [filters, setFilter] = useState({})
    const [selectedCard, setSelectedCard] = useState({})
    const [requestStatus, setRequestStatus] = useState(false)
    const [searchResult, setSearchResult] = useState(false)
    const [searchNumber, setSearchNumber] = useState("")
    const [crStatus, setCrStatus] = useState()

    const [curAudio, setCurAudio] = useState()
    // const [playStatus, setPlayStatus] = useState(false)
    const [activeSearch, setActiveSearch] = useState(false)
    const [audioData, setAudioData] = useState()
    const [curChart, setCurChart] = useState("")
    const tableRef = React.useRef(null);

    const parseData = (response) => {
        setRequestStatus(false)
        if (!response.results) return
        setCrData(response)
    }

    useEffect(() => {
        const loc_keys = Object.keys(CdrsDirections).filter(k => CdrsDirections[k] === "Внутренний")
        let cnts = {}
        if (crData.results) {
            cnts = {
                "all": crData.results.length,
                "inbound": crData.results.filter(e => inbound_maps.includes(e.direction)).length,
                "outbound": crData.results.filter(e => outbound_maps.includes(e.direction)).length,
                "local": crData.results.filter(e => loc_keys.includes(e.direction)).length,
            }

        }

        if (lostCallsStatistics && lostCallsStatistics.all_calls_current) {
            cnts["lost"] = lostCallsStatistics.all_calls_current
        }

        setCounts(cnts)
    }, [crData, lostCallsStatistics])

    const loadStatistics = () => {
        setLostCallsStatistics({})
        // if(!/\d+/.exec(selectedDevice.label)) return // Не нужно грузить статистику для абонентов РТУ
        if (!valueStart || !valueEnd || !selectedDevice) return;

        let urlContext = "?"
        urlContext += getDate()
        if (urlContext.length > 1) urlContext += "&";

        // Домен
        if (urlContext.length === 1) {
            urlContext += "domain=" + selectedDevice.label

        } else {
            urlContext += "&domain=" + selectedDevice.label
        }

        getLostCallsStatistics({
            login: userAccount["login"],
            token: userAccount["token"],
            url: urlContext,
        }).then(result => setLostCallsStatistics(result))
    }

    const getDate = (start=0, end=0) => {
        let date_str = ""
        if (valueStart === valueEnd) {
            date_str += "date="+convertDateToUcdrFull(addDayToDate(valueStart, start))
        } else {
            if (userAccount.virtual) {
                let d = new Date()
                date_str += "date_range_after="+convertDateToUcdrFull(d, virtualUsersCrMinutesDif)
                // Время
            } else {
                date_str += "date_range_after="+convertDateToUcdrFull(valueStart)
            }
            date_str += "&date_range_before="+convertDateToUcdrFull(valueEnd)
        }
        return date_str

    }

    const loadData = (target="") => {
        // console.log("loadData")
        setSearchResult(false)
        setActiveSearch(false)
        if (!valueStart || !valueEnd || !paginationSize.value || !selectedDevice) return;
        if (target === "csv" && crData) {
            parseData(crData, target)
            return
        }

        if (requestStatus) return

        setStatisticsData({})

        let requestPage = "all"
        // let requestPage = page

        let urlContext = "?"
        let statisticsUrl = "?"
        if ((!searchParams.get("src_num") && !searchParams.get("dst_num")) || target === "new") {
            urlContext += getDate()
        } else {
            urlContext += getDate(-7, 0)
        }

        // Домен
        if (urlContext.length > 1) urlContext += "&";
        urlContext += "domain=" + selectedDevice.label
        urlContext += "&is_normal=true"
        statisticsUrl = urlContext

        // Номер страницы
        if (!userAccount.virtual) {
            urlContext += "&page=" + requestPage
        }
        urlContext += "&page_size=500"

        if (searchParams.get("dst_num")) {
            urlContext += "&dst_num=" + searchParams.get("dst_num")
        }

        if (searchParams.get("src_num")) {
            urlContext += "&src_num=" + searchParams.get("src_num")
        }

        setRequestStatus(true)
        const checkResult = (r) => {
            setRequestStatus(false)

            if (r.status && r.status === "ready")
                parseData(r, target)
            else if (r.status && r.status === "prepare")
                loadData("cr")
        }
        getCallRecords({
            login: userAccount["login"],
            token: userAccount["token"],
            url: urlContext,
        }).then(result => checkResult(result))

        // if(!/\d+/.exec(selectedDevice.label)) {
        //     return // Не нужно грузить статистику для абонентов РТУ
        // }
        if (target === "cr" || isRtu) return
        getUcdrStatistics({
            login: userAccount["login"],
            token: userAccount["token"],
            url: statisticsUrl,
        }).then(result => setStatisticsData(result))
            .catch(e => {
                console.log(e)
                setRtu(true)
            })
        setSearchParams("")
    }

    const loadLostData = (target="") => {
        if (!valueStart || !valueEnd || !paginationSize.value || !selectedDevice) return;
        if (target === "csv" && crData) {
            parseData(crData, target)
            return
        }
        // if(!/\d+/.exec(selectedDevice.label)) return // Не нужно грузить статистику для абонентов РТУ

        if (requestStatus) return

        let requestPage = "all"
        // let requestPage = page

        let urlContext = "?"
        urlContext += getDate()

        // Домен
        if (urlContext.length > 1) urlContext += "&";
        urlContext += "domain=" + selectedDevice.label
        urlContext += "&is_normal=true"
        urlContext += "&is_lost=true"

        // Номер страницы
        if (!userAccount.virtual) {
            urlContext += "&page=" + requestPage
        }
        urlContext += "&page_size=500"
        // console.log(urlContext)

        const checkResult = (r) => {
            if (r.status && r.status === "ready")
                setLostData(r)
            else if (r.status && r.status === "prepare") {
                loadLostData(target)
            }

        }
        getCallRecordsLost({
            login: userAccount["login"],
            token: userAccount["token"],
            url: urlContext,
        }).then(result => checkResult(result))
    }

    const playAudio = (src) => {
        if (!src?.file) return
        if (src?.file === curAudio) {
            // if (playStatus) setPlayStatus(false)
            // if (!playStatus) setPlayStatus(true)
        } else {
            setCurAudio(src?.file)
            // setPlayStatus(true)
            setAudioData({
                datetime: src?.datetime || "",
                numerA: src?.numerA || "",
                numerB: src?.numerB || "",
            })
        }
    }

    const changeCallRecordsStatus = (id, val) => {
        //setCrStatus(id)
        console.log("Пока readonly")
        props.setActivePopup("failcr")

    }


    useEffect(() => {
        if (!selectedDevice) return;

        // Проверка на дату. Если отличается от сегодняшней, не делаем автозагрузку
        let today = new Date()
        let year = today.getFullYear()
        let month = today.getMonth()
        let day = today.getDate()

        if (valueStart.getDate() !== day ||
            valueStart.getMonth() !== month ||
            valueStart.getFullYear() !== year
        ) return

        if (valueEnd.getDate() !== day ||
            valueEnd.getMonth() !== month ||
            valueEnd.getFullYear() !== year
        ) return

        // console.log("useEffect", searchParams, valueStart, valueEnd, selectedDevice, selectedDevice)

        loadData()
        loadStatistics()
        loadLostData()
        // if (selectedCard.label === "lost") {
        //     setFilter({label: "Нет", value: "Нет", k: "direction"})
        //     loadLostData()
        // }

    }, [searchParams, valueStart, valueEnd, selectedDevice, selectedDevice])

    const loadAll = () => {
        loadData()
        loadStatistics()
        loadLostData()
    }


    useEffect(() => {
        if (selectedCard.k !== "notFilter") setFilter(selectedCard)
        else if (selectedCard.label === "lost") {
            setFilter({label: "Нет", value: "Нет", k: "direction"})
            // loadLostData()
        }
        else console.log(selectedCard)
    }, [selectedCard])

    const goToRef = (target) => {
        // console.log(target)
        if (target) window.scrollTo(0, target.current.offsetTop)
    }

    const setFilters = (f) => {

        if (f.label) {
            if (f.label === "Успешные") {
                setFilter({label: "success", value: "Успешные", k: "hangup_cause"})
            } else if (f.label === "Неуспешные") {
                setFilter({label: "fail", value: "Неуспешные", k: "hangup_cause"})
            }
            else {
                setFilter(f)
            }
            goToRef(tableRef)

        } else if (f.id) {
            if (f.indexValue && f.data && f.data.fullName)
                setFilter({label: f.data.fullName, value: f.data.fullName, k: "worker"})

            goToRef(tableRef)

            // if (f.id === "successful") setFilter({label: "success", value: "Успешные", k: "hangup_cause"})
            // if (f.id === "unsuccessful") setFilter({label: "fail", value: "Неуспешные", k: "hangup_cause"})
        } else {
            console.log(f)
        }
    }

    useEffect(() => {
        if (curChart === "lost" || selectedCard.k === "notFilter")
            setFilter({label: "clearAll", value: "clearAll", k: "clearAll"})

    }, [curChart, selectedCard])

    useEffect(() => {
        if (durationFilter.length !== 2) {
            setForeignStatistics(true)
            return
        }
        if (!durationFilter[1].from && !durationFilter[1].to) {
            setForeignStatistics(true)
            return
        }
        if (durationFilter[1].from === "" && durationFilter[1].to === "") {
            setForeignStatistics(true)
            return
        }
        setForeignStatistics(false)

    }, [durationFilter])

    const searchCdrsByNumber = (target="") => {
        if (searchNumber?.length < 4) {
            return
        }
        let urlContext = `?${getDate()}`

        // Домен
        if (urlContext.length > 1) urlContext += "&";
        urlContext = `${urlContext}domain=${selectedDevice.label}`
        urlContext = `${urlContext}&num=${searchNumber}`
        urlContext = `${urlContext}&page=1`

        // Номер страницы
        urlContext = `${urlContext}&page_size=500`
        setRequestStatus(true)
        findCallRecordsByNumber({...userAccount, urlContext: urlContext})
            .then(r => {
                setRequestStatus(false)
                setSearchResult(true)
                parseData(r, target)
            })
            .catch(r => {
                setRequestStatus(false)
                console.log(r)
            })
    }


    return(
        <div>
            <FilterCards counts={counts} selectFilterCard={setSelectedCard}/>

            <div className={s.headBlock}>
                <DateBubbleSmall setStart={setValueStart} setEnd={setValueEnd} />
                <DeviceBubble selectedDevice={selectedDevice} selectDevice={selectDevice}
                              paginationSize={paginationSize} setPaginationSize={setPaginationSize}
                              deviceList={vatsNames}/>
                <SearchBubble search={searchCdrsByNumber} requestStatus={requestStatus}
                              searchResult={searchResult} reload={loadAll}
                              searchNumber={searchNumber} setSearchNumber={setSearchNumber}/>
            </div>

            <div style={{marginBottom: "25px"}}>
                {!requestStatus &&
                <DefaultButton text={"Загрузить"} clickEvent={loadAll}/>
                }

                {requestStatus &&
                <DefaultButton text={"Идёт загрузка..."} />
                }

                {crData.results && crData.results.length >0 && selectedCard.label !== "lost" &&
                <DownloadButton data={crData} columns={callRecordsColls}/>
                }

                {lostData.results && lostData.results.length > 0 && selectedCard.label === "lost" &&
                <DownloadButton data={lostData} columns={lostClientsCols}/>
                }

            </div>

            <CallingRecords crStatus={crStatus} setCrStatus={changeCallRecordsStatus}/>

            {curAudio &&
                <AudioPlayer src={curAudio} data={audioData} />
            }


            {(callsCardsWGraphics.includes(selectedCard.label) && !isRtu) &&
            <div style={{marginBottom: "25px"}}>
                <AnalyticsBlock data={statisticsData} filter={selectedCard}
                                lostData={lostData} setCurChart={setCurChart}
                                foreignStatistics={foreignStatistics} filteredData={filteredData}
                                reload={loadData}
                                lostCallsStatistics={lostCallsStatistics} clickEvent={setFilters}/>
            </div>
            }

            <div ref={tableRef}>
                {selectedCard.label && selectedCard.label !== "lost" && curChart !== "lost" &&
                <CallsTable callsData={crData}
                            setFilteredData={setFilteredData}
                            columns={callRecordsColls}
                            pageSize={paginationSize}
                            filters={filters}
                            showLoader={requestStatus}
                            durationFilter={setDurationFilter}
                    // linkToCr={"src_num"}
                            curAudio={curAudio}
                            playAudio={playAudio}
                />
                }

                {((selectedCard.label && selectedCard.label === "lost") || curChart === "lost") &&
                <CallsTable callsData={lostData}
                            columns={lostClientsCols}
                            pageSize={paginationSize}
                            filters={filters}
                            showLoader={requestStatus}
                            linkToCr={"src_num"}
                />
                }
            </div>
        </div>
    )
}
