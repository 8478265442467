import React, {useEffect, useState} from "react";
import styles from "./ErrorMessagePopup.module.scss"
import {useTheme} from "../../hooks/useTheme";
import {sleep} from "../../sortFunction";

export const ErrorMessagePopup = () => {
    const {globalError, setGlobalError} = useTheme()
    const [errorsAll, setAllErrors] = useState([])

    const removeError = (error) => {
        setAllErrors([...errorsAll.filter(v => v !== error)])
        setGlobalError([...globalError.filter(v => v !== error)])
    }

    const addError = (error) => {
        if (error?.length === 0) return
        if (errorsAll?.includes(error)) return
        setAllErrors(oldArray => [...oldArray, error]);
        // sleep(2000).then(() => removeError(error))
    }

    const parseErrors = (errors) => {
        if (errors?.length === 0) return

        let err = [...errors]
        let next = err.pop()
        addError(next)
        sleep(500).then(() => parseErrors(err))
    }

    useEffect(() => {
        parseErrors(globalError)
    }, [globalError])

    return(
        errorsAll?.length > 0 &&
        <div className={styles.errorsBlock}>
            {errorsAll.map(text =>
                <div className={styles.errorMessage} key={text}>
                    <div className={styles.title}>
                        <span>Ошибка</span>
                        <span onClick={() => removeError(text)} className={styles.xButton}>X</span>
                    </div>

                    {text}
                </div>
            )}
        </div>

    )
}