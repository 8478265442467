import React, { useEffect, useState } from 'react'
import {
    documentsAll,
    getPacket,
    servicesAll,
    servicesAvailable,
    userBalance,
    userContacts,
    userSettings,
    getMailings,
    userAuth,
    userUpdateToken,
    getUserManagers,
    getNumbersPool,
    newNumbersPool,
    getProperties,
    getIvrData,
    getSavedIvrData,
    getSmsSettings,
    getFaqHelps,
    innerNumbersAuth, getFrontWcDomain, getVoipPrices
} from '../api'
import { ThemeContext } from '../context/ThemeContext'
import {
    menu,
    settingsMenu,
    filters,
    mailingsPlans,
    servicesList,
    callRecordsDrives, servicesId, prices,
} from './variables'
import {createBrowserHistory} from "history";
import {getSmsAeroSettings} from "../api-sms";
import {getPinsPacket} from "../components/pages/PacketsPage/api";


const history = createBrowserHistory();

const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark')
    const [designType, setDesignType] = useState(localStorage.getItem('designType') || 'minimal')

    const [userAccount, setUserAccount] = useState({
        login: localStorage.getItem("login") ? localStorage.getItem("login") : "",
        password: localStorage.getItem("password") ? localStorage.getItem("password") : "",
        token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
        manager: localStorage.getItem("manager") ? localStorage.getItem("manager") : "",
        virtual: localStorage.getItem("virtual") ? localStorage.getItem("virtual") : "",
        fusion_auth: localStorage.getItem("fusion_auth") ? localStorage.getItem("fusion_auth") : null,
    })
    const [activePopupGlobal, setActivePopupGlobal] = useState("")
    const [yesNoTrigger, setYesNoTrigger] = useState(false)
    const [callrecords, setCallrecords] = useState(false)

    const [balance, setBalance] = useState()
    const [services, setServicesAll] = useState()
    const [allDocuments, setAllDocuments] = useState()
    const [availableServices, setAvailableServices] = useState()
    const [packet, setPacket] = useState()
    const [contacts, setContacts] = useState()
    const [settings, setSettings] = useState()
    const [mailings, setMailings] = useState()
    const [existsDrives, setDrives] = useState([])
    const [cbcServices, setCbcServices] = useState([])
    const [vatsNames, setVats] = useState([])
    const [vatsDomain, setVatsDomain] = useState({})

    const [currentVatsPacket, setCurrentVatsPacket] = useState({})
    const [packageData, setPackageData] = useState({})

    const [voipNumers, setVoipNumers] = useState([])

    const [packetsVoipNumers, setPacketsVoipNumers] = useState([])
    const [phoneNumers, setPhoneNumers] = useState([])
    const [additionalServices, setAdditionalServices] = useState([])
    const [cbcAuth, setCbcAuth] = useState([])
    const [loginData, setLoginData] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [globalError, setGlobalError] = useState([])
    const [userManagers, setManagers] = useState()
    const [numbers, setNumbers] = useState([])
    const [properties, setProperties] = useState([])

    const [selectIsOpen, setSelectIsOpen] = useState(false)
    const [abonentPay, setAbonentPay] = useState(false)
    const [numbersShopPrice, setNumersShopPrice] = useState(0)
    const [shopPhonePrice, setShopPhonePrice] = useState(0)
    const [shopVoipPrice, setShopVoipPrice] = useState(0)
    const [helpText, isHelperOpen] = useState(false)
    const [ipAddresses, setIpAddresses] = useState([])
    const [currentVoipPacket, setCurrentVoipPacket] = useState({})
    const [newUserActions, setNewUserActions] = useState(false)
    const [ivrData, setIvrData] = useState({})
    const [savedIvrData, setSavedIvrData] = useState({})
    const [smsSettings, setSmsSettings] = useState({})
    const [faqHelp, setFaqHelp] = useState([])
    const [crIsActive, setCrIsActive] = useState(false)
    const [vzIsActive, setVzIsActive] = useState(false)
    const [crmIntegrIsActive, setCrmIntegrIsActive] = useState(false)
    const [tSecurityIsActive, setTSecurityIsActive] = useState(false)

    const [playStatus, setPlayStatus] = useState(false)
    const [curAudio, setCurAudio] = useState("")
    const [shopList, setShopList] = useState({fullPrice: 0, elements: []})
    const [voipPrices, setVoipPrices] = useState({})

    // Записи разговоров
    const [crData, setCrData] = useState([])

    // авторизация
    const loginRespHandler = (resp) => {
        // console.log(resp)
        resp.password = localStorage.getItem("password")
        setUserAccount(resp)
        addLocalData(resp)
    }

    const errorHandler = (error) => {
        if (error.response && error.response.data) {
            setActivePopupGlobal("errorLogin");
            // console.log(error.response.data);
            // let err = error.response.data.error;
            // if (err) {
            //     if (err.password) { // serializer error
            //         err = err.password[0]
            //         if (err.includes("this field has at least")) {
            //
            //         }
            //     } else  {
            //         setActivePopupGlobal("errorLogin");
            //     }
            //
            // } else {
            //     setActivePopupGlobal("errorLogin");
            // }
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else {
            setActivePopupGlobal("errorLogin");
        }
        // else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //     // http.ClientRequest in node.js
        //     console.log(error.request);
        // } else {
        //     // Something happened in setting up the request that triggered an Error
        //     console.log('Error', error.message);
        // }
        // console.log(error.config);
    }

    const loginFunc = (data) => {
        clearLocalData()

        addLocalData(data)
        userAuth(data)
            .then(response => loginRespHandler(response))
            .catch(error => {errorHandler(error)} )
    }

    const innerNumbersLogin = (data) => {
        clearLocalData()
        addLocalData(data)

        innerNumbersAuth(data)
            .then(response => loginRespHandler(response))
            .catch(error => error.response.status === 401 && setActivePopupGlobal("errorLogin") )
    }

    // Смотрим переменную авторизационных данных
    // Если данных нет, проверяем localstorage
    useEffect(() => {
        // console.log(userAccount)
        if (!userAccount.login || !userAccount.token) {
            // Берём из local storage
            loadLocalStorage()
        }
    }, [userAccount])

    const changeShopListLocal = (list) => {
        let shopListString = ""
        for (const shopElement of list.elements) {

            let service = shopElement.data.service
            let price = shopElement.data.price
            let name = ""
            if (service === "phoneNumber" && shopElement.data.param.NUMER) {
                name = [service, shopElement.data.param.NUMER].join("")
            } else if (service === "voip") {
                let options =  shopElement.data.param.options
                let money_packet = shopElement.data.param.money_packet

                let optionsNames = []
                if (shopElement?.data?.param?.newOptions?.length > 0) {
                    optionsNames = shopElement.data.param.newOptions.join("&")
                }
                name = [
                    service, price, money_packet, options.join("&"), optionsNames
                ].join("|")
            } else if (shopElement.data.param) {
                name = [service, shopElement.data.param.toString()].join("|")
            }

            if (shopListString.length > 0) shopListString = [shopListString, name].join(";")
            else shopListString = name

        }
        localStorage.setItem("shop", shopListString)
    }

    // сохранение элементов корзины локально
    useEffect(() => {
        if (!shopList.elements || !shopList.elements.length) {
            return
        }

        changeShopListLocal(shopList)
    }, [shopList])

    // Загрузка корзины
    useEffect(() => {
        if (shopList.elements && shopList.elements.length) return

        let shopStorageStr = localStorage.getItem("shop")
        if (!shopStorageStr || shopStorageStr.length === 0) return

        let loadedShopList = {fullPrice: 0, elements: []}
        for (const shopElem of shopStorageStr.split(";")) {
            if (shopElem.includes("phoneNumber") && numbers.length === 0) return
            let price = 0
            let param = {}
            let service = ""
            let payType = "monthly"
            let name = ""

            if (shopElem.includes("phoneNumber") && numbers.length > 0) { // номера
                let number = shopElem.replace("phoneNumber", "")
                let numberElem = numbers.find(e => e.NUMER === number)
                if (!numberElem) continue

                name = ["Номер", number].join(" ")
                service = "phoneNumber"
                price = numberElem.AP + numberElem.PRICE
                param = numberElem

            } else if (shopElem.includes("vats") && !shopElem.includes("vatsInstall")) {
                let workers = parseInt(shopElem.replace("vats", ""))
                let priceElem = prices.vats.find(elem => workers >= elem["from"] && workers <= elem["to"])
                if (!priceElem) continue
                name = ["Виртуальная АТС на", workers, "сотрудников"].join(" ")
                service = "vats"
                price = priceElem.price ? priceElem.price : 0
                param = workers
            } else if (shopElem.includes("callRecords")) {
                let workers = parseInt(shopElem.replace("callRecords", ""))
                let priceElem = prices.callRecords.find(elem => workers >= elem["from"] && workers <= elem["to"])

                name = "Запись телефонных разговоров"
                service = "callRecords"
                price = priceElem && priceElem.price ? priceElem.price : 0
                param = workers

            } else if (shopElem.includes("vamZvonily")) {
                let workers = parseInt(shopElem.replace("vamZvonily", ""))
                let priceElem = prices.vamZvonily.find(elem => workers >= elem["from"] && workers <= elem["to"])

                name = "Вам звонили"
                service = "vamZvonily"
                price = priceElem && priceElem.price ? priceElem.price : 0
                param = workers

            } else if (shopElem.includes("crmIntegration")) {
                let workers = parseInt(shopElem.replace("crmIntegration", ""))
                let priceElem = prices.crmIntegration.find(elem => workers >= elem["from"] && workers <= elem["to"])

                name = "Интеграция с CRM"
                service = "crmIntegration"
                price = priceElem && priceElem.price ? priceElem.price : 0
                param = workers

            } else if (shopElem.includes("vatsInstall")) {
                let workers = parseInt(shopElem.replace("vatsInstall", ""))
                let priceElem = prices.vatsInstall.find(elem => workers >= elem["from"] && workers <= elem["to"])

                name = "Настройка ВАТС по индивидуальному заданию"
                service = "vatsInstall"
                price = priceElem && priceElem.price ? priceElem.price : 0
                param = workers
                payType = "onetime"

            } else if (shopElem.includes("audioRecordingStatus")) {
                let workers = parseInt(shopElem.replace("audioRecordingStatus", ""))
                let priceElem = prices.audioRecordingStatus.find(elem => workers >= elem["from"] && workers <= elem["to"])

                name = "Профессиональная запись аудиороликов"
                service = "audioRecordingStatus"
                price = priceElem && priceElem.price ? priceElem.price : 0
                param = workers
                payType = "onetime"

            } else if (shopElem.includes("ivrDevelopStatus")) {
                let workers = parseInt(shopElem.replace("ivrDevelopStatus", ""))
                let priceElem = prices.ivrDevelopStatus.find(elem => workers >= elem["from"] && workers <= elem["to"])

                name = "Разработка сценариев голосового меню"
                service = "ivrDevelopStatus"
                price = priceElem && priceElem.price ? priceElem.price : 0
                param = workers
                payType = "onetime"
            } else if (shopElem.includes("voip")) {
                let data = shopElem.split("|")
                let options = data[3]?.length > 0 ? data[3].split("&") : []
                let optionNames = data[4]?.length > 0 ? data[4].split("&") : []

                price = data[1] ? parseInt(data[1]) : 0
                let packet_size = data[2] ? parseInt(data[2]) : 0
                name = ["Пакет исходящей связи", price.toString(), "руб."].join(" ")
                service = "voip"
                param = {"options": options, "money_packet": packet_size, newOptions: optionNames}
            } else if (shopElem.includes("tSecurity")) {
                let data = shopElem.split("|")

                name = "Telezon Security"
                service = "tSecurity"
                price = data?.[1] || 0
                param = price
                payType = "monthly"
            } else {
                console.log(shopElem)
            }

            if (name.length === 0 || service.length === 0) continue

            let numberElem = {
                name: name,
                data: {
                    service: service,
                    price: parseInt(price),
                    payType: payType,
                    param: param
                }
            }
            loadedShopList.fullPrice += parseInt(price)
            loadedShopList.elements.push(numberElem)
        }

        if (!loadedShopList.elements) return;
        setShopList({...loadedShopList})
    }, [localStorage, numbers])



    // обновление токена
    const tokenLoginFunc = (data) => {
        userUpdateToken(data)
            .then(response => {
                response.error
                    ? logoutFunc() // Удаляем все авторизационнные данные
                    //? console.log(response) // Удаляем все авторизационнные данные
                    : loginRespHandler(response) // обновляем авторизционные данные
            })
    }

    // logout
    const logoutFunc = () => {
        clearLocalData()
        setUserAccount({})

        history.push("/login")
        history.go(0)
    }

    const clearLocalData = () => {
        localStorage.removeItem("login")
        localStorage.removeItem("password")
        localStorage.removeItem("token")
        localStorage.removeItem("manager")
        localStorage.removeItem("virtual")
        localStorage.removeItem("fusion_auth")
    }

    const addLocalData = (data) => {
        // console.log(data)
        if (data.login) localStorage.setItem("login", data.login)
        if (data.password) localStorage.setItem("password", data.password)
        if (data.token) localStorage.setItem("token", data.token)
        if (data.virtual) localStorage.setItem("virtual", data.virtual)
        if (data.manager) localStorage.setItem("manager", data.manager)

        if (data.fusion_auth) localStorage.setItem("fusion_auth", data.fusion_auth)
        //else localStorage.removeItem("fusion_auth")
    }

    const loadLocalStorage = () => {
        if (!localStorage.getItem("login") || !localStorage.getItem("token")) return

        setUserAccount({
            login: localStorage.getItem("login"),
            password: localStorage.getItem("password"),
            token: localStorage.getItem("token"),
            manager: localStorage.getItem("manager"),
            virtual: localStorage.getItem("virtual"),
            fusion_auth: localStorage.getItem("fusion_auth"),
        })

        tokenLoginFunc({login: localStorage.getItem("login"), token: localStorage.getItem("token")})
    }

    useEffect(() => {
        if (!packetsVoipNumers || packetsVoipNumers.length === 0) return
        getPacket(userAccount).then((response) => setPacket(response)).catch((r) => errorHnd(r.response.status))
    }, [packetsVoipNumers])

    const reloadData = () => {
        // console.log("reloadData")
        servicesAll(userAccount).then((response) => parseDevices(response))
    }

    const updateNumbersPool = () => {
        if (userAccount && userAccount.token)
            newNumbersPool(userAccount)
                .then(response => setNumbers(response))
                .catch(r => errorHnd(r.response.status))
    }

    const parseDevices = (response) => {
        setServicesAll(response)
        // Парсим устройства

        // let d = ["ПД для ПГИ", "Головное устройство ПД для ПГИ", "Федеральный номер", "Интернет TCP/IP", "Интернет PPPoE"]

        let drives = []
        let cbc = []
        let vats = []
        let vats_service = null
        let voip = []
        let packets_voip = []
        let phone = []
        // let ip = ["195.209.96.245"]
        let ip = []

        let additional_services = []
        setCallrecords(false)

        if (response.active_usl) {
            for (const elem of response["active_usl"]) {
                //if (elem.usl_id === 77367735) console.log(elem)
                const driveName = elem["drive_name"] ? elem["drive_name"] : "no drive name"
                const driveNumber = elem["numer"] ? elem["numer"] : "no number"
                const uslId = elem?.usl_id || 0
                if (servicesId.callRecords.includes(uslId) && elem.usl_active)
                    setCrIsActive(true)

                if (driveName.includes("Доп.услуги")) {
                    additional_services.push(elem)
                    continue;
                }

                // ЖКХ
                if (elem.usl_active && servicesId?.zkh.includes(elem?.usl_id)
                    && !additional_services.find(e => e.id_set_usl === elem.id_set_usl)) {
                    additional_services.push(elem)
                }

                // Вам звонили
                if (elem.usl_active &&  elem.usl_id && servicesId.vamZvonili.includes(elem.usl_id)) {
                    additional_services.push(elem)
                }

                for (const [key, group] of Object.entries(servicesList)) {
                    if (group.includes(driveName) && !drives.includes(key)) drives.push(key)
                    // else if (!d.includes(elem["drive_name"])) console.log(elem["drive_name"], elem["usl_name"])
                }

                if (elem.usl_name && elem.usl_name.toLowerCase().includes("вам звонили") && elem.usl_active) {
                    setVzIsActive(true)
                }

                if (elem.usl_name && elem.usl_name.toLowerCase().includes("интеграция с crm") && elem.usl_active) {
                    setCrmIntegrIsActive(true)
                }
                if (servicesId?.callRecords?.includes(uslId) && elem?.usl_active) {
                    setCallrecords(true)
                }

                // Парсим устройства автообзвона
                if ((elem.usl_name && elem.usl_name === "Автообзвон") || (driveNumber.includes("cbc")))
                    cbc.push(elem)

                if (callRecordsDrives.includes(driveName) && driveName.includes("Виртуальная") &&
                    vats.filter(e => e["value"] === driveNumber && e["label"] === driveNumber).length === 0) {
                    vats.push({value: driveNumber, label: driveNumber})
                    vats_service = elem
                }

                // if (callRecordsDrives.includes(driveName) && elem.usl_name
                //     && elem.usl_name.includes("Виртуальная") &&
                //     vats.filter(e => e["value"] === driveNumber && e["label"] === driveNumber).length === 0) {
                //     vats.push({value: driveNumber, label: driveNumber})
                //     vats_service = elem
                // }

                if (servicesList.voip.includes(driveName) &&
                    !voip.find(e => e.value === driveNumber))
                    voip.push({value: driveNumber, label: driveNumber})

                // if (servicesList.voip.includes(driveName)) {
                //     console.log(elem)
                // }

                if (servicesList.voip.includes(driveName) &&
                    elem?.context?.packet_size && elem?.usl_active) {
                    packets_voip.push(uslId)

                    if (elem.usl_active) setCurrentVoipPacket(elem)
                }


                if (servicesList.phone.includes(driveName) &&
                    !phone.find(e => e.idSetDrive === elem.id_set_drive)) {

                    phone.push({value: driveNumber, label: driveNumber, idSetDrive: elem.id_set_drive})
                }


                if (servicesList.internet.includes(driveName) && elem.params && elem.params.length > 0) {
                    elem.params.map(drive_ip =>
                        drive_ip.param_value
                        && !ip.includes(drive_ip.param_value)
                        && ip.push(drive_ip.param_value))
                }

                if (elem.usl_name && elem.usl_name.includes("Телезон_Секьюрити") && elem.usl_active) {
                    setTSecurityIsActive(true)
                }

            }
        }

        setDrives(drives)
        setCbcServices(cbc)
        setVats(vats)
        setVoipNumers(voip)

        setCurrentVatsPacket(vats_service)

        setPacketsVoipNumers([...packets_voip])
        setPhoneNumers(phone)
        setIpAddresses([...ip])

        setAdditionalServices([...additional_services])
    }

    useEffect(() => {
        if (!currentVoipPacket?.usl_active) return
        // console.log(currentVoipPacket?.usl_active)
        // get packet data
        getPinsPacket({...userAccount})
            .then(r => setPackageData(r))

    }, [currentVoipPacket])

    const errorHnd = (e) => {
        if (e === 401 || e === 403) {
            logoutFunc()
        } else if (e === 404) {
            return
        } else {
            console.log(e)
        }
    }

    const loadSmsSettings = () => {
        let sms_exists = additionalServices.find(
            e => servicesId.smsServices.includes(e?.usl_id) && e?.usl_active
        )
        let vh_vizov = additionalServices.find(s => s.usl_id && servicesId.incomingCalls.includes(s.usl_id))
        if (!userAccount?.login || (!sms_exists && !vh_vizov)) return
        // console.log("loadSmsSettings", settings?.sms_service)

        if (settings?.sms_service === "aero") {
            getSmsAeroSettings(userAccount)
                .then(response => setSmsSettings({...response, service: "aero"}))
                .catch(r => errorHnd(r?.response?.status))
        } else {
            getSmsSettings({...userAccount})
                .then(r => {
                    setSmsSettings({...r?.data, service: "telezon"})
                })
                .catch(r => errorHnd(r))
        }
    }

    const getFaq = () => {
        getFaqHelps(userAccount)
            .then(response => setFaqHelp([...response]))
            .catch(r => errorHnd(r.response.status))

    }

    useEffect(() => {
        let sms_exists = additionalServices.find(
            e => servicesId.smsServices.includes(e?.usl_id) && e?.usl_active
        )

        // Обработка загрузки данных для услуги Обработка входящих запросов в ЛК
        if (additionalServices.length === 0 || !userAccount || !userAccount.token) return
        if (additionalServices.find(s => s.usl_id && servicesId.incomingCalls.includes(s.usl_id))) {
            getIvrData(userAccount)
                .then(response => setIvrData(response))
                .catch(r => errorHnd(r.response.status))

            getSavedIvrData(userAccount)
                .then(response => setSavedIvrData(response))
                .catch(r => errorHnd(r.response.status))

            loadSmsSettings()
        } else if (sms_exists) {
            loadSmsSettings()
        }

    }, [additionalServices])

    useEffect(() => {
        if (loaded) return

        if (userAccount && userAccount.token && !userAccount.fusion_auth) {
            getUserManagers(userAccount).then((response) => setManagers(response)).catch((r) => errorHnd(r.response.status))
            userBalance(userAccount).then((response) => setBalance(response)).catch((r) => errorHnd(r.response.status))
            servicesAll(userAccount).then((response) => parseDevices(response)).catch((r) => console.log(r.response.status))
            documentsAll(userAccount).then((response) => setAllDocuments(response)).catch((r) => errorHnd(r.response.status))
            servicesAvailable(userAccount).then((response) => setAvailableServices(response)).catch((r) => errorHnd(r.response.status))
            userContacts(userAccount).then((response) => setContacts(response)).catch((r) => errorHnd(r.response.status))
            userSettings(userAccount).then((response) => setSettings(response)).catch((r) => errorHnd(r.response.status))
            getMailings(userAccount).then((response) => setMailings(response)).catch((r) => errorHnd(r.response.status))
            //getFrontWcAuth(userAccount).then((response) => setCbcAuth(response)).catch((r) => errorHnd(r.response.status))
            getProperties(userAccount).then(response => setProperties(response)).catch(r => errorHnd(r.response.status))
            getFaq()
            // Вам звонили, проверяем, что абонента мигрировали
            getFrontWcDomain(userAccount).then(response => setVatsDomain(response)).catch(r => errorHnd(r.response.status))
            getVoipPrices(userAccount).then(response => setVoipPrices(response)).catch(r => errorHnd(r.response.status))
            setLoaded(true)
        } else if (userAccount && userAccount.token && userAccount.fusion_auth) {
            getUserManagers(userAccount).then((response) => setManagers(response)).catch((r) => errorHnd(r.response.status))
            documentsAll(userAccount).then((response) => setAllDocuments(response)).catch((r) => errorHnd(r.response.status))
            servicesAll(userAccount).then((response) => parseDevices(response)).catch((r) => errorHnd(r.response.status))
        }

        // } else if (userAccount && userAccount.token && loaded) {
        //     setLoaded(false)
        // }

    }, [userAccount])

    const loadNumbersPool = () => {
        getNumbersPool(userAccount)
            .then(response => setNumbers(response))
            .catch(r => errorHnd(r.response.status))
    }


    return (
        <ThemeContext.Provider
            value={{
                theme, setTheme, designType, setDesignType,
                activePopupGlobal, setActivePopupGlobal,
                menu, settingsMenu,
                mailingsPlans,
                filters,
                globalError, setGlobalError,
                userAccount, balance, userManagers,
                services, availableServices, packet,
                docs: allDocuments,

                contacts, settings, mailings, existsDrives,
                cbcServices,

                vatsNames, voipNumers, packetsVoipNumers, phoneNumers, setPhoneNumers,
                ipAddresses, setIpAddresses, additionalServices, ivrData, savedIvrData,
                loadSmsSettings,
                smsSettings,
                cbcAuth,
                loginData, setLoginData,
                crData, setCrData,
                numbers, currentVatsPacket,
                selectIsOpen, setSelectIsOpen,
                abonentPay, setAbonentPay,
                numbersShopPrice, setNumersShopPrice,
                shopPhonePrice, setShopPhonePrice,
                shopVoipPrice, setShopVoipPrice,
                helpText, isHelperOpen,

                properties, currentVoipPacket,
                newUserActions, setNewUserActions,

                reloadData, loginFunc, logoutFunc, tokenLoginFunc, innerNumbersLogin,
                updateNumbersPool, loadNumbersPool,
                faqHelp, getFaq,
                crIsActive, setCrIsActive, vzIsActive, setVzIsActive, crmIntegrIsActive, setCrmIntegrIsActive,
                tSecurityIsActive, setTSecurityIsActive,
                curAudio, setCurAudio, playStatus, setPlayStatus,
                vatsDomain, setVatsDomain,
                shopList, setShopList, changeShopListLocal,
                yesNoTrigger, setYesNoTrigger,
                setCurrentVatsPacket, voipPrices,
                callrecords,
                packageData, setPackageData
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeProvider
