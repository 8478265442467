import React, {useEffect, useState} from 'react'
import Card from './Card/Card'
import s from './Cards.module.scss'
import MinutesSlider from "../../NumersShop/MainBlock/OutgoingCall/MinutesSlider/MinutesSlider";
import RangeSelect from "../../NumersShop/MainBlock/OutgoingCall/RangeSelect/RangeSelect";
import ModalMessage from "../../../../ui/Popup/ModalMessage";
import {useTheme} from "../../../../hooks/useTheme";
import {deleteVoipCard, updateVoipCard} from "../../../../api";


const Cards = ({usl, cards, setActivePopup, activatable=true}) => {
    const {userAccount} = useTheme()
    const [clientWidth, setClientWidth] = useState(window.innerWidth)

    const [selectedPacket, setSelectedPacket] = useState()
    const [activeMinutes, setActiveMinutes] = useState(
        localStorage.getItem("activeMinutes") ?
            parseInt(localStorage.getItem("activeMinutes")) : 1000) // активное кол-во минут

    const [minutesPrice, setMinutesPrice] = useState(0) // цена зависящая от кол-ва минут
    const [newPacket, setPacket] = useState() // новый пакет
    const [popupTarget, setPopupTarget] = useState("")


    useEffect(() => {
        if (cards && cards.length > 0)
            setSelectedPacket(cards[0])
    }, [cards])


    const deletePacket = (service, confirmed=false) => {
        deleteVoipCard({id: service.id, ...userAccount})
            .then(r => setActivePopup("successSave"))
            .catch(e => setActivePopup("oups"))
    }

    const activatePacket = (service, confirmed=false) => {
        let s = service
        s.is_active = !s.is_active
        updateVoipCard({...s, ...userAccount} )
            .then(r => setActivePopup("successSave"))
            .catch(e => setActivePopup("oups"))
    }

    return (
        <div>
            <div className={s.cards}>
                {cards.map(card => (
                    <Card
                        key={card.id}
                        data={card}
                        service={usl}
                        selectedPacket={selectedPacket}
                        setSelectedPacket={setSelectedPacket}
                        deletePacket={deletePacket}
                        activatePacket={activatePacket}
                        activeMinutes={activeMinutes}
                        activatable={activatable}
                    />
                ))}
            </div>

            {clientWidth >= 768 ? (
                <MinutesSlider
                    activeMinutes={activeMinutes}
                    setActiveMinutes={setActiveMinutes}

                    minutesPrice={minutesPrice}
                    setMinutesPrice={setMinutesPrice}
                />
            ) : (
                <RangeSelect setMinutesPrice={setMinutesPrice} />
            )}

            <ModalMessage message={"Вы уверены, что хотите подключить новый тарифный план?"}
                          label={"Смена тарифного плана"}
                          show={popupTarget === "confirm"}
                          hide={() => {activatePacket({}, true)}}>
                <div>
                    <div className="white-button margin-center w20"
                         style={{marginTop: "10px"}}
                         onClick={() => {setPopupTarget("")}}>
                        Отмена
                    </div>
                </div>
            </ModalMessage>
        </div>
    )
}

export default Cards
