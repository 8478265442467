import s from "./Button.module.scss";
import {PriorityHigh, History, Search} from "@mui/icons-material";
import React from "react";


export const ExclamationButton = ({clickEvent, alter=null}) => {
    return(
        <div className={s.csvLink} onClick={clickEvent} style={{fontSize: "12pt"}}>
            <div
                className={`${alter ? s.popupHelpDwnld : null}`}
                data-title={alter ? alter : "Скачать" }>
                <PriorityHigh className={s.SvgElem}/>
            </div>
        </div>
    )
}

export const HistoryButton = ({clickEvent, alter=null}) => {
    return(
        <div className={s.csvLink} onClick={clickEvent} style={{fontSize: "12pt"}}>
            <div
                className={`${alter ? s.popupHelpDwnld : null}`}
                data-title={alter ? alter : "Скачать" }>
                <History className={s.SvgElem}/>
            </div>
        </div>
    )
}

export const SearchButton = ({clickEvent, alter=null}) => {
    return(
        <div className={s.csvLink} onClick={clickEvent} style={{fontSize: "12pt"}}>
            <div
                className={`${alter ? s.popupHelpDwnld : null}`}
                data-title={alter ? alter : "Скачать" }>
                <Search className={s.SvgElem}/>
            </div>
        </div>
    )
}
