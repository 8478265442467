import s from "./Popups.module.scss";
import ModalMessage from "./ModalMessage";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {GlobalGifSelector} from "../../components/GlobalGifSelector/GlobalGifSelector";
import {useTheme} from "../../hooks/useTheme";
import {useDropzone} from "react-dropzone";
import { MdCloudUpload } from "react-icons/md";
import convertFileToBase64 from "../../sortFunction";

import {addFaqHelps, getCredit, saveSettings} from "../../api";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PopupDeposit from "./PopupDeposit";
import PopupChangePass from "./PopupChangePass";
import PopupChangeUser from "./PopupChangeUser";
import {createBrowserHistory} from "history";
import {Link} from "react-router-dom";
import {ShopBLock} from "../ShopBlock/ShopBLock";
import DefaultButton from "../Button/Button";
import {SmsHistoryPopup} from "./SmsHistoryPopup";

const history = createBrowserHistory();


export const FeatureData = (props) => {
    const [curFeature, setCurFeature] = useState(0)
    const [features, setFeatures] = useState([])

    useEffect(() => {
        if (features.length > 0 || props.features.length === 0) return

        //props.features.filter(e => localStorage.removeItem(e.pseudo))
        const f = props.features.filter(e => !localStorage.getItem(e.pseudo)
            && e.page === props.page
            && !e.pseudo.includes("|old|")
        )
        setFeatures([...f])
        props.setFeaturesLen(f.length)
    }, [props.features])

    const changeVisibility = (elem) => {
        if (!localStorage.getItem(elem)) {
            localStorage.setItem(elem, elem)
        } else {
            localStorage.removeItem(elem)
        }
    }

    return(
        <div className={s.featuresMain}>
            {curFeature > 0
                ? <div className={s.featureButton} onClick={() => setCurFeature(curFeature - 1)}>назад</div>
                : <div className={s.rotateButton}>&nbsp;</div>
            }

            {features.map((elem, index) =>
                curFeature === index &&
                <div className={s.featureBlock} key={elem.id}>
                    <div className={s.featureLabel}>{elem.label}</div>

                    <div><GlobalGifSelector id={-1} data={elem.base64}/></div>
                    <div className={s.featureText}>{elem.text}</div>

                    <div className={s.checkBox}>
                        <label htmlFor={"noShow"+index}>больше не показывать</label>
                        <input type="checkbox" id={"noShow"+index} name={"noShow"+index}
                               onChange={() => changeVisibility(elem.pseudo)}/>
                    </div>
                </div>
            )}

            {curFeature + 1 < features.length
                ? <div className={s.featureButton} onClick={() => setCurFeature(curFeature + 1)}>вперёд</div>
                : <div className={s.rotateButton}>&nbsp;</div>
            }


        </div>
    )
}

export const NewFeatures = (props) => {
    const {faqHelp} = useTheme()
    const [features, setFeatures] = useState([])
    const [featuresLen, setFeaturesLen] = useState([])

    useEffect(() => {
        if (faqHelp.length <= 0) return

        setFeatures([...faqHelp])
    }, [faqHelp])


    return (
        <ModalMessage label={"Нововведения"}
                      standartButtonText={"Ок"}
                      show={props.show && featuresLen > 0}
                      hide={() => {
                          props.hide()
                      }}>
            <FeatureData features={features} page={props.page} setFeaturesLen={setFeaturesLen}/>
        </ModalMessage>
    )
}


export const Dropzone = ({onDrop}) => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png', '.jpg', '.gif'],
        }
    })

    return (
        <div {...getRootProps()}>
            <div className={s.dropLabel}>Изображение</div>
            <input {...getInputProps()} type={"image"}/>
            {
                isDragActive ?
                    <MdCloudUpload style={{ fontSize: 100, fill: "#17a439"}} /> :
                    <MdCloudUpload style={{ fontSize: 100}} />
            }
        </div>
    )
}

export const AddFeaturePopup = (props) => {
    const theme = useTheme()

    const [error, setError] = useState("")

    const [upload, setUpload] = useState(false)
    const [success, setSuccess] = useState(false)

    const [imageFile, setImageFile] = useState("")

    const schema = yup
        .object({
            label: yup.string().required().min(5).max(50),
            pseudo: yup.string().required().min(5).max(30)
                .matches(/^([A-Za-z0-9|\-_]*)$/g, "Допустима только латиница и цифры"),
            page: yup.string().required().min(5).max(25),
            text: yup.string().required().min(10).max(250),
            imageFile: yup.string(),

        })
        .required()

    const fieldNames = {
        label: "Заголовок",
        pseudo: "Короткое имя",
        page: "Раздел",
        text: "Текст",
    }

    const {
        watch,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            label: "",
            pseudo: "",
            page: "",
            text: "",
            imageFile: "",
        }
    })

    useEffect(() => {
        if (!success) return
        props.success()
    }, [success])

    const onDrop = useCallback(acceptedFiles => {
        if (Object.keys(acceptedFiles).length === 0) return

        // Do something with the files
        const file = acceptedFiles[0]
        let formData = new FormData();
        formData.append('file', file);
        convertFileToBase64(file)
            .then(dataUrl =>
                setImageFile(dataUrl)
            )
    }, [])

    const onSubmit = (data) => {
        let req = {...data, ...theme.userAccount}
        req.base64 = imageFile
        setUpload(true)

        addFaqHelps(req)
            .then(r => r.id ? setSuccess(true) : setError("Ошибка сохранения инструкции"))
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0) return

        let required = []
        for (const key of Object.keys(errors)) {
            const type = errors[key].type
            if (type === "required") required.push(fieldNames[key] ? fieldNames[key] : key)
        }
        setError("Не заполнены поля: "+required.join(", "))

    }, [errors])

    return (
        <ModalMessage label={"Добавить элемент FAQ"}
                      standartButtonText={"Отмена"}
                      show={props.show}
                      hide={() => {
                          props.hide()
                      }}>

            <form style={{display: "flex"}} onSubmit={handleSubmit(onSubmit)}>
                <div className={s.formBlock} >
                    <div className={s.formRow}>
                        <div>Заголовок</div>
                        <input type="text"
                               // value={label}
                               className={errors.label ? s.errorButton: null}
                               // onChange={(e) => setLabel(e.target.value)}
                               {...register('label')}
                        />

                    </div>

                    <div className={s.formRow}>
                        <div>Короткое имя</div>
                        <input type="text"
                               //value={getValues().pseudo}
                               className={errors.pseudo ? s.errorButton: null}
                               //onChange={changePseudo}
                               {...register('pseudo')}
                        />

                    </div>

                    <div className={s.formRow}>
                        <div>Раздел</div>
                        <input type="text"
                               className={errors.page ? s.errorButton: null}
                               {...register('page')}
                        />
                    </div>

                    <div className={s.formRow}>
                        <div>Текст</div>
                    </div>

                    <div className={s.formRow}>
                        <textarea
                            // value={text}
                                  className={errors.text ? s.errorButton: null}
                                  // onChange={(e) => setText(e.target.value)}
                                  style={{minHeight: "200px"}}
                                  {...register('text')}
                        />
                    </div>

                </div>

                <div className={s.buttons}>
                    {imageFile.length > 0 &&
                    <div style={{width: "100px"}}>
                        <GlobalGifSelector id={-2} data={imageFile}/>
                        <div className={s.deleteButton} onClick={() => setImageFile("")}>
                            Удалить
                        </div>
                    </div>
                    }

                    {imageFile.length === 0 &&
                    <div>
                        <Dropzone onDrop={onDrop}/>
                    </div>
                    }

                    {error.length > 0 &&
                    <div className={s.errorMessage}>{error}</div>
                    }

                    {(!upload || error.length > 0 || success) &&
                    <input className={s.bigButton} type="submit" value={"Сохранить"}/>
                    }

                    {upload && !error.length > 0 && !success &&
                    <div className={`${s.noActiveButton} ${s.processed}`}
                         style={{marginTop: "10px"}}>
                        <span>В процессе</span>
                    </div>
                    }

                </div>

            </form>

        </ModalMessage>
    )

}

export const ModulePopups = (props) => {
    // const [activePopup, setPopup] = useState("")
    const {userAccount, setActivePopupGlobal, activePopupGlobal} = useTheme()
    const {setYesNoTrigger} = useTheme()

    // useEffect(() => {
    //         setPopup(props.activePopup)
    //     }, [props.activePopup]
    // )

    const changeUserStatus = () => {
        setActivePopupGlobal("");
        saveSettings({
            login: userAccount.login,
            token: userAccount.token,
            flag: false
        })
            .then(r => console.log(r))
    }

    const setCredit = () => {
        getCredit({
            login: userAccount.login,
            token: userAccount.token,
        })
            .then(e => e.status ? setActivePopupGlobal("successCredit") : setActivePopupGlobal("failCredit"))
    }

    return(
        <>
            <PopupDeposit popup={activePopupGlobal} setPopup={setActivePopupGlobal} />
            <PopupChangePass popup={activePopupGlobal} setPopup={setActivePopupGlobal} />
            <PopupChangeUser popup={activePopupGlobal} setPopup={setActivePopupGlobal} />
            <ModalMessage message={"Изменения сохранены!"}
                          label={"Успешно!"}
                          show={activePopupGlobal === "successSave"}
                          hide={() => setActivePopupGlobal("")}
            />
            <ModalMessage message={"Тариф успешно изменён"}
                          label={"Успешно!"}
                          show={activePopupGlobal === "changeTariffSuccess"}
                          hide={() => setActivePopupGlobal("")}
            />
            <ModalMessage message={
                "Превышено количество попыток смены тарифа. " +
                "В течение дня тариф менять можно только единожды."
            }
                          label={"Не удалось сменить тариф..."}
                          show={activePopupGlobal === "max tries in day"}
                          hide={() => setActivePopupGlobal("")}
            />
            <ModalMessage message={
                "Недопустимо уменьшение пакета при смене тарифа. "
            }
                          label={"Не удалось сменить тариф..."}
                          show={activePopupGlobal === "small packet"}
                          hide={() => setActivePopupGlobal("")}
            />

            <ModalMessage message={
                "Не удалось сохранить тариф. Возможно тариф с таким названием или префиксом уже существует. "
            }
                          label={"Не удалось создать тариф..."}
                          show={activePopupGlobal === "wrongTarif"}
                          hide={() => setActivePopupGlobal("")}
            />

            <ModalMessage message={
                "Возникла непредвиденная ошибка. "
            }
                          label={"Упс..."}
                          show={activePopupGlobal === "oups"}
                          hide={() => setActivePopupGlobal("")}
            />

            <ModalMessage message={"Для использования услуг необходимо выбрать хотябы один номер"}
                          label={"Внимание!"}
                          show={activePopupGlobal === "noNumbersSelected"}
                          hide={() => setActivePopupGlobal("")}/>

            <ModalMessage message={"Не удалось приобрести услуги из-за нехватки средств на счёте"}
                          label={"Недостаточно средств"}
                          show={activePopupGlobal === "noBalance"}
                          hide={() => setActivePopupGlobal("payments")}/>

            <ModalMessage message={"Не удалось установить кредит или кредитный лимит уже установлен"}
                          label={"Ошибка при выполнении операции"}
                          show={activePopupGlobal === "failCredit"}
                          hide={() => window.location.reload()}/>

            <ModalMessage message={"Поздравляем! Был успешно установлен кредитный лимит, теперь вы можете совершать покупку"}
                          label={"Успешно!"}
                          show={activePopupGlobal === "successCredit"}
                          hide={() => window.location.reload()}/>

            <ModalMessage label={"Недостаточно средств"}
                          standartButtonText={"Пополнить баланс"}
                          show={activePopupGlobal === "noBalanceWCredit"}
                          hide={() => setActivePopupGlobal("payments")}>
                Для приобретения выбранных услуг необходимо пополнить счёт,
                либо воспользоваться заёмными средствами нашей компании.
                <div>
                    <div className="white-button margin-center w20"
                         style={{marginTop: "10px"}}
                         onClick={() => setCredit()}>
                        Взять кредит
                    </div>
                </div>
            </ModalMessage>

            <ModalMessage message={"Настройки успешно сохранены"}
                          label={"Успех!"}
                          show={activePopupGlobal === "settingsSuccess"}
                          hide={() => {
                              setActivePopupGlobal("");
                          }}
            />

            <ModalMessage message={"Возникли проблемы с оплатой... "}
                          label={"Неудачно..."}
                          show={activePopupGlobal === "failPay"}
                          hide={() => {
                              history.push("/");
                              history.go(0)
                          }}
            />

            {/*<ModalMessage label={"Какие услуги вас интересуют?"}*/}
            {/*              standartButtonText={"Я разберусь самостоятельно"}*/}
            {/*              show={activePopupGlobal === "newUser"}*/}
            {/*              hide={changeUserStatus}*/}
            {/*>*/}
            {/*    <div className={"servicesSelect"}>*/}
            {/*        <Link to={"/numersShop"} onClick={changeUserStatus}>*/}
            {/*            <div className={"servicesElem noLine"} >*/}
            {/*                <p>Входящая, исходящая связь</p>*/}
            {/*            </div>*/}
            {/*        </Link>*/}

            {/*        <Link to={"/autocalling"} onClick={changeUserStatus}>*/}
            {/*            <div className={"servicesElem line"} >*/}
            {/*                Автообзвон*/}
            {/*            </div>*/}
            {/*        </Link>*/}

            {/*    </div>*/}

            {/*</ModalMessage>*/}

            <ModalMessage message={"Оплата прошла успешно!"}
                          label={"Успешно!"}
                          show={activePopupGlobal === "successPay"}
                          hide={() => {
                              history.push("/");
                              history.go(0)
                          }}
            />



            <ModalMessage message={"Услуги добавлены в корзину"}
                          label={"Успешно!"}
                          show={activePopupGlobal === "addedToShopBasket"}
                          hide={() => setActivePopupGlobal("")}
            />
            <ModalMessage message={"Нечего добавлять в корзину, либо всё уже добавлено"}
                          label={"Упс.."}
                          show={activePopupGlobal === "addedToShopBasketNothing"}
                          hide={() => setActivePopupGlobal("")}
            />

            <ModalMessage message={"При внесении изменений возникла ошибка"}
                          label={"Неудачно..."}
                          show={activePopupGlobal === "failSave"}
                          hide={() => setActivePopupGlobal("")}
            />
            <ModalMessage message={"Введён неправильный пароль. Проверьте правильность введённого пароля и повторите попытку"}
                          label={"Неудачно..."}
                          show={activePopupGlobal === "failChangePassword"}
                          hide={() => setActivePopupGlobal("")}
            />

            <ModalMessage message={"Изменения сохранены!"}
                          label={"Успешно!"}
                          show={activePopupGlobal === "successSaveTemplate"}
                          hide={() => window.location.reload()}
            />

            <ModalMessage message={"Номер успешно отключен"}
                          label={"Успешно!"}
                          show={activePopupGlobal === "successSuspend"}
                          hide={() => window.location.reload()}
            />


            <ModalMessage message={
                "Вы стали абонентом Telezon. В качестве подарка мы дарим вам входящую VOIP-телефонию с бесплатным номером и "
                + " Виртуальной АТС на 3 сотрудника. "
            }
                          label={"Добро пожаловать в Telezon!"}
                          show={activePopupGlobal === "newUser"}
                          hide={() => changeUserStatus()}
            />

            <ModalMessage label={"Внимание!"}
                          message={"Вы уверены? Эту операцию нельзя отменить."}
                          show={activePopupGlobal === "yesno"}
                          hide={() => setActivePopupGlobal("")}
                          standartButtonText={"Нет"}
                          noStandartButton={true}
            >
                <div className={s.yesNoStyle}>
                    <DefaultButton text={"Да"} clickEvent={() => {setActivePopupGlobal(""); setYesNoTrigger(true)}}/>
                    <DefaultButton text={"Нет"} style={s.greenBG} clickEvent={() => setActivePopupGlobal("")}/>
                </div>

            </ModalMessage>

            <ModalMessage label={"Вы уверены?"}
                          message={"Вы действительно хотите отключить запись телефонных разговоров? Эту операцию нельзя отменить."}
                          show={activePopupGlobal === "yesnoCr"}
                          hide={() => setActivePopupGlobal("")}
                          standartButtonText={"Нет"}
                          noStandartButton={true}
            >
                <div className={s.yesNoStyle}>
                    <DefaultButton text={"Да"} clickEvent={() => {setActivePopupGlobal(""); setYesNoTrigger(true)}}/>
                    <DefaultButton text={"Нет"} style={s.greenBG} clickEvent={() => setActivePopupGlobal("")}/>
                </div>

            </ModalMessage>

            <ModalMessage message={
                "Изменение статуса услуги записи телефонных разговоров доступно только через менеджера"
            }
                          label={"Не удалось"}
                          show={activePopupGlobal === "failcr"}
                          hide={() => setActivePopupGlobal("")}
            />

            <ShopBLock show={activePopupGlobal === "shopBasket"} hide={() => setActivePopupGlobal("")}/>
            <SmsHistoryPopup show={activePopupGlobal === "smsHistory"} hide={() => setActivePopupGlobal("")}/>
        </>
    )
}

export const AudioDropzoneBig = ({setFile}) => {
    const onDrop = (response) => {
        if (!response?.[0]?.name) return
        const file = response?.[0]
        const fileName = response?.[0]?.name?.replace(/\.[^/.]+$/, "")
        setFile({file: file, fileName: fileName})
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'audio/mpeg': ['.mp3'],
            'audio/wav': ['.wav'],
        }
    })

    let inputRef = useRef()

    const readFile = (e) => {
        if (!e?.target?.files?.[0]) return
        const file = e?.target?.files?.[0]
        const fileName = file?.name?.replace(/\.[^/.]+$/, "")
        setFile({file: file, fileName: fileName})
    }

    return (
        <div style={{
            width: "inherit",
            height: "inherit",
        }}
             onClick={() => {inputRef?.current?.click()}}>
            <input type="file" id="audioupload" style={{display: "none"}}
                   accept=".mp3,.wav"
                   onChange={readFile}
                   ref={inputRef}/>
            <div className={s.greetingsDropzone} title={"Перетащите аудио-файл в эту область"}
                 {...getRootProps()}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <div className={s.dropLabel}>Аудио *.mp3, *.wav</div>
                    <input {...getInputProps()} type={"image"}/>
                    {
                        isDragActive ?
                            <MdCloudUpload style={{ fontSize: 100, fill: "#17a439"}} /> :
                            <MdCloudUpload style={{ fontSize: 100}} />
                    }
                </div>
            </div>
        </div>

    )
}