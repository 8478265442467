import React from "react";
import {ResponsiveBarCanvas} from "@nivo/bar";
import s from "./Charts.module.scss"

export const barTemplate = {
    "status": "Пропущенный",
    "target": "lost",
    "current": 72,
    "currentColor": "hsl(179, 70%, 50%)",
    "last": 69,
    "lastColor": "hsl(79, 70%, 50%)",
}

const tooltip = (elem) => {
    return(
        <div className={`${s.tooltipElem} ${s.pad}`}>
            {elem.indexValue} ({elem.id === 'last' ? "Прошлый период" : "Текущий период"}) : {elem.value}
        </div>
    )
}


export const BarChart = ({data, clickEvent}) => {
    return(
        <div style={{width: "650px", height: "300px", marginLeft: ""}}>
            <ResponsiveBarCanvas
                data={data}
                onClick={clickEvent && clickEvent}
                keys={[
                    'current',
                    'last',
                ]}
                indexBy="status"
                margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                pixelRatio={1}
                padding={0.15}
                innerPadding={0}
                minValue="auto"
                maxValue="auto"
                groupMode="grouped"
                layout="vertical"
                reverse={false}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set2' }}
                colorBy="id"
                borderWidth={0}
                borderRadius={0}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36
                }}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Статус',
                    legendPosition: 'middle',
                    legendOffset: 36
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Количество',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                enableGridX={true}
                enableGridY={false}
                enableLabel={true}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                isInteractive={true}
                tooltip={(e) => tooltip(e)}
                legends={[
                ]}
            />
        </div>
    )
}

export const HistogramChart = ({data, clickEvent}) => {
    return(
        <div style={{width: "850px", height: "300px", marginLeft: ""}}>
            <ResponsiveBarCanvas
                data={data}
                onClick={clickEvent && clickEvent}
                keys={[
                    'current',
                    'last',
                ]}
                indexBy="status"
                margin={{ top: 50, right: 20, bottom: 50, left: 150 }}
                pixelRatio={1}
                padding={0.15}
                innerPadding={0}
                minValue="auto"
                maxValue="auto"
                groupMode="grouped"
                layout="horizontal"
                reverse={false}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set2' }}
                colorBy="id"
                borderWidth={0}
                borderRadius={0}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36
                }}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Статус',
                    legendPosition: 'middle',
                    legendOffset: 36
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 25,
                    tickRotation: 0,
                    legend: 'Количество',
                    legendPosition: 'middle',
                    legendOffset: -15
                }}
                enableGridX={true}
                enableGridY={false}
                enableLabel={true}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                isInteractive={true}
                tooltip={(e) => tooltip(e)}
                legends={[
                ]}
            />
        </div>
    )
}