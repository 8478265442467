import React, {useEffect, useState} from "react";
import s from "./ExternalPagination.module.scss"


export const ExternalPagination = ({curPage, setCurPage, dataCnt, paginationSize}) => {
    const [pagesList, setPagesList] = useState([])

    useEffect(() => {
        getPages(Math.ceil(dataCnt / +paginationSize))
    }, [dataCnt, paginationSize, curPage])

    useEffect(() => {
        if (curPage > Math.ceil(dataCnt / +paginationSize))
            setCurPage(1)
    }, [curPage, paginationSize])

    const getPages = (pagesCount) => { // Пагинация
        let pagesRange = 3;

        let p = []
        if (pagesCount > 10) {
            if (curPage === 1) {
                for (let i = 1; i <= pagesRange; i++) p.push(i)

                p.push("...")
                p.push(pagesCount)
            } else {
                let start = 1;
                let end = pagesCount;

                if ((curPage - pagesRange) > 2) {
                    start = curPage - pagesRange;
                }

                if ((curPage + pagesRange) < pagesCount - 1) {
                    end = curPage + pagesRange;
                }

                if (start > 1) {
                    p.push(1)
                    p.push("...")
                }

                for (let i = start; i <= end; i++) p.push(i)

                if (end < pagesCount - 1) {
                    p.push("...")
                    p.push(pagesCount)
                }

            }
        } else {
            for (let i = 1; i <= pagesCount; i++) p.push(i)
        }

        setPagesList([...p])
    }

    return (
        pagesList?.length > 1 &&
        <div className={s.pages}>
            {pagesList.map((page, index) => (
                page !== "..." ?
                    <span
                        className={curPage === +page ? `${s.page} ${s.active}` : s.page}
                        key={page}
                        onClick={(e) => setCurPage(+e.target.innerHTML)}
                    >
                        {page}
                    </span>
                    :
                    <div key={page+"_"+index} className={s.etc}>{page}</div>
            ))}
        </div>
    )
}