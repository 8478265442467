import React, {useEffect, useState} from 'react'
import s from './SmsSenderPopup.module.scss'
import ModalMessage from "./ModalMessage";
import {useTheme} from "../../hooks/useTheme";
import {
    addSmsTemplate,
    addVats,
    changeTemplate,
    checkSmsStatus,
    deleteTemplateSms,
    getSmsBalance,
    sendSms, sendSmsAero
} from "../../api";
import {sleep} from "../../sortFunction";

const SmsSenderPopup = (props) => {
    const theme = useTheme()

    const [smsSend, setSmsSend] = useState(false)
    const [smsId, setSmsId] = useState(0)

    const [success, setSuccess] = useState(0)
    const [balance, setBalance] = useState(0)
    const [status, setStatus] = useState("")
    const [service, setService] = useState("")
    const [error, setError] = useState(false)

    const [isOpenTemplatesView, setOpenTemplatesView] = useState(false)
    const [text, setText] = useState("")
    const [smsSettings, setSmsSettings] = useState({})
    const [editTemplateId, setEditTemplateId] = useState(-1)
    const [templates, setTemplates] = useState([])
    const [targetNumber, setTargetNumber] = useState("")

    useEffect(() => {
        setTargetNumber(props.number)

    }, [props.number])

    const checkNumber = (number) => {
        if (!number || number.toString().length < 11) {
            console.log(number)
            return false
        }

        return true
    }

    const sendSmsHndlr = () => {
        setSmsId(0)
        setSuccess(0)
        setStatus("")
        setError(false)

        if (!checkNumber(targetNumber)) {
            setError(true)
            setStatus("Неправильно набран номер")
            return
        }

        if (text.length === 0) {
            setError(true)
            setStatus("Отсутствует сообщение")
            return
        }

        const setResult = (r) => {
            if (r.sms_id) {
                setSmsId(r.sms_id)
            } else {
                setSmsId(-1)
            }
        }

        setSmsSend(true)
        setError(false)
        setStatus("")

        if (service === "aero") {
            sendSmsAero({
                login: theme.userAccount["login"],
                token: theme.userAccount["token"],
                number: targetNumber,
                text: text

            }).then((r) => setResult(r))
        } else {
            sendSms({
                login: theme.userAccount["login"],
                token: theme.userAccount["token"],
                number: targetNumber,
                text: text

            }).then((r) => setResult(r))
        }
    }

    const checkStatus = () => {
        if (smsId < 0) {
            setStatus("Ошибка отправки сообщения")
            setSuccess(2)
            setSmsSend(false)
            return
        } else if (!smsId) return

        const setResult = (r) => {
            if (!r.status) return

            if (r.status === "Доставлено") {
                setStatus("Сообщение доставлено")
                setSuccess(1)
                setSmsSend(false)
                return
            }
            else if (r.status === "Не отправлено" || r.status === "Не доставлено") {
                setStatus("Ошибка отправки сообщения")
                setSuccess(2)
                setSmsSend(false)
                return
            }
            else if (r.status === "Модерация") {
                setStatus("Проходит модерацию")

            } else {
                setStatus(r.status)
            }

            sleep(2000).then(() => checkStatus())
        }

        checkSmsStatus({
            login: theme.userAccount["login"],
            token: theme.userAccount["token"],
            id: smsId,
        }).then((r) => {setResult(r)})
    }

    const saveTemplate = () => {
        let template_cnt = 0
        if (theme.smsSettings && theme.smsSettings.templates) {
            template_cnt = theme.smsSettings.templates.length;
        }

        addSmsTemplate({
            login: theme.userAccount["login"],
            token: theme.userAccount["token"],
            name: "Шаблон №" + template_cnt.toString(),
            text: text

        }).then(() => theme.loadSmsSettings())
    }

    const deleteTemplate = (template) => {
        deleteTemplateSms({
            login: theme.userAccount["login"],
            token: theme.userAccount["token"],
            id: template.id
        }).then(() => theme.loadSmsSettings())
    }

    const renameTemplate = (event, template) => {
        changeTemplate({
            login: theme.userAccount["login"],
            token: theme.userAccount["token"],
            name: template.name,
            text: text.length > 0 ? text : template.text,
            id: template.id
        }).then((r) => {
            // console.log(r)
            theme.loadSmsSettings()
        })
        setEditTemplateId(-1)
    }

    const setTextFromTemplate = (template) => {
        if (!template.text) return
        setText(template.text)

        // let templ = templates
        // templ.map(t => t.text = t.id === template.id ? template.text : t.text)
        // setTemplates([...templ])
    }

    const changeTemplateName = (event, e) => {
        const templateName = event.target.value
        let templ = templates
        templ.map(t => t.name = t.id === e.id ? templateName : t.name)
        setTemplates([...templ])
    }

    const loadAeroSettings = () => {
        const responseResult = (r) => {
            if (!r.balance) return
            setBalance(r.balance)
        }

        getSmsBalance({
            login: theme.userAccount["login"],
            token: theme.userAccount["token"],
        }).then((r) => responseResult(r))
        setService("aero")
    }

    const loadTelezonService = () => {
        setService("telezon")
    }

    useEffect(() => {
        if (!theme.smsSettings || !theme.smsSettings.api_key || !props.show) return
        setSmsSettings({...theme.smsSettings})
        setTemplates([...theme.smsSettings.templates])

        // if (theme.smsSettings?.service === "aero") loadAeroSettings()
        // if (theme.smsSettings?.service === "telezon") loadTelezonService()

        loadTelezonService()
    }, [theme.smsSettings, props.show])

    useEffect(() => {
        if (success || !smsSend || smsId === 0 || !props.show) return
        checkStatus()
    }, [smsSend, success, smsId, props.show])


    return (
        <ModalMessage
            label={status}
            standartButtonText={"Закрыть"}
            show={props.show}
            error={error}
            hide={() => props.hide()}>
            <div style={{display: "flex"}}>
                <div  className={s.formBlock}>

                    <div className={s.formRow}>
                        <div>Номер телефона</div>
                        <input type="text" value={targetNumber} readOnly={false}
                               onChange={(e) => setTargetNumber(e.target.value)}/>

                    </div>

                    {service === "aero" &&
                    <div className={s.formRow}>
                        <div>Подпись</div>
                        <input type="text" defaultValue={smsSettings.sign}/>
                    </div>
                    }

                    <div className={s.formRow}>
                        <div>Текст сообщения</div>

                    </div>

                    <div className={s.formRow}>
                        <textarea value={text} onChange={(e) => setText(e.target.value)}/>
                    </div>

                </div>

                <div className={s.buttons}>
                    <div>
                        {smsSettings && smsSettings.api_key && !smsSend &&
                        <div className={s.bigButton}
                             style={{marginTop: "10px"}} onClick={sendSmsHndlr}>
                            <span>Отправить</span>
                        </div>
                        }

                        {smsSettings && smsSettings.api_key && smsSend && success !== 1 &&
                        <div className={`${s.noActiveButton} ${s.processed}`}
                             style={{marginTop: "10px"}}>
                            <span>В процессе</span>
                        </div>
                        }

                        {(!smsSettings || !smsSettings.api_key) &&
                        <div className={s.noActiveButton}
                             style={{marginTop: "10px"}}>
                            <span>Нет настроек</span>
                        </div>
                        }

                        <div className={s.bigButton}
                             style={{marginTop: "10px"}} onClick={() => theme.setActivePopupGlobal("smsHistory")}>
                            <span>История сообщений</span>
                        </div>
                    </div>

                    <div>
                        {text.length > 0 &&
                        <div className={s.bigButton}
                             style={{marginTop: "10px"}} onClick={saveTemplate}>
                            <span>Сохранить как шаблон</span>
                        </div>
                        }

                        {templates.length > 0 &&
                        <div className={s.bigButton}
                             style={{marginTop: "10px"}} onClick={() => setOpenTemplatesView(!isOpenTemplatesView)}>
                            <span>{isOpenTemplatesView ? "Скрыть шаблоны" : "Выбрать шаблон"}</span>
                        </div>

                        }
                    </div>

                </div>
            </div>

            {service === "aero" &&
            <div className={s.textCounter}>
                Баланс: {balance} руб. Количество символов: {text.length}
            </div>
            }

            {service === "telezon" &&
            <div className={s.textCounter}>
                Количество символов: {text.length}
            </div>
            }

            {templates.length > 0 && isOpenTemplatesView &&
            <div className={s.templatesViewBlock}>
                <span>Шаблоны сообщений</span>
                <div className={s.templates}>
                    {templates.map((e, index) =>
                        editTemplateId !== index
                            ?
                            <div key={e.id} value={e.name} className={s.templateRow}>
                                <span onClick={() => setTextFromTemplate(e)}>{e.name}</span>
                                <div className={s.deleteTemplateButton}
                                     onClick={() => deleteTemplate(e)}>Удалить</div>
                                <div className={s.renameTemplateButton}
                                     onClick={() =>setEditTemplateId(index)}>Изменить</div>
                            </div>
                            :
                            <div key={e.id} value={e.name} className={s.templateRow}>
                                <input type="text" defaultValue={e.name}
                                       onChange={(event) => changeTemplateName(event, e)}/>
                                <div className={s.renameTemplateButton}
                                     onClick={(event) => renameTemplate(event, e)}>Сохранить</div>
                                <div className={s.deleteTemplateButton}
                                     onClick={() =>setEditTemplateId(-1)}>Отменить</div>
                            </div>
                    )}
                </div>
            </div>
            }
        </ModalMessage>

    )
}

export default SmsSenderPopup