import React, {useState} from 'react'
import s from "../../../IncomingCalls/FeedbackMain/TableRow/TableRow.module.scss";
import {CdrsDirections, phoneHeader, voipHeader} from "../../../../../provider/variables";
import Creatable from "react-select/creatable";
import {useTheme} from "../../../../../hooks/useTheme";



const TableRow = ({data, headers}) => {

    // console.log(data)
    return(
        <>
            <tr className={s.tableRow}>
                {headers.map(
                    elem =>
                        <td key={data.CONNECT_TIME+"_"+elem.key}>
                            {elem.key === "DIRECTION" ?
                                CdrsDirections?.[data[elem.key]] ? CdrsDirections[data[elem.key]] : data[elem.key]
                                :
                                data[elem.key]
                            }
                        </td>
                )}

            </tr>
        </>
    )
}

export const TableRows = (
    {
        data,
        getFilters,
        addFilter,
        curFilterSequence,
        headers
    }) => {
    const theme = useTheme()

    const freeFilter = (key, e) => {
        if (key === "DURATION") {
            key = ["DURATION", e.target.id].join("_")
            e = e.target.value
        }
        // console.log(key, e)
        addFilter({label: e, value: e, k: key})
    }

    // https://react-select.com/styles
    const filterSelectStyle = {
        control: () => ({
            height: "30px",
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        menu: (provided, state) => ({
            ...provided,
            minWidth: "150px"
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            backgroundColor: state.isSelected
                ? "rgba(41,165,61,1)"
                : state.isFocused
                    ? "rgba(254,79,26,1)"
                    : "white",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: state.data && Array.isArray(state.data.label) ? "bold" : "normal"
        }),
    }

    return(
        <>
            <tr className={s.tableFilter}>
                {headers.map(string =>
                string.key === "DURATION"
                    ?
                    <td key={string.key+"_filter"}>
                        <input style={{width: "35px", borderRadius: "5px"}}
                               id={"from"} onChange={(e) => freeFilter(string.key, e)}/>
                        -
                        <input style={{width: "35px", borderRadius: "5px"}}
                               id={"to"} onChange={(e) => freeFilter(string.key, e)}/>
                    </td>
                    :
                    <td onClick={() => getFilters(string.key)} key={string.key+"_filter"}>
                        <Creatable
                            options={curFilterSequence}
                            onCreateOption={(e) => freeFilter(string.key, e)}
                            createOptionPosition={"first"}
                            formatCreateLabel={(v) => ["Поиск ", v].join(" ")}

                            defaultValue={{label: "Нет", value: "Нет", k: string.key}}
                            onChange={addFilter}
                            maxMenuHeight={175}
                            aria-rowcount={10}
                            styles={filterSelectStyle}
                            />
                    </td>

                )}
            </tr>

            {data && data.length > 0 &&
                data.map((string, index) =>
                    <TableRow data={string} key={data.CONNECT_TIME+"_"+index.toString()} headers={headers}/>
                )
            }
        </>
    )
}