import React, {useCallback, useEffect, useState} from 'react'
import { useTheme } from '../../../../hooks/useTheme'
import s from './MainBlock.module.scss'
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import {menu, settingsMenu} from "../../../../provider/variables";
import {GlobalGifSelector} from "../../../GlobalGifSelector/GlobalGifSelector";
import {deleteFaqHelps, getFaqHelps, updateFaqHelps} from "../../../../api";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import DefaultButton from "../../../../ui/Button/Button";
import {Dropzone} from "../../../../ui/Popup/Popups";
import convertFileToBase64 from "../../../../sortFunction";
import ModalMessage from "../../../../ui/Popup/ModalMessage";
import {createBrowserHistory} from "history";
const history = createBrowserHistory();

const cyrillicToTranslit = new CyrillicToTranslit();



const SectionsMenu = ({setCurFeature}) => {
    const {faqHelp} = useTheme()
    const [sections, setSections] = useState({})
    const [curSectionName, setCurSectionName] = useState("")
    const [curSubSectionName, setCurSubSectionName] = useState("")


    useEffect(() => {
        //if (Object.keys(sections).length > 0) return
        let sectionsNames = {}
        faqHelp.map(elem =>
            !sectionsNames[elem.page]
            && menu.find(s => s.service===elem.page)
                ? sectionsNames[elem.page] = menu.find(s => s.service===elem.page).title
                : settingsMenu.find(s => s.service===elem.page)
                ? sectionsNames[elem.page] = settingsMenu.find(s => s.service===elem.page).title
                : !sectionsNames[elem.page] ? sectionsNames[elem.page] = elem.page : null
        )
        setSections({...sectionsNames})
        setCurSectionName("")

    }, [faqHelp.length])

    useEffect(() => {
        setCurFeature({})
        setCurSubSectionName("")

    }, [curSectionName])

    return(
        <div>
            <div className={s.buble}>
                <div className={s.menuBlock}>
                    <div className={s.sectionLabel}>Раздел</div>
                    {Object.keys(sections).map(key =>
                        <div onClick={() => setCurSectionName(key)} key={key}
                             className={`${s.sectionButton} ${curSectionName === key && s.activeSection}`}>
                            {sections[key]}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.buble}>
                {curSectionName.length > 0 &&
                <div className={s.menuBlock}>
                    <div className={s.sectionLabel}>Подраздел</div>
                    {faqHelp && faqHelp.filter(f => f.page === curSectionName)
                        .map(f =>
                            <div key={f.pseudo}
                                className={`${s.sectionButton} ${curSubSectionName === f.pseudo && s.activeSection}`}
                                 onClick={() => {
                                     setCurSubSectionName(f.pseudo);
                                     setCurFeature(f)
                                 }}
                            >
                                {f.label}
                            </div>
                        )
                    }
                </div>
                }

            </div>

        </div>
    )
}

const FeatureDetail = ({feature}) => {
    const theme = useTheme()
    const [activePopup, setActivePopup] = useState("")
    const [success, setSuccess] = useState(false)

    const [error, setError] = useState("")
    const [curFeature, setCurFeature] = useState({})
    const [editMode, setEditMode] = useState(false)
    const [label, setLabel] = useState("")
    const [pseudo, setPseudo] = useState("")
    const [section, setSection] = useState("")
    const [text, setText] = useState("")
    const [picture, setPicture] = useState("")

    useEffect(() => {
        setCurFeature({...feature})
    }, [feature])

    useEffect(() => {
        if (Object.keys(curFeature).length === 0) return

        setLabel(curFeature.label)
        setPseudo(curFeature.pseudo)
        setSection(curFeature.page)
        setText(curFeature.text)
        setPicture(curFeature.base64)
    }, [curFeature])

    const changePseudo = (e) => {
        let val = e.target.value
        val = cyrillicToTranslit.transform(val)
        val = val.replaceAll(" ", "")
        setPseudo(val)
    }

    useEffect(() => {
        let f = curFeature
        f.label = label
        f.pseudo = pseudo
        f.page = section
        f.text = text
        f.base64 = picture
        setCurFeature(f)

    }, [label, pseudo, section, text, picture])

    const saveChanges = () => {
        setError("")

        if (label.length === 0) {
            setError("label")
            return
        }

        if (pseudo.length === 0) {
            setError("pseudo")
            return
        }

        if (section.length === 0) {
            setError("section")
            return
        }

        if (text.length === 0) {
            setError("text")
            return
        }

        let req = theme.userAccount
        req.label = label
        req.pseudo = pseudo
        req.page = section
        req.text = text
        req.base64 = picture
        req.id =  curFeature.id

        setSuccess(false)
        updateFaqHelps(req)
            .then(r => r.id ? setSuccess(true) : setError(r))
    }

    useEffect(() => {
        if (success) {
            //theme.getFaq()
            history.push("/faq")
            history.go(0)
        }
        setEditMode(false)
    }, [success])

    const onDrop = useCallback(acceptedFiles => {
        if (Object.keys(acceptedFiles).length === 0) return

        // Do something with the files
        const file = acceptedFiles[0]
        let formData = new FormData();
        formData.append('file', file);
        convertFileToBase64(file)
            .then(dataUrl =>
                setPicture(dataUrl)
            )
    }, [])

    const deleteFaq = (deleteConfirmation = false) => {
        if (!deleteConfirmation) {
            setActivePopup("confirmation")
            return
        }

        let req = theme.userAccount
        req.id =  curFeature.id
        setSuccess(false)

        deleteFaqHelps(req)
            .then(r => r.id ? setSuccess(true) : setError(r))
        setSuccess(true)
    }

    return (
        Object.keys(curFeature).length > 0 ?
            <div className={s.content}>
                {!editMode &&
                    <>
                        {theme.userAccount && theme.userAccount.manager &&
                        <DefaultTitle title={curFeature.label} button={{func: () => setEditMode(true), title: "Редактировать"}}/>
                        }

                        {!theme.userAccount || !theme.userAccount.manager &&
                        <DefaultTitle title={curFeature.label} />
                        }

                        <div className={s.featureText}>{curFeature.text}</div>
                        <GlobalGifSelector id={-1} data={curFeature.base64}/>
                    </>
                }

                {editMode && theme.userAccount && theme.userAccount.manager &&
                <>
                    <div>
                        <div className={s.formRow}>
                            <div>Заголовок</div>
                            <input type="text" value={label} className={error === "label" ? s.errorButton: null}
                                   onChange={(e) => setLabel(e.target.value)}/>

                        </div>

                        <div className={s.formRow}>
                            <div>Короткое имя</div>
                            <input type="text" value={pseudo} className={error === "pseudo" ? s.errorButton: null}
                                   onChange={changePseudo}/>
                        </div>

                        <div className={s.formRow}>
                            <div>Раздел</div>
                            <input type="text" value={section} className={error === "section" ? s.errorButton: null}
                                   onChange={(e) => setSection(e.target.value)}/>
                        </div>

                        <div className={s.formRow}>
                            <div>Текст</div>
                        </div>

                        <div className={s.formRow}>
                        <textarea value={text} className={error === "text" ? s.errorButton: null}
                                  onChange={(e) => setText(e.target.value)}
                                  style={{minHeight: "200px"}}
                        />
                        </div>

                        {picture.length === 0 &&
                        <div>
                            <Dropzone onDrop={onDrop}/>
                        </div>
                        }

                        {picture.length > 0 &&
                        <div>
                            <GlobalGifSelector id={-2} data={picture} width={"550px"}/>
                            <div className={s.deleteButton} onClick={() => setPicture("")}>
                                Удалить
                            </div>
                        </div>
                        }
                    </div>
                    <div className={s.editButtons}>
                        <DefaultButton text={"Сохранить"} clickEvent={saveChanges}/>
                        <DefaultButton text={"Отменить"} clickEvent={() => setEditMode(false)}/>
                        <DefaultButton text={"Удалить"} clickEvent={() => deleteFaq()}/>
                    </div>



                </>
                }

                <ModalMessage label={"Вы уверены?"}
                              standartButtonText={"Отмена"}
                              show={activePopup === "confirmation"}
                              hide={() => setActivePopup("")}
                >
                    <div className="white-button margin-center w20"
                         style={{marginTop: "10px"}}
                         onClick={() => {setActivePopup(""); deleteFaq(true)}}>
                        Удалить
                    </div>
                </ModalMessage>


            </div>
            :
            <div className={s.content}>
                <DefaultTitle title={"Выберите раздел"} />
            </div>
    )
}

const MainBlock = ({setPopup}) => {
    const {userAccount} = useTheme()
    const [curFeature, setCurFeature] = useState({})

    return (
        <div>
            {userAccount.manager &&
            <DefaultTitle title="Помощь по возможностям личного кабинета, FAQ"
                          button={{func: () => setPopup("AddFeature"), title: "Добавить"}}
            />
            }

            {!userAccount.manager &&
            <DefaultTitle title="Помощь по возможностям личного кабинета, FAQ" />
            }

            <div className={s.flex}>
                <SectionsMenu setCurFeature={setCurFeature}/>
                <FeatureDetail feature={curFeature} setPopup={setPopup}/>
            </div>
        </div>
    )
}

export default MainBlock
