import React, {useState} from 'react'
import Sidebar from "../../../ui/Sidebar/Sidebar";
import s from "./CallLog.module.scss"
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import {Tabs} from "../../../ui/Tabs/Tabs";
import {callsTabsDev, filters} from "../../../provider/variables";
import {DetailPage} from "./Detalizations/DetailPage";

import {Calls} from "./Calls/Calls";
import {useTheme} from "../../../hooks/useTheme";

const CallLogDev = (props) => {
    const theme = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [showFeatures, setShowFeatures] = useState(true)

    const [activeTab, setActiveTab] = useState({})
    const handleMenu = () => setActiveBurger(!activeBurger)

    return (
        <div className={`${s.mainPart} ${theme.designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            {/*<NewFeatures show={showFeatures} hide={() => setShowFeatures(false)} page={"calllog"}/>*/}
            <div className={s.tabsMain}>
                <DefaultTitle title="Журнал вызовов" justify={true}>
                    <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={callsTabsDev} noMargin={true}/>
                </DefaultTitle>

                <div className={s.pageData}>
                    {activeTab.section === "detail" &&
                    <DetailPage />
                    }

                    {activeTab.section === "calls" &&
                    <Calls setActivePopup={props.setActivePopup}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default CallLogDev
