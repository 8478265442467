import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../../../../hooks/useTheme'
import s from './RangeSelect.module.scss'
import {mobileMinutesMarks} from "../../../../../../provider/variables";

const RangeSelect = ({ setMinutesPrice }) => {
  const [minutes, setMinutes] = useState(100)
  const [localOpen, setLocalOpen] = useState(false)
  const { selectIsOpen, setSelectIsOpen } = useTheme()

  useEffect(() => {
    if (!selectIsOpen && localOpen) setLocalOpen(false)
  }, [selectIsOpen])

  return (
    <div className={s.wrapper}>
      <p className={s.paragraph}>Количество сотрудников -&nbsp;</p>
      <div className={s.relative}>
        <span
          className={s.value}
          onClick={() => {
            setLocalOpen(!localOpen)
            setSelectIsOpen(!selectIsOpen)
          }}
        >
          {minutes}
        </span>
        <select
          multiple
          className={`${s.select} ${localOpen ? s.active : ''}`}
          onChange={(e) => {
            setMinutes(e.target.value)
            setMinutesPrice(e.target.value)
            setLocalOpen(!localOpen)
            setSelectIsOpen(!selectIsOpen)
          }}
        >
          {mobileMinutesMarks.map((mark) => (
            <option value={mark.value} key={mark.value}>{mark.text}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default RangeSelect
