import React, {useEffect, useState} from 'react'
import s from './ATSFooter.module.scss'
import ManagedCheckbox from './ManagedCheckbox/ManagedCheckbox'
import Tippy from '@tippyjs/react'
import 'tippy.js/animations/scale.css'
import {useTheme} from '../../../../../../hooks/useTheme'
import CheckBox from "../../../../../../ui/CheckBox/CheckBox";

const   ATSFooter = ({securityPrice, vatsPrice, crPrice, installingStatus, setInstallingStatus, installingPrice}) => {
  const [localOpen, setLocalOpen] = useState(false)
  const [secondTooltip, setSecondTooltip] = useState(false)
  const [thrdTooltip, setThrdTooltip] = useState(false)
  const [abSize, setAbSize] = useState(0)

  const {helpText, isHelperOpen} = useTheme()

    useEffect(() => {
        if (!helpText) {
            setLocalOpen(false)
            setSecondTooltip(false)
        }
    }, [helpText])



    useEffect(() => {
        setAbSize(securityPrice + vatsPrice + crPrice)
    }, [securityPrice, vatsPrice, crPrice])

  return (
    <div className={s.wrapper}>
      <div className={s.settings}>
        <CheckBox status={installingStatus} chStatus={setInstallingStatus}/>
        <p className={s.paragraph}>Требуется профессиональная настройка</p>

        <Tippy
          visible={localOpen}
          animation={'scale'}
          content={
            <div
              style={{
                background: 'var(--background-gradient-st)',
                color: 'var(--main-font-color)',
                padding: '12px',
                borderRadius: '10px',
                border: '1px solid var(--border-default)',
                boxShadow: '0 0 20px 0 var(--border-default)',
              }}
            >
                Не обязательно самостоятельно проводить ремонтные работы, тянуть провода, покупать и настраивать аппаратуру.
                Все оборудование, которое необходимо для обеспечения качественной телефонии, находится в Телезон.
                По желанию вы можете заказать профессиональную настройку виртуальной АТС по индивидуальному заданию.
                <br/><br/>
                Данная услуга оплачивается отдельно согласно действующему прейскуранту.
                За настройку виртуальной АТС взимается разовый платеж в соответствии с общим количеством сотрудников.
            </div>
          }
        >
          <p
            className={s.mark}
            onClick={() => {
              if (document.body.clientWidth < 1250) {
                setLocalOpen(true)
                isHelperOpen(!helpText)
              }
            }}
            onMouseOver={() => {
              setLocalOpen(true)
            }}
            onMouseLeave={() => {
              setLocalOpen(false)
            }}
          >
            ?
          </p>
        </Tippy>
      </div>
      <div className={s.costs}>
        <div className={s.flex}>
          <p>
            Абонентская плата - {abSize} <span className="ruble"> ₽</span> в месяц
          </p>

          <Tippy
            visible={secondTooltip}
            animation={'scale'}
            content={
              <div
                style={{
                  background: 'var(--background-gradient-st)',
                  color: 'var(--main-font-color)',
                  padding: '12px',
                  borderRadius: '10px',
                  border: '1px solid var(--border-default)',
                  boxShadow: '0 0 20px 0 var(--border-default)',
                }}
              >
                  Ежемесячная абонентская плата рассчитывается в соответствии с общим количеством подключенных к
                  виртуальной АТС сотрудников и дополнительно выбранными опциями, которые оплачиваются отдельно.
              </div>
            }
          >
            <p
              className={s.mark}
              onClick={() => {
                if (document.body.clientWidth < 1250) {
                  setSecondTooltip(true)
                  isHelperOpen(!helpText)
                }
              }}
              onMouseOver={() => {
                setSecondTooltip(true)
              }}
              onMouseLeave={() => {
                setSecondTooltip(false)
              }}
            >
              ?
            </p>
          </Tippy>
        </div>
          <div className={s.flex}>
              <p>
                  Разовый платеж - {installingPrice} <span className="ruble"> ₽</span>
              </p>

              <Tippy
                  visible={thrdTooltip}
                  animation={'scale'}
                  content={
                      <div
                          style={{
                              background: 'var(--background-gradient-st)',
                              color: 'var(--main-font-color)',
                              padding: '12px',
                              borderRadius: '10px',
                              border: '1px solid var(--border-default)',
                              boxShadow: '0 0 20px 0 var(--border-default)',
                          }}
                      >
                          Оплата осуществляется по факту выполненной работы
                      </div>
                  }
              >
                  <p
                      className={s.mark}
                      onClick={() => {
                          if (document.body.clientWidth < 1250) {
                              setThrdTooltip(true)
                              isHelperOpen(!helpText)
                          }
                      }}
                      onMouseOver={() => {
                          setThrdTooltip(true)
                      }}
                      onMouseLeave={() => {
                          setThrdTooltip(false)
                      }}
                  >
                      ?
                  </p>
              </Tippy>
          </div>


      </div>
    </div>
  )
}

export default ATSFooter
