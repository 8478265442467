import React, {useEffect, useState} from 'react'
import s from "./SearchBubble.module.scss"

import {useTheme} from "../../../hooks/useTheme";
import ReactSelect from "react-select";
import CheckBox from "../../CheckBox/CheckBox";
import {WebSitesDivButton} from "../../Button/WebSitesButton";
import DefaultButton, {DefaultButtonSmall} from "../../Button/Button";



export const SearchBubble = ({search, requestStatus, searchNumber, setSearchNumber, searchResult, reload}) => {
    const {theme} = useTheme()
    const [openedMenu, setOpenedMenu] = useState("")

    const validateSearchInput = (v) => {
        let regex = /^[0-9]+$/;
        if (regex.test(v) || v?.length === 0) {
            setSearchNumber(v)
        }
    }

    return(
        <div className={s.chooseDevice} onClick={() => {
            openedMenu.length > 0 && setOpenedMenu("")
        }}>
            <div className={s.blocks}>
                <p style={theme === 'light' ? {color: 'var(--light-color)'} : {}}>Поиск звонков по номеру телефона</p>
                <label className={s.label}>
                    <span>Номер</span>
                    <input value={searchNumber} onChange={e => validateSearchInput(e?.target?.value)}/>

                </label>
                <div className={s.flex} style={{justifyContent: "space-between"}}>
                    {/*{searchNumber?.length >= 4 &&*/}
                    <div style={{marginBottom: "0 !important"}}>
                        {!requestStatus && searchNumber?.length >= 4 &&
                        <DefaultButtonSmall text={"поиск"} clickEvent={search}/>
                        }

                        {requestStatus && searchNumber?.length >= 4 &&
                        <DefaultButtonSmall text={"идёт загрузка"} color={'noactive'} animated={false}/>
                        }

                        {searchNumber?.length < 4 &&
                        <DefaultButtonSmall text={"поиск"} color={'noactive'} animated={false} alter={"минимальное количество символов для поиска - 4"}/>
                        }
                    </div>
                    {/*}*/}

                    {searchResult &&
                    <div>
                        {!requestStatus &&
                        <DefaultButtonSmall text={"x"} clickEvent={() => reload()} alter={"Сбросить результаты поиска"}/>
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}