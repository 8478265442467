import React from 'react'
import ReactDOM from 'react-dom/client';
import './index.css'
import App from './App'
import ThemeProvider from './provider/ThemeProvider'

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </React.StrictMode>
)
