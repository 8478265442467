import React, {useEffect, useState} from 'react'
import ATSFooter from './ATSFooter/ATSFooter'
import CallingRecords from './CallingRecords/CallingRecords'
import RangeSlider from './RangeSlider/RangeSlider'
import s from './VirtualATS.module.scss'
import {marks, prices, servicesList} from "../../../../../provider/variables";
import {Security} from "./TelezonSecurity/TelezonSecurity";
import {useTheme} from "../../../../../hooks/useTheme";
import DefaultTitle from "../../../../../ui/DefaultTitle/DefaultTitle";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import {CollapseB} from "../../../../../ui/Button/CollapseB";


const VirtualATS = (
    {
        rangeBar, setRangeBar, crStatus, installingStatus, setInstallingStatus,
        fraudStatus, changeCallRecordsStatus, changeAntifraudStatus,
        securityPrice, vatsPrice, setVatsPrice, crPrice,
    }) => {

    const [installingPrice, setInstallingPrice] = useState(0)
    const [needVats, setNeedVats] = useState(true)
    const {vatsNames, currentVatsPacket} = useTheme()

    const changeInstallingStatus = (id, val) => {
        setInstallingStatus(val)
        localStorage.setItem("installingStatus", val ? "1" : "0")

        if (val) {
            let p = prices.vatsInstall;
            p = p.filter(elem => rangeBar >= elem["from"] && rangeBar <= elem["to"])
            if (p.length > 0) {
                setInstallingPrice(p[0]["price"])
            }
        } else {
            setInstallingPrice(0)
        }
    }

  useEffect(() => {
      // сотрудники ВАТС
      let p = prices.vats;
      let sCount = marks[rangeBar] && marks[rangeBar].label ? parseInt(marks[rangeBar].label): 0
      p = p.filter(elem => sCount >= elem["from"] && sCount <= elem["to"])

      if (checkFreeVats()) {
          setVatsPrice(0)
          return
      }

      if (p.length > 0) {
          setVatsPrice(p[0]["price"])
          //if (fraudStatus) changeAntifraudStatus(0, true)
          //else changeAntifraudStatus(0, false)

      } else {
          setVatsPrice(0)
          //changeAntifraudStatus(0, false)
      }

      if (rangeBar === 0) {
          //changeCallRecordsStatus(0, false)
          //changeAntifraudStatus(0, false)
          //changeInstallingStatus(0, false)
      } else {
          //changeCallRecordsStatus(0, crStatus)
          //changeAntifraudStatus(0, fraudStatus)
          //changeInstallingStatus(0, installingStatus)
      }

      // Сохраняем локально
      localStorage.setItem("vatsRangeBar", rangeBar.toString())

  }, [rangeBar, needVats, currentVatsPacket])

    useEffect(() => {
        if (vatsNames.length > 0) setNeedVats(false)
        if (vatsNames.length === 0) setNeedVats(true)
    }, [vatsNames])

    useEffect(() => {
        if (!needVats && vatsNames.length > 0) setVatsPrice(0)
    }, [needVats, vatsNames])

    const checkFreeVats = () => {
        if (!currentVatsPacket || !currentVatsPacket.usl_name) return false

        let workers = /<\d+>/g.exec(currentVatsPacket.usl_name);
        if (!workers) return false

        workers = /\d+/g.exec(workers[0])[0];

        return workers.toString() === rangeBar.toString()

    }

    useEffect(() => {


        //console.log(rangeBar, currentVatsPacket)
    }, [rangeBar, currentVatsPacket])


  return (
    <div>
        <div className={s.header}>
            <div style={{display: "flex"}}>
                <DefaultTitle title="Покупка виртуальной АТС"/>

                {/*{vatsNames.length > 0 &&*/}
                {/*<div style={{display: "inline-block", marginLeft: "50px"}}>*/}
                {/*    <CheckBox styleParams="inline"*/}
                {/*              status={needVats}*/}
                {/*              chStatus={() => setNeedVats(!needVats)}/>*/}
                {/*</div>*/}
                {/*}*/}
            </div>

            <p>
                <a href="https://telezon.ru/products/item/3">Подробно о ВАТС</a>
            </p>
        </div>
        {vatsNames.length > 0 &&<CollapseB expanded={needVats} changeStatus={setNeedVats}/>}

        {needVats &&
        <div>
            <div className={s.flex}>
                <RangeSlider
                    setRangeBar={setRangeBar}
                    rangeBar={rangeBar}
                />
                <CallingRecords crStatus={crStatus} setCrStatus={changeCallRecordsStatus}/>
                <Security antifraud={fraudStatus} changeAntifraud={changeAntifraudStatus}/>
            </div>
            <ATSFooter
                securityPrice={securityPrice}
                vatsPrice={vatsPrice}
                crPrice={crPrice}
                installingStatus={installingStatus}
                setInstallingStatus={changeInstallingStatus}
                installingPrice={installingPrice}
            />
        </div>
        }

    </div>
  )
}

export default VirtualATS
