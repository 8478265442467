import React, {useEffect, useState} from 'react'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import s from './Form.module.scss'
import {yupResolver} from '@hookform/resolvers/yup'
import {useTheme} from '../../../../hooks/useTheme'
import Creatable from 'react-select/creatable';
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'

import {
    deleteVZTemplate,
    generateTTs, getVamZvoniliSettings, getVZExtensions,
    saveVamZvoniliSettings, saveVZTemplate, updateVZExtensions, updateVZTemplate
} from "../../../../api"
import * as yup from "yup";
import DefaultButton, {SubmitButton} from "../../../../ui/Button/Button";
import {AudioPlayerMini} from "../../../../ui/AudioPlayer/AudioPlayer";
import {PauseCircleFilled, PlayCircleFilled} from "@mui/icons-material";
import {ttsPrefixLink, vamZvoniliTabs} from "../../../../provider/variables";
import {Tabs} from "../../../../ui/Tabs/Tabs";
import CheckBox from "../../../../ui/CheckBox/CheckBox";
import {HelpElem} from "../../../../ui/HelpElem/HelpElem";
import ModalMessage from "../../../../ui/Popup/ModalMessage";
import {WebSitesButton, WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";
import PaginationBlock from "../../../../ui/PaginationBlock/PaginationBlock";
import {LoadingDots} from "../../../../ui/Loading/Loading";
import {sleep} from "../../../../sortFunction";

//const defaultText = {text: "Вам звонил [[должность]] [[департамент]] [[имя]] из компании "}
const defaultText = {text: "Вам звонил [[должность]] [[имя]] из компании "}

// const tableSchema = yup.object({})
const tableSchema = yup.object({
    values: yup.array()
})

const templateSchema = yup
    .object({
        text: yup.string().min(1).max(255).required(),
        // label: yup.string().min(1).max(32).required(),
    })
    .required()

const settingsSchema = yup.object({
    values: yup.array()
        .of(
            yup.object({
                dialplan_uuid: yup.string(),
                dialplan_number: yup.string(),
                enabled: yup.bool()
            })
        )
})


const textMapping = [
    ["[[должность]]", "position"],
    ["[[департамент]]", "department"],
    ["[[имя]]", "effective_caller_id_name"],
    ["[[номер]]", "extension"],
]


const TableForm = ({template}) => {
    const theme = useTheme()
    const {setCurAudio, playStatus, setPlayStatus} = useTheme()
    const [vzExtensions, setVzExtensions] = useState([])

    const [curPage, setCurPage] = useState(1)
    const [filteredRows, setFilteredRows] = useState([])
    const pageSize = 10

    const [allSelected, setAllSelected] = useState(false)
    const [savingStatus, setSavingStatus] = useState(false)
    const [yesnoTriggered, setYesNoTrigger] = useState(false)
    const [activePopup, setActivePopup] = useState("")
    const [objectToChange, setObjectToChange] = useState("")


    const {register, handleSubmit, control, reset, watch, setValue, formState: {errors}} = useForm({
        resolver: yupResolver(tableSchema),
        mode: 'onBlur',
        defaultValues: {
            "values": [{
                effective_caller_id_name: "",
                position: "",
                extension: "",
                department: "",
                audio_for_missed_call: "",
                status: false,
            }]
        }
    })

    const { fields, update} = useFieldArray(
        {control, name: "values"}
    );

    const watchAllFields = watch();

    const [curAudioOriginal, setCurAudioOriginal] = useState("")
    // const [playStatus, setPlayStatus] = useState(false)

    const playAudio = (filePath) => {
        if (!filePath || filePath.length === 0) return

        if (filePath === curAudioOriginal && playStatus) {
            setPlayStatus(false)
            return
        }

        if (filePath === curAudioOriginal && !playStatus) {
            setPlayStatus(true)
            return
        }

        setCurAudioOriginal(filePath)
        let fileName = filePath
        if (fileName.includes("/"))
            fileName = fileName.split("/")[fileName.split("/").length - 1]

        if (fileName.includes("#"))
            fileName = fileName.split("#")[fileName.split("#").length - 1]

        const url = [ttsPrefixLink, encodeURIComponent(fileName)].join("")
        setCurAudio(url)
        // console.log(url)
    }

    const changeAllStatuses = (status) => {
        for (const key of Object.keys(watchAllFields)) {
            setValue(key, {...watchAllFields[key], status: status})
        }
    }

    const changeCb = (v) => {
        if (!v) {
            setObjectToChange("changeAllStatuses")
            setActivePopup("yesno")

            return
        }
        changeAllStatuses(v)
        setAllSelected(v)
    }

    useEffect(() => {
        if (!yesnoTriggered || objectToChange.length === 0) {
            setObjectToChange("")
            setYesNoTrigger(false)
            setActivePopup("")
        }

        if (objectToChange === "changeAllStatuses") {
            changeAllStatuses(false)
            setAllSelected(false)
        }

        setObjectToChange("")
        setYesNoTrigger(false)
        setActivePopup("")
    }, [yesnoTriggered])


    const loadVzExtensions = () => {
        const f = (data) => {
            setVzExtensions(data)
            reset({values: data})

            if (data?.length === 0) return
            if (data.filter(e => e?.audio_for_missed_call?.length > 0).length === data.length) {
                setAllSelected(true)
            }
        }
        getVZExtensions(theme.userAccount)
            .then(r => f(r))
            .catch(e => console.log(e))

    }

    useEffect(() => {
        if (!theme.userAccount.login || !theme.userAccount.token) return
        loadVzExtensions()
    }, [theme.userAccount])

    const prepareTtsText = (data) => {
        if (!template || template.length === 0) return ""
        let text = template

        for (const elem of textMapping) {
            if (elem.length !== 2) continue
            text = text.replace(elem[0], data[elem[1]])
        }
        text += ". Для соединения наберите 0"
        // console.log(text)
        return text
    }

    const submitData = (data) => {
        for (const string of data?.values) {
            if (!string?.effective_caller_id_name) string["effective_caller_id_name"] = ""
            if (string.status) {
                string["text"] = prepareTtsText(string)
                // console.log(string)
            }
        }

        setSavingStatus(true)
        updateVZExtensions({...data, ...theme.userAccount})
            .then(r => sleep(15000).then(
                () => {
                    setSavingStatus(false)
                    theme.setActivePopupGlobal("successSaveTemplate")
                }
            ))
            .catch(() => {
                setSavingStatus(false)
                theme.setActivePopupGlobal("failSave")
            })
    }

    useEffect(() => {
        if (!fields?.length) {
            setFilteredRows([])
            return
        } else if (fields?.length <= pageSize) {
            setFilteredRows([...fields.map(e => e?.vmid)])
            return
        }

        let start = 0
        if (curPage > 1) start = (curPage - 1) * pageSize
        let end = start + pageSize - 1
        setFilteredRows([...fields.filter((_, index) => index >= start && index <= end).map(e => e?.extension_uuid)])
    }, [curPage, fields, pageSize])

    return (
        <form onSubmit={handleSubmit(submitData)}>
            <div style={{margin: "-52px 0px 0px", float: "right", position: "relative"}}>
                {!savingStatus &&
                    <SubmitButton text={"Сохранить изменения"}/>
                }

                {savingStatus &&
                    <LoadingDots />
                }
            </div>

            <div className={s.formBlockReq}>
                <div style={{display: 'none'}}>
                    {/*{curAudio ?*/}
                    {/*    <AudioPlayer src={curAudio}*/}
                    {/*                 playStatus={playStatus}*/}
                    {/*                 vr={false}*/}
                    {/*                 setPlayStatus={setPlayStatus}/> : null*/}
                    {/*}*/}
                </div>
                <table
                    style={{textAlign: "left", width: "100%"}}>
                    <thead>
                    <tr>
                        <th>Имя сотрудника</th>
                        <th>Номер</th>
                        <th>Должность (департамент, отдел)</th>
                        {/*<th>Департамент</th>*/}
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <HelpElem text={allSelected ? "Выключить все" : "Включить все"}>
                                <CheckBox checked={allSelected} chStatus={changeCb}/>
                            </HelpElem>
                        </td>
                    </tr>
                    {fields.length > 0 &&
                        fields.map((string, index) =>
                            filteredRows?.includes(string.extension_uuid) &&
                            <tr key={string.extension_uuid}>
                                <td width="26%">
                                    <input type="text" size="35"
                                           placeholder="Имя сотрудника"
                                        // defaultValue={string.effective_caller_id_name}
                                           {...register(`values.${index}.effective_caller_id_name`)}
                                    />
                                </td>
                                <td width="6%">
                                    <input type="text" size="12" style={{background: "#f0f0f0" }}
                                           placeholder="Внутренний номер"
                                           readOnly={true}
                                        // defaultValue={string.extension}
                                           {...register(`values.${index}.extension`)}
                                    />

                                </td>
                                <td width="52%">

                                    <input type="text" size="70"
                                           placeholder="Должность"
                                           defaultValue={
                                               string.description && string.description.includes("|")
                                                   ? string.description.split("|")[0].trim()
                                                   : string.description
                                           }
                                           {...register(`values.${index}.position`)}
                                    />
                                </td>
                                <td style={{display: 'none', width: "0px"}} width="26%">
                                    <input type="text" size="30"
                                           hidden={true}
                                           placeholder="Департамент"
                                           defaultValue={
                                               string.description && string.description.includes("|")
                                                   ? string.description.split("|")[1].trim()
                                                   : string.description
                                           }
                                           {...register(`values.${index}.department`)}
                                    />
                                </td>
                                <td width="10%">
                                    <input type="text" size="20"
                                           hidden={true}
                                           placeholder="Департамент"
                                           defaultValue={string.audio_for_missed_call}
                                           {...register(`values.${index}.audio_for_missed_call`)}
                                    />
                                    {template && template.length > 0 &&
                                        <label>
                                            <input type={"checkbox"}
                                                   className={s.realCheckBox}
                                                   defaultChecked={!!string.audio_for_missed_call}
                                                   {...register(`values.${index}.status`)}
                                            />
                                            <div className={s.fakeCb}/>
                                        </label>
                                    }

                                    {string.audio_for_missed_call && string.audio_for_missed_call.length > 10 &&
                                        <button className={s.buttonPlayUser} onClick={(event) => {
                                            event.preventDefault()
                                            playAudio(string.audio_for_missed_call)
                                        }}>

                                            {(curAudioOriginal === string.audio_for_missed_call) && playStatus
                                                ? <PauseCircleFilled/>
                                                : <PlayCircleFilled/>
                                            }
                                        </button>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>

            {/*После мерджа разделов магазина с мэйном нужно перенести в основной попап*/}
            <ModalMessage label={"Внимание!"}
                          message={"Вы уверены, что хотите отключить услугу на всех номерах?"}
                          show={activePopup === "yesno"}
                          hide={() => setActivePopup("")}
                          standartButtonText={"Нет"}
                          noStandartButton={true}
            >
                <div className={s.yesNoStyle}>
                    <DefaultButton text={"Да"}
                                   clickEvent={() => {setActivePopup(""); setYesNoTrigger(true)}}/>
                    <DefaultButton text={"Нет"} style={s.greenBG}
                                   clickEvent={() => {setActivePopup(""); setYesNoTrigger(false)}}/>
                </div>
            </ModalMessage>

            <PaginationBlock curPage={curPage} setCurPage={setCurPage} callData={fields} paginationSize={pageSize}/>
        </form>
    )
}

const TtsTemplates = ({activeTtsTemplates, setActiveTtsTemplates, ttsText, setTtsText, setActivePopup}) => {
    const theme = useTheme()
    const [ttsTemplates, setTtsTemplates] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState()

    const {curAudio, setCurAudio} = useTheme()
    const [ttsGenerated, setTtsGenerated] = useState(false)
    const [ttsAudio, setTtsAudio] = useState("")

    const {register, handleSubmit, control, watch, setValue, formState: {errors}} = useForm({
        resolver: yupResolver(templateSchema),
        mode: 'onBlur',
        defaultValues: {
            text: localStorage.getItem('tts')==null?defaultText.text:localStorage.getItem('tts'),
            label: "",
        }
    })

    const textField = watch(["text"]);

    useEffect(() => {
        if (!theme.contacts || !theme.contacts.name) return

        let text = localStorage.getItem('tts') ? localStorage.getItem('tts') : defaultText.text

        if (!text.toString().endsWith(theme.contacts.name))
            setValue('text', [text, theme.contacts.name].join(" "));
        else setValue('text', text);
    }, [theme.contacts])

    useEffect(() => {

        setTtsText(textField[0])
        localStorage.setItem('tts', textField[0])
        //setTtsText(localStorage.getItem('tts'))

        // При изменении текста, убираем ссылку на аудио

        if (curAudio.length === 0) return
        if (ttsText === textField[0]) return

        setCurAudio("")
    }, [textField[0]])

    useEffect(() => {
        setCurAudio("")
        if (!activeTtsTemplates) return

        if (activeTtsTemplates.text) {
            setTtsText(activeTtsTemplates.text)
        }

        if (activeTtsTemplates.tts_link) {
            setCurAudio(activeTtsTemplates.tts_link)
        }

        if (activeTtsTemplates.text) {
            setValue("text", activeTtsTemplates.text)
        }
        setSelectedTemplate(activeTtsTemplates)

    }, [activeTtsTemplates])

    // const loadVzTemplates = () => {
    //     getVZTemplates(theme.userAccount)
    //         .then(r => setTtsTemplates([...r]))
    //         .catch(e => console.log(e))
    //
    // }
    //
    // useEffect(() => {
    //     if (!theme.userAccount.login || !theme.userAccount.token) return
    //     loadVzTemplates()
    // }, [theme.userAccount])

    const createTTS = () => {
        const result = (url) => {
            // r.url && setTtsAudio(r.url) && setTtsGenerated(true) && saveTemplate
            if (!url || url.length === 0) return
            setTtsGenerated(true)
            setTtsAudio(url)
        }

        localStorage.setItem('tts', ttsText);
        generateTTs({
            text: textField[0]

        })
            .then(r => r.url && result(r.url))
            .catch(e => console.log(e.data))
    }

    const deleteTemplate = () => {
        if (!activeTtsTemplates) return
        deleteVZTemplate({...activeTtsTemplates, ...theme.userAccount})
            .then(() => {
                // loadVzTemplates();
                setActivePopup("successSaveTTSTemplate")
            })
            .catch(r => console.log(r.data))
    }

    const saveTemplate = (data) => {
        // Смотрим шаблоны с таким же заголовком
        //const defaultTemplateName = "default"

        //const t = ttsTemplates.find(elem => elem.label === defaultTemplateName)

        const t = ttsTemplates.find(elem => elem.label === data.label)
        data.login = theme.userAccount.login
        data.tts_link = curAudio

        if (t) { // если есть - апдейтим
            data.id = t.id
            updateVZTemplate({...data, ...theme.userAccount})
                .then(() => {
                    // loadVzTemplates();
                    setActivePopup("successSave")
                })
                .catch(r => console.log(r.data))
        } else { // если нет - создаём
            saveVZTemplate({...data, ...theme.userAccount})
                .then(() => {
                    // loadVzTemplates();
                    setActivePopup("successSave")
                })
                .catch(r => console.log(r.data))
        }
    }

    return (
        <form className={s.ttsBlock} onSubmit={handleSubmit(saveTemplate)} >
            <div className={s.textareatts} style={{width: "100%"}}>
                    <textarea defaultValue={ttsText}
                              className={
                                  errors.text
                                      ? `${s.defaultInput} ${s.error}`
                                      : s.defaultInput
                              }
                              {...register('text')}
                    />
                <div>
                    <br/>
                    Используйте символ + перед ударной гласной, в случаях, когда нужно уточнить корректный вариант произношения слова, например:
                    <div className={s.blockquote}>Дверь в комнату заперта на зам+ок. </div>
                </div>

                <div style={{margin: "17px 0px 0px 0px"}}>
                    {(!ttsAudio || ttsAudio.length === 0) &&
                        <SubmitButton
                            text={"Генерировать аудио"}
                            color={`${s.green} ${s.w}`}
                            clickEvent={(e) => {
                                e.preventDefault();

                                createTTS()
                                // saveTemplate()
                            }}/>}

                    {ttsAudio && ttsAudio.length > 0 &&
                        <SubmitButton
                            text={"Прослушать шаблон"}
                            color={`${s.green} ${s.w}`}
                            clickEvent={(e) => {
                                e.preventDefault();

                                setCurAudio(ttsAudio)
                                // saveTemplate()
                            }}/>}
                </div>

            </div>

            <div className={s.buttonsBlock} style={{display: 'none', width: "0px"}}>
                {/*<div className={s.buttonsBlock} >*/}

                <div className={s.ttsSelect} style={{visibility: 'hidden'}}>
                    {/*<div className={s.ttsSelect} >*/}
                    Выберите или создайте шаблон:
                    <Controller
                        control={control}
                        values={ttsTemplates}
                        name="label"
                        instanceId={2}
                        rules={{required: true}}
                        render={({field: {onChange}}) => (
                            <Creatable
                                onChange={(v) => {
                                    v && onChange(v.label);
                                    v && setActiveTtsTemplates(v)
                                }}
                                defaultValue={"Default"}
                                options={ttsTemplates}
                                placeholder={'название'}
                                isClearable
                                //isMulti
                                isSearchable
                                value={activeTtsTemplates}
                                className={
                                    errors.label
                                        ? `${s.error}`
                                        : ``
                                }
                            />
                        )}
                    />

                </div>

                <div>

                    <div style={{marginLeft: "10px", display: 'none'}}>
                        {!curAudio && selectedTemplate && selectedTemplate.label && !selectedTemplate.tts_link &&
                            <span style={{fontSize: "12px"}}>Необходимо сгенерировать аудио</span>
                        }

                        {curAudio && selectedTemplate && selectedTemplate.label && !selectedTemplate.tts_link &&
                            <span style={{fontSize: "12px"}}>Шаблон можно сохранить</span>
                        }

                        {curAudio.length > 0 &&
                            <SubmitButton text={"Сохранить шаблон"}
                                          color={`${s.green} ${s.w}`}/>}


                    </div>

                    {selectedTemplate && selectedTemplate.tts_link &&
                        <div style={{marginLeft: "10px", display: 'none'}}>
                            <SubmitButton text={"Удалить шаблон"}
                                          color={s.w}
                                          clickEvent={e => {
                                              e.preventDefault();
                                              deleteTemplate()
                                          }}/>
                        </div>

                    }


                </div>

            </div>
        </form>
    )
}

const TtsBlock = (props) => {
    const {curAudio, playStatus, setPlayStatus} = useTheme()
    const [template, setTemplate] = useState(localStorage.getItem('tts'))
    const [ttsText, setTtsText] = useState(localStorage.getItem('tts'))

    useEffect(() => {
        if (!curAudio || !curAudio.length) return
        setPlayStatus(true)
    }, [curAudio])

    return(
        <>
            <TtsTemplates activeTtsTemplates={template} setActiveTtsTemplates={setTemplate}
                          ttsText={ttsText} setTtsText={setTtsText} setActivePopup={props.setActivePopup}/>

            {curAudio && curAudio.length > 0 &&
                <div className={s.playerMain}>
                    <div className={s.player}>
                        <AudioPlayerMini src={curAudio} vr={false}
                                         playStatus={playStatus} setPlayStatus={setPlayStatus}
                                         stopEvent={setPlayStatus}/>
                    </div>

                    {!playStatus &&
                        <button className={s.buttonPlay} onClick={() => setPlayStatus(!playStatus)}>
                            <PlayCircleFilled/>
                        </button>
                    }

                    {playStatus &&
                        <button className={`${s.buttonPlay} ${s.buttonStop}`} onClick={() => setPlayStatus(!playStatus)}>
                            <PauseCircleFilled/>
                        </button>
                    }
                </div>
            }
            <TableForm template={ttsText}/>
        </>
    )
}


const ServiceSettingsBlock = (props) => {
    const theme = useTheme()
    const [data, setData] = useState([])

    const [allSelected, setAllSelected] = useState(false)
    const [yesnoTriggered, setYesNoTrigger] = useState(false)
    const [startActivity, setStartActivity] = useState(false)
    const [activePopup, setActivePopup] = useState("")
    const [objectToChange, setObjectToChange] = useState("")

    const {register, handleSubmit, control, reset, setValue, watch, formState: {errors}} = useForm({
        resolver: yupResolver(settingsSchema),
        mode: 'onBlur'
    })

    const { fields, update} = useFieldArray(
        {control, name: "values"}
    );

    const watchAllFields = watch();


    useEffect(() => {
        if (!theme.vatsDomain || !theme.vatsDomain.new_cluster || !theme.vatsDomain.domain) return

        const f = (response) => {
            if (!response.length) return
            response = response.sort((a, b) => b.enabled ? 1 : -1)
            reset({values: response})
            if (!response.find(e => e?.enabled === false)) setAllSelected(true)
        }

        getVamZvoniliSettings({...theme.userAccount, domain: theme.vatsDomain.domain})
            .then(r => f(r))
            .catch(e => console.log("error: ", e))

    }, [theme.vatsDomain, theme.userAccount])

    const submitData = (formData) => {
        setStartActivity(true)

        saveVamZvoniliSettings({...theme.userAccount, data: formData?.values})
            .then(() => {
                props.setActivePopup("successSave")
                setStartActivity(false)
            })
            .catch(r => {console.log(r); setStartActivity(false); props.setActivePopup("oups"); })
    }

    const changeAllStatuses = (status) => {
        for (const key of Object.keys(watchAllFields)) {
            setValue(key, {...watchAllFields[key], enabled: status})
        }
    }

    const changeCb = (v) => {
        if (!v) {
            setObjectToChange("changeAllStatuses")
            setActivePopup("yesno")

            return
        }
        changeAllStatuses(v)
        setAllSelected(v)
    }

    useEffect(() => {
        if (!yesnoTriggered || objectToChange.length === 0) {
            setObjectToChange("")
            setYesNoTrigger(false)
            setActivePopup("")
        }

        if (objectToChange === "changeAllStatuses") {
            changeAllStatuses(false)
            setAllSelected(false)
        }

        setObjectToChange("")
        setYesNoTrigger(false)
        setActivePopup("")
    }, [yesnoTriggered])

    return(
        <form onSubmit={handleSubmit(submitData)}>
            <div className={s.formBlockReq}>
                <table
                    style={{textAlign: "left"}}>
                    <thead>
                    <tr>
                        <th>Внешний номер</th>
                        <th>Активность услуги на номере</th>
                    </tr>
                    <tr>
                        <td></td>
                        <HelpElem text={allSelected ? "Выключить все" : "Включить все"}>
                            <CheckBox checked={allSelected} chStatus={changeCb}/>
                        </HelpElem>
                    </tr>
                    </thead>
                    <tbody>
                    {fields?.length > 0 &&
                        fields?.map((string, index) =>
                            <tr key={string.dialplan_uuid}>
                                <td width="26%">
                                    <input type="hidden" {...register(`values.${index}.dialplan_uuid`)} />
                                    <input type="text" size="35"
                                           placeholder="Внешний номер"
                                           readOnly={true}
                                        // defaultValue={string.dialplan_number}
                                           {...register(`values.${index}.dialplan_number`)}
                                    />
                                </td>
                                <td width="20%">
                                    <label >
                                        <input type={"checkbox"}
                                               className={s.realCheckBox}
                                            // onBlur={onBlur} // notify when input is touched
                                            // defaultChecked={string.enabled}
                                               {...register(`values.${index}.enabled`)}
                                        />
                                        <div className={s.fakeCb}/>
                                    </label>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>

                <div>
                    {!startActivity &&
                        <SubmitButton text={"Сохранить изменения"} />
                    }

                    {startActivity &&
                        <LoadingDots />
                    }

                    <div style={{width: "450px", margin: "35px 0", fontSize: "18px"}}>
                        Активность услуги на конкретном номере не несёт дополнительных издержек.<br/><br/>
                        Включение услуги лишь активирует соответствующую настройку
                    </div>
                </div>
            </div>

            {/*После мерджа разделов магазина с мэйном нужно перенести в основной попап*/}
            <ModalMessage label={"Внимание!"}
                          message={"Вы уверены, что хотите отключить услугу на всех номерах?"}
                          show={activePopup === "yesno"}
                          hide={() => setActivePopup("")}
                          standartButtonText={"Нет"}
                          noStandartButton={true}
            >
                <div className={s.yesNoStyle}>
                    <DefaultButton text={"Да"}
                                   clickEvent={() => {setActivePopup(""); setYesNoTrigger(true)}}/>
                    <DefaultButton text={"Нет"} style={s.greenBG}
                                   clickEvent={() => {setActivePopup(""); setYesNoTrigger(false)}}/>
                </div>
            </ModalMessage>
        </form>
    )
}


const Form = ({setActivePopup}) => {
    const [activeTab, setActiveTab] = useState({})

    return (
        <div style={{minHeight: "300px"}}>
            <div style={{marginBottom: "25px"}}>
                <DefaultTitle title="Вам Звонили" justify={true}>
                    <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={vamZvoniliTabs} noMargin={true}/>
                </DefaultTitle>
            </div>

            {activeTab.section === "tts" &&
                <TtsBlock setActivePopup={setActivePopup}/>
            }

            {activeTab.section === "settings" &&
                <ServiceSettingsBlock setActivePopup={setActivePopup}/>
            }
        </div>
    )
}

export default Form
