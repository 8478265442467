import React, {useEffect, useState} from 'react'
import s from './DdosEvent.module.scss'
import {ddosHeaders} from "../../../../../../provider/variables";
import {convertDateToUcdrFull} from "../../../../../../sortFunction";


const DdosEvent = ({event}) => {

  const [selectedEvent, setSelectedEvent] = useState(false)

  const normalizeSignature = (string) => {
      let a_str = ""
      let b_str = ""
      if(string.includes(':')) {
          a_str = string.substring(0, string.indexOf(':') + 1)
          b_str = string.substring(string.indexOf(':') + 2, string.length)
      }

      return a_str + "\n" + b_str
  }

  // useEffect(() => {
  //     if (!selectedEvent) return
  //
  //     if (event.id === 194472) console.log(event.ext)
  //
  // }, [selectedEvent])


  return (
      <>
          <tr className={s.tableBody}
              onClick={() => setSelectedEvent(!selectedEvent)}>
              {Object.keys(ddosHeaders).map((key, index) => (
                      event[key] === "nan" ? <td key={key+"_"+index}>Нет информации</td>:
                          key === "datetime" ?
                              <td key={key+"_"+index}>
                                  {convertDateToUcdrFull(event[key].replace("T", " ").replace("Z", ""), 0, 4)}
                              </td>
                              : key === "signature" ?
                              <td key={key+"_"+index}>{normalizeSignature(event[key])}</td>
                              : <td key={key+"_"+index}>{event[key]}</td>
                  )
              )}

          </tr>

          <tr>
              <td>
                  {event.ext && (event.ext["target_data"].length > 0 || event.ext["target_total"].length > 0) &&

                  <div className={s.tableExtend}
                       onClick={(e) => e.stopPropagation()}
                       style={{display: selectedEvent? "block": "none"}}>
                      <div className={s.sub} key={event.id+"_ext"}>
                          {event.ext && event.ext["target_data"] && event.ext["target_data"].length > 0 &&
                          event.ext["target_data"].map(
                              (elem, index) =>
                                  <div className={s.extendElem} key={index}>
                                      <div className={s.elemTitle}>{elem.title}</div>
                                      {elem.content.map(c =>
                                          <div className={s.elem} key={c.join(" ")}>
                                              {c.join(" ")}
                                          </div>
                                      )}
                                  </div>
                          )

                          }

                      </div>

                  </div>

                  }
              </td>
          </tr>
      </>



  )
}

export default DdosEvent
