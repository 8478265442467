import React, {useEffect, useState} from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './VirtualATS.module.scss'
import {useTheme} from "../../../hooks/useTheme";
import {fwcUrlOld, fwcUrl, fwBetaServer} from "../../../provider/variables";
import Select from "react-select";
import {getFrontWcDomain} from "../../../api";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import {useSearchParams} from "react-router-dom";


const VatsOld = () => {
    const {vatsNames, cbcAuth, userAccount, theme } = useTheme()
    const [atsSelectIsOpen, openAtsSelect] = useState(false)
    const [domain, setDomain] = useState({})
    const [vatsAuthUrl, setVatsAuthUrl] = useState("")
    const [cbcFullUrl, setCbcUrl] = useState("")

    useEffect(() => {
        if (cbcAuth["uid"] && !cbcFullUrl) {
            let url = [fwcUrl, cbcAuth["uid"]].join("?session_uuid=")
            setCbcUrl(url)
        }
    }, [cbcAuth, cbcFullUrl])

    useEffect(() => {
        if (!vatsNames) return

        setDomain(vatsNames[0])
    }, [vatsNames])

    const setUrl = (fullDomain) => {
        if (!fullDomain || !fullDomain["domain"] || !userAccount.password) return

        let d = [userAccount["login"], fullDomain["domain"]].join("@")
        let pass = userAccount.password

        let newUrl = [fwcUrlOld, "login.php"].join("")
        newUrl = [newUrl,
            [["username", d].join("="), ["password", pass].join("=")].join("&")
        ].join("?")
        setVatsAuthUrl(newUrl)
    }

    useEffect(() => {
        if (!domain || !domain.value || !userAccount.password) return

        getFrontWcDomain({
            login: userAccount["login"],
            name: 'Token',
            token: userAccount["token"],
            domain: domain["value"]
        }).then(response => setUrl(response))
    }, [domain, userAccount])


    const customStyles = {
        control: () => ({
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            cursor: 'pointer',
        }),
        placeholder: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            opacity: '.3',
        }),
        menuList: () => ({
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            position: 'absolute',
            width: '100%',
            borderRadius: '10px',
            top: 'calc(100% + 12px)',
            overflow: 'hidden',
            background:
                theme === 'light' ? 'var(--night-color)' : 'var(--light-color)',
        }),
        menu: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        option: () => ({
            background: 'none',
            padding: '5px',
            fontSize: '16px',
            cursor: 'pointer',

            '&:hover': {
                background: '#3a3a3a',
                color: 'var(--light-color)',
            },
        }),
        singleValue: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        input: () => ({
            display: 'none',
        }),
    }

    return(
        <div>
            <div style={{display: "inline"}}>Выберите домен: </div>

            <div onClick={() => openAtsSelect(!atsSelectIsOpen)} className={s.atsSelect}>
                <Select
                    onChange={setDomain}
                    options={vatsNames}
                    styles={customStyles}
                    isClearable
                    isSearchable
                    value={domain}

                    menuIsOpen={atsSelectIsOpen}
                />

            </div>

            { vatsAuthUrl ?
                <div className={s.vatsLink}>
                    <script type="text/javascript" src={vatsAuthUrl}/>
                    <a href={vatsAuthUrl} target={"__blank"}>Перейти на сайт Управление ВАТС</a>
                </div>
                :
                <div className={s.vatsLink}>
                    <DefaultTitle title={"Возможно услуга управления ВАТС не настроена"} />
                </div>
            }

        </div>
    )
}

const VatsNew = () => {
    const {userAccount, vatsDomain} = useTheme()
    const [url, setUrl] = useState("")
    const [searchParams, ] = useSearchParams();

    useEffect(() => {
        if (!userAccount.login || !userAccount.token || !vatsDomain.domain) return
        let main_path = "/core/user_settings/user_dashboard.php"
        if (searchParams.get("path") && searchParams.get("path")==="extensions") {
            main_path = "/app/extensions/extensions.php"
        }

        let u = fwBetaServer + main_path
        u += "?username=" + userAccount.login + "@" + vatsDomain.domain
        u += "&token=" + userAccount.token
        setUrl(u)
    }, [userAccount, vatsDomain])

    return (
        <div className={s.flex}>
            <div>
                { url &&
                <div className={s.vatsFrame}>
                    <iframe src={url} allowFullScreen/>
                </div>
                }
            </div>
        </div>
    )

}

const VirtualATSPage = () => {
    const [activeBurger, setActiveBurger] = useState(false)
    const theme = useTheme()

    const [domain, setDomain] = useState({})


    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    useEffect(() => {
        if (!theme.userAccount.password || !theme.userAccount.login) return

        getFrontWcDomain({
            login: theme.userAccount.login,
            name: 'Token',
            token: theme.userAccount.token,
            domain: theme.userAccount.login
        }).then(response => setDomain(response))
    }, [theme.userAccount])


    return (
        <div className={`${s.mainPart} ${theme.designType === "minimal" && s.minimalDesign}`}>
        {/*<div className={`${s.mainPart}`}>*/}
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                {Object.keys(domain).length > 0 && !domain.new_cluster &&
                <VatsOld />
                }

                {Object.keys(domain).length > 0 && domain.new_cluster &&
                <VatsNew />
                }

                {Object.keys(domain).length === 0 &&
                <div>
                    <div style={{display: "inline"}}>
                        <DefaultTitle title={"Услуга управления ВАТС не подключена, либо недоступна"} />
                    </div>
                </div>
                }

            </div>
        </div>

  )
}

export default VirtualATSPage
