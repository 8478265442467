import React, {useEffect, useState} from "react"
import s from "./Abonents.module.scss"
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import {useTheme} from "../../../../hooks/useTheme";
import {
    createClient,
    deleteClient,
    getClients,
    getMeters,
    updateClient,
    uploadClientMetersMass,
    uploadClients, uploaderStatuses, uploadUniversal
} from "../api";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {clientsSchema} from "../schemas";
import {
    abonentsHeaders,
    abonentsUploadHeaders,
    clientMetersHeaderDwnld, universalUploadHeaderDwnld, universalUploadTemplate,
    uploadClientsMetersTemplate
} from "../variables";
import DefaultButton, {
    DefaultButtonSmall,
    DownloadButton, UploadButtonLess, UploadPreview
} from "../../../../ui/Button/Button";
import {WebSitesButton, WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";
import {HelpElem} from "../../../../ui/HelpElem/HelpElem";
import {sleep} from "../../../../sortFunction";
import PaginationBlock from "../../../../ui/PaginationBlock/PaginationBlock";
import {AbonentsMetersPopup} from "../popups/abonentsMetersPopup";
import {ExclamationButton, HistoryButton, SearchButton} from "../ui/buttons";
import {UploadsPopup} from "../popups/uploadsPopup";
import {ExternalPagination} from "../ui/ExternalPagination";
import {LoadingDots} from "../../../../ui/Loading/Loading";


export const Abonents = () => {
    const pageSize = 10
    const [curPage, setCurPage] = useState(1)
    const [dataCount, setDataCount] = useState(0)
    const [showPagination, setShowPagination] = useState(true)

    const {userAccount, setActivePopupGlobal} = useTheme()
    const [searchWord, setSearchWord] = useState("")
    const [searchKey, setSearchKey] = useState("name")
    const [filteredFields, setFilteredFields] = useState([])
    const [initialFields, setInitialFields] = useState([])
    const [metersList, setMetersList] = useState([])
    const [shakeAction, setShakeAction] = useState(false)
    const [savingAction, setSavingAction] = useState(false)
    const [showUploadPopup, setShowUploadPopup] = useState(false)
    const [showUploadUniversalPopup, setShowUploadUniversalPopup] = useState(false)
    const [showUploadMetersPopup, setShowUploadMetersPopup] = useState(false)
    const [loadingUploads, setLoadingUploads] = useState(false)
    const [uploadsHistory, setUploadsHistory] = useState([])
    const [showLoads, setShowLoads] = useState(false)
    const [afterUpdate, setAfterUpdate] = useState(false)

    const [activeMetersPopup, setActiveMetersPopup] = useState(null)

    const {register, handleSubmit, control, reset, setValue, watch, formState: {errors}} = useForm({
        resolver: yupResolver(clientsSchema),
        mode: 'onBlur',
        defaultValues: {
            results: [{
                address: "",
                client_id: "",
                name: "",
                num: "",
            }]}
    })

    const { fields, append, remove} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "results", // unique name for your Field Array
    });

    useEffect(() => {
        setLoadingUploads(uploadsHistory.filter(e => !e?.uploaded)?.length > 0)

        if (uploadsHistory.filter(e => !e?.uploaded)?.length > 0) {
            sleep(1000).then(() => loadUploads())
            setAfterUpdate(true)
        } else if (afterUpdate) {
            updateData()
            setAfterUpdate(false)
        }
    }, [uploadsHistory])

    const loadUploads = () => {
        uploaderStatuses({auth: userAccount})
            .then(r => {
                setUploadsHistory(r?.data || [])
                setSavingAction(false)
            })
            .catch(r => {
                console.log(r)
                setUploadsHistory([])
                setSavingAction(false)
            })
    }

    useEffect(() => {
        if (Object.keys(errors)?.length === 0) return
        setShakeAction(true)
        sleep(1000).then(() => setShakeAction(false))

    }, [errors])

    const loadAbonents = () => {
        setDataCount(0)
        if (!userAccount?.login) return

        const resetResults = (data) => {
            let d = data?.data?.results || []
            d = d.sort((a, b) => parseInt(a?.client_id) > parseInt(b?.client_id) ? 1 : -1)
            reset({results:  d})
            setInitialFields(d)
            setDataCount(data?.data?.count)
        }
        setSavingAction(true)
        getClients({...userAccount, curPage: curPage, pageSize: pageSize, searchKey: searchKey, searchWord: searchWord})
            .then(r => {
                resetResults(r)
                setSavingAction(false)
            })
            .catch(r => {
                console.log(r)
                setSavingAction(false)
            })
    }

    useEffect(() => {
        setSavingAction(true)
        loadAbonents()
    }, [curPage, pageSize])

    const loadMeters = () => {
        const fail = (data) => {
            console.log(data?.response?.data)
            setSavingAction(false)
        }

        const success = (r) => {
            if (r?.data) setMetersList(r?.data)
        }

        getMeters({...userAccount})
            .then(r => success(r))
            .catch(e => fail(e))
    }

    const updateData = () => {
        loadUploads()
        loadAbonents()
        loadMeters()
    }

    useEffect(() => {
        if (!userAccount?.login) return
        updateData()
    }, [userAccount])

    const submitFunc = async (data) => {
        // Ищем удалённые
        let deleted = [...initialFields].filter(e => !data?.results.find(ne => ne?.uuid === e?.uuid)).map(e => e?.uuid)
        let actions = []
        for (const row of data?.results) {
            if (row?.num?.length === 0) row.num = null
            if (row?.uuid) actions.push({...row, action: "update"})
            else actions.push({...row, action: "create"})
        }

        for (const row of deleted) {
            actions.push({target: row, action: "delete"})
        }

        setSavingAction(true)
        Promise.all(actions.map(
            row => row?.action === "update"
            ? new Promise((resolve, reject) =>
                    updateClient({payload: row, auth: userAccount})
                        .then(() => resolve("ok"))
                        .catch((e) => reject(e)))
            : row?.action === "create"
            ? new Promise((resolve, reject) =>
                    createClient({payload: row, auth: userAccount})
                        .then(() => resolve())
                        .catch((e) => reject(e)))
            : row?.action === "delete"
            ? new Promise((resolve, reject) =>
                            deleteClient({uuid: row?.target, auth: userAccount})
                                .then(() => resolve())
                                .catch((e) => reject(e)))
            : null
            )
        )
            .then(() => {
                setActivePopupGlobal("successSave")
                setSavingAction(false)
                loadAbonents()
            })
            .catch(() => {
                setActivePopupGlobal("failSave")
                setSavingAction(false)
            })
    }

    const appendVoidRow = () => {
        append(Object.assign({}, ...abonentsHeaders.map((x) => ({[x.key]: ""}))))
    }


    useEffect(() => {
        let filtered = []
        if (searchKey?.length === 0 || searchWord?.length === 0 ) {
            filtered = [...fields]
        } else {
            let f = [...fields].filter(row => row?.[searchKey]?.toString()?.includes(searchWord.toString()))
            filtered = [...f]
        }

        setFilteredFields([...filtered].map(row => row?.client_id))
    }, [searchKey, searchWord, fields, curPage])

    // useEffect(() => {
    //     let paginated = filteredFields.slice(
    //         pageSize * curPage - pageSize,
    //         pageSize * curPage
    //     )
    //
    //     setPaginatedFields([...paginated])
    // }, [filteredFields])

    const goToLastPage = () => {
        setCurPage(Math.ceil(filteredFields.length / +pageSize))
    }

    const uploadAction = (data) => {
        setSavingAction(true)
        uploadClients({auth: userAccount, formData: data})
            .then(() => {
                setActivePopupGlobal("successSave")
                setSavingAction(false)
                loadAbonents()
            })
            .catch(() => {
                setActivePopupGlobal("failSave")
                setSavingAction(false)
            })
    }

    const uploadActionMeters = (data) => {
        setSavingAction(true)
        uploadClientMetersMass({auth: userAccount, formData: data})
            .then(() => {
                setSavingAction(false)
                loadMeters()
            })
            .catch(() => {
                setSavingAction(false)
            })
    }

    const universalUpload = (data) => {
        setSavingAction(true)
        uploadUniversal({auth: userAccount, formData: data})
            .then(() => {
                setSavingAction(false)
                loadAbonents()
                loadMeters()
            })
            .catch(() => {
                setSavingAction(false)
            })
    }

    const searchInputKeyDown = (event) => {
        if (event.key === 'Enter') loadAbonents()
    }

    return(
        <div className={s.mainBlock} >
            <DefaultTitle title={"Таблица абонентов"} justify={true}>
                {savingAction
                    ? <WebSitesDivButton text={"Обработка"} color={"noactive"} animated={false}/>
                    : <WebSitesDivButton text={"Обновить"} clickEvent={updateData}/>
                }
            </DefaultTitle>
            <div className={`${s.flexBlock} ${s.spaceBetween}`}>
                <label className={s.searchField}>
                    <span>Найти клиентов</span>
                    <select defaultValue={searchKey} onChange={(e) => setSearchKey(e?.target?.value)}>
                        {abonentsHeaders.map(option =>
                            <option key={option?.key} value={option?.key}>
                                {option?.label}
                            </option>
                        )}
                    </select>
                    <input value={searchWord} onChange={(e) => setSearchWord(e?.target?.value)} onKeyDown={searchInputKeyDown}/>
                    <div className={s.searchB}>
                        <HelpElem text={"Поиск"}>
                            <SearchButton clickEvent={loadAbonents}/>
                        </HelpElem>
                    </div>
                </label>
                <div className={`${s.flexBlock} ${s.margin}`}>
                    {loadingUploads
                        ?   <HelpElem text={"Некоторые данные ещё загружаются"}>
                                <div className={s.orangeChild}>
                                    <ExclamationButton clickEvent={() => setShowLoads(true)}/>
                                </div>
                            </HelpElem>
                        :
                            <HelpElem text={"История загрузок"}>
                                <HistoryButton clickEvent={() => setShowLoads(true)}/>
                            </HelpElem>
                    }
                    <HelpElem text={"Загрузить список абонентов"}>
                        <UploadButtonLess clickEvent={() => setShowUploadPopup(true)}/>
                    </HelpElem>

                    <HelpElem text={"Получить список абонетов"}>
                        <DownloadButton data={fields} columns={abonentsHeaders} file={"abonents.csv"}/>
                    </HelpElem>

                    <HelpElem text={"Загрузить ИПУ клиентов массово"}>
                        <div className={s.orangeChild}>
                            <UploadButtonLess clickEvent={() => setShowUploadMetersPopup(true)}/>
                        </div>
                    </HelpElem>

                    <HelpElem text={"Универсальная загрузка"}>
                        <div className={s.blueChild}>
                            <UploadButtonLess clickEvent={() => setShowUploadUniversalPopup(true)}/>
                        </div>
                    </HelpElem>
                </div>

            </div>

            <div>

            </div>
            <form onSubmit={handleSubmit(submitFunc)} >
                <div className={s.formTable}>
                    <table style={{textAlign: "left", margin: "24px 0"}}>
                        <thead>
                        <tr>
                            {abonentsHeaders.map(
                                elem => <th key={`header_${elem?.key}`}>{elem?.label}</th>
                            )}
                            <th>Действия</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {/*{fields.filter(row => row?.[searchKey]?.toLowerCase().includes(searchWord.toLowerCase())).map((field, index) => (*/}
                        {fields.map((field, index) =>
                            // paginatedFields.includes(field?.client_id) &&
                            <tr key={index}>
                                {abonentsHeaders.map(elem =>
                                    <td key={`${index}_${elem?.key}`}>
                                        <input style={{
                                            background: "#f0f0f0",
                                            width: elem?.width
                                        }}
                                               key={field.id} // important to include key with field's id
                                               className={
                                                   errors?.results?.[index]?.[elem?.key] && shakeAction
                                                       ? s.shake
                                                       : null
                                               }
                                               {...register(`results.${index}.${elem?.key}`)}
                                        />
                                    </td>
                                )}

                                <td style={{
                                    textAlign: "center"
                                }}>
                                    <span className={s.action} onClick={() => remove(index)}>Удалить</span>
                                </td>

                                <td style={{
                                    textAlign: "center"
                                }}>
                                    <DefaultButtonSmall text={"Приборы"} color={"blue"}
                                                        clickEvent={() => setActiveMetersPopup(field?.uuid)}/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                </div>

                <div style={{marginTop: "15px", display: "flex", justifyContent: "space-between"}}>
                    <HelpElem text={"Добавить строку"}>
                        <DefaultButton text={"+"} clickEvent={() => {
                            appendVoidRow(); goToLastPage();
                        }}/>
                    </HelpElem>

                    {(showPagination && !savingAction) &&
                    <div className={s.childPadding}>
                        <ExternalPagination
                            curPage={curPage} setCurPage={setCurPage}
                            dataCnt={dataCount} paginationSize={pageSize}
                        />
                    </div>
                    }

                    {savingAction &&
                    <LoadingDots />
                    }

                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        {savingAction
                            ? <WebSitesDivButton text={"Обработка"} color={"noactive"} animated={false}/>
                            : <WebSitesButton text={"Сохранить"}/>
                        }
                    </div>
                </div>
            </form>

            <UploadPreview show={showUploadPopup} hide={setShowUploadPopup}
                           headers={abonentsUploadHeaders}
                           uploadAction={uploadAction}
                           mainHeader={"Загрузка списка абонентов"}
                           helpHeader={"Для загрузки таблицы CSV её поля должны быть в следующей последовательности:"}
                           data={[["89884541", "Телевизорная 1 502 офис", "891111111", "Иванов Иван Иванович"]]}
            />

            <UploadPreview show={showUploadMetersPopup} hide={setShowUploadMetersPopup}
                           headers={clientMetersHeaderDwnld}
                           uploadAction={uploadActionMeters}
                           mainHeader={"Загрузка ИПУ абонентов (массово)"}
                           helpHeader={"Внимание! При успешной загрузке все предыдущие данные будут удалены. " +
                           "Для загрузки таблицы CSV её поля должны быть в следующей последовательности:"}
                           data={[uploadClientsMetersTemplate]}
            />

            <UploadPreview show={showUploadUniversalPopup} hide={setShowUploadUniversalPopup}
                           headers={universalUploadHeaderDwnld}
                           uploadAction={universalUpload}
                           mainHeader={"Универсальная загрузка данных клиентов"}
                           helpHeader={"Внимание! При успешной загрузке все предыдущие данные будут удалены. " +
                           "Для загрузки таблицы CSV её поля должны быть в следующей последовательности:"}
                           data={[universalUploadTemplate]}
            />

            <AbonentsMetersPopup show={activeMetersPopup?.length > 0} hide={setActiveMetersPopup}
                                 userId={activeMetersPopup}
                                 metersList={metersList}
            />

            <UploadsPopup show={showLoads} hide={() => setShowLoads(false)}
                          loading={savingAction}
                          update={() => {loadUploads(); setSavingAction(true)}} data={uploadsHistory}/>
        </div>
    )
}