import axios from "axios";
// const main_url = "http://127.0.0.1:8440/"
const main_url = "https://api.telezon.ru/zkh/"

export const getSettings = async (params) => {
    let url = `${main_url}settings/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params.login} ${params.token}`,
                'Content-Type': 'application/json',
                'pin': params?.login
            },
        })
        .then((response) => response.data)
}

export const updateSettings = async (params) => {
    let url = `${main_url}settings/${params?.payload?.uuid}/`

    return await axios
        .put(url, {...params?.payload},{
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const createSettings = async (params) => {
    let url = `${main_url}settings/`

    return await axios
        .post(url, {...params?.payload},{
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const getIvrText = async (params) => {
    let url = `${main_url}text/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params.login} ${params.token}`,
                'Content-Type': 'application/json',
                'pin': params?.login
            },
        })
        .then((response) => response.data)
}

export const createIvrText = async (params) => {
    let url = `${main_url}text/`

    return await axios
        .post(url, {...params?.payload}, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const updateIvrText = async (params) => {
    let url = `${main_url}text/${params?.payload?.uuid}/`

    return await axios
        .put(url, {...params?.payload}, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const getClients = async (params) => {
    let url = `${main_url}client/?page_size=${params?.pageSize}`
    if (params?.curPage > 1) {
        url = `${url}&page=${params?.curPage}`
    }
    if (params?.searchWord && params?.searchKey) {
        url = `${url}&${params?.searchKey}=${params?.searchWord}`
    }
    // console.log(url)

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params.login} ${params.token}`,
                'Content-Type': 'application/json',
                'pin': params?.login
            },
        })
        .then((response) => response.data)
}

export const createClient = async (params) => {
    let url = `${main_url}client/`

    return await axios
        .post(url, {...params?.payload}, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const uploadMeters = async (params) => {
    let url = `${main_url}meter/upload/?headers=true`

    const formData = new FormData();
    formData.append("file", params?.formData);

    return await axios
        .post(url, formData, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const uploadClients = async (params) => {
    let url = `${main_url}client/upload/?headers=true`

    const formData = new FormData();
    formData.append("file", params?.formData);

    return await axios
        .post(url, formData, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const updateClient = async (params) => {
    let url = `${main_url}client/${params?.payload?.uuid}/`

    return await axios
        .patch(url, {...params?.payload}, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const deleteClient = async (params) => {
    let url = `${main_url}client/${params?.uuid}/`

    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const getMeters = async (params) => {
    let url = `${main_url}meter/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params.login} ${params.token}`,
                'Content-Type': 'application/json',
                'pin': params?.login
            },
        })
        .then((response) => response.data)
}

export const createMeter = async (params) => {
    let url = `${main_url}meter/`

    console.log(url)

    return await axios
        .post(url, {...params?.payload}, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const updateMeter = async (params) => {
    let url = `${main_url}meter/${params?.payload?.uuid}/`

    return await axios
        .patch(url, {...params?.payload}, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const deleteMeter = async (params) => {
    let url = `${main_url}meter/${params?.uuid}/`

    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const getReaders = async (params) => {
    let url = `${main_url}reader/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params.login} ${params.token}`,
                'Content-Type': 'application/json',
                'pin': params?.login
            },
        })
        .then((response) => response.data)
}


export const getClientMeters = async (params) => {
    let url = `${main_url}client/${params.clientId}/meters/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params.login} ${params.token}`,
                'Content-Type': 'application/json',
                'pin': params?.login
            },
        })
        .then((response) => response.data)
}

export const createClientMeters = async (params) => {
    let url = `${main_url}client/${params.clientId}/meters/`

    return await axios
        .post(url, params?.payload,{
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const updateClientMeters = async (params) => {
    let url = `${main_url}client/${params.clientId}/meters/${params?.payload?.original_meter_id}/`

    return await axios
        .put(url, params?.payload,{
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const deleteClientMeters = async (params) => {
    let url = `${main_url}client/${params.clientId}/meters/${params?.payload?.original_meter_id}/`

    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const uploadClientMeters = async (params) => {
    let url = `${main_url}client/${params.clientUuid}/meters/upload/?headers=true`

    const formData = new FormData();
    formData.append("file", params?.formData);

    return await axios
        .post(url, formData, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const uploadClientMetersMass = async (params) => {
    let url = `${main_url}client/meters/upload/?headers=true`

    const formData = new FormData();
    formData.append("file", params?.formData);

    return await axios
        .post(url, formData, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}


export const uploadUniversal = async (params) => {
    let url = `${main_url}uploader/?headers=true`

    const formData = new FormData();
    formData.append("file", params?.formData);

    return await axios
        .post(url, formData, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}

export const uploaderStatuses =  async (params) => {
    let url = `${main_url}uploader/`

    const formData = new FormData();
    formData.append("file", params?.formData);

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params?.auth?.login} ${params?.auth?.token}`,
                'pin': params?.auth?.login
            },
        })
        .then((response) => response.data)
}