import React, {useEffect, useState} from 'react'
import MinutesSlider from './MinutesSlider/MinutesSlider'
import s from './OutgoingCall.module.scss'
import RangeSelect from './RangeSelect/RangeSelect'
import {useTheme} from "../../../../../hooks/useTheme";
import Cards from "../../../../../ui/Cards/Cards";
import {voipCards} from "../../../../../api";

const OutgoingCall = (props) => {
  const [clientWidth, setClientWidth] = useState(window.innerWidth)
  const {services, packetsVoipNumers, userAccount, shopVoipPrice} = useTheme()
  const [service, setService] = useState()
  const [cards, setCards] = useState([])

  useEffect(() => {
    if (!userAccount.login || !userAccount.token) return
    voipCards(userAccount)
        .then((r) => setCards(r.sort((a, b) => a.id > b.id ? 1 : -1)))
  }, [userAccount])

  useEffect(() => {
    if (!services || !services.active_usl || !packetsVoipNumers || service) return

    const s = services.active_usl.find(usl => usl.usl_id && packetsVoipNumers.includes(usl.usl_id))
    setService(s)
    // console.log(s)

  }, [services, packetsVoipNumers])

  window.addEventListener('resize', e => {
    setClientWidth(window.innerWidth)
  })

  useEffect(() => {
      localStorage.setItem("activeMinutes", props.activeMinutes.toString())
  }, [props.activeMinutes])

  return (
    <div>
      {/*<Cards*/}
      {/*    setActivePacket={setActivePacket}*/}
      {/*    activePacket={activePacket}*/}
      {/*    activeMinutes={activeMinutes}*/}
      {/*    setPacketPrice={setPacketPrice}*/}
      {/*/>*/}
      <div className={s.cards}>
        <Cards usl={service} cards={cards} {...props} activatable={false} setPacketPrice={props.setPacketPrice}/>
      </div>
      {/*{clientWidth >= 768 ? (*/}
      {/*  <MinutesSlider*/}
      {/*    activeMinutes={props.activeMinutes}*/}
      {/*    minutesPrice={props.minutesPrice}*/}
      {/*    setMinutesPrice={props.setMinutesPrice}*/}
      {/*    setActiveMinutes={props.setActiveMinutes}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <RangeSelect setMinutesPrice={props.setMinutesPrice} />*/}
      {/*)}*/}
      <div className={s.wrapper}>
        <p>
          Абонентская плата - {shopVoipPrice} <span className="ruble"> ₽</span> в месяц
        </p>
      </div>
      {/*<p style={{fontSize: "12px"}}>*/}
      {/*  Городская связь при наборе 7-значного номера 2хх-хх-хх в рамках пакета минут бесплатно.*/}
      {/*  При наборе 10-значного федерального номера в формате 391 2хх-хх-хх по междугороднему тарифу для*/}
      {/*  г. Красноярск в ценовом диапазоне от 1,62-1,79 руб/мин, в зависимости от количества минут в пакете*/}

      {/*  /!*<br />*!/*/}
      {/*  /!*Цена минуты вне пакета (звонки на все направления мобильных и городских номеров РФ, не*!/*/}
      {/*  /!*включая международную связь) 2,88 ₽.*!/*/}
      {/*  <br />*/}
      {/*  <br />*/}
      {/*  *Список деловых центров: Москва, Красноярск, Санкт-Петербург, Владивосток, Екатеринбург,*/}
      {/*    Ростов-на-Дону, Тюмень, Хабаровск, Челябинск, Астрахань, Белгород, Брянск, Волгоград, Воронеж,*/}
      {/*    Ижевск, Иркутск, Казань, Калининград, Пенза, Киров, Краснодар, Курск, Липецк, Махачкала,*/}
      {/*    Набережные Челны, Нижний Новгород, Новосибирск, Омск, Тула, Оренбург, Пермь, Самара, Саратов,*/}
      {/*    Сочи, Севастополь, Сургут, Тверь, Тольятти, Томск, Улан-Удэ, Ульяновск, Уфа, Чита, Рязань, Барнаул,*/}
      {/*    Кемерово, Новокузнецк, Ярославль*/}
      {/*</p>*/}
    </div>
  )
}

export default OutgoingCall
