export const tabs = [
    {id: 0, label:"Показания", section: "meters"},
    {id: 1, label:"Настройки", section: "settings"},
    {id: 2, label:"Абоненты", section: "abonents"},
]

export const abonentsHeaders = [
    {key: "client_id", label: "Лицевой счет", width: "100px"},
    {key: "name", label: "Имя клиента", width: "200px"},
    {key: "address", label: "Адрес клиента", width: "350px"},
    {key: "num", label: "Номер телефона", width: "150px"},
]

export const abonentsUploadHeaders = [
    {key: "client_id", label: "Лицевой счет", width: "150px"},
    {key: "address", label: "Адрес клиента", width: "150px"},
    {key: "num", label: "Номер телефона", width: "150px"},
    {key: "name", label: "Имя клиента", width: "150px"},
]

export const metersHeaders = [
    {key: "name", label: "Тип прибора", width: "100px"},
    {key: "priority", label: "Приоритет", width: "50px", input_type: "int"},
    {key: "dative_case_name", label: "Тип прибора *", width: "100px"},
    {key: "install_point", label: "Точка установки прибора", width: "100px"},
    {key: "dative_case_install_point", label: "Точка установки прибора *", width: "100px"},
    {key: "comment", label: "Комментарий", width: "100px"},
    {key: "is_enabled", label: "Активность счётчика", width: "50", input_type: "bool"},
]

export const uploadMetersHeaders = [
    {key: "name", label: "Тип прибора", width: "100px"},
    {key: "install_point", label: "Точка установки прибора", width: "100px"},
    {key: "dative_case_name", label: "Тип прибора *", width: "100px"},
    {key: "dative_case_install_point", label: "Точка установки прибора *", width: "100px"},
    {key: "comment", label: "Комментарий", width: "100px"},
]

export const readersHeaders = [
    {key: "client_id", label: "Идентификатор клиента", width: "70px"},
    {key: "num", label: "Номер клиента", width: "100px"},
    {key: "created_at", label: "Дата передачи показаний", width: "150px"},
]

export const clientMetersHeader = [
    // {key: "client_parent", label: "Идентификатор клиента", width: "70px"},
    {key: "meter_id", label: "Идентификатор прибора", width: "150px", editable: true},
    {key: "name", label: "Тип прибора", width: "150px", editable: false, selectable: true},
    {key: "install_point", label: "Точка установки", width: "150px", editable: false},
]

export const exportReadersCols = [
    {key: "client_id", label: "Идентификатор клиента", width: "70px"},
    {key: "num", label: "Номер клиента", width: "100px"},
    {key: "created_at", label: "Дата передачи показаний", width: "150px"},
    {key: "serial_number", label: "Идентификатор прибора", width: "150px"},
    {key: "meter", label: "Тип прибора", width: "150px"},
    {key: "install_point", label: "Точка установки", width: "150px"},
    {key: "value", label: "Показания", width: "150px"},
]

export const clientMetersHeaderDwnld = [
    {key: "client_parent", label: "Идентификатор клиента", width: "70px"},
    {key: "meter_id", label: "Идентификатор прибора", width: "150px"},
    {key: "name", label: "Тип прибора", width: "150px"},
    {key: "install_point", label: "Точка установки", width: "150px"},
]

export const uploadClientsMetersTemplate = [
    "112233", "12001", "гв_к", "кухня"
]

export const universalUploadHeaderDwnld = [
    {key: "a", label: "ИД клиента", width: "70px"},
    {key: "b", label: "Адрес", width: "150px"},
    {key: "c", label: "Тип счетчика", width: "150px"},
    {key: "d", label: "Место счетчика", width: "150px"},
    {key: "e", label: "ИД счетчика", width: "150px"},
    {key: "f", label: "Заводской номер счетчика", width: "150px"},
]

export const universalUploadTemplate = [
    "24232423", "Вилевский д.11, 1", "ГВС-Кухня", "Кухня", 354725, 866519
]

export const uploadsTypes = {
    "universal": "Универсальный"
}