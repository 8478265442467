import React, {useEffect, useState} from "react";
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import s from "../CRMIntegrationsPage.module.scss"
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {WebSitesButton, WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";
import {scroller} from "react-scroll";
import {useTheme} from "../../../../hooks/useTheme";
import {saveCrmIntegrations} from "../api";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import {FreeTippyElement} from "../../../../ui/TippyElement/TipyElemet";
import {bitrixSchema} from "../schemas";
import {InputLine, CheckboxLine} from "../FormElements"
import Select from "react-select";
import {NoOptionsMessage, useFormSelectStyles, useSelectStyles} from "../../../../ui/react-select-styles";


const BitrixLinesSettings = ({integration, register, errors, control, removeString}) => {
    const [crmSettings, setCrmSettings] = useState([])
    const [additionalStrings, setAdditionalStrings] = useState(1)
    const [openedMenu, setOpenedMenu] = useState("")
    const [leads, setLeads] = useState([])
    const [entities, setEntities] = useState([])


    useEffect(() => {
        if (!integration?.integration?.all_line) return
        if (integration?.integration?.all_reg_lead_type) {
            setLeads(integration?.integration?.all_reg_lead_type.map(e => ({
                id: e?.id,
                value: e?.reg_type,
                label: e?.reg_descriptions
            })))
        }

        if (integration?.integration?.all_entity_type) {
            setEntities(integration?.integration?.all_entity_type.map(e => ({
                id: e?.id,
                value: e?.entity_type,
                label: e?.entity_descriptions
            })))
        }

        setCrmSettings(integration?.integration?.all_line)
    }, [integration])

    const getLeadType = (id) => {
        // while (leads?.length === 0) continue
        return leads.find(e => e?.id === id) || leads.find(e => e?.id === 3)
    }

    const getEntityType = (id) => {
        // while (entities?.length === 0) continue
        return entities.find(e => e?.id === id) || entities.find(e => e?.id === 1)
    }

    useEffect(() => {}, [])

    return(
        <div className={s.bitrixSettings} onClick={() => setOpenedMenu("")}>
            <table className={s.tableForm}>
                <thead>
                <tr>
                    <th>Входящая линия</th>
                    <th>Номер по умолчанию</th>
                    <th>Умная переадресация</th>
                    <th>Всплывающая карточка</th>
                    <th>Создать лид</th>
                    <th>Тип звонков для создания лидов</th>
                    <th>Смена ответственного менеджера</th>
                    <th>Объект в CRM для привязки звонка</th>
                    <th>Регистрация холодных лидов</th>
                    <th>Номер для холодных лидов</th>
                    <th>Действие</th>

                </tr>
                </thead>
                <tbody>
                {crmSettings?.length > 0 &&
                crmSettings.map((string, index) =>
                    <tr key={string.id}>
                        <td>
                            <input type="text"
                                   name={`integration.all_line[${index}].phone_external`}
                                   className={errors.crmDomain
                                       ? `${s.tableInput} ${s.error}` : s.tableInput}
                                // defaultValue={string.phone_external}
                                   {...register(`integration.all_line[${index}].phone_external`)}
                            />
                        </td>
                        <td>
                            <input type="text"
                                   name={`integration.all_line[${index}].phone_internal`}
                                   className={errors.defaultNumber
                                       ? `${s.tableInput} ${s.error}` : s.tableInput}
                                // defaultValue={string.phone_internal}
                                   {...register(`integration.all_line[${index}].phone_internal`)}
                            />
                        </td>
                        <td>
                            <Controller
                                control={control} values={[true, false]}
                                name={`integration.all_line[${index}].smart_forwarding`} instanceId={2}
                                rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                <label>
                                    <input type={"checkbox"} className={s.realCheckBox}
                                           onBlur={onBlur} // notify when input is touched
                                           defaultChecked={string.smart_forwarding}
                                           onChange={onChange}
                                           {...register(`integration.all_line[${index}].smart_forwarding`)}
                                    />
                                    <div className={s.fakeCb}/>
                                </label>

                            )}
                            />
                        </td>
                        <td>
                            <Controller
                                control={control} values={[true, false]}
                                name={`integration.all_line[${index}].show_card`} instanceId={2}
                                rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                <label>
                                    <input type={"checkbox"} className={s.realCheckBox}
                                           onBlur={onBlur} // notify when input is touched
                                           defaultChecked={string.show_card}
                                           onChange={onChange}
                                           {...register(`integration.all_line[${index}].show_card`)}
                                    />
                                    <div className={s.fakeCb}/>
                                </label>

                            )}
                            />
                        </td>
                        <td>
                            <Controller
                                control={control} values={[true, false]}
                                name={`integration.all_line[${index}].create_lead`} instanceId={2}
                                rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                <label>
                                    <input type={"checkbox"} className={s.realCheckBox}
                                           onBlur={onBlur} // notify when input is touched
                                           defaultChecked={string.create_lead}
                                           onChange={onChange}
                                           {...register(`integration.all_line[${index}].create_lead`)}
                                    />
                                    <div className={s.fakeCb}/>
                                </label>
                            )}
                            />
                        </td>
                        <td
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpenedMenu(openedMenu === `${index}.reg_lead_type` ? "" : `${index}.reg_lead_type`)
                            }}
                        >
                            <div style={{display: "flex", alignItems: "center", minWidth: "100px",}}>
                                <Controller
                                    control={control} values={leads}
                                    name={`integration.all_line[${index}].reg_lead_type`} instanceId={2}
                                    rules={{required: true}} render={({field: {onChange, value},}) => (
                                    <Select
                                        onChange={(e) => onChange(e?.id)}
                                        options={leads}
                                        styles={useFormSelectStyles}
                                        hideSelectedOptions
                                        // value={() => getLeadType(string?.reg_lead_type)}
                                        value={getLeadType(value)}
                                        placeholder={"Выбрать"}
                                        components={{NoOptionsMessage}}
                                        noOptionsText={"Не загружены"}
                                        menuIsOpen={openedMenu === `${index}.reg_lead_type`}
                                    />
                                )}
                                />
                            </div>
                        </td>
                        <td>
                            <Controller
                                control={control} values={[true, false]}
                                name={`integration.all_line[${index}].change_manager`} instanceId={2}
                                rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                <label>
                                    <input type={"checkbox"} className={s.realCheckBox}
                                           onBlur={onBlur} // notify when input is touched
                                           defaultChecked={string.change_manager}
                                           onChange={onChange}
                                           {...register(`integration.all_line[${index}].change_manager`)}
                                    />
                                    <div className={s.fakeCb}/>
                                </label>

                            )}
                            />
                        </td>
                        <td onClick={(e) => {
                            e.stopPropagation()
                            setOpenedMenu(openedMenu === `${index}.entity_type` ? "" : `${index}.entity_type`)
                        }}>
                            <div style={{display: "flex", alignItems: "center", minWidth: "100px",}}>
                                <Controller
                                    control={control} values={entities}
                                    name={`integration.all_line[${index}].entity_type`} instanceId={2}
                                    rules={{required: true}} render={({field: {onChange, onBlur, value},}) => (
                                    <Select
                                        onChange={(e) => onChange(e?.id)}
                                        options={entities}
                                        styles={useFormSelectStyles}
                                        hideSelectedOptions
                                        // value={() => getLeadType(string?.reg_lead_type)}
                                        value={getEntityType(value)}
                                        placeholder={"Выбрать"}
                                        components={{NoOptionsMessage}}
                                        noOptionsText={"Не загружены"}
                                        menuIsOpen={openedMenu === `${index}.entity_type`}
                                    />
                                )}
                                />
                            </div>
                        </td>
                        <td>
                            <Controller
                                control={control} values={[true, false]}
                                name={`integration.all_line[${index}].reg_cold_lead`} instanceId={2}
                                rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                <label>
                                    <input type={"checkbox"} className={s.realCheckBox}
                                           onBlur={onBlur} // notify when input is touched

                                           defaultChecked={string.reg_cold_lead}
                                           onChange={onChange}
                                           {...register(`integration.all_line[${index}].reg_cold_lead`)}
                                    />
                                    <div className={s.fakeCb}/>
                                </label>

                            )}
                            />
                        </td>
                        <td>
                            <input type="text"
                                   className={errors.coldLidNumber
                                       ? `${s.tableInput} ${s.error}` : s.tableInput}

                                   name={`integration.all_line[${index}].cold_call_number`}
                                   {...register(`integration.all_line[${index}].cold_call_number`)}
                            />
                        </td>
                        <td>
                            <FreeTippyElement text={"Удалить"}>
                                <span onClick={() => removeString(index)}>
                                    <GlobalSvgSelector id={"remove"}/>
                                </span>

                            </FreeTippyElement>
                        </td>
                    </tr>
                )}

                <tr key={"spacer"} className={s.spacer}/>
                {[...Array(additionalStrings).keys()].map(
                    (k) =>
                        <tr key={crmSettings.length.toString()+k.toString()} className={s.newString}>
                            <td>
                                <input type="text"
                                       name={`integration.all_line[${crmSettings.length + k}].phone_external`}
                                       defaultValue={""}
                                       className={errors.phone_external
                                           ? `${s.tableInput} ${s.error}` : s.tableInput}
                                    // defaultValue={string.phone_external}
                                       {...register(`integration.all_line[${crmSettings.length + k}].phone_external`)}
                                />
                            </td>
                            <td>
                                <input type="text"
                                       name={`integration.all_line[${crmSettings.length + k}].phone_internal`}
                                       defaultValue={""}
                                       className={errors.phone_internal
                                           ? `${s.tableInput} ${s.error}` : s.tableInput}
                                    // defaultValue={string.phone_internal}
                                       {...register(`integration.all_line[${crmSettings.length + k}].phone_internal`)}
                                />
                            </td>
                            <td>
                                <Controller
                                    control={control} values={[true, false]}
                                    name={`integration.all_line[${crmSettings.length + k}].smart_forwarding`} instanceId={2}
                                    rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                    <label>
                                        <input type={"checkbox"} className={s.realCheckBox}
                                               onBlur={onBlur} // notify when input is touched
                                               defaultChecked={false}
                                               onChange={onChange}
                                               {...register(`integration.all_line[${crmSettings.length + k}].smart_forwarding`)}
                                        />
                                        <div className={s.fakeCb}/>
                                    </label>

                                )}
                                />
                            </td>
                            <td>
                                <Controller
                                    control={control} values={[true, false]}
                                    name={`integration.all_line[${crmSettings.length + k}].show_card`} instanceId={2}
                                    rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                    <label>
                                        <input type={"checkbox"} className={s.realCheckBox}
                                               onBlur={onBlur} // notify when input is touched
                                               defaultChecked={false}
                                               onChange={onChange}
                                               {...register(`integration.all_line[${crmSettings.length + k}].show_card`)}
                                        />
                                        <div className={s.fakeCb}/>
                                    </label>

                                )}
                                />
                            </td>
                            <td>
                                <Controller
                                    control={control} values={[true, false]}
                                    name={`integration.all_line[${crmSettings.length + k}].create_lead`} instanceId={2}
                                    rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                    <label>
                                        <input type={"checkbox"} className={s.realCheckBox}
                                               onBlur={onBlur} // notify when input is touched
                                               defaultChecked={false}
                                               onChange={onChange}
                                               {...register(`integration.all_line[${crmSettings.length + k}].create_lead`)}
                                        />
                                        <div className={s.fakeCb}/>
                                    </label>

                                )}
                                />
                            </td>
                            <td
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setOpenedMenu(openedMenu === `${crmSettings.length + k}.reg_lead_type` ? "" : `${crmSettings.length + k}.reg_lead_type`)
                                }}
                            >
                                <div style={{display: "flex", alignItems: "center", minWidth: "100px",}}>
                                    <Controller
                                        control={control} values={leads}
                                        name={`integration.all_line[${crmSettings.length + k}].reg_lead_type`} instanceId={2}
                                        rules={{required: true}} render={({field: {onChange, value},}) => (
                                        <Select
                                            onChange={(e) => onChange(e?.id)}
                                            options={leads}
                                            styles={useFormSelectStyles}
                                            hideSelectedOptions
                                            // value={() => getLeadType(string?.reg_lead_type)}
                                            value={getLeadType(value)}
                                            placeholder={"Выбрать"}
                                            components={{NoOptionsMessage}}
                                            noOptionsText={"Не загружены"}
                                            menuIsOpen={openedMenu === `${crmSettings.length + k}.reg_lead_type`}
                                        />
                                    )}
                                    />
                                </div>
                            </td>
                            <td>
                                <Controller
                                    control={control} values={[true, false]}
                                    name={`integration.all_line[${crmSettings.length + k}].change_manager`} instanceId={2}
                                    rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                    <label>
                                        <input type={"checkbox"} className={s.realCheckBox}
                                               onBlur={onBlur} // notify when input is touched
                                               defaultChecked={false}
                                               onChange={onChange}
                                               {...register(`integration.all_line[${crmSettings.length + k}].change_manager`)}
                                        />
                                        <div className={s.fakeCb}/>
                                    </label>

                                )}
                                />
                            </td>
                            <td onClick={(e) => {
                                e.stopPropagation()
                                setOpenedMenu(openedMenu === `${crmSettings.length + k}.entity_type` ? "" : `${crmSettings.length + k}.entity_type`)
                            }}>
                                <div style={{display: "flex", alignItems: "center", minWidth: "100px",}}>
                                    <Controller
                                        control={control} values={entities}
                                        name={`integration.all_line[${crmSettings.length + k}].entity_type`} instanceId={2}
                                        rules={{required: true}} render={({field: {onChange, onBlur, value},}) => (
                                        <Select
                                            onChange={(e) => onChange(e?.id)}
                                            options={entities}
                                            styles={useFormSelectStyles}
                                            hideSelectedOptions
                                            // value={() => getLeadType(string?.reg_lead_type)}
                                            value={getEntityType(value)}
                                            placeholder={"Выбрать"}
                                            components={{NoOptionsMessage}}
                                            noOptionsText={"Не загружены"}
                                            menuIsOpen={openedMenu === `${crmSettings.length + k}.entity_type`}
                                        />
                                    )}
                                    />
                                </div>
                                {/*<input type="text"*/}
                                {/*       defaultValue={0}*/}
                                {/*       className={*/}
                                {/*           errors.entity_type ? `${s.tableInput} ${s.error}` : s.tableInput}*/}

                                {/*       name={`integration.all_line[${crmSettings.length + k}].entity_type`}*/}
                                {/*       {...register(`integration.all_line[${crmSettings.length + k}].entity_type`)}*/}
                                {/*/>*/}
                            </td>
                            <td>
                                <Controller
                                    control={control} values={[true, false]}
                                    name={`integration.all_line[${crmSettings.length + k}].reg_cold_lead`} instanceId={2}
                                    rules={{required: true}} render={({field: {onChange, onBlur},}) => (
                                    <label>
                                        <input type={"checkbox"} className={s.realCheckBox}
                                               onBlur={onBlur} // notify when input is touched

                                               defaultChecked={false}
                                               onChange={onChange}
                                               {...register(`integration.all_line[${crmSettings.length + k}].reg_cold_lead`)}
                                        />
                                        <div className={s.fakeCb}/>
                                    </label>

                                )}
                                />
                            </td>
                            <td>
                                <input type="text"
                                       defaultValue={""}
                                       className={errors.cold_call_number
                                           ? `${s.tableInput} ${s.error}` : s.tableInput}

                                       name={`integration.all_line[${crmSettings.length + k}].cold_call_number`}
                                       {...register(`integration.all_line[${crmSettings.length + k}].cold_call_number`)}
                                />
                            </td>

                            {((k + 1) === additionalStrings) && k > 0 &&
                            <td>
                                <FreeTippyElement text={"Удалить"}>
                                <span onClick={() => setAdditionalStrings(additionalStrings - 1)}>
                                    <GlobalSvgSelector id={"remove"}/>
                                </span>

                                </FreeTippyElement>
                            </td>
                            }
                        </tr>
                )}

                </tbody>
            </table>
            <WebSitesDivButton text={"Добавить строку"} color={"blue"}
                               clickEvent={() => setAdditionalStrings(additionalStrings + 1)}/>
        </div>
    )
}

export const BitrixIntegration = ({integration, reload, bubbled=true}) => {
    const [showSettings, setShowSettings] = useState(false)
    const [integrationData, setIntegrationData] = useState({})
    const {userAccount, setActivePopupGlobal, vatsDomain} = useTheme()

    useEffect(() => {
        if (showSettings) {
            scroller.scrollTo("settings", {
                duration: 300,
                delay: 0,
                smooth: 'easeInOutQuart'
            })
        }
    }, [showSettings])

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(bitrixSchema),
        mode: 'onBlur',
        defaultValues: {
            domain: "",
            integration: {
                domain: "",
                disk_support: false,
                disk_day_save: 0,
                is_webhook: false,
                webhook_url: "",
                all_reg_lead_type: [],
                all_entity_type: [],
                all_line: []
            }
        }
    })

    useEffect(() => {
        if (!integration?.integration) return
        setIntegrationData(integration)
    }, [integration])

    useEffect(() => {
        let d = {...integrationData}
        if (d?.integration?.all_line?.length > 0) {
            for (let line of d.integration.all_line) {
                line.entity_type = line.entity_type_id
                line.member_id = line.member_id_id
                line.reg_lead_type = line.reg_lead_type_id

                delete line.entity_type_id
                delete line.member_id_id
                delete line.reg_lead_type_id
            }
        }


        reset({...d})
    }, [integrationData])

    const saveIntegration = (data) => {
        // data.integration.active = !!integrationIsEnabled
        let formData = {...data}
        delete formData.integration.all_entity_type;
        delete formData.integration.all_reg_lead_type;
        formData.integration.type = "bitrix"

        saveCrmIntegrations({auth: userAccount, ...vatsDomain, data: formData})
            .then(() => {setActivePopupGlobal("successSave"); reload()})
            .catch((r) => {console.log(r.response.data); setActivePopupGlobal("failSave")})
    }

    const submitForm = (formData) => {
        if (!userAccount?.login || !vatsDomain.domain) return
        if (formData?.integration?.all_line) {
            formData.integration.all_line = formData.integration.all_line.filter(e => e.phone_internal !== "")
        }

        delete formData.integration.all_entity_type;
        delete formData.integration.all_reg_lead_type;

        saveIntegration(formData)
        // console.log(formData)
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0) return

        console.log(errors)
    }, [errors])

    const removeString = (index) => {
        let integration_data = integrationData
        integration_data.integration.all_line = integration_data.integration.all_line.filter((e, ind) => ind !== index)
        setIntegrationData({...integration_data})
    }

    return(
        <>
            <div className={`${bubbled ? s.bubble : s.noBlubble} ${showSettings && s.bubbleAnimation} margin-b-24`}
                 style={{width: "70%"}}
            >
                <div className={`${s.fixedBubble}`}>
                    <div className={s.title}>
                        <DefaultTitle title="Интеграция с Битрикс" justify={true}/>
                    </div>

                    <form onSubmit={handleSubmit(submitForm)} className={s.formFlex}>
                        <InputLine errors={errors} register={register} name={"integration.domain"} title={"Домен CRM Битрикс"}/>
                        <CheckboxLine errors={errors} register={register}
                                      name={"integration.disk_support"} title={"Поддержка Битрикс.Диск"} control={control}/>
                        <InputLine errors={errors} register={register} name={"integration.disk_day_save"}
                                   dataType={"numbers"}
                                   title={"Количество дней хранения записей"}/>
                        <CheckboxLine errors={errors} register={register}
                                      name={"integration.is_webhook"} title={"Поддержка Webhook"} control={control}/>
                        <InputLine errors={errors} register={register} name={"integration.webhook_url"}
                                   title={"Обратный домен Webhook"}/>

                        <WebSitesDivButton text={"Настройка входящих линий"} color={"blue"}
                                           clickEvent={() => setShowSettings(!showSettings)}/>
                        <WebSitesButton text={
                            integration?.integration?.type === "bitrix"
                                ? "Сохранить настройки"
                                : "Применить интеграцию"
                        }/>

                    </form>


                </div>
            </div>

            {showSettings &&
            <form onSubmit={handleSubmit(submitForm)} className={`${s.formFlex} ${bubbled ? s.bubble : s.noBlubble}`}

                  style={{width: "97.5%"}}
            >
                <div className={s.title}>
                    <DefaultTitle title="Настройка входящих линий Bitrix24" justify={true}/>
                </div>
                <BitrixLinesSettings integration={integrationData}
                                     register={register} errors={errors}
                                     control={control}
                                     removeString={removeString}
                />
                <WebSitesButton text={"Сохранить"}/>
            </form>
            }
        </>

    )
}
