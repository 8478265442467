import * as yup from "yup";
import { get, isEmpty } from 'lodash';

export const settingsSchema = yup
    .object({
        always: yup.bool().required(),
        download_all_data: yup.bool().required(),
        external_num: yup.string().required(),
        show_all_data: yup.bool().required(),
        is_enabled: yup.bool().required(),
        start: yup.number(),
        stop: yup.number(),
    })
    .required()


export const clientsSchema = yup.object({
    results: yup.array().of(
        yup.object({
            uuid: yup.string(),
            client_id: yup.string().required().required("Требуется ID клиента"),
            address: yup.string().required().required("Требуется адрес клиента"),
            name: yup.string().required().required("Требуется имя клиента"),
        }))
}).required()

export const metersSchema = yup.object({
    meters: yup.array().of(
        yup.object({
            name: yup.string().required(),
            dative_case_name: yup.string().required(),
            is_enabled: yup.bool().required(),
        }))
}).required()

const uniquePropertyTest = function(value, propertyName, message) {
    if (
        this.parent
            .filter(v => v !== value)
            .some(v => get(v, propertyName) === get(value, propertyName))
    ) {
        throw this.createError({
            path: `${this.path}.${propertyName}`,
            message
        });
    }

    return true;
};
yup.addMethod(yup.object, 'uniqueProperty', function(propertyName, message) {
    return this.test('unique', message, function(value) {
        return uniquePropertyTest.call(this, value, propertyName, message);
    });
});
yup.addMethod(yup.object, 'uniqueProperties', function(propertyNames) {
    return this.test('unique', '', function(value) {
        const errors = propertyNames.map(([propertyName, message]) => {
            try {
                return uniquePropertyTest.call(this, value, propertyName, message);
            } catch(error) {
                return error;
            }
        }).filter(error => error instanceof yup.ValidationError);

        if(!isEmpty(errors)) {
            throw new yup.ValidationError(errors);
        }

        return true;
    });
});

export const clientsMetersSchema = yup.object({
    data: yup.array()
        .of(
        yup.object({
            // client_parent: yup.string().required(),
            meter_id: yup.string().required(),
            install_point: yup.string().required(),
            name: yup.string().required(),
            meter_parent: yup.string().required(),
        }).uniqueProperties([
            ['meter_id', 'meter_id must be unique'],
        ])
    )
}).required()

export const metersDefaultValue = [
    {name: "", dative_case_name: "", is_enabled: false, comment: ""}
]

export const textSchema = yup.object({
    uuid: yup.string(),
    main_text: yup.string().min(10, "требуется текст \"Главное приветствие IVR\"").required("требуется текст \"Главное приветствие IVR\""),
    // finish_text: yup.string().min(10, "требуется текст \"Финальный текст IVR\"").required("требуется текст \"Финальный текст IVR (опционально)\""),
    error_meter_readings_text: yup.string().min(10, "требуется текст \"Текст ошибки распознания показаний\"").required("требуется текст \"Текст ошибки распознания показаний\""),
    get_id_text: yup.string().min(10, "требуется текст \"Текст запроса лицевого счёта\"").required("требуется текст \"Текст запроса лицевого счёта\""),
    not_found_id_text: yup.string().min(10, "требуется текст \"Текст ошибки распознания лицевого счёта\"").required("требуется текст \"Текст ошибки распознания лицевого счёта\""),
    not_work_text: yup.string().min(10, "требуется текст \"Текст, если показания не принимаются\"").required("требуется текст \"Текст, если показания не принимаются\""),
    lexicon_good_answer: yup.string().min(2, "требуется список слов согласия").required("требуется список слов согласия"),
})