import s from "./HelpElem.module.css";
import Tippy from "@tippyjs/react";
import React, {useState} from "react";


export const HelpElem = (props) => {
    const [isOpen, setIsOpen] = useState(false)

    return(
        <div style={{width: "32px", height: "30px"}}>
            <div onMouseOver={() => setIsOpen(true)} onMouseOut={() => setIsOpen(false)}>
                {props.children}
            </div>

            <div
                style={{
                    display: isOpen ? "block" : "none",
                    background: 'var(--background-gradient-st)',
                    color: 'var(--main-font-color)',
                    padding: '12px',
                    borderRadius: '10px',
                    border: '1px solid var(--border-default)',
                    boxShadow: '0 0 20px 0 var(--border-default)',
                    position: "absolute",
                    zIndex: "9999"
                }}
            >
                {props.text}
            </div>
        </div>

    )
}