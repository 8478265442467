import React, {useEffect, useState} from 'react'
import ModalMessage from "../ModalMessage";
import s from './StatusPopup.module.scss'
import {useTheme} from "../../../hooks/useTheme";


export const SrcStatus = ({elements={}, rmFunc}) => {
    // console.log(elements)
    if (elements.rmFunction && elements.rmFunction.value)
        return  (<div className={s.spinnerMain} style={{display: "flex"}}>
                    <div className={s.crossBut} onClick={() => rmFunc(elements.rmFunction)}>Удалить</div>
                    <div className={s.price}>{elements.price ? elements.price : 0} руб.</div>
                </div>)

    else
        return  (<div className={s.spinnerMain} style={{display: "flex"}}>
                    <div className={s.crossButNo}/>
                    <div className={s.price}>{elements.price ? elements.price : 0} руб.</div>
                </div>)
}

const ConfirmationPopup= ({label, data, status, hide, message, rmFunc, success}) => {
    const {shopPhonePrice, shopVoipPrice} = useTheme()

  return (
      <ModalMessage
          label={label}
          show={status}
          standartButtonText={"Отмена"}
          hide={() => hide(false)}>

          {message &&
          <div className={s.errorMessage}>
              {message}
          </div>

          }
          {data.length === 0 &&
          <div>
              Корзина пуста
          </div>
          }

          {data.map((elem, index) =>
              elem.elements.length
              ?
                  <div key={elem.title} className={s.elemGroup}>
                      <div className={s.label}>{elem.title}</div>
                      {elem.elements.length &&
                      elem.elements.map(e =>
                          <div className={s.elem} key={e.name}>
                              <div className={s.elemTitle}>{e.name}</div>
                              <div className={s.elemStatus}>
                                  {
                                      elem.elements.length > 1
                                          ? <SrcStatus elements={e} rmFunc={rmFunc}/>
                                          : <SrcStatus elements={{...e, rmFunction: null}} rmFunc={rmFunc}/>
                                  }

                              </div>
                          </div>
                      )
                      }
                  </div>
              :
                  <div key={index} className={s.elemGroup}>
                      <div className={s.labelInline}>{elem.title}</div>
                      <div className={s.elemStatus}>
                          <SrcStatus elements={elem.elements} rmFunc={rmFunc}/>
                      </div>

                  </div>

          )}

          <div className={s.resultPrice}>
              Итого: {shopVoipPrice + shopPhonePrice} руб.
          </div>

          <div className="white-button margin-center w20"
               style={{marginTop: "10px"}}
               onClick={(v) => success()}>
              ОК
          </div>


      </ModalMessage>
  )
}

export default ConfirmationPopup
