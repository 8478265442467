import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../hooks/useTheme'
import Loading from '../../../ui/Loading/Loading'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './DocumentPage.module.scss'
import Documents from './Documents/Documents'
import Filters from './Filters/Filters'
import Pagination from './Pagination/Pagination'
import {documentsAll} from "../../../api";

const DocumentPage = () => {
    const pageSize = 10
    const { userAccount, designType, errorHnd} = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [documents, setDocuments] = useState([])
    const [filters, setFilters] = useState([])

    // локальное хранилище привязанное к документам
    const [pages, setPages] = useState(1) // количество страниц
    const [activeFilters, setActiveFilters] = useState([]) // активные фильтры
    const [activeDocs, setActiveDocs] = useState([]) // те документы которые показываются на активной странице
    const [activePage, setActivePage] = useState(1) // активная страница

    const loadDocuments = () => {
        documentsAll(userAccount)
            .then((response) => setDocuments(response))
            .catch((r) => errorHnd(r?.response?.status))
    }

    useEffect(() => {
        if (!userAccount?.login) return
        loadDocuments()
    }, [userAccount])

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    const handleChangeFilters = (arr) => {
        setActiveFilters(arr)
    }

    useEffect(() => {
        if (documents?.length === 0) return
        let docsTypes = []
        for (const doc of documents) {
            if (docsTypes.includes(doc?.doc_type)) continue
            docsTypes.push(doc?.doc_type)
        }
        setFilters([...docsTypes.map((d, index) => ({ id: index, title: d.toLowerCase()}))])
    }, [documents])

    useEffect(() => {
        setPages(1)
        if (documents?.length === 0) {
            return
        }

        if (activeFilters?.length > 0) {
            let docs = documents.filter((doc) => {
                return activeFilters.indexOf(doc.doc_type) >= 0
            })

            setActiveDocs(docs)
            setPages(Math.ceil(docs.length / pageSize))
            setActivePage(1)
        } else {
            setActiveDocs(documents)
            setPages(Math.ceil(documents.length / pageSize))
            setActivePage(1)
        }
    }, [activeFilters, documents])

    return (
        <div className={`${s.mainPart} ${designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                <Filters activeFilters={activeFilters} handleChangeFilters={handleChangeFilters} filters={filters}/>
                {activeDocs?.length > 0
                    ? <Documents docsInOnePage={pageSize} documents={activeDocs} activePage={activePage}/>
                    : <div className={s.loading}><Loading /></div>
                }
                <Pagination activePage={activePage} setActivePage={setActivePage} pages={pages}/>
            </div>
        </div>

    )

}

export default DocumentPage
