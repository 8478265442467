import React, {useEffect, useState} from 'react'
import s from './EventsInfo.module.scss'
import DdosEvent from "./DdosEvent/DdosEvent";
import {ddosHeaders} from "../../../../../provider/variables";


const EventsInfo = ({data}) => {

  return (

    <div className={s.eventTableBlock}>
        <table className={s.table}>
            <thead>
                <tr className={s.tableHead}>
                    {Object.values(ddosHeaders).map(
                        (elem, key) => <th key={key}>{elem}</th>)}
                </tr>
            </thead>
            <tbody>
            {data.map(e => <DdosEvent event={e} key={e.id}/>)}

            </tbody>
        </table>

    </div>
  )
}

export default EventsInfo
