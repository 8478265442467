import React from "react";
import s from "./buttons.module.scss"

export const SelectionButton = ({text, selected, inactive, onClick, typpyText}) => {
    return(
        <div className={s.buttonWithText}>
            {typpyText &&
            <button className={`${s.selectionButton} ${inactive && s.inactive} ${(selected && !inactive) && s.activeButton}`}
                    data-title={typpyText} onClick={onClick}>{text}</button>
            }
        </div>
    )
}