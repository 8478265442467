// сервер разработки
// export const backendUrl = "https://backend.telezon.ru:8001/main/"
// export const servicesUrl = "http://127.0.0.1"
// export const thisUrl = "http://127.0.0.1:3000"
// export const authServer = "http://10.248.0.98"
// export const authServer = "http://127.0.0.1"

// сервер разработки
// export const backendUrl = "http://127.0.0.1"
// export const thisUrl = "http://127.0.0.1"
// export const authServer = "http://127.0.0.1"

// сервер production
// export const backendUrl = "https://cabinet.telezon.ru"
// export const thisUrl = "https://cabinet.telezon.ru"
// export const thisUrl = "http://127.0.0.1"
// export const authServer = "https://cabinet.telezon.ru"
export const paymentServer = "https://betacabinet.telezon.ru"
// export const paymentServer = "http://127.0.0.1"

// сервер beta
export const backendUrl = "https://betacabinet.telezon.ru"
export const thisUrl = "https://betacabinet.telezon.ru"
export const authServer = "https://cabinet.telezon.ru"

// сервер beta
// export const backendUrl = "https://betacabinet.telezon.ru"
// export const thisUrl = "https://betacabinet.telezon.ru"
// export const authServer = "https://betacabinet.telezon.ru"

// Локальный
// export const backendUrl = "http://10.248.0.98"
// export const thisUrl = "http://127.0.0.1:3000"
// export const authServer = "http://10.248.0.98"

// export const backendUrl = "https://cabinet.telezon.ru"
// export const thisUrl = "http://127.0.0.1:3000"

// export const backendUrl = "https://betacabinet.telezon.ru"
// export const thisUrl = "http://127.0.0.1:3000"

// export const backendUrl = "https://cabinet.telezon.ru"
// export const thisUrl = backendUrl

// export const backendUrl = "https://lk.telezon.ru"
// export const thisUrl = backendUrl

// export const backendUrl = "https://betacabinet.telezon.ru"
// export const thisUrl = backendUrl

// menu block
export const menu = [
    { id: 1, href: '/unlp', title: 'Безграничная телефония', service: "unlp", showVirtual: false},
    { id: 2, href: '/packets', title: 'Пакетная телефония', service: "packets", showVirtual: false},
    // { id: 2, href: '/virtual-ats', title: 'Виртуальная АТС', service: "vats", showVirtual: false},
    { id: 3, href: '/vats', title: 'Виртуальная АТС', service: "vats", showVirtual: false},
    // { id: 4, href: '/vats-dev', title: 'Виртуальная АТС (dev)', service: "vats", showVirtual: false},
    { id: 5, href: '/autocalling', title: 'Сервис голосовых уведомлений', service: "cbc", showVirtual: false},
    { id: 6, href: '/ddos-protect', title: 'Защита от DDOS', service: "ddos", showVirtual: false},
    { id: 7, href: '/incoming-calls', title: 'Обработка входящих запросов', service: "incoming-calls", showVirtual: true},
    //{ id: 8, href: '/numersShop', title: 'Управление услугами', service: "shop"},
    { id: 9, href: '/vamzvonili', title: 'Вам Звонили', service: "vamzvonili", showVirtual: true},
    { id: 10, href: '/crm-integration', title: 'Интеграции CRM', service: "crmIntegration", showVirtual: false},
    { id: 11, href: '/zkh', title: 'Показания приборов учёта ЖКХ', service: "zkh", showVirtual: false},
]

export const settingsMenu = [
  // { id: 1, href: '/account', title: 'Аккаунт', showVirtual: false },
  { id: 2, href: '/settings', title: 'Параметры', showVirtual: false, gifName: "settings" },
  { id: 3, href: '/documents', title: 'Документы', showVirtual: true, gifName: "docs" },
  // { id: 4, href: '/calllog-old', title: 'Журнал вызовов', service: "calllogOld", showVirtual: true},
  { id: 5, href: '/calllog', title: 'Журнал вызовов', service: "calllog", showVirtual: false, gifName: "callog"},
]

export const MenuTooltips = [
    {id: 0, show: true, key: "menuTooltipSettings",
        title: 'Большинство настроек, контактная информация', service: "settings"},
    {id: 1, show: true, key: "menuTooltipDocs",
        title: 'Документы, лицензии, инструкции', service: "docs"},
    {id: 2, show: true, key: "menuTooltipCallog",
        title: 'Журнал входящих и исходящих вызовов, детализации', service: "callog"},
    {id: 3, show: true, key: "menuTooltipUnlp",
        title: 'В этом разделе вы можете приобрести пакеты исходящей связи', service: "unlp"},
    {id: 4, show: true, key: "menuTooltipVats",
        title: 'В этом разделе доступно управление Виртуальной АТС', service: "vats"},
    {id: 5, show: true, key: "menuTooltipCbc",
        title: 'В этом разделе встроено управление кампаниями сервиса голосовых уведомлений', service: "cbc"},
    {id: 6, show: true, key: "menuTooltipDDos",
        title: 'В этом разделе доступен просмотр статистики по DDOS-атакам', service: "ddos"},
    {id: 7, show: true, key: "menuTooltipIncomingCalls",
        title: 'В этом разделе размещён журнал обработки входящих запросов с ваших почтовых адресов и IVR-меню', service: "incoming-calls"},
    {id: 8, show: true, key: "menuTooltipVamzvonili",
        title: 'В этом разделе находятся настроки услуги Вам звонили, помогающей распределять входящие вызовы между ответственными сотрудниками',
        service: "vamzvonili"},
    {id: 9, show: true, key: "menuTooltipShop",
        title: 'Этот раздел предназначен для покупки новых услуг и расширения существующих', service: "shop"},
    {id: 10, show: true, key: "menuTooltipTelephony",
        title: 'Этот раздел предназначен для управления услугами телефонии, настройки Виртальной АТС и списка телефонных номеров', service: "telephony"},
]

export const callsTabs = [
    // {id: 0, label:"Аналитика звонков", section: "analytics"},
    {id: 0, label:"Потерянные входящие вызовы", section: "lostClients", needVats: true, showVirtual: true},
    {id: 1, label:"Запись входящих/исходящих вызовов", section: "callRecords", needVats: true, showVirtual: true},
    {id: 2, label:"Детализация вызовов", section: "detail", needVats: false, showVirtual: false},
]

export const callsTabsDev = [
    // {id: 0, label:"Аналитика звонков", section: "analytics"},
    // {id: 0, label:"Потерянные входящие вызовы", section: "lostClients", needVats: true, showVirtual: true},
    // {id: 1, label:"Запись входящих/исходящих вызовов", section: "callRecords", needVats: true, showVirtual: true},
    // {id: 2, label:"Детализация вызовов", section: "detail", needVats: false, showVirtual: false},
    {id: 0, label:"Звонки", section: "calls", needVats: true, showVirtual: true},
    {id: 1, label:"Тарификация", section: "detail", needVats: false, showVirtual: false},
]

export const vamZvoniliTabs = [
    {id: 0, label:"Text To Speech", section: "tts", needVats: true, showVirtual: true},
    {id: 1, label:"Управление услугой", section: "settings", needVats: false, showVirtual: false},
]

export const callsCards = [
    {id: 0, label:"Все вызовы", filter: "all", showVirtual: true},
    {id: 1, label:"Потерянные вызовы", filter: "lost", showVirtual: true},
    {id: 2, label:"Входящие вызовы", filter: "inbound", showVirtual: true},
    {id: 3, label:"Исходящие вызовы", filter: "outbound", showVirtual: true},
    {id: 4, label:"Локальные вызовы", filter: "local", showVirtual: true},
]

export const callsCardsWGraphics = [
    "inbound", "outbound", "lost"
]

// menu items
export const headerMenu = [
    { href: '/documents', text: 'Документы', showVirtual: true },
    // { href: '/detalization', text: 'Детализация', showVirtual: false},
    { href: '/support', text: 'Поддержка', showVirtual: true},
]



export const servicesList = {
    "main": ["Головное устройство ПД для ПГИ"],
    "voip": ["ПД для ПГИ", "Головное устройство ПД для ПГИ"],
    "phone": ["Федеральный номер", "Федеральный номер 8800"],
    "internet": ["Интернет TCP/IP", "Интернет PPPoE"],
    "b-seti": ["VLAN"],
    "vats": ["Виртуальная АТС"]
}

export const ddosHeaders = {
    id: "ID события",
    ip_address: "IP адрес",
    datetime: "Дата и время",
    duration: "Длительность (минуты)",
    effect: "Влияние",
    signature: "Сигнатура"
}

export const incomingCallsHeaders = {
    subject: {name: "Источник", collSize: 0},
    date: {name: "Дата, время", collSize: 0},
    phone: {name: "Телефон", collSize: 0},
    message: {name: "Текст обращения", collSize: 0},
    comment: {name: "Комментарий", collSize: 0},
    status: {name: "Статус", collSize: 0},

}

// mailings block
export const mailingsPlans = [
    {id:1, value: "everyday", label: 'Ежедневно' },
    {id:2, value: "week", label: 'Еженедельно' },
    {id:3, value: "two_week", label: 'Раз в 2 недели' },
    {id:4, value: "month", label: 'Раз в месяц' },
    {id:5, value: "half_year", label: 'Каждые полгода' },
    {id:6, value: "year", label: 'Ежегодно' },
]
// tts_template block
export const ttsTemplates = [
    {id:1, value: "standart", label: 'Стандартный' },
    {id:2, value: "medic", label: 'Медицина' },
    {id:3, value: "fitness", label: 'Спортивный' },
]

// timezones block
export const timezones = [
    {id:0, value: 0, label: 'UTC+0' },
    {id:1, value: 1, label: 'UTC+1' },
    {id:2, value: 2, label: 'UTC+2' },
    {id:3, value: 3, label: 'UTC+3' },
    {id:4, value: 4, label: 'UTC+4' },
    {id:5, value: 5, label: 'UTC+5' },
    {id:6, value: 6, label: 'UTC+6' },
    {id:7, value: 7, label: 'UTC+7' },
    {id:8, value: 8, label: 'UTC+8' },
    {id:9, value: 9, label: 'UTC+9' },
    {id:10, value: 10, label: 'UTC+10' },
    {id:11, value: 11, label: 'UTC+11' },
    {id:12, value: 12, label: 'UTC+12' },
]

// documents filters
export const filters = [
    { id: 0, title: 'инструкция' },
    { id: 1, title: 'лицензия' },
    { id: 2, title: 'реквизиты' },
    { id: 3, title: 'свидетельство' },
    { id: 4, title: 'доверенность' },
    { id: 5, title: 'протокол' },
    { id: 6, title: 'решение' },
    { id: 7, title: 'устав' },
    { id: 8, title: 'шаблон' },
    { id: 9, title: 'программы' },
    { id: 10, title: 'оферта' },
    { id: 11, title: 'соглашение' },
]

export const callRecordsDrives = [
    "Виртуальная АТС", "Виртуальная АТС ЛК"
]

export const callRecordsColls = {
    //"id": "id",
    "worker": "Сотрудник",
    "direction": "Направление",
    "hangup_cause": "Тип",
    "src_num": "Откуда",
    "dst_num": "Куда",
    "external_num": "Внешний номер",
    "call_start_stamp": "Дата и время",
    "duration": "Длительность (сек)",
    "record_file": "Запись"
}

export const lostClientsCols = {
    //"id": "id",
    // "direction": "Направление",
    "lost_status": "Статус",
    "dst_type": "Где вызов прервался",
    "dst_name": "Приложение/сотрудник",
    "src_num": "Откуда",
    "dst_num": "Куда",
    "external_num": "Внешний номер",
    "call_start_stamp": "Дата и время",
}

export const CdrsDirections = {
    "outbound": "Исходящий",
    "inbound": "Входящий",
    "local": "Внутренний",
    "DOM_IN": "Входящий",
    "DOM_OUT": "Исходящий",
    "DOM_SELF": "Внутренний",
}

export const voipHeader = [
    {key: "CONNECT_TIME", label: "Дата/время начала вызова"},
    // {key: "DESTINATION", label: "Направление"},
    {key: "USER_NAME", label: "Кто звонил"},
    {key: "DEST_NUM", label: "Куда звонил"},
    {key: "DURATION", label: "Длительность вызова (сек.)"},
    {key: "TARIFF", label: "Тариф (руб.)"},
    {key: "PRICE", label: "Стоимость вызова"},
]

export const phoneHeader = [
    {key: "CONNECT_TIME", label: "Дата/время начала вызова"},
    // {key: "DESTINATION", label: "Направление"},
    {key: "SRC_NUM", label: "Кто звонил"},
    {key: "DEST_NUM", label: "Куда звонил"},
    {key: "DURATION", label: "Длительность вызова (сек.)"},
    //{key: "TARIFF", label: "Тариф"},
    //{key: "PRICE", label: "Стоимость"},
]

export const servicesId = {
    "incomingCalls": [77543824],
    "callRecords": [47079830, 47937170, 45700230, 45700231, 79435364, 79888669, 79435368],
    "antiDdos": [76957501, 76957521, 77036194],
    "smsServices": [79211433],
    "vamZvonili": [78604599],
    "zkh": [80219528]
}

export const stockCities = [
    {id: 1, name: 'Москва'},
    {id: 2, name: 'Санкт-Петербург'},
    {id: 3, name: 'Ростов-на-Дону'},
    {id: 4, name: 'Красноярск'},
    {id: 5, name: 'Харьков'},
    {id: 6, name: 'Франкфурт'},
] // города (временно)

// значения для слайдеров
export const marks = {
    0: {label: '3',},
    5: {label: '5',},
    10: {label: '10',},
    15: {label: '15',},
    20: {label: '20',},
    25: {label: '25',},
    30: {label: '30',},
    35: {label: '40',},
    40: {label: '45',},
    45: {label: '50',},
    50: {label: '75',},
    55: {label: '100',},
}

export const minutesMarks = {
    1: {
        label: '100',
    },
    2: {
        label: '300',
    },
    3: {
        label: '500',
    },
    4: {
        label: '1000',
    },
    5: {
        label: '1500',
    },
    6: {
        label: '2500',
    },
    7: {
        label: '3000',
    },
    8: {
        label: '5000',
    },
    9: {
        label: '7000',
    },
    10: {
        label: '10000',
    },
    11: {
        label: '15000',
    },
    12: {
        label: '20000',
    },
}

export const prices = {
    "antifraud": [
        {"from": 0, "to": 0, "price": 0},
        {"from": 100, "to": 100, "price": 250},
        {"from": 300, "to": 300, "price": 250},
        {"from": 500, "to": 500, "price": 250},
        {"from": 1000, "to": 1000, "price": 250},
        {"from": 2500, "to": 2500, "price": 250},
        {"from": 3000, "to": 3000, "price": 250},
        {"from": 5000, "to": 5000, "price": 250},
        {"from": 7000, "to": 7000, "price": 250},
        {"from": 10000, "to": 10000, "price": 250},
        {"from": 15000, "to": 15000, "price": 250},
        {"from": 20000, "to": 20000, "price": 250},
    ],
    "vats": [
        {"from": 1, "to": 3, "price": 0},
        {"from": 4, "to": 5, "price": 940},
        {"from": 6, "to": 10, "price": 1130},
        {"from": 11, "to": 15, "price": 1710},
        {"from": 16, "to": 20, "price": 2130},
        {"from": 21, "to": 25, "price": 2490},
        {"from": 26, "to": 30, "price": 2920},
        {"from": 31, "to": 40, "price": 3690},
        {"from": 41, "to": 45, "price": 4130},
        {"from": 46, "to": 50, "price": 4490},
        {"from": 51, "to": 75, "price": 6465},
        {"from": 76, "to": 100, "price": 8520},
    ],
    "callRecords": [
        {"from": 1, "to": 3, "price": 350},
        {"from": 4, "to": 5, "price": 350},
        {"from": 6, "to": 10, "price": 350},
        {"from": 11, "to": 15, "price": 350},
        {"from": 16, "to": 20, "price": 350},
        {"from": 21, "to": 25, "price": 350},
        {"from": 26, "to": 30, "price": 0},
        {"from": 31, "to": 40, "price": 0},
        {"from": 41, "to": 45, "price": 0},
        {"from": 46, "to": 50, "price": 0},
        {"from": 51, "to": 75, "price": 0},
        {"from": 76, "to": 100, "price": 0},
    ],
    "vamZvonily": [
        {"from": 1, "to": 3, "price": 250},
        {"from": 4, "to": 5, "price": 250},
        {"from": 6, "to": 10, "price": 250},
        {"from": 11, "to": 15, "price": 250},
        {"from": 16, "to": 20, "price": 250},
        {"from": 21, "to": 25, "price": 250},
        {"from": 26, "to": 30, "price": 250},
        {"from": 31, "to": 40, "price": 250},
        {"from": 41, "to": 45, "price": 250},
        {"from": 46, "to": 50, "price": 250},
        {"from": 51, "to": 75, "price": 250},
        {"from": 76, "to": 100, "price": 250},
    ],
    "crmIntegration": [
        {"from": 1, "to": 3, "price": 489},
        {"from": 4, "to": 5, "price": 489},
        {"from": 6, "to": 10, "price": 489},
        {"from": 11, "to": 15, "price": 489},
        {"from": 16, "to": 20, "price": 489},
        {"from": 21, "to": 25, "price": 489},
        {"from": 26, "to": 30, "price": 489},
        {"from": 31, "to": 40, "price": 489},
        {"from": 41, "to": 45, "price": 489},
        {"from": 46, "to": 50, "price": 489},
        {"from": 51, "to": 75, "price": 489},
        {"from": 76, "to": 100, "price": 489},
    ],
    "audioRecordingStatus": [
        {"from": 1, "to": 3, "price": 2500},
        {"from": 4, "to": 5, "price": 2500},
        {"from": 6, "to": 10, "price": 2500},
        {"from": 11, "to": 15, "price": 2500},
        {"from": 16, "to": 20, "price": 2500},
        {"from": 21, "to": 25, "price": 2500},
        {"from": 26, "to": 30, "price": 2500},
        {"from": 31, "to": 40, "price": 2500},
        {"from": 41, "to": 45, "price": 2500},
        {"from": 46, "to": 50, "price": 2500},
        {"from": 51, "to": 75, "price": 2500},
        {"from": 76, "to": 100, "price": 2500},
    ],
    "ivrDevelopStatus": [
        {"from": 1, "to": 3, "price": 0},
        {"from": 4, "to": 5, "price": 0},
        {"from": 6, "to": 10, "price": 0},
        {"from": 11, "to": 15, "price": 0},
        {"from": 16, "to": 20, "price": 0},
        {"from": 21, "to": 25, "price": 0},
        {"from": 26, "to": 30, "price": 0},
        {"from": 31, "to": 40, "price": 0},
        {"from": 41, "to": 45, "price": 0},
        {"from": 46, "to": 50, "price": 0},
        {"from": 51, "to": 75, "price": 0},
        {"from": 76, "to": 100, "price": 0},
    ],
    "vatsInstall": [
        {"from": 1, "to": 3, "price": 300},
        {"from": 4, "to": 5, "price": 450},
        {"from": 6, "to": 10, "price": 850},
        {"from": 11, "to": 15, "price": 1350},
        {"from": 16, "to": 20, "price": 1750},
        {"from": 21, "to": 25, "price": 2250},
        {"from": 26, "to": 30, "price": 2650},
        {"from": 31, "to": 40, "price": 3550},
        {"from": 41, "to": 45, "price": 4050},
        {"from": 46, "to": 50, "price": 4450},
        {"from": 51, "to": 75, "price": 6675},
        {"from": 76, "to": 100, "price": 8950},
    ],

    "packet": { // Стоимость пакета, в зависимости от тарифа
        "rf": {
            100: 2.1, 300: 2.1, 500: 1.99, 1000: 1.99, 2000: 1.99, 2500: 1.99,
            3000: 1.89, 5000: 1.79, 7000: 1.68, 10000: 0, 15000: 0, 20000: 0},
    },
    "cbc": 0
}

export const DEFAULT_MOBILE_PRICE = 2.1
export const DEFAULT_CITY_PRICE = 0.76
export const DEFAULT_8800_PRICE = 2.9
export const DEFAULT_MG_PRICE = 2.1
export const DEFAULT_REGION_PRICE = 2.1
export const DEFAULT_BIG_CITY_PRICE = 2.1
export const DEFAULT_CAPITALS_PRICE = 2.1
export const DEFAULT_OPTIONS_PRICE = 200
export const DEFAULT_T_SECURITY_PRICE = 250

export const cards = [
    {
        id: 1,
        title: 'Звоним на мобильные',
        wTitle: "VoIP_пакет_Звоним_на_мобильные_",
        text: 'Включает звонки на все мобильные РФ. Не включает звонки на городские номера. Звонки на городские номера и при превышении пакета минут, оплата по тарифу "Вне пакета минут"',
        checkbox: true
    },
    {
        id: 2,
        title: 'Вся РФ',
        wTitle: "VoIP_пакет_Вся_РФ_",
        text: 'Включает звонки на все телефонные номера РФ: городские и мобильные. При превышении пакета минут, оплата по тарифу "Вне пакета минут"',
        checkbox: true
    },
    {
        id: 3,
        title: 'Деловые центры',
        wTitle: "VoIP_пакет_Деловые центры_",
        text: 'Включает звонки на городские номера городов согласно списку*. Звонки на мобильные номера, в другие города и при превышении пакета минут, оплата по тарифу "Вне пакета минут"',
        checkbox: true
    },
    {
        id: 4,
        title: 'Родной край',
        wTitle: "VoIP_пакет_Родной_край_",
        text: 'Включает звонки на городские номера Красноярского края. Звонки на мобильные номера, в другие города и при превышении пакета минут, оплата по тарифу "Вне пакета минут"',
        checkbox: true
    },
    {
        id: 5,
        title: 'Две столицы',
        wTitle: "VoIP_пакет_Родной_край_",
        text: 'Включает звонки на городские номера Москвы и Санкт-Петербурга. Звонки на мобильные номера, в другие города и при превышении пакета минут, оплата по тарифу "Вне пакета минут"',
        checkbox: true
    },
    {
        id: 6,
        title: '8800',
        wTitle: "VoIP_8800",
        text: 'Включает входящие звонки на ваши номера 8800. Доступна после приобретения номера 8800',
        checkbox: true
    },
    {
        id: 7,
        title: 'Вызовы на номера вашего города',
        wTitle: "VoIP_local",
        text: 'Включает звонки на номера вашего города',
        checkbox: false
    },
]

export const individualVatsTariff =
        {
            workers: -1,
            price: -1,
            title: "Индивидуальный",
            description: "Пакет настроен индивидуально под нужды вашей компании."
        }

export const vatsTariffs = [
    {id: 0,
        workers: 3, price: 0,
        title: "Пакет базовый",
        description: "Пакет включает в себя все функции ВАТС для 3 пользователей и городской многоканальный номер."},
    {id: 1,
        workers: 10, price: 1130,
        title: "Пакет на 10 сотрудников",
        description: "Пакет ВАТС подходит для малого бизнеса с численностью не более 10 человек. " +
            "В стоимость включена абонентская плата за пользование городским номером без категории."},
    {id: 2,
        workers: 20, price: 2130,
        title: "Пакет на 20 сотрудников",
        description: "Пакет подходит для активно развивающихся компаний с численностью до 20 человек. " +
            "В стоимость включена абонентская плата за пользование городским номером без категории."},
    {id: 3,
        workers: 50, price: 4490,
        title: "Пакет на 50 сотрудников",
        description: "Данный пакет подходит для крупных организаций со штатом до 50 человек. " +
            "В стоимость включена абонентская плата за пользование городским номером без категории."},

]

export const packetPriceSlider = {
    0: {label: '0', value: "0"},
    1: {label: '100', value: "100"},
    2: {label: '300', value: "300"},
    3: {label: '500', value: "500"},
    4: {label: '1000', value: "1000"},
    5: {label: '2500', value: "2500"},
    6: {label: '3000', value: "3000"},
    7: {label: '5000', value: "5000"},
    8: {label: '7000', value: "7000"},
    9: {label: '10000', value: "10000"},
    10: {label: '15000', value: "15000"},
    11: {label: '20000', value: "20000"},
}

export const noShowCards = [6, 7]

export const CdrsHangupCause = {
    "NORMAL_CLEARING": "Отвеченный",
    "NO_ANSWER": "Вызов отклонён",
    "Failed": "Вызов отклонён",
    "ORIGINATOR_CANCEL": "Вызов сброшен",
    "APPLICATION": "Без соединения",
    "USER_BUSY": "Занят",
    "User busy": "Занят",
    "NO_USER_RESPONSE": "Нет ответа",
    "LOSE_RACE": "Сбой вызова",
    "DESTINATION_OUT_OF_ORDER": "Сбой вызова",
    "ATTENDED_TRANSFER": "Сбой вызова",
    "USER_NOT_REGISTERED": "Сбой вызова",
    "OTHER": "Сбой вызова",
    "CALL_REJECTED": "Вызов отклонён",
    "NO_ROUTE_DESTINATION": "Сбой вызова",
    "NORMAL_TEMPORARY_FAILURE": "Сбой вызова",
    "NORMAL_UNSPECIFIED": "Сбой вызова",
    "Normal call clearing": "Отвеченный",
    "Request terminated": "Вызов сброшен",
    "UNALLOCATED_NUMBER": "Сбой вызова",
    "failure": "Сбой вызова",
    "INVALID_GATEWAY": "Сбой вызова",
    "ALLOTTED_TIMEOUT": "Сбой вызова",
    "INVALID_NUMBER_FORMAT": "Неправильно набран номер",
}

export const mobileMinutesMarks = [
    {value: 100, text: '100'},
    {value: 300, text: '300'},
    {value: 500, text: '500'},
    {value: 1000, text: '1000'},
    {value: 1500, text: '1500'},
    {value: 2500, text: '2500'},
    {value: 3000, text: '3000'},
    {value: 5000, text: '5000'},
    {value: 7000, text: '7000'},
    {value: 10000, text: '10000'},
    {value: 15000, text: '15000'},
    {value: 20000, text: '20000'},
]

export const minCallDuration = 3;
export const crAudioPath = "https://vr.telezon.ru/audio/"
export const cbcUrl = "https://cbc.telezon.ru/"
export const fwcUrl = "https://frontwc.telezon.ru/"
export const fwcUrlOld = "https://fusiondev.telezon.ru/"
//export const fwBetaServer = "https://betafusion.telezon.ru/"
export const fwBetaServer = "https://lkfsn.telezon.ru/"
export const ttsPrefixLink = "https://api.telezon.ru/tts/v1/get/yandex/"

export const virtualUsersCrMinutesDif = -30

export const uuidRe = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

export const timeLineList = [
    "00:00", "01:00", "02:00", "03:00", "04:00", "05:00",
    "06:00", "07:00", "08:00", "09:00", "10:00", "11:00",
    "12:00", "13:00", "14:00", "15:00", "16:00", "17:00",
    "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
]

export const inbound_maps = ['inbound', "DOM_IN"]
export const outbound_maps = ['outbound', "DOM_OUT"]
export const successed_maps = ['NORMAL_CLEARING', "Normal call clearing"]
export const busy_maps = ['USER_BUSY', "User busy"]

export const smsServices = [
    {id:0, value: "telezon", label: 'Сервис Telezon' },
    {id:1, value: "aero", label: 'SMS Aero' },
]
