import { createContext } from 'react'

export const ThemeContext = createContext({
  theme: '',
  changeTheme: () => {},
  menu: [],
  settingsMenu: [],
  select: [],
  docs: [],
  filters: [],
  table: [],
})
