import React, {useEffect, useState} from 'react'
import s from "./DeviceBubble.module.scss"

import {useTheme} from "../../../hooks/useTheme";
import ReactSelect from "react-select";
import CheckBox from "../../CheckBox/CheckBox";



export const DeviceBubble = (
    {
        selectedDevice, selectDevice, isAllSelected, selectAll,
        deviceList, setPaginationSize}) => {
    const {theme} = useTheme()
    const [openedMenu, setOpenedMenu] = useState("")
    const [numbers, setNumbers] = useState([])

    useEffect(() => {

        if (isAllSelected) selectDevice(deviceList)
        else selectDevice([])

    }, [isAllSelected])

    useEffect(() => {
        if (!deviceList) return
        setNumbers(deviceList)

        if (deviceList.find(e => e.value === "1")) {
            selectDevice(deviceList.find(e => e.value === "1"))
        } else {
            selectDevice(deviceList[0])
        }

    }, [deviceList])

    const customStyles = {
        control: () => ({
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            maxWidth: "200px",
        }),
        placeholder: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            padding: '5px',
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            maxWidth: '120px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            opacity: '.3',
        }),
        menuList: () => ({
            overflowY: "auto",
            textAlign: 'left',
            borderRadius: '5px',
            position: 'absolute',
            zIndex: '15',
            left: '-5px',
            width: '120%',
            marginTop: '5px',
            maxHeight: "300px",
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            background: theme !== 'light' ? '#fff' : 'var(--night-color)',
            border: theme === 'light' ? '1px solid #fff' : 'none',
        }),
        menu: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        option: () => ({
            background: 'none',
            padding: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            borderRadius: '5px',
            maxHeight: "300px",

            '&:hover': {
                background: '#3a3a3a',
                color: 'var(--light-color)',
            },
        }),
        singleValue: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        input: () => ({
            display: "none"
        }),
    }

    return(
        <div className={s.chooseDevice} onClick={() => {
            openedMenu.length > 0 && setOpenedMenu("")
        }}>
            {deviceList &&
            <div>
                <p style={theme === 'light' ? {color: 'var(--light-color)'} : {}}>Выберите номер</p>
                {document.body.clientWidth > 992 ? (
                    <div onClick={
                        () => {
                            openedMenu === "device" && setOpenedMenu("")
                            openedMenu !== "device" && !isAllSelected && setOpenedMenu("device")
                        }}
                         className={s.cursor} style={{height: "100%"}}>
                        <ReactSelect
                            placeholder="Номер"
                            defaultValue={numbers[0]}
                            options={numbers}
                            onChange={selectDevice}
                            styles={customStyles}
                            menuIsOpen={openedMenu === "device"}
                            isSearchable={true}
                            isDisabled={isAllSelected || !deviceList.length}
                            value={isAllSelected ? {label: "Все номера", value: "Все номера"} : selectedDevice}
                        />

                    </div>
                ) : (
                    <div className={s.cursor}>
                        <ReactSelect
                            placeholder="Номер"
                            defaultValue={deviceList[0]}
                            options={deviceList}
                            onChange={selectDevice}
                            styles={customStyles}
                            isDisabled={isAllSelected || !deviceList.length}
                        />
                    </div>
                )}

                {selectAll &&
                <div className={s.flex}>
                    <p className={s.AllAtsChbLabel}>Все </p>
                    {deviceList.length > 0 &&
                    <div style={{height: "24px !important"}}>
                        <CheckBox styleParams="inline fl-r"
                                  status={isAllSelected}
                                  chStatus={() => selectAll(!isAllSelected)}/>
                    </div>

                    }

                    {!deviceList.length &&
                    <div style={{height: "24px !important", marginTop: "10px"}}>
                        Нет устройств
                    </div>
                    }


                </div>
                }

            </div>
            }

            {setPaginationSize &&
            <div onClick={(e) => e.stopPropagation()}>
                <p style={theme === 'light' ? {color: 'var(--light-color)'} : {}}>Размер страницы</p>
                {document.body.clientWidth > 992 ? (
                    <div onClick={() => {
                        openedMenu === "pagination" &&setOpenedMenu("")
                        openedMenu !== "pagination" &&setOpenedMenu("pagination")
                    }}
                         className={s.cursor}>
                        <ReactSelect
                            placeholder="Кол-во эл. на странице"
                            defaultValue={{ value: 20, label: '20' }}
                            options={[
                                { value: 20, label: '20' },
                                { value: 50, label: '50' },
                                { value: 100, label: '100' },
                                { value: 150, label: '150' },
                                { value: 200, label: '200' },
                            ]}
                            onChange={setPaginationSize}
                            styles={customStyles}
                            menuIsOpen={openedMenu === "pagination"}
                        />
                    </div>
                ) : (
                    <div className={s.cursor}>
                        <ReactSelect
                            placeholder="Кол-во эл. на странице"
                            defaultValue={{ value: 20, label: '20' }}
                            options={[
                                { value: 20, label: '20' },
                                { value: 50, label: '50' },
                                { value: 100, label: '100' },
                                { value: 150, label: '150' },
                                { value: 200, label: '200' },
                            ]}
                            onChange={setPaginationSize}
                            styles={customStyles}
                        />
                    </div>
                )}
            </div>
            }
        </div>
    )
}

export const DeviceBubbleSmall = () => {
    return(
        <div className={`${s.bubble}`}>
            asd
        </div>
    )
}