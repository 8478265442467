import React, {useEffect, useState} from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './Support.module.scss'
import {sendEmail} from "../../../api";
import {useTheme} from "../../../hooks/useTheme";
import ModalMessage from "../../../ui/Popup/ModalMessage";
import DefaultButton from "../../../ui/Button/Button";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";

export const CreateMessageInner = function (props) {
    const {userAccount, userManagers, theme} = useTheme()

    const charCount = 25 // Минимальное количество символов

    const [text, setText] = useState("")
    const [contactText, setContact] = useState("")
    const [inputClass, setInputClass] = useState("")
    const [timerStyle, setTimerStyle] = useState([s.timer, s.invisible].join(" "));
    const [target, setTarget] = useState("ts");

    useEffect(() => {
        if (userManagers && userManagers["manager"]) {
            setTarget("manager")
        }

    }, [userManagers])





    if (timerStyle === s.timer && text.length >= charCount) {
        setInputClass("")
        setTimerStyle([s.timer, s.invisible].join(" "))
    }

    useEffect(() => {
        if(inputClass === "wrong-input") {
            if (text.length > charCount) {
                setInputClass("")
                setTimerStyle([s.timer, s.invisible].join(" "))
            }

        }
    },[text])

    function sendMessage() {
        if (text.length === 0) {
            props.hide()
            return
        }

        if (text.length < charCount) {
            setInputClass("wrong-input")
            setTimerStyle("timer")
            return
        }

        sendEmail({
            login: userAccount.login,
            token: userAccount.token,
            page: props.page,
            text: text,
            target: target === "manager" ? userManagers["manager"]["ident"] + "@telezon.ru" : "sd@telezon.ru"
        }).then(response => console.log(response))

        props.hide()
    }

    function chText(e) {
        setText(e.target.value)
    }

    const changeTarget = (e) => {
        if (e.target.value === "manager") {
            if (target === "manager") setTarget("ts")
            else setTarget("manager")
        }
        else if (e.target.value === "ts") {
            if (target === "manager") setTarget("ts")
            else setTarget("manager")
        }
    }

    return(
        <div style={{
            color: "#555",
            padding: "25px",
            textAlign: "center",
            borderRadius: "12px",
            boxShadow: "unset 0 4px 0 rgb(0 0 0 / 8%)",
        }}>

            {userManagers && userManagers["manager"] &&
            <div className={s.managersMail}
                 style={theme === 'light' ? {color: 'var(--light-color)'} : {}}
            >
                <div className={s.managerLabel}>Ваш персональный менеджер: </div>
                <div className={s.managerName}>
                    <a href={"mailto:"+userManagers["manager"]["ident"]+"@telezon.ru"}
                       style={theme === 'light' ? {color: 'var(--light-color)'} : {}}
                    >
                        {userManagers["manager"]["name"]}
                    </a>
                </div>
                <div>
                    <div className={s.managerLabel}>Отправить вопрос менеджеру </div>
                    <input type="checkbox" name="manager" value="manager"
                           className={s.checkBox}
                           checked={target === "manager"}
                           onChange={changeTarget}/>

                </div>


            </div>
            }

            <div className={s.managersMail}
                 style={theme === 'light' ? {color: 'var(--light-color)'} : {}}
            >
                <div>
                    <div className={s.managerLabel}>Отправить вопрос в техподдержку </div>
                    <input type="checkbox" name="ts" value="ts"
                           className={s.checkBox} checked={target === "ts"}
                           onChange={changeTarget}/>

                </div>


            </div>

            <div style={{
                fontSize: "35px",
                fontWeight: "bold",
                marginBottom: "20px",
                color: theme === 'light' ? 'var(--light-color)' : 'var(--main-font-color)'
            }}>
                {props.label ? props.label : "None"}
            </div>
            {props.withEmail &&
                <div>
                    <div className={"inline text-2"}>Адрес электронной почты или телефон</div>
                    <input type="text" style={{width: "100%"}} className={["textarea-white", inputClass].join(" ")}
                           value={contactText} onChange={(e) => setContact(e.target.value)}/>
                </div>
            }
            {props.children ? props.children : null}


            <span style={{
                fontSize: "35px",

            }}>
                    <div className={[timerStyle, s.errorMsg].join(" ")}>
                        Сообщение должно содержать не менее {charCount} символов</div>
                    <textarea
                        style={theme === 'light'
                            ? {backgroundColor: 'var(--night-color)', color: 'var(--light-color)'}
                            : {backgroundColor: 'var(--light-color)', color: 'var(--night-color)'}}
                        className={[s.textareaWhite, inputClass].join(" ")}
                        value={text} onChange={chText}>

                    </textarea>
                </span>
            <div className="white-button margin-center w20"
                 style={{marginTop: "10px"}}
                 onClick={sendMessage}>
                Отправить
            </div>
        </div>
    )
}

export const CreateMessage = function (props) {
    const charCount = 25 // Минимальное количество символов

    const [text, setText] = useState(props.text ? props.text : "")
    const [contactText, setContact] = useState("")
    const [inputClass, setInputClass] = useState("")
    const [contactsClass, setContactsClass] = useState("")
    const [timerStyle, setTimerStyle] = useState('timer invisible');

    const {userAccount} = useTheme()

    if (timerStyle === "timer" && text.length >= charCount) {
        setInputClass("")
        setTimerStyle("timer invisible")
    }

    function sendMessage() {
        //console.log("CreateMessage , sendMessage")
        if (text.length === 0) {
            props.hide()
            return
        }

        if (text.length < charCount) {
            setInputClass("wrong-input")
            setTimerStyle("timer")
            return
        }

        if (props.withEmail && contactText.length === 0) {
            setContactsClass("wrong-input")
            setTimerStyle("timer")
            return
        }

        let r = {
            page: props.page,
            text: text
        }

        if (userAccount && userAccount.login && userAccount.token) {
            r.login = userAccount.login
            r.token = userAccount.token
        }

        let resultText = text
        if (props.withEmail) {
            resultText += ". Контакты для связи: " + contactText + ". "
        }

        sendEmail({
            login: props.userAccount && props.userAccount.login ? props.userAccount.login : "",
            token: props.userAccount && props.userAccount.token ? props.userAccount.token : "",
            page: props.page,
            text: resultText
        }).then(response => console.log(response))

        props.hide()
    }

    function chText(e) {
        setText(e.target.value)

    }
    return(
        <div className={s.mainPopup} onClick={(e) => e.stopPropagation()}>
            <DefaultTitle title={props.label ? props.label : "Внимане!"} />
            {props.withEmail &&
                <div className={s.messFormContacts}>
                    <p>Email или телефон</p>
                    <input type="text" name="contact" className={contactsClass}
                           value={contactText}
                           onChange={(e) => setContact(e.target.value)}
                    />
                </div>
            }
            {props.children && props.children}

            {text?.length < charCount.toString() &&
                <div className={s.messInfo}>Сообщение должно содержать не менее {charCount.toString()} символов</div>
            }
            <textarea value={text} onChange={chText} />
            <DefaultButton text={"Отправить"} clickEvent={sendMessage}/>
        </div>
    )
}

export const MessageForm = function (props) {
    return(
        <div style={{
            position: "fixed",
            zIndex: 1000,
            inset: 0,
            width: "100%",
            height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.5)",
            display: props.show ? "block" : "none",
        }} onClick={props.hide}>
            <CreateMessage {...props} />
        </div>
    )
}

const Support = (props) => {
    const {designType} = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    const sucessMail = () => {
        setShowSuccess(true)
    }

    return (
        <div className={`${s.mainPart} ${designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div>
                <CreateMessageInner hide={sucessMail} label={"Ваше сообщение"}
                                    authForm={{login: 1, token: 2}} page={"Помощь"} />
                <ModalMessage show={showSuccess} hide={() => setShowSuccess(false)}
                              label={"Успешно!"} message={"Сообщение успешно отправлено"}/>
            </div>
        </div>
    )
}

export default Support
