import s from "../../components/pages/IncomingCalls/FeedbackMain/FeedbackMain.module.scss";
import ModalMessage from "./ModalMessage";
import React, {useEffect, useState} from "react";
import {uuidRe} from "../../provider/variables";
import {callRecordElementsEdit, changeFeedbackComment} from "../../api";
import {useTheme} from "../../hooks/useTheme";

export const AddCommentPopup = ({show, changeData, elem, hide}) => {
    const {userAccount} = useTheme()
    const [comment, setComment] = useState("")
    const chCommentText = (event) => {
        setComment(event.target.value)
    }

    const changeComment = (uuid, text, response) => {
        console.log(response)
        console.log(uuid, text)
    }


    const saveComment = () => {
        // Проверяем на uuid
        if (uuidRe.exec(elem.uuid)) {
            // отправляем в UCDR
            callRecordElementsEdit({
                login: userAccount.login,
                token: userAccount.token,
                id: elem.uuid,
                comment: comment === "none" ? "" : comment
            }).then(r => changeData(elem.uuid, "comment", comment))
            // }).then(r => console.log(r))

        } else {
            changeFeedbackComment({
                login: userAccount.login,
                token: userAccount.token,
                id: elem.uuid,
                comment: comment === "none" ? "" : comment
            }).then(r => changeData(elem.uuid, "comment", comment))
            // }).then(r => console.log(r))
        }

        setComment("")
        hide()
    }

    useEffect(() => {
        if (elem.comment) setComment(elem.comment)
        else setComment("")
    }, [elem])

    return(
        <ModalMessage label={"Комментарий"}
                      standartButtonText={"Отменить"}
                      show={show}
                      hide={() => {
                          hide("")
                      }}>

            <div>
                <textarea className={s.commentBlock}
                          onChange={chCommentText}
                          value={comment === "none" ? "" : comment}/>
                <div className="white-button margin-center w20"
                     style={{marginTop: "10px"}} onClick={saveComment}>
                    Сохранить
                </div>
            </div>
        </ModalMessage>
    )

}