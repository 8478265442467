import React, {useEffect, useState} from "react";
import s from "./Styles.module.scss";
import Select from "react-select";
import Slider from "rc-slider";
import {PauseCircleFilled, PlayCircleFilled} from "@mui/icons-material";
import {AudioPlayerMini} from "../AudioPlayer/AudioPlayer";
import {useTheme} from "../../hooks/useTheme";
import {moodsTemplate, voiceSpeedMarks, voicesTemplate} from "../../components/pages/RobotPage/variables";
import {audioToBlob, generateTts} from "../../components/pages/RobotPage/api";
import {WebSitesDivButton} from "../Button/WebSitesButton";
import {useSelectStyles} from "../react-select-styles";
import {sleep} from "../../sortFunction";

export const greetingsAudioTabs = [
    {id: 0, label: "Сгенерировать из текста", name: "fromText"},
    {id: 1, label: "Загрузить аудиофайл", name: "fromAudio"},
]


export const Player = ({file}) => {
    const [curAudio, setAudio] = useState("")
    const [playStatus, setPlayStatus] = useState(false)

    useEffect(() => {
        setAudio(file)

        if (file.length > 0) {
            setPlayStatus(true)
        }  else {
            setPlayStatus(false)
        }
    }, [file])

    return(
        <div className={s.playerMain}>
            <div className={s.player}>
                <AudioPlayerMini src={curAudio} vr={false} playStatus={playStatus}
                                 setPlayStatus={setPlayStatus} stopEvent={setPlayStatus}/>
            </div>

            {!playStatus &&
            <button className={s.buttonPlay} onClick={() => setPlayStatus(!playStatus)}>
                <PlayCircleFilled/>
            </button>
            }

            {playStatus &&
            <button className={`${s.buttonPlay} ${s.buttonStop}`} onClick={() => setPlayStatus(!playStatus)}>
                <PauseCircleFilled/>
            </button>
            }
        </div>
    )
}

export const TtsConfig = ({voice, setVoice, mood, setMood, voiceSpeed, setVoiceSpeed}) => {
    const {theme} = useTheme()
    const [openedMenu, setOpenedMenu] = useState("")
    const [moods, setMoods] = useState(moodsTemplate)

    useEffect(() => {
        if (!voice) {
            setMoods([])
            return
        }
        setMoods([...moodsTemplate.filter(m => voice.emotions.includes(m.value))])
    }, [voice])

    useEffect(() => {
        if (moods?.length === 0) {
            setMood({})
            return
        }
        setMood(moods[0])
    }, [moods])


    return(
        <div className={s.buttonsBlock}>
            <div className={`${s.voiceSelect}`}
                onClick={() => setOpenedMenu(openedMenu === "voice" ? "" : "voice")}
            >
                <label>Голос</label>
                <Select
                    onChange={setVoice}
                    options={voicesTemplate}
                    styles={useSelectStyles()}
                    isSearchable
                    hideSelectedOptions
                    value={voice}
                    defaultValue={voicesTemplate[0]}

                    menuIsOpen={openedMenu === "voice"}
                />
            </div>

            <div className={`${s.voiceSelect}`}
                onClick={() => setOpenedMenu(openedMenu === "mood" ? "" : "mood")}
            >
                <label>Настроение</label>
                <Select
                    onChange={setMood}
                    options={moods}
                    styles={useSelectStyles()}
                    isSearchable
                    hideSelectedOptions
                    value={mood}
                    defaultValue={moods[0]}
                    menuIsOpen={openedMenu === "mood"}
                />
            </div>

            <div className={`${s.voiceRange} ${theme === "light" && s.lightTheme}`}>
                <label>Скорость голоса</label>
                <div className={s.slider}>
                    {theme === 'dark' ? (
                        <Slider
                            onChange={(e) => setVoiceSpeed(e.toString())}
                            value={voiceSpeed} max={2} min={1} step={0.1}
                            marks={voiceSpeedMarks} defaultValue={voiceSpeedMarks[0]}
                        />
                    ) : (
                        <Slider
                            className="dark-mode"
                            onChange={(e) => setVoiceSpeed(e.toString())}
                            value={voiceSpeed} max={2} min={1} step={0.1}
                            marks={voiceSpeedMarks} defaultValue={voiceSpeedMarks[0]}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export const TextAreaAudioBlock = ({setAudioFile}) => {
    const needSymbols = 10
    const {theme, userAccount, setActivePopupGlobal, activePopupGlobal} = useTheme()

    const [voice, setVoice] = useState({...voicesTemplate[0]})
    const [mood, setMood] = useState({...moodsTemplate[0]})
    const [voiceSpeed, setVoiceSpeed] = useState(1.0)
    const [text, setText] = useState("")
    const [audio, setAudio] = useState("")
    const [fileName, setFileName] = useState("")

    const [error, setError] = useState("")
    const [saveFileAction, setSaveFileAction] = useState(false)
    const [generateAudioAction, setGenerateAudioAction] = useState(false)

    useEffect(() => {
        if (error?.length > 0) {
            sleep(2000).then(() => setError(""))
        }
    }, [error])

    const generate = () => {
        // setAudio("https://api.telezon.ru/tts/v1/get/yandex/9dc011cc-d3dd-4612-b536-f8f6b96b683e.mp3")
        // return
        if (text.length < 10 || !voice.value || !mood.value || !voiceSpeed || !userAccount?.login) {
            setActivePopupGlobal("oups")
            return
        }
        // return
        let payload = {
            "pin": userAccount?.login || 0,
            "text": text,
            "voice": voice.value,
            "emotion": mood.value,
            "speed": voiceSpeed
        }

        const failGenerate = (r) => {
            console.log(r)
            setActivePopupGlobal("oups")
        }

        setGenerateAudioAction(true)
        generateTts({...userAccount, payload: payload})
            .then(r => {
                r?.url ? setAudio(r?.url) : failGenerate(r)
                setGenerateAudioAction(false)
            })
            .catch(e => failGenerate(`generateTts fail: ${e.data}`))
    }

    useEffect(() => {
        if (audio?.length === 0) return
        console.log(audio)
    }, [audio])

    const runSaveAction = () => {
        setSaveFileAction(true)
        const parseBlob = (response) => {
            if (!response?.data) return

            let formData = new FormData();
            formData.append("file", response?.data, fileName);
            formData.append("name", fileName);
            setAudioFile(formData)
        }

        audioToBlob({url: audio})
            .then(dataUri => parseBlob(dataUri))
            .catch((e) => {
                setActivePopupGlobal("failSave")
                console.log(e)
            })
    }

    useEffect(() => {
        if (activePopupGlobal === "successSave" || activePopupGlobal === "failSave") {
            setSaveFileAction(false)
        }
    }, [activePopupGlobal])

    return(
        <div className={s.audioTextArea}>
            {audio.length > 0 &&
            <Player file={audio}/>
            }

            {audio.length > 0 && !saveFileAction &&
            <WebSitesDivButton text={"Сохранить файл в аккаунте"} clickEvent={
                () => fileName?.length > 0
                    ? runSaveAction()
                    : setError("file-name")
            }/>
            }

            {saveFileAction &&
            <WebSitesDivButton text={"Сохраняю..."} color={'noactive'} animated={false}/>
            }

            {audio.length > 0 &&
                <label className={s.filenameLabel}>
                    Название
                    <input type={"text"} className={error === "file-name" ? s.shake : null}
                           value={fileName} onChange={(e) => setFileName(e.target.value)}/>
                </label>
            }

            {text?.length < needSymbols &&
            <span className={`${s.helpText}`}>
                Для генерации ТТС необходимое количество символов: {needSymbols}.
                <br/>
                Напечатано: {text?.length}
            </span>
            }
            <div className={s.bordered}>
                <div className={s.textArea}>
                    <textarea value={text} onChange={(e) => setText(e.target.value)}/>
                </div>

                <TtsConfig voice={voice} setVoice={setVoice}
                           mood={mood} setMood={setMood}
                           voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />

                {(text?.length > needSymbols && !generateAudioAction) &&
                <div className={`${s.voiceButton} ${theme === "light" && s.lightTheme}`}>
                    <WebSitesDivButton text={"Сгенерировать"} clickEvent={generate}/>
                </div>
                }

                {(text.length < needSymbols && !generateAudioAction) &&
                <div className={`${s.voiceButton} ${theme === "light" && s.lightTheme}`}>
                    <WebSitesDivButton text={"короткий текст"} color={"noactive"} animated={false}/>
                </div>
                }

                {generateAudioAction &&
                <div className={`${s.voiceButton} ${theme === "light" && s.lightTheme}`}>
                    <WebSitesDivButton text={"Генерирую..."} color={"noactive"} animated={false}/>
                </div>
                }

            </div>
        </div>
    )
}
