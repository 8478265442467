import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTheme } from '../../../../hooks/useTheme'
import s from './Slider.module.scss'
import 'swiper/css'
import './navigation.css'
import { Navigation } from 'swiper/modules';

const SliderInfo = (props) => {
  let { balance, designType } = useTheme()

    // с помощью него, добавляется класс к шрифту и слайдер докручивает слайд до конца
  let [activeSlider, setActiveSlider] = useState(false)

  return (
      <>
          <div
              className={s.swiperContainer}
              onMouseEnter={() => setActiveSlider(true)}
          >
              {balance && (
                  <>
                      <Swiper
                          modules={[Navigation]}
                          spaceBetween={activeSlider ? 12 : 12.01}
                          slidesPerView={4}
                          className={s.styles}
                          navigation={{
                              prevEl: '.sliderArrowPrev',
                              nextEl: '.sliderArrowNext',
                          }}
                          breakpoints={{
                              320: {
                                  slidesPerView: 1.3,
                              },
                              450: {
                                  slidesPerView: 2,
                              },
                              800: {
                                  slidesPerView: 3,
                              },
                              1250: {
                                  slidesPerView: 4,
                              },
                          }}
                          grabCursor
                      >
                          <SwiperSlide className={s.swiperSlide}>
                              <div className={s.card}>
                                  <div>
                                      <p className={s.name}>Телефония</p>
                                      <p className={s.cost}>
                                          {balance.balance_p.toFixed(2)}
                                          <span>₽</span>
                                      </p>
                                  </div>
                              </div>
                          </SwiperSlide>
                          <SwiperSlide className={s.swiperSlide}>
                              <div className={s.card}>
                                  <div>
                                      <p className={s.name}>Межгород</p>
                                      <p className={s.cost}>
                                          {balance.balance_v.toFixed(2)}
                                          <span>₽</span>
                                      </p>
                                  </div>
                              </div>
                          </SwiperSlide>
                          <SwiperSlide className={s.swiperSlide}>
                              <div className={s.card}>
                                  <div>
                                      <p className={s.name}>Интернет</p>
                                      <p className={s.cost}>
                                          {balance.balance_i.toFixed(2)}
                                          <span>₽</span>
                                      </p>
                                  </div>
                              </div>
                          </SwiperSlide>
                          <SwiperSlide className={s.swiperSlide}>
                              <div className={s.card}>
                                  <div>
                                      <p className={s.name}>Бизнес-сети</p>
                                      <p className={s.cost}>
                                          {balance.balance_s.toFixed(2)}
                                          <span>₽</span>
                                      </p>
                                  </div>
                              </div>
                          </SwiperSlide>
                      </Swiper>
                      <div className={s.wrapNavigation}>
                          <div className="slider-navigation sliderArrowPrev swiper-button-disabled"/>
                          <div className="slider-navigation sliderArrowNext"/>
                      </div>
                  </>
              )}
          </div>
      </>
  )

}

export default SliderInfo
