import React, { useState } from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './UnlimitedTelephonyPage.module.scss'
import MainBlock from "./MainBlock/MainBlock";
import {useTheme} from "../../../hooks/useTheme";

const UnlimitedTelephonyPage = (props) => {
    const theme = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    return (
        <div className={`${s.mainPart} ${theme.designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                <MainBlock {...props}/>
            </div>
        </div>

    )
}

export default UnlimitedTelephonyPage
