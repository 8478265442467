import React, {useEffect, useState} from 'react'
import { useTheme } from '../../../../hooks/useTheme'
import Loading from '../../../../ui/Loading/Loading'
import s from './MainBlock.module.scss'
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import {getDdosStatistics} from "../../../../api";
import EventsInfo from "./EventInfo/EventsInfo";
import {callRecordsColls, ddosHeaders} from "../../../../provider/variables";
import {GetApp} from "@mui/icons-material";
import {CSVLink} from "react-csv";


const MainBlock = () => {
    const {ipAddresses, userAccount} = useTheme()

    const [selectedIp, selectIp] = useState("")
    const [errorResponse, setErrorResponse] = useState(false)
    const [ddosData, setDdosData] = useState([])
    const [exportData, setExportData] = useState([])

    useEffect(() => {
        if (!errorResponse) return
        console.log("error response")

        setDdosData([])

    }, [errorResponse])

    useEffect(() => {
        if (!ddosData || ddosData.length === 0) return

        let result = []
        for (const event of ddosData) {
            let event_list = []
            for (const header of Object.keys(ddosHeaders)) {
                event[header]
                    ? event_list.push(event[header])
                    : event_list.push("")
            }

            result.push(event_list)

            if (event["ext"] && event.ext.target_data) {
                for (const ext_event of event.ext.target_data) {
                    for (const e of ext_event.content) {
                        result.push([event_list[0], "", ext_event.title, e.join(" ")])
                    }
                }

            }
        }

        setExportData(result)

    }, [ddosData])

    useEffect(() => {
        if (selectedIp.length === 0) {
            if (ipAddresses.length > 0) {
                selectIp(ipAddresses[0])
            } else {
                return
            }
        }

        if (selectedIp.length === 0) return
        setErrorResponse(false)
        getDdosStatistics({
            login: userAccount.login,
            token: userAccount.token,
            ip: selectedIp
        })
            .then(r => setDdosData(r))
            .catch(e => setErrorResponse(true))


    }, [selectedIp])

  return (
    <div className={s.flex}>
        <div className={s.list}>
            {ipAddresses.map(ip =>
                <div key={ip} className={s.elem}
                     onClick={() =>selectIp(ip)}>
                    {ip}
                </div>
            )}
        </div>

        <div className={s.mainFrame}>
            <div className={s.titleBlock}>
                {selectedIp.length > 0
                    ? <DefaultTitle title={"Статистика по DDOS-атакам для "+selectedIp} />
                    : <DefaultTitle title={"Выберите IP-адрес для вывода статистики"} />
                }

            </div>
            {exportData.length > 0 &&
            <CSVLink data={exportData} className={s.csvLink}
                     headers={Object.values(ddosHeaders)}
                     separator=";"
                     filename={"ddos_events.csv"}
                     data-tooltip="Скачать в csv">
                <button className={[s.btn, s.btnDownload].join(" ")}>
                    <GetApp className={s.SvgElem}/> в csv
                </button>
            </CSVLink>
            }

            { !errorResponse && ddosData.length === 0 &&
                <div style={{display: "block"}}>
                    <Loading />
                </div>

            }
            { errorResponse &&
            <div>
                Нет информации по DDOS-атакам на указанный IP-адрес
            </div>
            }

            {ddosData.length > 0 && <EventsInfo data={ddosData}/>}



        </div>
    </div>
  )
}

export default MainBlock
