// export const robotsUrl = "http://10.248.0.173:8383/robot/"
export const robotsUrl = "https://api.telezon.ru/mr.robot/"


export const pauseSpeedMarks = {
    0: {label: '0',},
    1: {label: '1',},
    2: {label: '2',},
    3: {label: '3',},
    4: {label: '4',},
    5: {label: '5',},
    6: {label: '6',},
    7: {label: '7',},
    8: {label: '8',},
    9: {label: '9',},
    10: {label: '10',},
}

export const waitingAudiosTemplate = [
    {id: 0, value: "friendly", label: 'Добрый'},
    {id: 1, value: "angry", label: 'Злой'},
]

const workerAction = {
    action: "transfer_ats",
    opts: {
        num: 601
    }
}

const hangupAction = {
    action: "hangup",
    opts: {}
}


export const finishRedirect = [
    {id: 0, value: "worker", label: 'На сотрудника', data: workerAction},
    {id: 1, value: "group", label: 'На группу', data: workerAction},
    {id: 1, value: "cancel", label: 'Положить трубку', data: hangupAction},
]

export const waitingMarks = {
    0: {label: '0',},
    2: {label: '2',},
    4: {label: '4',},
    6: {label: '6',},
    8: {label: '8',},
    10: {label: '10',},
}

export const voicesTemplate = [
    {id: 0, value: "oksana", label: 'Оксана', name: "oksana", emotions: ["good", "evil", "neutral"]},
    {id: 1, value: "jane", label: 'Джейн', name: "jane", emotions: ["good", "evil", "neutral"]},
    {id: 2, value: "zahar", label: 'Захар', name: "zahar", emotions: ["good", "neutral"]},
    {id: 3, value: "ermil", label: 'Ермил', name: "ermil", emotions: ["good", "neutral"]},
    {id: 4, value: "filipp", label: 'Филипп', name: "filipp",  emotions: ["good", "evil", "neutral"]},
    {id: 5, value: "omazh", label: 'Омаж', name: "omazh",  emotions: ["evil", "neutral"]},
]

export const moodsTemplate = [
    {id: 0, value: "neutral", label: 'Нейтральный'},
    {id: 1, value: "good", label: 'Доброжелательный'},
    {id: 2, value: "evil", label: 'Злой'},
]

export const voiceSpeedMarks = {
    1.0: {label: '1.0',},
    1.1: {label: '1.1',},
    1.2: {label: '1.2',},
    1.3: {label: '1.3',},
    1.4: {label: '1.4',},
    1.5: {label: '1.5',},
    1.6: {label: '1.6',},
    1.7: {label: '1.7',},
    1.8: {label: '1.8',},
    1.9: {label: '1.9',},
    2.0: {label: '2.0',},
}