import React, {useEffect, useState} from 'react'
import {useTheme} from '../../../../../../../hooks/useTheme'
import GlobalSvgSelector from '../../../../../../GlobalSvgSelector/GlobalSvgSelector'
import s from './NumberHead.module.scss'

const NumberHead = ({search, setSearch, activeCategory, setActiveCategory}) => {
  const {theme, numbers} = useTheme()
  const [categories, setCategories] = useState([])

  useEffect(() => {
    let new_cats = []
    numbers.map(elem => !new_cats.includes(elem["CATEGORY"]) ? new_cats.push(elem["CATEGORY"]) : null)

    let result = []
    new_cats.map(elem => result.push({id: result.length, name: elem}))

    setCategories(result)
  }, [numbers])

  const changeActiveCategory = category => {
    setActiveCategory(category)
  }

  return (
    <div className={s.head}>
      <div className={s.inputWrapper}>
        <p className={s.icon}>
          {theme !== 'light' ? (
            <GlobalSvgSelector id="search-light" />
          ) : (
            <GlobalSvgSelector id="search-dark" />
          )}
        </p>
        <input
          className={s.input}
          value={search}
          onChange={e => setSearch(e.target.value)}
          type="text"
          placeholder="Искать номер"
        />
      </div>
      <div className={s.categories}>
        {categories.map(cat => {
          return (
            <button
              key={cat.id}
              className={`${s.category} ${activeCategory.id === cat.id && s.active}`}
              onClick={() => changeActiveCategory(cat)}
            >
              {cat.name}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default NumberHead
