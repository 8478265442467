import React, {useEffect, useState} from 'react'
import s from "./DetailPage.module.scss"
import {TabsNoColored} from "../../../../ui/Tabs/Tabs";
import {VoipDetailPage} from "./VoipDetailPage/VoipDetailPage";
import {DateBubble} from "../../../../ui/Bubbles/DateBubble/DateBubble";
import {DeviceBubble} from "../../../../ui/Bubbles/DeviceBubble/DeviceBubble";
import {useTheme} from "../../../../hooks/useTheme";
import {getDetails} from "../../../../api";
import {convertDateForDetail} from "../../../../sortFunction";
import {PhoneDetailPage} from "./PhoneDetailPage/PhoneDetailPage";


const pages = [
    {id:0, label: "Исходящие вызовы (VoIP)"},
    {id:1, label: "Входящие, исходящие вызовы (Местная телефония)"},
]

export const DetailPage = () => {
    const { userAccount, voipNumers, phoneNumers } = useTheme()

    const [activeTab, setActiveTab] = useState(pages[0].id)
    const [valueStart, setValueStart] = useState(new Date())
    const [valueEnd, setValueEnd] = useState(new Date())

    const [selectedDevice, selectDevice] = useState([])
    const [isAllSelected, selectAll] = useState(false)
    const [deviceList, setDeviceList] = useState([])
    const [paginationSize, setPaginationSize] = useState({ value: 20, label: '20' })
    const [data, setData] = useState([])

    const [loadStatus, setLoadStatus] = useState(null)

    useEffect(() => {
        // console.log(valueStart)
        // console.log(valueEnd)
        // console.log(isAllSelected)
        // console.log(selectedDevice)


    }, [valueEnd, valueStart, selectedDevice])

    useEffect(() => {
        setData([])
        setLoadStatus(null)

        if (activeTab === 0) {
            setDeviceList(voipNumers)
        } else if (activeTab === 1) {
            setDeviceList(phoneNumers)
        }

    }, [voipNumers, phoneNumers, activeTab])

    const loadData = () => {
        if (!selectedDevice.value && !selectedDevice.length) return

        userAccount["start"] = convertDateForDetail(valueStart)+" 00:00"
        userAccount["end"] = convertDateForDetail(valueEnd)+" 23:59"
        // userAccount["ats"] = selectedDevice.value ? [selectedDevice.value] : selectedDevice.map(e => e.value)
        if (isAllSelected) {
            userAccount["ats"] = deviceList.map(e => e.value)
        } else if (selectedDevice.value) {
            userAccount["ats"] = [selectedDevice.value]
        }
        userAccount["src"] = activeTab === 0 ? "voip" : "phone"

        setLoadStatus(false)
        // console.log(userAccount)
        getDetails({...userAccount})
            // .then(result => {result?.data ? setData(result?.data) : console.log("false"); setLoadStatus(true)})
            .then(result => {result?.data && setData(result.data); setLoadStatus(true);})

    }

    return(
        <div>
            <TabsNoColored activeTab={activeTab} setActiveTab={setActiveTab} tabs={pages}/>

            <div className={s.bubbles}>
                <DateBubble setStart={setValueStart} setEnd={setValueEnd}/>
                <DeviceBubble selectedDevice={selectedDevice} selectDevice={selectDevice}
                              isAllSelected={isAllSelected} selectAll={selectAll}
                              paginationSize={paginationSize} setPaginationSize={setPaginationSize}
                              deviceList={deviceList}/>

            </div>


            {activeTab === 0 &&
            <VoipDetailPage loadData={loadData} callData={data} status={loadStatus}
                            paginationSize={paginationSize} valueEnd={valueEnd} valueStart={valueStart}/>
            }

            {activeTab === 1 &&
            <PhoneDetailPage loadData={loadData} callData={data} status={loadStatus}
                            paginationSize={paginationSize} valueEnd={valueEnd} valueStart={valueStart}/>
            }


        </div>
    )
}