import s from "./MenuTooltip.module.scss"
import {DefaultButtonSmall} from "../Button/Button";
import React from "react";

export const MenuTooltip = (props) => {
    return(
        <div className={s.MenuTooltip}>
            <div className={s.MenuTitleGif}>
                {props.elem.title}
            </div>

            <DefaultButtonSmall text={"скрыть"} clickEvent={props.hide}/>
        </div>
    )
}