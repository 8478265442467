import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useTheme} from '../../../../../../hooks/useTheme'
import ActiveCities from './ActiveCities'
import s from './Cities.module.scss'
import MultipleSelect from './MultipleSelect/MultipleSelect'
import GlobalSvgSelector from '../../../../../GlobalSvgSelector/GlobalSvgSelector'
import Tippy from '@tippyjs/react'
import 'tippy.js/animations/scale.css'

const Cities = ({activeCities, setActiveCities}) => {
  let {theme, selectIsOpen, setSelectIsOpen, helpText, isHelperOpen, numbers} = useTheme()
  const [citiesList, setCitiesList] = useState([]) // города которые можно выбрать
  const [localOpen, setLocalOpen] = useState(false) // локально проверяем открыт ли селект
  const [localTooltip, setLocalTooltip] = useState(false)

  const handleClick = id => {
      setCitiesList([...citiesList, ...activeCities.filter(city => city.id === id)])
      setActiveCities(activeCities.filter(city => city.id !== id))
  }

  useEffect(() => {
      if (numbers.length === 0) return

      let cities_names = []
      let cities = []
      numbers.map(numer =>
          !cities_names.includes(numer.CITY) && cities_names.push(numer.CITY)
          && cities.push({id: cities.length, name: numer.CITY})
      )

      setCitiesList([...cities])

  }, [numbers])

  useEffect(() => {
    if (!helpText) setLocalTooltip(false)
  }, [helpText])

  return (
    <div className={s.wrapper}>
      <div className={s.preWrap}>
        <div className={s.chooseCity}>
          <div
            className={s.addCity}
            onClick={() => {
              setLocalOpen(!localOpen)
              setSelectIsOpen(!selectIsOpen)
            }}
          >
            {theme === 'light' ? (
              <div className={s.cross}>
                <GlobalSvgSelector id="numbers-cross-light" />
              </div>
            ) : (
              <div className={s.cross}>
                <GlobalSvgSelector id="numbers-cross-dark" />
              </div>
            )}
            <p>Фильтровать по городу</p>
          </div>
          <MultipleSelect
            isOpen={selectIsOpen}
            setIsOpen={setSelectIsOpen}
            value={citiesList}
            setValue={setCitiesList}
            activeCities={activeCities}
            setActiveCities={setActiveCities}
            localOpen={localOpen}
            setLocalOpen={setLocalOpen}
          />
        </div>
        <div className={`${s.cities} ${activeCities.length && s.active}`}>
          <ActiveCities handleClick={handleClick} activeCities={activeCities} />
        </div>
      </div>
      <div className={s.emergency}>
        <Tippy
          visible={localTooltip}
          animation={'scale'}
          content={
            <div
              style={{
                background: 'var(--background-gradient-st)',
                color: 'var(--main-font-color)',
                padding: '12px',
                borderRadius: '10px',
                border: '1px solid var(--border-default)',
                boxShadow: '0 0 20px 0 var(--border-default)',
              }}
            >
                <b>Городской номер</b> – местный телефонный номер для оказания услуг местной связи
                посредством бесплатной переадресации входящих звонков на мобильный (федеральный) номер.
                <br/><br/>
                <b>Номер 8-800</b> – федеральный многоканальный номер, звонки на который бесплатны для ваших клиентов из
                Красноярского края или на территории всей России в зависимости от выбранного тарифа,
                как с мобильного, так и с городского телефона.
            </div>
          }
        >
          <p
            className={s.mark}
            onClick={() => {
              if (document.body.clientWidth < 1250) {
                setLocalTooltip(true)
                isHelperOpen(!helpText)
              }
            }}
            onMouseOver={() => {
              setLocalTooltip(true)
            }}
            onMouseLeave={() => {
              setLocalTooltip(false)
            }}
          >
            ?
          </p>
        </Tippy>
      </div>
    </div>
  )
}

export default Cities
