import {cards, crAudioPath} from "./provider/variables";
import CyrillicToTranslit from "cyrillic-to-translit-js";





export const sorting = (param, wayOfSort, activeSequence) => {
  if (!activeSequence) return []
  if (wayOfSort) {
    return activeSequence
      .sort((x, y) => {
        return x[param] < y[param] ? -1 : x[param] > y[param] ? 1 : 0
      })
      .reverse()
  } else {
    return activeSequence.sort((x, y) => {
      return x[param] < y[param] ? -1 : x[param] > y[param] ? 1 : 0
    })
  }
}

export const convertDateToUcdr = (date_str, add_day = 0) => {
  let dt = new Date(date_str);
  dt.setDate(dt.getDate() + add_day)

  let day = dt.getDate().toString()
  let month = (dt.getMonth() + 1).toString()
  let year = dt.getFullYear().toString()

  if (day.length === 1) day = "0"+day
  if (month.length === 1) month = "0"+month

  return year+"-"+month+"-"+day

}

export const convertDateToUcdrFull = (date_str,
                                      add_minutes = 0,
                                      add_hours = 0,
                                      add_days = 0) => {
  let dt = new Date(date_str);
  dt.setDate(dt.getDate() + add_days)
  dt.setHours(dt.getHours() + add_hours)
  dt.setMinutes(dt.getMinutes() + add_minutes)

  let day = dt.getDate().toString()
  let month = (dt.getMonth() + 1).toString()
  let year = dt.getFullYear().toString()
  let hour = dt.getHours().toString()
  let minutes = dt.getMinutes().toString()

  if (day.length === 1) day = "0"+day
  if (month.length === 1) month = "0"+month
  if (hour.length === 1) hour = "0"+hour
  if (minutes.length === 1) minutes = "0"+minutes

  return year+"-"+month+"-"+day+" "+hour+":"+minutes

}

export const convertDateForDetail = (date_str, add_day = 0) => {
  let dt = new Date(date_str);
  dt.setDate(dt.getDate() + add_day)

  let day = dt.getDate().toString()
  let month = (dt.getMonth() + 1).toString()
  let year = dt.getFullYear().toString()

  if (day.length === 1) day = "0"+day
  if (month.length === 1) month = "0"+month

  return day+"."+month+"."+year

}

export const getAudioFilePath = (fileName) => {
  if (!fileName) return ""
  while (fileName.includes("#")){
    fileName = fileName.replace("#", "%23")
  }
  return crAudioPath + fileName.replace(".wav", ".mp3")
}

export function changeDateTime(oldDate) {
  // TODO: корректировка даты с поправкой на часовой пояс
  let newDate = oldDate

  if (newDate.includes('T')) {
    newDate = newDate.split('T')
    let date = newDate[0]
    let time = newDate[1]
    time = time.split('.')[0]
    newDate = [date, time].join(' ')
  }

  return newDate
}

export const getMonthName = (m) => {
  if (m <= 0 || m >= 12) return ""

  switch (parseInt(m)) {
    case 1: return "января";
    case 2: return "февраля";
    case 3: return "марта";
    case 4: return "апреля";
    case 5: return "мая";
    case 6: return "июня";
    case 7: return "июля";
    case 8: return "августа";
    case 9: return "сентября";
    case 10: return "октября";
    case 11: return "ноября";
    case 12: return "декабря";
  }
}

export const normalizeDateYMD = (date_str) => {
  let date = new Date(date_str);

  let day = date.getDate().toString()
  let month = (date.getMonth() + 1).toString()

  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()

  month = month < 10 ? "0"+month : month;
  h = h < 10 ? "0"+h : h;
  m = m < 10 ? "0"+m : m;
  s = s < 10 ? "0"+s : s;

  // return day+" "+getMonthName(month)+" "+h+":"+m+":"+s
  return month+"."+day+" "+h+":"+m+":"+s
}

export const normalizeDateDMHMS = (date_str) => {
  let date = new Date(date_str);

  let day = date.getDate().toString()
  let month = (date.getMonth() + 1).toString()

  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()


  month = month < 10 ? "0"+month : month;
  h = h < 10 ? "0"+h : h;
  m = m < 10 ? "0"+m : m;
  s = s < 10 ? "0"+s : s;

  // return day+" "+getMonthName(month)+" "+h+":"+m+":"+s

  return day+"."+month+" "+h+":"+m+":"+s
}

export const normalizeDateDMY = (date_str) => {
  let date = new Date(date_str);

  let day = date.getDate().toString()
  let month = (date.getMonth() + 1).toString()
  let year = (date.getFullYear()).toString()

  month = month < 10 ? "0"+month : month;

  // return day+" "+getMonthName(month)+" "+h+":"+m+":"+s
  return day+"."+month+"."+year
}

export const getDateFromStr = (date_str) => {
  return new Date(date_str);
}

export const getDateFromStrDetail = (date_str) => {
  let date = new Date()
  let dt = date_str.split(" ")[0]
  let time = date_str.split(" ")[1]
  date.setDate(dt.split(".")[0])
  date.setMonth(dt.split(".")[1] - 1)
  date.setFullYear(dt.split(".")[2])
  date.setHours(time.split(":")[0])
  date.setMinutes(time.split(":")[1])

  return date;
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const addDayToDate = (date, cnt) => {
  date.setDate(date.getDate() + cnt)
  return date
}

export const dateToYMD = (date_str) => {
  let date = new Date(date_str);

  let day = date.getDate().toString()
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()

  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()

  month = month < 10 ? "0"+month : month;
  h = h < 10 ? "0"+h : h;
  m = m < 10 ? "0"+m : m;
  s = s < 10 ? "0"+s : s;

  // return day+" "+getMonthName(month)+" "+h+":"+m+":"+s
  return [year, month, day].join(".")
}

export const dateToDMY = (date_str) => {
  let date = new Date(date_str);

  let day = date.getDate().toString()
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()

  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()

  month = month < 10 ? "0"+month : month;
  h = h < 10 ? "0"+h : h;
  m = m < 10 ? "0"+m : m;
  s = s < 10 ? "0"+s : s;

  // return day+" "+getMonthName(month)+" "+h+":"+m+":"+s
  return [day, month, year].join(".")
}

export const generatePassword = (length=24, charset='abcedfghijklmnopqrsstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_!')  => {
  let password = '';
  for(let i = 0; i < length; ++i){
    password += charset[
        Math.floor(
            Math.random() * charset.length
        )];
  }
  return password;
}


const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(event.target.result)
  };
  reader.readAsDataURL(file);
});

export default convertFileToBase64;

export const getDate = (date) => {
  let days = 0
  let months = 0
  let year = date.getFullYear()

  if (date.getDate() < 10) {
    days = `0${date.getDate()}`
  } else {
    days = date.getDate()
  }

  if (date.getMonth() + 1 < 10) {
    months = `0${date.getMonth() + 1}`
  } else {
    months = date.getMonth() + 1
  }

  return `${days}.${months}.${year}`
}

export const getDateStrFromDMYHMstr = (date_str) => {
  return date_str.split(" ")[0]
}

export const getStartDay = (date) => {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  return date
}

export const getEndDay = (date) => {
  date.setHours(23)
  date.setMinutes(59)
  date.setSeconds(59)
  return date
}

export const normalizeDateDMYHMS = (date_str) => {
  let date = new Date(date_str);

  let day = date.getDate().toString()
  let month = (date.getMonth() + 1).toString()
  let year = date.getFullYear().toString()

  let h = date.getHours()
  let m = date.getMinutes()
  let s = date.getSeconds()


  month = month < 10 ? "0"+month : month;
  day = day < 10 ? `0${day}` : day
  h = h < 10 ? "0"+h : h;
  m = m < 10 ? "0"+m : m;
  s = s < 10 ? "0"+s : s;

  // return day+" "+getMonthName(month)+" "+h+":"+m+":"+s

  return day+"."+month+"."+year+" "+h+":"+m+":"+s
}