import s from "./HelpElemTppy.module.css";
import Tippy from "@tippyjs/react";
import React, {useState} from "react";
import {useTheme} from "../../hooks/useTheme";


export const HelpElemTippy = (props) => {
    const {theme} = useTheme()
    const [isOpen, setIsOpen] = useState(false)

    return(
        <div style={{width: "32px", height: "32px"}}>
            <Tippy
                visible={isOpen}
                animation={'scale'}
                content={
                    <div
                        style={{
                            background: 'var(--background-gradient-st)',
                            color: theme === 'dark' ? 'var(--main-font-color)' : 'var(--light-color)',
                            padding: '12px',
                            borderRadius: '10px',
                            border: '1px solid var(--border-default)',
                            boxShadow: '0 0 20px 0 var(--border-default)',
                        }}
                    >
                        {props.text}
                    </div>
                }
            >
                <p
                    className={!props.white ? s.mark : s.whiteMark}
                    style={{
                        color: theme === 'dark' ? 'var(--main-font-color)' : 'var(--light-color)',
                    }}
                    onClick={() => {
                        if (document.body.clientWidth < 1250) {
                            setIsOpen(true)
                        }
                    }}
                    onMouseOver={() => {
                        setIsOpen(true);
                    }}
                    onMouseLeave={() => {
                        setIsOpen(false)
                    }}
                >
                    ?
                </p>
            </Tippy>
        </div>

    )
}