import React, {useEffect, useState} from 'react'
import s from "./Button.module.scss"

export const WebSitesButton = ({text="ok", color=null, clickEvent}) => {
    const [useColor, setUseColor] = useState()

    useEffect(() => {
        if (color === "blue") setUseColor(s.blueButton)
        if (color === "green") setUseColor(s.greenButton)
        if (color === "orange") setUseColor(null)
        if (color === "noactive") setUseColor(s.noActiveButton)
    }, [color])
    return(
        <button type={"submit"} className={`${s.orangeButton} ${useColor && useColor}`}
                onClick={() => {clickEvent && clickEvent()}}
        >{text}</button>
    )
}

export const WebSitesDivButton = ({text="ok", color=null, clickEvent=null, wide=false, animated=true}) => {
    const [useColor, setUseColor] = useState()

    useEffect(() => {
        if (color === "blue") setUseColor(s.blueButton)
        else if (color === "green") setUseColor(s.greenButton)
        else if (color === "orange") setUseColor(null)
        else if (color === "noactive") setUseColor(s.noActiveButton)
        else setUseColor(s.orangeButton)
    }, [color])

    return(
        <div className={`${s.orangeButton} ${animated && s.animated}
                        ${wide && s.wideB} ${s.noButton} ${useColor && useColor}`}
                onClick={() => {clickEvent && clickEvent()}}
        >{text}</div>
    )
}