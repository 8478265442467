import React, {useEffect, useState} from 'react';
import './CheckBox.css'
import s from "./CheckBox.module.scss";

const CheckBox = (props) => {
    return (
        <label style={{lineHeight: "30px", display: "block"}}>
            <input type={"checkbox"}
                   className={s.realCheckBox}
                   onChange={(e) => {
                       props.chStatus(e.target.checked);
                   }} // send value to hook form
                   checked={props.checked}
                   value={props.checked}
            />
            <div className={s.fakeCb} />
        </label>
    )
}

export default CheckBox

export const InputCheckPox = (props) => {
    const [status, setStatus] = useState(props.defaultChecked)

    const changeStatus = (value) => {
        props.onClick(value)
    }

    // useEffect(() => {
    //     console.log(props.value)}
    // , [props.value])
    return (
        <>
            <input type="checkbox" {...props}/>
            <div className={"cb_1 " + props.styleParams +" "+(status ? "on" : "off")}
                 onClick={() => changeStatus(!status)}>
                <div className={"cbb_1 "+(status ? "on" : "off")}/>
            </div>
        </>

    )
}