import './App.scss'
import Header from './components/header/Header'
import {BrowserRouter, Routes, Route, useSearchParams} from 'react-router-dom'
import SettingsPage from './components/pages/SettingsPage/SettingsPage'
import DocumentPage from './components/pages/DocumentPage/DocumentPage'
import Autocalling from './components/pages/AutocallingPage/Autocalling'
import React, {useEffect, useState} from 'react'
import useScrollbarSize from 'react-scrollbar-size'
import Support from './components/pages/SupportPage/Support'
import VirtualATSPage from "./components/pages/VirtualATSPage/VirtualATSPage";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import {useTheme} from "./hooks/useTheme";
import {Navigate} from "react-router";
import ModalMessage from "./ui/Popup/ModalMessage";
import NumersShop from "./components/pages/NumersShop/NumersShop";
import DdosPage from "./components/pages/DdosPage/DdosPage";
import IncomingCalls from "./components/pages/IncomingCalls/IncomingCalls";
import FaqPage from "./components/pages/FaqPage/FaqPage";
import VamZvonili from "./components/pages/VamZvonili/VamZvonili";
import {headerMenu, menu, settingsMenu} from "./provider/variables";
import UnlimitedTelephonyPage from "./components/pages/UnlimitedTelephonyPage/UnlimitedTelephonyPage";
import ManagersPage from "./components/pages/ManagersPage/ManagersPage";
import CallLog from "./components/pages/CallLog/CallLog";

import {createBrowserHistory} from "history";

import {ModulePopups} from "./ui/Popup/Popups";
import {CRMIntegrationsPage} from "./components/pages/CRMIntegrationsPage/CRMIntegrationsPage";
import {ZkhIpuPage} from "./components/pages/ZkhIpuPage/ZkhIpuPage";
import {ErrorMessagePopup} from "./ui/Popup/ErrorMessagePopup";
import {MasterHelp, MasterHelpButton} from "./ui/MasterHelp/MasterHelpButton";
import PacketsPage from "./components/pages/PacketsPage/PacketsPage";
const history = createBrowserHistory();

const ReLogin = () => {
    const [searchParams, ] = useSearchParams();
    const [redirectTo, setRedirectTo] = useState("")

    useEffect(() => {
        if (searchParams.get("redirect")) setRedirectTo(["/", searchParams.get("redirect")].join(""))

    }, [searchParams])

    return(
        redirectTo.length > 0
            ? <Navigate replace to={redirectTo}/>
            : <FaqPage />
    )


}


function App() {
    const theme = useTheme()
    const [showMessage, closeMsg] = useState(true)
    const [redirectTo, setRedirectTo] = useState("")
    const [showMaster, setShowMaster] = useState(false)

    const {
        userAccount, settings, newUserActions,
        setNewUserActions, activePopupGlobal, setActivePopupGlobal} = useTheme()
    const { width } = useScrollbarSize()

    if (activePopupGlobal.length > 0) {
        document.body.classList.add('hidden')
    } else {
        document.body.classList.remove('hidden')
    }

    useEffect(() => {
        if ((!settings || !settings.flag || !settings.cabinet) && !newUserActions) return

        setActivePopupGlobal("newUser")
        setNewUserActions(true)

    }, [settings, newUserActions])


  return (
    userAccount.login && userAccount.token ?
    <BrowserRouter>
        <div className={`${activePopupGlobal.length > 0 ? `App blur` : 'App'} ${theme.designType === "minimal" && "full-width"}`}
             style={activePopupGlobal.length > 0 ? { paddingRight: `${width + 15}px` } : {}}
        >
            <Header activePopup={activePopupGlobal} setActivePopup={setActivePopupGlobal} />
            {!userAccount.virtual && !userAccount.fusion_auth &&
            <Routes>
                <Route path="/" element={<SettingsPage setActivePopup={setActivePopupGlobal}/>} />
                <Route path="/settings" element={<SettingsPage setActivePopup={setActivePopupGlobal}/>}/>
                <Route path="/documents" element={<DocumentPage />}/>
                {/*<Route path="/calllog-old" element={<CallLogOld />}/>*/}
                <Route path="/calllog" element={<CallLog setActivePopup={setActivePopupGlobal}/>}/>

                {/*<Route path="/voip" element={<VoipPage other_voip={false}/>}/>*/}
                <Route path="/unlp" element={<UnlimitedTelephonyPage setActivePopup={setActivePopupGlobal}/>}/>
                {/*<Route path="/virtual-ats" element={<VirtualAts />}/>*/}

                <Route path="/vats" element={<VirtualATSPage />}/>
                {/*<Route path="/vats-dev" element={<VirtualATSDev />}/>*/}
                <Route path="/shop" element={<NumersShop setActivePopup={setActivePopupGlobal}/>}/>

                <Route path="/autocalling" element={<Autocalling setActivePopup={setActivePopupGlobal}/>}/>
                <Route path="/packets" element={<PacketsPage setActivePopup={setActivePopupGlobal}/>}/>
                <Route path="/calculations" element={<DocumentPage />}/>
                <Route path="/support" element={<Support />}/>
                <Route path="/ddos-protect" element={<DdosPage />}/>
                <Route path="/incoming-calls" element={<IncomingCalls />}/>
                <Route path="/faq" element={<FaqPage />}/>
                <Route path="/vamzvonili" element={<VamZvonili setActivePopup={setActivePopupGlobal}/>}/>
                {/*<Route path="/telephony" element={<TelephonyPage setActivePopup={setActivePopupGlobal}/>}/>*/}
                <Route path="/crm-integration" element={<CRMIntegrationsPage setActivePopup={setActivePopupGlobal}/>}/>
                <Route path="*" element={<Navigate replace to="/"/>}/>
                {userAccount.manager &&
                <Route path="/mgrs" element={<ManagersPage setActivePopup={setActivePopupGlobal}/>}/>
                }

                <Route path="/zkh" element={<ZkhIpuPage />}/>
                {/*{redirectTo && console.log(redirectTo)}*/}

                { // check redirects
                    redirectTo.length > 0 &&
                    (menu.find(e => e.href.includes(redirectTo))
                        || settingsMenu.find(e => e.href.includes(redirectTo))
                        || headerMenu.find(e => e.href.includes(redirectTo)))
                        ? <Route path="*" element={<Navigate replace to={"/"+redirectTo}/>}/>
                        : <Route path="*" element={<Navigate replace to="/"/>}/>
                }
            </Routes>
            }

            {userAccount.virtual &&
            <Routes>
                <Route path="/" element={<FaqPage />} />
                <Route path="/settings" element={<SettingsPage setActivePopup={setActivePopupGlobal}/>}/>
                <Route path="/documents" element={<DocumentPage />}/>
                <Route path="/calllog" element={<CallLog />}/>

                <Route path="/support" element={<Support />}/>
                <Route path="/incoming-calls" element={<IncomingCalls />}/>
                <Route path="/faq" element={<FaqPage />}/>
                <Route path="/login" element={<ReLogin />}/>


                { // check redirects
                    redirectTo.length > 0 &&
                    (menu.find(e => e.href.includes(redirectTo))
                        || settingsMenu.find(e => e.href.includes(redirectTo))
                        || headerMenu.find(e => e.href.includes(redirectTo)))
                        ? <Route path="*" element={<Navigate replace to={"/"+redirectTo}/>}/>
                        : <Route path="*" element={<Navigate replace to="/"/>}/>
                }
            </Routes>
            }

            {userAccount.fusion_auth &&
            <Routes>
                <Route path="/" element={<CallLog />}/>
                <Route path="/documents" element={<DocumentPage />}/>
                <Route path="/calllog" element={<CallLog />}/>

                <Route path="/support" element={<Support />}/>
                <Route path="/incoming-calls" element={<IncomingCalls />}/>
                <Route path="/faq" element={<FaqPage />}/>

                { // check redirects
                    redirectTo.length > 0 &&
                    (menu.find(e => e.href.includes(redirectTo))
                        || settingsMenu.find(e => e.href.includes(redirectTo))
                        || headerMenu.find(e => e.href.includes(redirectTo)))
                        ? <Route path="*" element={<Navigate replace to={"/"+redirectTo}/>}/>
                        : <Route path="*" element={<Navigate replace to="/"/>}/>
                }
            </Routes>
            }

        </div>
        <div>&nbsp;</div>

        {/*{showMessage && <MessageModule closeMsg={closeMsg} />}*/}
        <ModulePopups activePopup={activePopupGlobal} setActivePopup={setActivePopupGlobal}/>
        <ErrorMessagePopup />

        {!showMaster &&
        <MasterHelpButton clickEvent={() => setShowMaster(!showMaster)}/>
        }

        {showMaster &&
        <MasterHelp show={showMaster} hide={() => setShowMaster(false)}/>
        }

        <ModalMessage message={"Оплата прошла успешно!"}
                      label={"Успешно!"}
                      show={activePopupGlobal === "successPay"}
                      hide={() => {
                          setActivePopupGlobal("");
                          history.push("/");
                          history.go("/")
                      }}
        />

        <ModalMessage message={"Оплата прошла успешно! "}
                      label={"Неудачно..."}
                      show={activePopupGlobal === "failPay"}
                      hide={() => {
                          setActivePopupGlobal("");
                          history.push("/");
                          history.go("/")
                      }}
        />

        <ModalMessage message={"Настройки успешно сохранены"}
                      label={"Успех!"}
                      show={activePopupGlobal === "settingsSuccess"}
                      hide={() => {
                          setActivePopupGlobal("");
                      }}
        />

    </BrowserRouter>

    :
    <BrowserRouter>
        <Routes>
            <Route path="/login" element={<LoginPage setRedirectTo={setRedirectTo}/>} />
            {/*<Route path="/register" element={<RegisterPageMain />} />*/}
            {/*<Route path="/register/esia/" element={<RegisterPageESIA />} />*/}
            {/*<Route path="/documents/" />*/}

            {/*<Route path="/documents/privacy.pdf" element={<Navigate replace to="/documents/privacy.pdf"/>}/>*/}
            <Route path="*" element={<Navigate replace to="/login"/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default App
