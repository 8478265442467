import React, {useEffect, useState} from "react";
import {useTheme} from "../../../../hooks/useTheme";
import {
    createClientMeters,
    deleteClientMeters,
    getClientMeters,
    updateClientMeters,
    uploadClientMeters,
    uploadClients
} from "../api";
import DefaultButton, {
    DownloadButton,
    SubmitButton,
    UploadButtonLess,
    UploadPreview
} from "../../../../ui/Button/Button";
import s from "./abonentsMetersPopup.module.scss"
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {clientsMetersSchema} from "../schemas";
import {
    clientMetersHeader,
    clientMetersHeaderDwnld,
    uploadClientsMetersTemplate
} from "../variables";
import {HelpElem} from "../../../../ui/HelpElem/HelpElem";
import {LoadingDots} from "../../../../ui/Loading/Loading";
import Select from "react-select";
import PaginationBlock from "../../../../ui/PaginationBlock/PaginationBlock";


const get_child_dict_elem = (d, key)  => {
    if (key.includes(".")) {
        let k_list = key.split(".")

        let value = d
        for (const k_l of k_list) {
            value = value[k_l]
        }
        return value
    } else {
        return d?.[key]
    }
}


const ControllerInput = ({control, values, elem, index, elem_key, errors, menuIsOpen, menuElem, changeElem}) => {
    const {theme} = useTheme()

    const customStylesError = {
        input: (provided, state) => ({
            ...provided,
            backgroundColor: "rgba(255, 0, 0, 0.28) !important"
        }),
    }

    const customStyles = {
        input: (provided, state) => ({
            ...provided,
        }),
    }

    return (
        <Controller
            control={control}
            values={values}
            name={`data.${index}.${elem_key}`}
            instanceId={2}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
                <Select
                    onChange={e => {
                        onChange(e?.label)
                        changeElem(e?.label)
                    }}
                    options={values}
                    styles={errors?.data?.[index]?.[elem_key] ? customStylesError : customStyles}
                    isSearchable
                    hideSelectedOptions
                    defaultValue={values.find(e => e?.label === elem?.[elem_key])}

                    menuIsOpen={menuIsOpen && menuElem === `${elem?.id}.${elem_key}`}
                />
            )}
        />
    )
}


export const AbonentsMetersPopup = (props) => {
    const {userAccount} = useTheme()

    const [currentClientUuid, setCurrentClientUuid] = useState("")
    const [currentClientMeters, setCurrentClientMeters] = useState({})
    const [metersGroups, setMetersGroups] = useState([])
    const [initialElements, setInitialElements] = useState([])
    const [downloadableData, setDownloadableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showUploadPopup, setShowUploadPopup] = useState(false)

    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [menuElem, setMenuElem] = useState("")

    const pageSize = 5
    const [filteredRows, setFilteredRows] = useState([])
    const [curPage, setCurPage] = useState(1)

    const uploadAction = (data) =>{
        setLoading(true)
        uploadClientMeters({auth: userAccount, formData: data, clientUuid: currentClientUuid})
            .then(() => {
                setLoading(false)
                loadMeters()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!props.show) return;
        if (props?.userId?.length === 0 || !props?.userId) return

        setCurrentClientUuid(props.userId)
    }, [props.userId])

    useEffect(() => {
        if (props?.metersList?.length === 0) return
        let metersList =  props.metersList.map(e => (
            {label: e?.name, value: e?.uuid, install_point: e?.install_point}
            ))
        setMetersGroups(metersList)
    }, [props.metersList])

    const {register, handleSubmit, control, reset, setValue, watch, formState: {errors}} = useForm({
        resolver: yupResolver(clientsMetersSchema),
        mode: 'onBlur'
    })

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "data", // unique name for your Field Array
    });

    useEffect(() => {
        // console.log(errors)
    }, [errors])

    useEffect(() => {
        if (!currentClientMeters?.data) return

        let cols = [
            {col_key: "client_parent", target_key: "client_parent"},
            {col_key: "meter_id", target_key: "meter_id"},
            {col_key: "meter_id", target_key: "original_meter_id"},
            {col_key: "meter_parent.name", target_key: "name"},
            {col_key: "meter_parent.install_point", target_key: "install_point"},
            {col_key: "meter_parent.uuid", target_key: "meter_parent"},
        ]

        let result = {
            data: []
        }
        for (const elem of currentClientMeters?.data) {
            let row = {}
            for (const k of cols) {
                row[k?.target_key] = get_child_dict_elem(elem, k?.col_key)
            }
            result.data.push(row)
        }

        result.data = result.data.sort((a, b) => a?.meter_id > b?.meter_id ? 1 : -1)
        reset(result)
        setInitialElements(result?.data)
    }, [currentClientMeters])

    const loadMeters = () => {
        setLoading(true)
        getClientMeters({clientId: currentClientUuid, ...userAccount})
            .then(r => {
                setCurrentClientMeters(r)
                setLoading(false)
            })
            .catch(e => {
                console.log("getClientMeters fail: ", e?.response)
                setLoading(false)
            })
    }

    useEffect(() => {
        reset({})
        setInitialElements([])

        // if (!props.show) return;
        if (currentClientUuid?.length > 0) {
            loadMeters()
        }
    }, [currentClientUuid, props.metersList])

    const appendVoidRow = () => {
        if (!props.show) return;
        append({})
        // append(Object.assign({}, ...clientMetersHeader.map((x) => ({[x.key]: ""}))))
    }

    useEffect(() => {
        if (!props.show) return;
        if (fields?.length === 0) appendVoidRow()
        if (fields?.length === 0) return

        let ddata = []
        for (const e of fields) {
            if (!e?.meter_id) continue
            ddata.push(
                Object.assign(
                    {},
                    ...clientMetersHeaderDwnld.map(
                        x => ({[x.key]: x?.key?.includes(".") ? e?.[x?.key?.split(".")[0]][x?.key?.split(".")[1]] : e?.[x?.key]}
                        )
                    )
                )
            )
        }

        setDownloadableData(ddata)
    }, [fields])

    const submitForm = (data) => {
        let update_elements = data?.data?.filter(e => e?.client_parent)
        let create_elements = data?.data?.filter(e => !e?.client_parent)
        let delete_elements = initialElements.filter(elem => !update_elements.find(e => e?.meter_id?.toString()  === elem?.meter_id?.toString()))
        // for (const elem of initialElements) {
        //     if (!update_elements.find(e => e?.meter_id === elem?.meter_id)) delete_elements.push(elem)
        // }
        // console.log("update_elements", update_elements)
        // console.log("create_elements", create_elements)
        // console.log("delete_elements", delete_elements)

        let actions = []
        for (const elem of create_elements) actions.push({action: "create", data: elem})
        for (const elem of update_elements) actions.push({action: "update", data: elem})
        for (const elem of delete_elements) actions.push({action: "delete", data: elem})

        setLoading(true)
        Promise.all(actions.map(
            row => row?.action === "create"
            ? new Promise((resolve, reject) =>
                    createClientMeters({payload: row?.data, auth: userAccount, clientId: currentClientUuid})
                        .then(() => resolve("ok"))
                        .catch((e) => reject(e)))
            : row?.action === "update"
            ? new Promise((resolve, reject) =>
                        updateClientMeters({payload: row?.data, auth: userAccount, clientId: currentClientUuid})
                            .then(() => resolve("ok"))
                            .catch((e) => reject(e)))
            : row?.action === "delete"
            ? new Promise((resolve, reject) =>
                        deleteClientMeters({payload: row?.data, auth: userAccount, clientId: currentClientUuid})
                            .then(() => resolve("ok"))
                            .catch((e) => reject(e)))
            : null
        ))
            .then(() => {
                setLoading(false)
                // setActivePopupGlobal("successSave")
                // setSavingAction(false)
                // loadAbonents()
            })
            .catch(() => {
                setLoading(false)
                // setActivePopupGlobal("failSave")
                // setSavingAction(false)
            })
    }

    useEffect(() => {
        setFilteredRows(
            fields.slice(
                pageSize * curPage - pageSize,
                pageSize * curPage
            ).map(e => e?.meter_id)
        )

    }, [curPage, fields])

    return (
        <div style={{
            position: "fixed", zIndex: 1000, inset: 0,
            width: "100%", height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.5)",
            display: props.show ? "block" : "none",
        }} onClick={(v) => props.hide()}>
            <div style={{
                position: "fixed",
                display: "flex", flexDirection: "column", alignItems: "center",
                top: "50%", left: "50%", marginRight: "-50%", transform: "translate(-50%, -50%)",
                color: "#555", backgroundColor: "white",
                maxWidth: "800px", padding: "25px",
                textAlign: "center",
                borderBottom: "1px solid #dbdbdb", borderRadius: "10px",
                boxShadow: "unset 0 4px 0 rgb(0 0 0 / 8%)",
            }} onClick={(e) => {
                e.stopPropagation()
                setMenuIsOpen(false)
            }}>
                <div style={{
                    display: "flex", justifyContent: "space-between",
                    fontSize: "16pt", fontWeight: "bold",
                    height: "100%", width: "100%",
                    marginBottom: "20px",
                    color: props.error ? "red": "rgb(85, 85, 85)"
                }}>
                    ИПУ абонента
                    <div className={s.buttonsTop}>
                        <HelpElem text={"Загрузить таблицу"}>
                            <UploadButtonLess clickEvent={() => setShowUploadPopup(true)}/>
                        </HelpElem>
                        <HelpElem text={"Сохранить таблицу"}>
                            <DownloadButton data={downloadableData} columns={clientMetersHeaderDwnld} file={"abonents.csv"}/>
                        </HelpElem>

                        {loading && <LoadingDots />}

                        {!loading &&
                        <div>
                            <DefaultButton text={"↺"} clickEvent={loadMeters} alter={"Обновить"} />
                        </div>
                        }

                        <div>
                            <DefaultButton text={"╳"} clickEvent={() => props.hide()} alter={"Закрыть окно"}/>
                        </div>
                    </div>
                </div>

                {/*{fields?.length > 0 &&*/}
                <form onSubmit={handleSubmit(submitForm)}>
                    <table className={`${s.formTableM}`}>
                        <thead>
                            {clientMetersHeader?.map(
                                elem => <th key={`header_${elem?.key}`} width={elem?.width}>{elem?.label}</th>
                            )}
                            <th>Действия</th>
                        </thead>

                        <tbody className={s.tbody}>
                        {fields.map((elem, index) =>
                            filteredRows.includes(elem?.meter_id) &&
                            <tr key={elem?.id}>
                                {clientMetersHeader?.map(col_elem =>
                                    <td key={`${elem?.id}_${col_elem?.key}`}
                                        onClick={e => {
                                        e.stopPropagation()
                                        setMenuIsOpen(menuElem === `${elem?.id}.${col_elem?.key}` || !menuIsOpen ? !menuIsOpen : menuIsOpen)
                                        setMenuElem(`${elem?.id}.${col_elem?.key}`)
                                    }}>
                                        {(!col_elem?.editable && !col_elem?.selectable) &&
                                        <input readOnly={true} key={elem?.id}
                                               {...register(`data.${index}.${col_elem?.key}`)} />
                                        }

                                        {col_elem?.editable &&
                                        <input key={elem?.id}
                                               className={errors?.data?.[index]?.[col_elem?.key] ? s.errorElem : null}
                                               {...register(`data.${index}.${col_elem?.key}`)} />
                                        }

                                        {col_elem?.key === "name" &&
                                        <ControllerInput control={control}
                                                         elem={elem}
                                                         index={index}
                                                         elem_key={col_elem?.key}
                                                         menuIsOpen={menuIsOpen}
                                                         menuElem={menuElem}
                                                         values={metersGroups}
                                                         errors={errors}
                                                         changeElem={e => {
                                                             setValue(`data.${index}.install_point`, metersGroups.find(g => g?.label === e)?.install_point || "")
                                                             setValue(`data.${index}.meter_parent`, metersGroups.find(g => g?.label === e)?.value || "")
                                                         }}
                                        />
                                        }

                                    </td>
                                )}
                                <td>
                                    <span className={s.actionB} onClick={() => remove(index)}>Удалить</span>
                                </td>
                            </tr>
                        )}
                        </tbody>

                        <div style={{width: "20px", marginTop: "15px", display: "flex", justifyContent: "flex-end"}}>
                            <HelpElem text={"Добавить строку"}>
                                <DefaultButton text={"+"} clickEvent={() => {
                                    appendVoidRow()
                                    // goToLastPage()
                                }}/>
                            </HelpElem>
                        </div>
                    </table>
                    <div className={s.pagination}>
                        <PaginationBlock curPage={curPage} setCurPage={setCurPage} callData={fields} paginationSize={pageSize}/>
                    </div>

                    <div className={`${s.flexBlock} ${s.margin}`}>
                        {loading && <LoadingDots />}
                        {!loading &&
                        <SubmitButton text={"Сохранить"}/>
                        }
                    </div>
                </form>

                <UploadPreview show={showUploadPopup} hide={setShowUploadPopup}
                               headers={clientMetersHeaderDwnld}
                               uploadAction={uploadAction}
                               mainHeader={"Загрузка ИПУ абонента"}
                               helpHeader={"Внимание! При успешной загрузке все предыдущие данные будут удалены. " +
                                    "Для загрузки таблицы Excel её поля должны быть в следующей последовательности:"}
                               data={[uploadClientsMetersTemplate]}
                />
            </div>
        </div>
    )
}