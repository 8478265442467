import React from "react";


export const BreadCrumb = ({elements, goTo}) => {
    return(
        <ul itemScope="itemscope" itemType="https://schema.org/BreadcrumbList" className="breadcrumb bottom-border">
            <li className="active">
                <span className="divider icon-location"/>
            </li>
            {elements && elements.map((elem, index) =>
                // <div>{index} - {elem.name}</div>
                <li itemProp="itemListElement" itemScope="itemscope" itemType="https://schema.org/ListItem">
                    {elem.page &&
                    <a itemProp="item" onClick={() => goTo(elem.page)} className="pathway">
                        <span itemProp="name">{elem.name}</span>
                    </a>
                    }

                    {!elem.page &&
                    <span itemProp="name">{elem.name}</span>
                    }

                    {(index + 1) < elements.length &&
                    <span className="divider">
                        <img src={require("../../../img/icons/arrow.png")} alt=">" width="9" height="9"/>
                    </span>
                    }

                    <meta itemProp="position" content={(index + 1).toString()}/>

                </li>
            )}
        </ul>
    )
}