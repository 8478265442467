import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import DefaultTitle from '../../DefaultTitle/DefaultTitle'
import s from './MenuBar.module.scss'
import {useTheme} from "../../../hooks/useTheme";
import {MenuTooltips, servicesId} from "../../../provider/variables";
import {GlobalGifSelector} from "../../../components/GlobalGifSelector/GlobalGifSelector";
import {GifMenuElem} from "../SettingsBar/SettingsBar";
import {MenuTooltip} from "../../MenuTooltip/MenuTooltip";
// import {ToolTip} from "../SettingsBar/SettingsBar";

const ToolTip = ({text}) => {
    return(
        <div className={s.toolTip}
             style={{
                 background: 'var(--background-gradient-st)',
                 color: 'var(--main-font-color)',
                 padding: '12px',
                 borderRadius: '10px',
                 border: '1px solid var(--border-default)',
                 boxShadow: '0 0 20px 0 var(--border-default)',
                 position: "absolute",
                 zIndex: "999",
                 display: "none",
                 cursor: "none"
             }}
        >
            {text}
        </div>
    )
}

const MenuBar = ({ menu }) => {
    const packetRubStartsWith = "Услуга_ПР"
    const location = useLocation()
    const theme = useTheme()

    const [filteredMenu, filterMenu] = useState(menu)
    const [tooltipsList, setTooltipsList] = useState([])
    const noServices = {id: -1, href: '/', title: 'Нет подключенных услуг', service: 'none', showVirtual: true}

    const nextTooltip = () => {
        let ttps = [...tooltipsList]
        let elem = ttps.pop()
        localStorage.setItem(elem.key, "true")
        setTooltipsList(ttps)
    }

    useEffect(() => {
        let m = []
        let ttpsList = []
        for (const elem of menu) {
            if ((theme.userAccount.virtual || theme.userAccount.fusion_auth) && !elem.showVirtual) {
                // Прячем от виртуалов
                continue
            }

            if (elem.service === "vats") {
                if (!theme.vatsNames || theme.vatsNames.length === 0) continue
            }

            if (elem.service === "incoming-calls") {
                if (!theme.additionalServices.find(e => servicesId.incomingCalls.includes(e.usl_id))) continue
            }

            if (elem.service === "unlp") {
                if (!theme.voipNumers || theme.voipNumers.length === 0) continue
                if (!theme?.currentVoipPacket?.usl_name?.includes("VoIP_пакет")) continue
                // if (theme.vatsDomain.new_cluster) continue
            }

            if (elem.service === "packets") {
                if (!theme?.packageData?.package_name?.startsWith(packetRubStartsWith)) continue
            }

            if (elem.service === "cbc") {
                if (!theme.cbcServices || theme.cbcServices.length === 0) continue
            }

            if (elem.service === "ddos") {
                if (!theme.properties || !theme.properties["properties"] || !theme.properties["properties"]["ddos"]) continue
            }

            if (elem.service === "vamzvonili") {
                if (!theme.additionalServices.find(e => servicesId.vamZvonili.includes(e.usl_id))) continue

                // Проверка, что домен мигрирован
                if (!theme.vatsDomain.new_cluster) continue
            }

            if (elem.service === "zkh") {
                if (!theme.additionalServices.find(e => servicesId.zkh.includes(e.usl_id))) continue
            }

            // if (userAccount.login && userAccount.login === '9037') {
            //     const blocked_services = ["shop", "vats", "cbc"]
            //     if (blocked_services.includes(elem.service)) {
            //         continue
            //     }
            // }


            m.push(elem)

            let mt = MenuTooltips.find(
                ttpElem => ttpElem.service === elem.service && ttpElem.show && !localStorage.getItem(ttpElem.key))
            if (mt) ttpsList.push(mt)

        }

        if (m.length === 0) {
            m = [noServices]
        }

        filterMenu(m)
        setTooltipsList(ttpsList.sort((a, b) => a.id < b.id ? 1 : -1))
    }, [
        theme.vatsNames, theme.cbcServices, theme.properties,
        theme.voipNumers, theme.additionalServices, theme.vatsDomain,
        theme?.packageData
    ])

    return (
        <div className={`${s.menuBar} ${theme.designType === 'minimal' ? s.smallBubble : ""}`}>
            {theme.designType === 'normal' &&
            <div className={s.title}>
                <DefaultTitle title="Продукты"/>
            </div>
            }
            <ul className={s.ul}>
                {filteredMenu.map((m) => (
                    <li
                        key={m.id}
                        className={
                            location.pathname === m.href || location.pathname === `${m.href}/`
                                ? `${s.href} ${s.active}`
                                : s.href
                        }
                    >
                        {theme.designType === 'normal' &&
                        <>
                            {m.service !== "none"
                                ? <Link to={m.href}>{m.title}</Link>
                                : <div style={{cursor: "default"}}>{m.title}</div>
                            }
                        </>
                        }

                        {theme.designType === 'minimal' && m.service &&
                        <div className={`${s.inline}`}>
                            <Link to={m.href} className={s.withTooltip} style={{width: "35px", height: "35px"}}>
                                <GifMenuElem id={m.service}/>
                                <ToolTip text={m.title}/>
                            </Link>

                            {MenuTooltips.find(elem => elem.service === m.service && elem.show)
                            && tooltipsList.length > 0 && tooltipsList[tooltipsList.length - 1].service === m.service &&
                            <MenuTooltip elem={MenuTooltips.find(elem => elem.service === m.service && elem.show)} hide={nextTooltip}/>
                            }
                        </div>

                        }
                    </li>
                ))}
            </ul>
        </div>
    )

}

export default MenuBar
