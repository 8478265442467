// функция которая понимает как менять тему

const checkTheme = (arg1, arg2, theme) => {
  if (theme === 'light') return `var(${arg1})`
  else return `var(${arg2})`
}

// основная функция смены темы
export const changeVariables = (root, theme) => {
  root.style.setProperty(
    `--background-gradient-st`,
    checkTheme(
      '--background-gradient-dark-start',
      '--background-gradient-light-start',
      theme,
    ),
  )

  root.style.setProperty(
    `--background-gradient-end`,
    checkTheme(
      '--background-gradient-dark-end',
      '--background-gradient-light-end',
      theme,
    ),
  )

  root.style.setProperty(
    `--balance-background-st`,
    checkTheme(
      '--balance-background-dark-st',
      '--balance-background-light-st',
      theme,
    ),
  )

  root.style.setProperty(
    `--balance-background-end`,
    checkTheme(
      '--balance-background-dark-end',
      '--balance-background-light-end',
      theme,
    ),
  )

  root.style.setProperty(
    `--balance-card-background-st`,
    checkTheme(
      '--balance-card-background-dark-st',
      '--balance-card-background-light-st',
      theme,
    ),
  )

  root.style.setProperty(
    `--balance-card-background-end`,
    checkTheme(
      '--balance-card-background-dark-end',
      '--balance-card-background-light-end',
      theme,
    ),
  )

  root.style.setProperty(
    `--header-font-color`,
    checkTheme('--header-font-dark', '--header-font-light', theme),
  )

  root.style.setProperty(
    `--main-font-color`,
    checkTheme('--main-font-dark', '--main-font-light', theme),
  )

  root.style.setProperty(
    `--pin-btn-color`,
    checkTheme('--pin-btn-dark', '--pin-btn-light', theme),
  )

  root.style.setProperty(
    `--pin-background-color`,
    checkTheme('--pin-background-dark', '--pin-background-light', theme),
  )

  root.style.setProperty(
    `--pin-border-color`,
    checkTheme('--pin-border-dark', '--pin-border-light', theme),
  )

  root.style.setProperty(
    `--border-default`,
    checkTheme('--dark-border', '--light-border', theme),
  )

  root.style.setProperty(
    `--cash-border`,
    checkTheme('--cash-border-dark', '--cash-border-light', theme),
  )

  root.style.setProperty(
    `--main-border`,
    checkTheme('--main-border-dark', '--main-border-light', theme),
  )

  root.style.setProperty(
    `--diagramma-font-default`,
    checkTheme('--diagramma-font-dark', '--diagramma-font-light', theme),
  )

  root.style.setProperty(
    `--orange-text-default`,
    checkTheme('--orange-text-dark', '--orange-text-light', theme),
  )

  root.style.setProperty(
    `--diagramma-background-default`,
    checkTheme(
      '--diagramma-background-dark',
      '--diagramma-background-light',
      theme,
    ),
  )

  root.style.setProperty(
    `--text-other-default`,
    checkTheme('--text-other-dark', '--text-other-light', theme),
  )
}
