import * as yup from "yup";


export const amoSchema = yup
    .object({
        integration: yup.object({
            id: yup.number(),
            relationships: yup.array().of(
                yup.object({
                    user_id: yup.number(),
                    name: yup.string(),
                    // inner_phone: yup.string().optional(),
                })
            ),
            domain: yup.string(),
            fs_domain: yup.string(),
            client_id: yup.string(),
            access_token: yup.string(),
            is_active: yup.bool(),
            external_num: yup.string(),
            default_num: yup.string(),
            add_at: yup.string(),
            upd_at: yup.string(),
            owner: yup.number(),
        })
    })
    .required()

export const amoTemplate = {
    id: -1,
    relationships: [
        {
            user_id: 0,
            name: "",
            inner_phone: "",
        }
    ],
}


export const bitrixSchema = yup
    .object({
        domain: yup.string().required(),
        integration: yup.object({
            domain: yup.string().required(),
            disk_support: yup.bool(),
            disk_day_save: yup.number().required(),
            is_webhook: yup.bool().required(),
            webhook_url: yup.string().default('').nullable(),
            all_reg_lead_type: yup.array(),
            all_entity_type: yup.array(),
            all_line: yup.array().of(
                yup.object({
                    phone_external: yup.string(),
                    phone_internal: yup.string(),
                    smart_forwarding: yup.bool(),
                    show_card: yup.bool(),
                    create_lead: yup.bool(),
                    reg_lead_type: yup.number(),
                    change_manager: yup.bool(),
                    entity_type: yup.number(),
                    reg_cold_lead: yup.bool(),
                    cold_call_number: yup.string(),
                })
            )
        })
    })
    .required()


export const megaplanSchema = yup
    .object({
        domain: yup.string().required(),
        integration: yup.object({
            domain: yup.string().required(),
            default_phone: yup.number().required(),
            smart_forwarding: yup.bool().required(),
            api_key: yup.string().required().min(10),
        })
    })
    .required()


export const s2Schema = yup
    .object({
        domain: yup.string().required(),
        integration: yup.object({
            event_domain: yup.string().required(),
            default_phone: yup.number().required(),
            smart_forwarding: yup.bool().required(),
            instance_uuid: yup.string(),
            api_key: yup.string().required().min(10),
        })
    })
    .required()
