import React, {useEffect, useState} from 'react'
import s from "./LoginMain.module.scss"
import {useTheme} from "../../../../hooks/useTheme";
import {getEsiaUrl, getSberUrl} from "../../../../api";

import {generatePassword} from "../../../../sortFunction";
import {MessageForm} from "../../SupportPage/Support";
import ModalMessage from "../../../../ui/Popup/ModalMessage";
import {Link, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";



const RegisterButton = (props) => {
    return(
        !props.children ?
            <div className={`${s.buttonSubmit} ${props.color}`} onClick={props.click}>
                {props.text && !props.child ? props.text : "Зарегистрироваться"}
            </div> :
            <div className={`${s.buttonSubmit} ${props.color}`}>
                {props.children}
            </div>
    )
}

const LinkButton = (props) => {
    return(
        <a href={props.href} className={`${s.buttonSubmit} ${props.color} ${s.aButton}`}>
            {props.text && !props.child ? props.text : "Зарегистрироваться"}
        </a>
    )
}

const AuthBlock = (props) => {
    const theme = useTheme()
    const [searchParams, ] = useSearchParams();

    const schema = yup
        .object({
            login: yup.string().required().min(1).max(7),
            password: yup.string().required().min(5).max(30),
            domain: yup.string().min(0).max(7),
        }).required()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            login: "",
            password: "",
            domain: "",
        }
    })

    const formSubmit = (data) => {
        if (searchParams.get("redirect")) {
            let redirect = searchParams.get("redirect")
            props.setRedirectTo(redirect)
        }
        theme.loginFunc({login: data.login, password: data.password})
    }

    useEffect(() => {
        if (searchParams.get("redirect")) {
            let redirect = searchParams.get("redirect")
            props.setRedirectTo(redirect)
        }

        if (searchParams.get("login") && searchParams.get("token")) {
            theme.tokenLoginFunc({token: searchParams.get("token"), login: searchParams.get("login")})
        }

        if (searchParams.get("status") && searchParams.get("status") === "success" && searchParams.get("email")) {
            console.log(searchParams.get("email"))
            theme.setActivePopupGlobal("successRegister")
        }

        if (searchParams.get("error")) {
            console.log(searchParams.get("error"))
            theme.setActivePopupGlobal("failRegister")
        }
    }, [searchParams])

    return (
        <div className={s.authBlock}>
            <div className={s.label}>Авторизация</div>
            <form onSubmit={handleSubmit(formSubmit)}>
                <div className={s.inputBox}>
                    <input type="text" name="login" placeholder="Логин"
                           className={
                               errors.login && s.error
                           }
                           {...register('login')}
                    />
                    <input type="password" name="pass" placeholder="Пароль"
                           className={
                               errors.password && s.error
                           }
                           {...register('password')}
                    />
                </div>


                <input type="submit" value="Вход" className={s.green} />
                <RegisterButton text={"Вход по внутреннему номеру"} click={() => props.setRegButtons("innerAuth")}
                                color={s.orange}/>
                <LinkButton text={"Регистрация"} href={"https://cabinet.telezon.ru/register/"} color={s.orange}/>
                {/*<input className={`${s.sberButton} ${s.hiddenButton}`} onClick={() => setInnerNumber(true)}*/}
                {/*       type="submit" value="по внутреннему номеру"/>*/}
            </form>
        </div>
    )
}


const NumberAuthBlock = (props) => {
    const theme = useTheme()
    const [searchParams, ] = useSearchParams();

    const schema = yup
        .object({
            login: yup.string().required().min(1).max(7),
            password: yup.string().required().min(5).max(30),
        }).required()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            login: "",
            password: "",
        }
    })

    const formSubmit = (data) => {
        if (searchParams.get("redirect")) {
            let redirect = searchParams.get("redirect")
            props.setRedirectTo(redirect)
        }

        theme.innerNumbersLogin({login: data.login, password: data.password})
    }

    return (
        <div className={s.authBlock}>
            <div className={s.label}>Авторизация по внутреннему номеру</div>
            <form onSubmit={handleSubmit(formSubmit)}>

                <div className={s.inputBox}>
                    <input type="text" name="login" placeholder="Внутренний номер"
                           className={
                               errors.login && s.error
                           }
                           {...register('login')}
                    />
                    <input type="password" name="pass" placeholder="Пароль"
                           className={
                               errors.password && s.error
                           }
                           {...register('password')}
                    />
                </div>

                {/*<input type="text" name="domain" placeholder="domain"*/}
                {/*       onChange={(event) => handleChange(event)}/>*/}


                <input type="submit" value="Вход" className={s.green} />
                <RegisterButton text={"Назад"} click={() => props.setRegButtons("")} color={s.orange}/>
            </form>
        </div>
    )
}

const SberButton = (props) => {
    const [showLoader, setShowLoader] = useState(false)

    return(
        <a href={props.url} onClick={() => setShowLoader(true)}>
            <div className="sber-button margin-center mar-bot-10">
                <svg width="26" height="45" viewBox="0 0 26 26"
                     xmlns="http://www.w3.org/2000/svg"
                     className={showLoader ? "invisible" : "activate-button"}>
                    <g>
                        <path d="M6.96777 8.9283V12.5088L11.7577 15.4839L23.2258
                                        7.12439C22.8212 6.24266 22.3142 5.41064 21.7152 4.64514L11.7577
                                        11.9034L6.96777 8.9283Z"/>
                        <path d="M21.2254 11.7457C21.2254 11.8276 21.2254 11.9125 21.2254
                                        11.9944C21.2286 14.0578 20.5407 16.0628 19.2713 17.6895C18.002
                                        19.3162 16.2244 20.471 14.2222 20.9695C12.2199 21.468 10.1084
                                        21.2815 8.22453 20.4398C6.34064 19.5982 4.79292 18.1498 3.8283
                                        16.3258C2.86369 14.5018 2.53777 12.4073 2.90253 10.3764C3.2673
                                        8.34556 4.30174 6.49538 5.8408 5.12107C7.37987 3.74677 9.33488
                                        2.92752 11.3939 2.79405C13.453 2.66058 15.4974 3.22058 17.201
                                        4.38469L19.5424 2.66664C17.4255 0.955872 14.7898 0.0156144
                                        12.068 0.000192763C9.34622 -0.0152289 6.70005 0.895101 4.56382
                                        2.58177C2.42759 4.26844 0.928214 6.63123 0.311798 9.28233C-0.304618
                                        11.9334 -0.0014541 14.7153 1.17153 17.1714C2.34451 19.6276 4.31762
                                        21.6119 6.76701 22.7989C9.2164 23.9858 11.9965 24.3048 14.6511
                                        23.7035C17.3057 23.1022 19.677 21.6163 21.3758 19.4897C23.0746
                                        17.3631 23.9999 14.7221 24 12.0003C23.9997 11.2789 23.9351
                                        10.559 23.8068 9.84907L21.2254 11.7457Z"/>
                    </g>
                </svg>
                <div className={showLoader ? "loading-spinner" : "invisible"} />
                <span className={showLoader ? "invisible" : "activate-button"}>
            Войти по СберБизнес ID
          </span>
            </div>
        </a>
    )
}

const EsiaButton = (props) => {
    return (
        <a href={props.url}>
            <div className="sber-button margin-center mar-bot-10">
                <div className={s.esiaIdIco}/>
            </div>
        </a>
    )
}

const RegisterByNumberButton = (props) => {
    return (
        <a href={props.url}>
            <div className="sber-button margin-center mar-bot-10">
                <span>По номеру телефона</span>
            </div>
        </a>
    )
}

const RegisterBlock = (props) => {
    const theme = useTheme()
    const [sberUrl, setSberUrl] = useState("")
    const [esiaUrl, setEsiaUrl] = useState("")

    const [searchParams, ] = useSearchParams();

    useEffect(() => {
        let redirect = ""
        if (searchParams.get("redirect")) {
            redirect = searchParams.get("redirect")
        }

        // Ссылка esia
        if (esiaUrl.length === 0) {
            let pass = generatePassword()
            getEsiaUrl({pass: pass, redirect: redirect})
                .then((r) => setEsiaUrl(r["url"]))
        }

        // Ссылка SberID
        if (sberUrl.length === 0) {
            let pass = generatePassword()
            getSberUrl({pass: pass, redirect: redirect})
                .then((r) => setSberUrl(r["url"]))
        }

    }, [])

    return (
        <div className={s.authBlock}>
            <div className={s.label}>Регистрация</div>
            <div style={{margin: "10px 0"}}>
                <div className={`${s.inputBox} ${s.marginTop10}`}>
                    <SberButton url={sberUrl}/>
                    <EsiaButton url={esiaUrl} />
                    {/*<RegisterByNumberButton url={"/register"} />*/}
                </div>

                <RegisterButton text={"Назад"} click={() => props.setRegButtons("")} color={s.orange}/>
            </div>
        </div>
    )
}

export const Footer = () => {
    const theme = useTheme()

    return (
        <div className={s.footer}>
            <div className={s.offertsText}>
                Нажимая кнопку, вы принимаете&nbsp;
                <Link to={"/documents/asd01msbafv34-234mdgi549asRFT-d12.doc"} target="_blank" download>
                    публичную оферту&nbsp;
                </Link>
                и&nbsp;
                <Link to={"/documents/privacy.pdf"} target="_blank" download>
                    условия о конфиденциальности персональной информации&nbsp;
                </Link>
            </div>

            <div className={s.helpText}
                 onClick={(v) => theme.setActivePopupGlobal("help")}>
                Нажмите, если нужна помощь
            </div>

            <a href={"Tel:+73912458888"} className={s.helpText}>Звонок в поддержку: +7 (391) 245 88 88</a>
        </div>
    )
}

const Popups = (props) => {
    const theme = useTheme()
    const [searchParams, setSearchParams] = useSearchParams();

    return(
        <div>
            <ModalMessage message={"Неверный логин или пароль"}
                          label={"Ошибка входа"}
                          show={props.activePopup === "errorLogin"}
                          hide={() => theme.setActivePopupGlobal("")}>
                Пожалуйста, проверте правильность введённых данных и повторите попытку
            </ModalMessage>

            <MessageForm show={props.activePopup === "help"}
                         hide={() => theme.setActivePopupGlobal("")}
                         label={"Ваше сообщение"} withEmail={true}
                         page={"Помощь"} />

            <ModalMessage message={"Регистрация прошла успешно. Данные для входа были отправлены на " + searchParams.get("email")}
                          label={"Успешно!"}
                          show={props.activePopup === "successRegister"}
                          hide={() => {theme.setActivePopupGlobal(""); setSearchParams("")}}/>

            <ModalMessage message={"Регистрация не удалась. Попробуйте ещё раз или обрататитесь в техническую поддержку"}
                          label={"Возникла ошибка"}
                          show={props.activePopup === "failRegister"}
                          hide={() => {theme.setActivePopupGlobal(""); setSearchParams("")}}/>
        </div>
    )
}

const LoginMain = ({setRedirectTo}) => {
    const theme = useTheme()
    const [activePopup, setPopupTarget] = useState("")
    const [regButtons, setRegButtons] = useState("")

    useEffect(() => {
        setPopupTarget(theme.activePopupGlobal)
    }, [theme.activePopupGlobal])

    return (
        <div className={s.loginPage}>
            <div className={s.mainBlock}>
                <div className={s.logo}/>

                {regButtons === "" &&
                <AuthBlock setRegButtons={setRegButtons} setRedirectTo={setRedirectTo}/>
                }

                {/*{regButtons === "registration" &&*/}
                {/*<RegisterBlock setRegButtons={setRegButtons} setRedirectTo={setRedirectTo}/>*/}
                {/*}*/}

                {regButtons === "innerAuth" &&
                <NumberAuthBlock setRegButtons={setRegButtons} setRedirectTo={setRedirectTo}/>
                }

                <Footer />
            </div>

            <Popups activePopup={activePopup} />
        </div>
    )
}

export default LoginMain
