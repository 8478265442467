import React, {useEffect, useState} from "react"
import s from "./Settings.module.scss"
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {settingsSchema, textSchema} from "../schemas";
import {WebSitesButton, WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";
import CheckBox from "../../../../ui/CheckBox/CheckBox";
import {useTheme} from "../../../../hooks/useTheme";
import {createIvrText, createSettings, getIvrText, updateIvrText, updateSettings} from "../api";
import {Player, TtsConfig} from "../../../../ui/AudioGenerate/TextAreaAudioBlock";
import {DefaultButtonSmall} from "../../../../ui/Button/Button";
import {moodsTemplate, voicesTemplate} from "../../RobotPage/variables";
import {generateTts} from "../../RobotPage/api";
import {NoOptionsMessage, useSelectStyles} from "../../../../ui/react-select-styles";
import Select from "react-select";
import {sleep} from "../../../../sortFunction";

const CheckBoxField = ({name, label, control}) => {
    return(
        <div className={s.formBlock}>
            <p className={s.blockTitle} style={{fontWeight: "bold"}}>{label || ""}</p>
            <div style={{display: "inline-block"}}>
                <Controller
                    control={control}
                    values={[true, false]}
                    name={name}
                    rules={{required: true}}
                    render={({field: {onChange, value}}) => (
                        <CheckBox styleParams="inline"
                                  checked={value}
                                  chStatus={(r) => {onChange(r);}}
                        />
                    )}
                />
            </div>
        </div>
    )
}

const SelectField = ({name, label, control, help, noData, options, errors}) => {
    const [mnuIsOpen, setMenuIsOpen] = useState(false)
    const customStyles = useSelectStyles()
    const [error, setError] = useState(false)


    useEffect(() => {
        setError(true)
        sleep(2000).then(() => setError(false))
    }, [errors])

    return(
        <div className={s.formBlock}
             onClick={(e) => {
                 e.stopPropagation()
                 setMenuIsOpen(!mnuIsOpen)
             }}
        >
            <p className={s.blockTitle} style={{fontWeight: "bold"}}>{label || ""}</p>
            <div style={{display: "inline-block"}} className={
                (errors?.[name] && error) ? s.shake : "normal"
            }>
                <Controller
                    control={control}
                    values={[true, false]}
                    name={name}
                    rules={{required: true}}
                    render={({field: {onChange, value}}) => (
                        <Select
                            onChange={(e) => onChange(e?.value)}
                            options={options}
                            styles={customStyles}
                            isClearable
                            isSearchable
                            hideSelectedOptions
                            value={options.find(e => e?.value === value)}
                            placeholder={help || ""}
                            components={{NoOptionsMessage}}
                            noOptionsText={noData || ""}

                            menuIsOpen={mnuIsOpen}
                        />
                    )}
                />
            </div>
        </div>
    )
}


const MainSettings = (props) => {
    const {userAccount, setActivePopupGlobal, phoneNumers} = useTheme()
    const [active, setActive] = useState(false)
    const [saveActions, setSaveActions] = useState(false)

    const {handleSubmit, register, control, reset, formState: {errors}} = useForm({
        resolver: yupResolver(settingsSchema),
        mode: 'onBlur',
        defaultValues: {
            uuid: "",
            is_enabled: false,
            download_all_data: false,
            always: false,
            external_num: 0,
            start: 15,
            stop: 20,
        }
    })

    useEffect(() => {
        setSaveActions(false)
        if (!props?.settingsData?.uuid) return
        reset(props.settingsData)

        setActive(props.settingsData?.is_enabled)
    }, [props?.settingsData])

    const saveData = (data) => {
        const uuid = data?.uuid

        const success = (r) => {
            setSaveActions(false)

            setActivePopupGlobal("successSave")
            if (r?.length === 0) return
            props.reload()
        }

        const fail = (e) => {
            console.log(e?.response?.data)
            setSaveActions(false)
            setActivePopupGlobal("failSave")
        }

        setSaveActions(true)
        if (uuid?.length > 0) {
            updateSettings({payload: data, auth: userAccount})
                .then((r) => success(r))
                .catch((r) => fail(r))
        } else {
            createSettings({payload: data, auth: userAccount})
                .then((r) => success(r))
                .catch((r) => fail(r))
        }

    }

    const turnOff = () => {
        let data;
        setSaveActions(true)
        if (props?.settingsData?.uuid) {
            data = {
                ...props.settingsData,
                show_all_data: false,
                download_all_data: false,
                is_enabled: false,
                weekdays: false,
                weekends: false,
                always: false,
                external_num: 0,
                start: 15,
                stop: 20,
            }
            updateSettings({payload: data, auth: userAccount})
                .then(() => props.reload())
                .catch((r) => console.log(r))
        } else {
            data = {
                show_all_data: false,
                download_all_data: false,
                is_enabled: false,
                weekdays: false,
                weekends: false,
                always: false,
                external_num: 0,
                start: 15,
                stop: 20,
            }
            createSettings({payload: data, auth: userAccount})
                .then(() => props.reload())
                .catch((r) => console.log(r))
        }
    }
    const turnOn = () => {
        let data;
        setSaveActions(true)
        if (props?.settingsData?.uuid) {
            data = {
                ...props.settingsData,
                show_all_data: true,
                download_all_data: true,
                is_enabled: true,
                weekdays: true,
                weekends: true,
                always: true,
                external_num: 0,
                start: 15,
                stop: 20,
            }
            updateSettings({payload: data, auth: userAccount})
                .then(() => props.reload())
                .catch((r) => console.log(r))
        } else {
            data = {
                "show_all_data": true,
                "download_all_data": true,
                is_enabled: true
            }
            createSettings({payload: data, auth: userAccount})
                .then(() => props.reload())
                .catch((r) => console.log(r))
        }

    }

    useEffect(() => {
        if (Object.keys(errors)?.length === 0) return
        console.log(errors)
    }, [errors])

    return(
        <form onSubmit={handleSubmit(saveData)} className={s.formSmall}>
            {active &&
            <div className={`${s.serviceStatus} ${s.activeService}`}>
                <span>
                    Сервис активен
                </span>
                {saveActions
                    ? <WebSitesDivButton text={"Отключаю"} color={"noactive"} animated={false}/>
                    : <WebSitesDivButton text={"Отключить"} clickEvent={turnOff}/>
                }

            </div>
            }

            {!active &&
            <div className={`${s.serviceStatus} ${s.noActiveService}`}>
                <span>
                    Сервис неактивен
                </span>
                {saveActions
                    ? <WebSitesDivButton text={"Включаю"} color={"noactive"} animated={false}/>
                    : <WebSitesDivButton text={"Включить"} color={"green"} clickEvent={turnOn}/>
                }

            </div>
            }

            {active &&
            <div style={{height: "inherit"}}>
                <SelectField label={"Внешний номер для приёма показаний"} noData={"Нет номеров"} options={phoneNumers}
                             name={"external_num"} control={control} help={"Выбрать"} errors={errors}/>
                <CheckBoxField label={"Отображать все данные"} name={"show_all_data"} control={control} />
                <CheckBoxField label={"Выгружать все данные"} name={"download_all_data"} control={control} />
                <CheckBoxField label={"Принимать показания всегда"} name={"always"} control={control} />

                <Controller
                    control={control}
                    values={[true, false]}
                    name={"always"}
                    rules={{required: true}}
                    render={({field: {value}}) => (
                        !value &&
                        <div className={s.formBlock}>
                            <p style={{fontWeight: "bold", width: "230px"}}>Период приёма показний (дни)</p>
                            <div className={s.diap}>
                                <label>
                                    <span>c</span>
                                    <input {...register("start")}/>
                                </label>
                                <label>
                                    <span>по</span>
                                    <input {...register("stop")}/>
                                </label>
                                число
                            </div>
                        </div>
                    )}
                />

                {saveActions
                    ? <WebSitesDivButton text={"Обновляю"} color={"noactive"} animated={false}/>
                    : <WebSitesButton text={"Сохранить"} />
                }

            </div>
            }
        </form>
    )
}

const IvrConfig = ({voice, setVoice, mood, setMood, voiceSpeed, setVoiceSpeed, generate, audio, text}) => {
    const [start, setStart] = useState(false)

    useEffect(() => {
        if (audio?.length > 0) setStart(false)
    }, [audio])

    return(
        <div className={s.ivrConfig}>
            {audio?.length > 0 && <Player file={audio}/>}
            <TtsConfig voice={voice} setVoice={setVoice}
                       mood={mood} setMood={setMood}
                       voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
            />

            {(text?.length > 10 && !start && generate) &&
            <WebSitesDivButton text={"Прослушать"} clickEvent={() => {
                generate()
                setStart(true)
            }}/>
            }

            {(text?.length < 10 && !start && generate) &&
            <WebSitesDivButton text={"Текст недостаточной длины"} color={"noactive"} animated={false}/>
            }

            {start && generate &&
            <WebSitesDivButton text={"Формирую аудиозапись"} color={"noactive"} animated={false}/>
            }

            {text?.length < 10 && generate &&
                <span className={s.helpText}>Длина текста должна быть не менее 10 символов</span>
            }
        </div>
    )
}

const IvrTextArea = ({activeConfig, setActiveConfig, name, label, generateTts, register, watch,
                         audioFile, voice, setVoice, mood, setMood, voiceSpeed, setVoiceSpeed}) => {
    const watchField = watch(name);

    return(
        <div className={s.textArea} >
            <label className={s.labelWButton} onClick={() => setActiveConfig(activeConfig === name  ? "" : name)}>
                <span>{label}</span>
                <DefaultButtonSmall text={"Настроить"}
                                    clickEvent={() =>
                                        setActiveConfig(activeConfig === name  ? "" : name)
                                    }/>
            </label>

            {activeConfig === name &&
            <>
                <textarea {...register(name)}/>
                <IvrConfig generate={() => generateTts(watchField)} audio={audioFile}
                           voice={voice} setVoice={setVoice}
                           mood={mood} setMood={setMood} text={watchField}
                           voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />
            </>
            }
        </div>
    )
}

const IvrSettings = () => {
    const {userAccount, setActivePopupGlobal, setGlobalError} = useTheme()
    const [savingAction, setSavingAction] = useState(false)
    const [voice, setVoice] = useState({...voicesTemplate[0]})
    const [mood, setMood] = useState({...moodsTemplate[0]})
    const [voiceSpeed, setVoiceSpeed] = useState(1.0)
    const [audioFile, setAudioFile] = useState("")
    const [activeConfig, setActiveConfig] = useState("")

    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
        resolver: yupResolver(textSchema),
        mode: 'onBlur',
        defaultValues: {
            main_text: "",
            finish_text: "",
            error_meter_readings_text: "",
            get_id_text: "",
            not_found_id_text: "",
            not_work_text: "",
            lexicon_good_answer: ""
        }
    })

    useEffect(() => {
        if (!userAccount?.login) return

        const success = (r) => {
            setSavingAction(false)
            if (r?.data?.length === 0) return
            // console.log(r?.data?.[0])
            reset(r?.data?.[0])
        }

        const fail = (e) => {
            console.log(e)
            setSavingAction(false)
        }

        setSavingAction(true)
        getIvrText({...userAccount})
            .then(r => success(r))
            .catch(r => fail(r))
    }, [userAccount])

    const saveData = (data) => {
        setSavingAction(true)

        let payload = {
            ...data,
            ivr_emotion: mood?.value,
            ivr_voice: voice?.value,
            ivr_speed: voiceSpeed
        }

        const success = (r) => {
            setSavingAction(false)
            setActivePopupGlobal("successSave")
        }

        const fail = (e) => {
            console.log(e?.response?.data)
            setSavingAction(false)
            setActivePopupGlobal("failSave")
        }

        if (!payload?.uuid) {
            createIvrText({payload: payload, auth: userAccount})
                .then(r => success(r))
                .catch(r => fail(r))
        } else {
            updateIvrText({payload: payload, auth: userAccount})
                .then(r => success(r))
                .catch(r => fail(r))
        }
    }

    const generate = (text) => {
        if (text.length < 10 || !voice.value || !mood.value || !voiceSpeed || !userAccount?.login) {
            setActivePopupGlobal("oups")
            return
        }
        let payload = {
            "pin": userAccount?.login || 0,
            "text": text,
            "voice": voice.value,
            "emotion": mood.value,
            "speed": voiceSpeed
        }

        const failGenerate = (r) => {
            console.log(r)
            setActivePopupGlobal("oups")
            setSavingAction(false)
        }

        setSavingAction(true)
        generateTts({...userAccount, payload: payload})
            .then(r => {
                r?.url ? setAudioFile(r?.url) : failGenerate(r)
                setSavingAction(false)
            })
            .catch(e => failGenerate(`generateTts fail: ${e.data}`))
    }

    useEffect(() => {
        if (Object.keys(errors)?.length === 0) return
        // console.log(errors)
        setGlobalError([...Object.values(errors).map(e => e?.message)])
    }, [errors])

    useEffect(() => {
        setAudioFile("")
    }, [activeConfig])

    return(
        <div className={`${s.formSmall}`} >

            <form onSubmit={handleSubmit(saveData)} className={`btm-marg-24-child ${s.flexBlocks}`}>
                <div>
                    <span>Настройки озвучивания IVR</span>
                    <IvrConfig voice={voice} setVoice={setVoice}
                               mood={mood} setMood={setMood}
                               voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                    />

                </div>

                <IvrTextArea register={register} name={"main_text"} label={"Главное приветствие IVR"}
                    activeConfig={activeConfig} setActiveConfig={setActiveConfig}
                    generateTts={generate} watch={watch}
                    audioFile={audioFile} voice={voice} setVoice={setVoice}
                    mood={mood} setMood={setMood} voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />
                <IvrTextArea register={register} name={"finish_text"} label={"Финальный текст IVR (опционально)"}
                             activeConfig={activeConfig} setActiveConfig={setActiveConfig}
                             generateTts={generate} watch={watch}
                             audioFile={audioFile} voice={voice} setVoice={setVoice}
                             mood={mood} setMood={setMood} voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />
                <IvrTextArea register={register} name={"error_meter_readings_text"} label={"Текст ошибки распознания показаний"}
                             activeConfig={activeConfig} setActiveConfig={setActiveConfig}
                             generateTts={generate} watch={watch}
                             audioFile={audioFile} voice={voice} setVoice={setVoice}
                             mood={mood} setMood={setMood} voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />
                <IvrTextArea register={register} name={"get_id_text"} label={"Текст запроса лицевого счёта"}
                             activeConfig={activeConfig} setActiveConfig={setActiveConfig}
                             generateTts={generate} watch={watch}
                             audioFile={audioFile} voice={voice} setVoice={setVoice}
                             mood={mood} setMood={setMood} voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />
                <IvrTextArea register={register} name={"not_found_id_text"} label={"Текст ошибки распознания лицевого счёта"}
                             activeConfig={activeConfig} setActiveConfig={setActiveConfig}
                             generateTts={generate} watch={watch}
                             audioFile={audioFile} voice={voice} setVoice={setVoice}
                             mood={mood} setMood={setMood} voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />
                <IvrTextArea register={register} name={"not_work_text"} label={"Текст, если показания не принимаются"}
                             activeConfig={activeConfig} setActiveConfig={setActiveConfig}
                             generateTts={generate} watch={watch}
                             audioFile={audioFile} voice={voice} setVoice={setVoice}
                             mood={mood} setMood={setMood} voiceSpeed={voiceSpeed} setVoiceSpeed={setVoiceSpeed}
                />

                <div className={`${s.textArea} ${s.toBottom}`}>
                    <label className={s.labelWButton} onClick={() =>
                        setActiveConfig(activeConfig === "lexicon_good_answer"
                            ? "" : "lexicon_good_answer")}>
                        <span>Список слов согласия (через запятую)</span>
                        <DefaultButtonSmall text={"Настроить"}
                                            clickEvent={() =>
                                                setActiveConfig(activeConfig === "lexicon_good_answer"
                                                    ? "" : "lexicon_good_answer")
                                            }/>
                    </label>

                    {activeConfig === "lexicon_good_answer" &&
                    <textarea {...register("lexicon_good_answer")}/>
                    }
                </div>

                {savingAction
                    ?  <WebSitesDivButton text={"Обновляю"} color={"noactive"} animated={false}/>
                    :  <WebSitesButton text={"Сохранить"} />
                }
            </form>
        </div>
    )
}

export const Settings = (props) => {
    const [activeService, setActiveService] = useState(false)

    useEffect(() => {
        if (!props?.settingsData) return
        setActiveService(props.settingsData?.is_enabled)
    }, [props?.settingsData])

    return(
        <div className={s.mainBlock}>
            <DefaultTitle title={"Настройки системы"} />

            <div className={s.flexBlocks}>
                <MainSettings {...props}/>
                {activeService && <IvrSettings />}
            </div>
        </div>
    )
}