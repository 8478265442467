import axios from "axios";
import {robotsUrl} from "./variables";
import {backendUrl} from "../../../provider/variables";

export const getRobots = async (info) => {
    const url = `${robotsUrl}robot/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getRobots fail", error))
}

export const updateRobot = async (info) => {
    const url = `${robotsUrl}robot/${info?.robotUuid}/`
    console.log(url)
    // return

    return await axios
        .patch(url, info?.payload, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getRobots fail", error))
}


export const createRobot = async (info) => {
    const url = `${robotsUrl}robot/`

    return await axios
        .post(url, info?.payload, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getRobots fail", error))
}


export const deleteRobot = async (info) => {
    const url = `${robotsUrl}robot/${info?.robotUuid}/`
    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getRobots fail", error))
}

export const getBlockData = async (info) => {
    const url = `${robotsUrl}robot/${info.robotUuid}/block/${info.uuid}/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getBlockData fail", error))
}

export const saveBlocksData = async (info) => {
    const url = `${robotsUrl}robot/${info.robotUuid}/block/${info.uuid}/`

    return await axios
        .put(url, info?.payload, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const createBlocksData = async (info) => {
    const url = `${robotsUrl}robot/${info.robotUuid}/block/`
    console.log(url)
    console.log(info?.payload)

    return await axios
        .post(url, info?.payload, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const createBlock = async (props) => {
    const url = `${robotsUrl}robot/${props.robotUuid}/block/`

    return await axios
        .post(url, props?.payload, {
            headers: {
                Authorization: `Token ${props?.login} ${props?.token}`,
                'Content-Type': 'application/json',
                pin: props.login,
                domain: props?.domain,
            },
        })
        .then((response) => response.data)
}

export const deleteBlocksData = async (info) => {
    const url = `${robotsUrl}robot/${info.robotUuid}/block/${info.uuid}/`

    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const getRobotsBlocks = async (info) => {
    const url = `${robotsUrl}robot/${info.robotUuid}/block/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getBlockData fail", error))
}

export const getActions = async (info) => {
    const url = `${robotsUrl}action/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getRobots fail", error))
}

export const getLexicon = async (info) => {
    const url = `${robotsUrl}lexicon/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info?.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log("getRobots fail", error))
}

export const saveLexicon = async (info) => {
    const url = `${robotsUrl}lexicon/${info.lexiconUuid}/`
    return await axios
        .put(url, info?.payload, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const deleteLexicon = async (info) => {
    const url = `${robotsUrl}lexicon/${info.lexiconUuid}/`
    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const createLexicon = async (info) => {
    const url = `${robotsUrl}lexicon/`
    return await axios
        .put(url, info?.payload, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const getBlocksAudio = async (info) => {
    const url = `${robotsUrl}robot/${info.robotUuid}/block/${info.blockUuid}/audio/`
    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const generateTts = async (info) => {
    const url = `${backendUrl}/lost_calls/tts/generate/`

    return await axios
        .post(url, {...info.payload}, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const addAudioToBLock = async (info) => {
    // const url = `${robotsUrl}/robot/${info.robotUuid}/block/${info.blockUuid}/audio/`
    // const url = `http://192.168.3.144:8009/robot/${info.robotUuid}/block/${info.blockUuid}/audio/`
    const url = `${robotsUrl}/robot/${info.robotUuid}/block/${info.blockUuid}/audio/`
    return await axios
        .post(url, info?.formData, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                pin: info.login,
                domain: info?.domain,
            },
        })
        .then((response) => response.data)
}

export const audioToBlob = async ({url}) => {
     return await axios({
        method: 'get',
        url: url,
        responseType: 'blob'
    }).then((response) => {
        return response;
    })
}
