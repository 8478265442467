import React from 'react'
import s from './Sidebar.module.scss'
import { useTheme } from '../../hooks/useTheme'
import MenuBar from '../MenuBlock/MenuBar/MenuBar'
import SettingsBar from '../MenuBlock/SettingsBar/SettingsBar'

const Sidebar = () => {
  const { menu, settingsMenu } = useTheme()

  return (
      <div className={s.slider}>
          <SettingsBar settings={settingsMenu} />
          <MenuBar menu={menu} />
      </div>
  )
}

export default Sidebar
