import React from 'react'
import './style.css'
import {useTheme} from "../../hooks/useTheme";

const SvgPicture = ({ stPercent, id }) => {
    const {theme} = useTheme()

  return (
    <div>
      <svg
        className="CircularProgressbar DiagramSlider_svgElement__2NnLy"
        viewBox="0 0 100 100"
        data-test-id="CircularProgressbar"
      >
        <linearGradient id={`linear-gradient${id}`}>
          <stop offset={`25%`} stopColor="#B01314" />
          <stop offset={`100%`} stopColor="#FE4F1A" />
        </linearGradient>

        <path
          className="CircularProgressbar-trail"
          d="
          M 50,50
          m 0,-45
          a 45,45 0 1 1 0,90
          a 45,45 0 1 1 0,-90
        "
          strokeWidth="10"
          fillOpacity="0"
          style={{
            stroke: theme !== 'light' ? 'var(--diagramma-background-default)' : 'var(--main-font-color)',
            strokeLinecap: 'butt',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
            strokeDasharray: '289.027px, 289.027px',
            strokeDashoffset: '0px',
          }}
        ></path>
        <path
          className="CircularProgressbar-path"
          d="
          M 50,50
          m 0,-45
          a 45,45 0 1 1 0,90
          a 45,45 0 1 1 0,-90
        "
          strokeWidth="10"
          fillOpacity="0"
          style={{
            stroke: `url(#linear-gradient${id})`,
            strokeLinecap: 'butt',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
            strokeDasharray: `${stPercent * 2.82 + 217 + '%'}, 289.027px`,
            strokeDashoffset: '216.77px',
          }}
        ></path>
      </svg>
    </div>
  )
}

export default SvgPicture
