import React from 'react'
import s from './GlobalGifSelector.module.scss'
import {useTheme} from "../../hooks/useTheme";

export const GlobalGifSelector = ({ id, data, width }) => {
    const theme = useTheme()

    switch (id) {
        case 0:
            return (
                <img
                    width="600px"
                    alt=""
                    src={require("../../img/gifs/gotocr.gif")}
                />
            )
        case 1:
            return (
                <img
                    width="600px"
                    alt=""
                    src={require("../../img/gifs/smsSend.gif")}
                />
            )
        case 2:
            return (
                <img
                    width="600px"
                    alt=""
                    src={require("../../img/gifs/filters.gif")}
                />
            )
        case -1:
            return (
                <img
                    width="600px"
                    alt=""
                    src={data ? data : ""}
                />
            )
        case -2:
            return (
                <img
                    width={width ? width : "200px"}
                    alt=""
                    src={data ? data : ""}
                />
            )
        case "arrow-left":
            return (
                <img
                    width={width ? width : "35px"}
                    alt=""
                    src={theme.theme !== 'dark'
                        ? require("../../img/gifs/arrowLeftDark.gif")
                        : require("../../img/gifs/arrowLeft.gif")
                    }
                />
            )
        case "arrow-right":
            return (
                <img
                    width={width ? width : "35px"}
                    alt=""
                    src={theme.theme !== 'dark'
                        ? require("../../img/gifs/arrowRightDark.gif")
                        : require("../../img/gifs/arrowRight.gif")
                    }
                />
            )
        case "settings":
            return (
                <img
                    width={width ? width : "35px"}
                    alt=""
                    src={theme.theme !== 'dark'
                        ? require("../../img/gifs/settingsButtonDark.gif")
                        : require("../../img/gifs/settingsButton.gif")
                    }
                />
            )
        case "docs":
            return (
                <img
                    width={width ? width : "35px"}
                    alt=""
                    src={theme.theme !== 'dark'
                        ? require("../../img/gifs/docsButtonDark.gif")
                        : require("../../img/gifs/docsButton.gif")
                    }
                />
            )
        case "callog":
            return (
                <img
                    width={width ? width : "35px"}
                    alt=""
                    src={theme.theme !== 'dark'
                        ? require("../../img/gifs/callLogButtonDark.gif")
                        : require("../../img/gifs/callLogButton.gif")
                    }
                />
            )
        case "unlp":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/unlimitedPhButtonDark.gif")
                         : require("../../img/gifs/unlimitedPhButton.gif")
                     }
                />
            )
        case "packets":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/unlimitedPhButtonDark.gif")
                         : require("../../img/gifs/unlimitedPhButton.gif")
                     }
                />
            )
        case "vats":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/vatsButtonDark.gif")
                         : require("../../img/gifs/vatsButton.gif")
                     }
                />
            )
        case "cbc":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/autocallingButtonDark.gif")
                         : require("../../img/gifs/autocallingButton.gif")
                     }
                />
            )
        case "ddos":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/ddosButtonDark.gif")
                         : require("../../img/gifs/ddosButton.gif")
                     }
                />
            )
        case "incoming-calls":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/incommingCallsButtonDark.gif")
                         : require("../../img/gifs/incommingCallsButton.gif")
                     }
                />
            )
        case "vamzvonili":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/vzButtonDark.gif")
                         : require("../../img/gifs/vzButton.gif")
                     }
                />
            )
        case "wifi":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/wifiButton.gif")
                         : require("../../img/gifs/wifiButton.gif")
                     }
                />
            )
        case "shop":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/shoppingButtonDark.gif")
                         : require("../../img/gifs/shoppingButton.gif")
                     }
                />
            )
        case "telephony":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/phoneButtonDark.gif")
                         : require("../../img/gifs/phoneButton.gif")
                     }
                />
            )
        case "crmIntegration":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/crmIntegrationDark.gif")
                         : require("../../img/gifs/crmIntegration.gif")
                     }
                />
            )
        case "zkh":
            return (
                <img width={width ? width : "35px"}
                     alt=""
                     src={theme.theme !== 'dark'
                         ? require("../../img/gifs/zkhDark.gif")
                         : require("../../img/gifs/zkh.gif")
                     }
                />
            )
        default:
            return null
    }


}