import React, {useEffect, useState} from 'react'
import { useTheme } from '../../../../hooks/useTheme'
import s from './MainBlock.module.scss'
import Settings from './Settings/Settings'
import SvgDiagram from './SvgDiagram/SvgDiagram'
import Finances from "./Finances/Finances";
import {voipCards} from "../../../../api";
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import Cards from "../MainBlock/Cards/Cards";


const PacketVoipUsl = ({usl, cards}) => {
    const { packet, balance} = useTheme()
    const [serviceName, setServiceName] = useState("")


    useEffect(() => {
        if (!usl.usl_name || !cards || cards.length === 0) return

        const service_card = cards.find(c => usl.usl_name.includes(c.wTitle))

        let packet_size = 0
        if (usl.context && usl.context.packet_size) {
            packet_size = usl.context.packet_size.toString()
        }

        if (service_card) setServiceName([service_card.name, "("+packet_size+" мин.)"].join(" "))
    }, [usl, cards])

    return(
        <div key={usl.id_set_usl}>

            <div className={s.feature}>
                <p className={s.title}>Информация о состоянии услуги</p>

                <h1 className={s.name}>
                    <span>{serviceName}</span>
                </h1>

                { // Рисуем график, если пакет есть и он соответствует нашему тарифу
                    packet && usl["usl_name"].replaceAll("_", " ").includes(packet["name"])
                    && usl["context"]
                    && usl["context"]["packet_size"]
                    && usl["context"]["packet_size"] === parseInt(packet["exists"]) ?
                        <div className={s.diagramWrapper}>
                            <SvgDiagram packet={packet} />
                            <div className={s.infoBlock}>
                                <Finances balance={balance} packet={packet}/>
                                <Settings usl={usl}/>
                            </div>
                        </div> : null

                }
            </div>
        </div>
    )
}

const MainBlock = (props) => {
    const {currentVoipPacket, userAccount} = useTheme()
    const [cards, setCards] = useState([])

    useEffect(() => {
        if (!userAccount.login || !userAccount.token) return
        voipCards(userAccount)
            .then((r) => setCards(r))
    }, [userAccount])

    // useEffect(() => {
    //     if (!currentVoipPacket) return
    //
    //     const s = services.active_usl.find(usl => usl.usl_id && packetsVoipNumers.includes(usl.usl_id))
    //     setService(currentVoipPacket)
    //     // console.log(s)
    //
    // }, [currentVoipPacket, packetsVoipNumers])

    return (
        <div>
            <DefaultTitle title="Безграничная телефония" />

            {!currentVoipPacket &&
            <div style={{margin: "25px 0"}}>
                <DefaultTitle title="Нет подключенных услуг безграничной телефонии" />
            </div>
            }

            {currentVoipPacket &&
            <div style={{margin: "25px 0"}}>
                <PacketVoipUsl usl={currentVoipPacket} cards={cards}/>
            </div>

            }

            <div style={{borderBottom: "1px solid black"}}/>
            <div style={{margin: "25px 0"}}>
                <DefaultTitle title={!currentVoipPacket ? "Выбор тарифного плана" : "Изменение тарифного плана"} />
            </div>


            <div className={s.cards}>
                <Cards usl={currentVoipPacket} cards={cards} {...props}/>
            </div>

        </div>
    )



}

export default MainBlock
