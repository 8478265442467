import React from 'react'
import s from './Loading.module.scss'

const Loading = () => {
  return (
    <div className={`${s.ldsDefault}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loading


export const LoadingDots = () => {
    return (
        <div className={`${s.ldsDefaultSmal}`}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}
