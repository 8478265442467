// import {crmIntegrationsUrl} from "../../../provider/variables";
import axios from "axios";


const crmIntegrationsUrl = "https://api.telezon.ru/imanager/"
// const crmIntegrationsUrl = "http://127.0.0.1:8101/"


export const getCrmIntegrations = async (info) => {
    if (!info?.auth?.login || !info.domain) return {"error": "no pin"}
    let url = `${crmIntegrationsUrl}integration/?domain=${info.domain}`

    return await axios
        .get(url, {headers: {
                Authorization: `Token ${info?.auth?.login} ${info?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': info?.auth?.login
            }})
        // .get(url)
        .then((response) => response.data)
        .catch((response) => response)
}

export const deleteCrmIntegrations = async (info) => {
    if (!info?.auth?.login || !info.domain) return {"error": "no pin"}
    let url = `${crmIntegrationsUrl}integration/?domain=${info.domain}`

    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${info?.auth?.login} ${info?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': info?.auth?.login
            }})
        .then((response) => response.data)
    // .catch((response) => response.response)
}

export const saveCrmIntegrations = async (info) => {
    if (!info?.auth?.login || !info.domain) return {"error": "no pin"}
    let url = `${crmIntegrationsUrl}integration/?domain=${info.domain}`

    // let url = [crmIntegrationsUrl, "integration/?domain="].join('');
    return await axios
        .post(url, info.data, {
            headers: {
                Authorization: `Token ${info?.auth?.login} ${info?.auth?.token}`,
                'Content-Type': 'application/json',
                'pin': info?.auth?.login
            }})
        .then((response) => response.data)
        // .catch((response) => response.response)
}


export const getAmoSettings = async (info) => {
    console.error("getAmoSettings устарел")
    return
    // if (info?.domain?.length === 0) return

    let url = 'https://api.telezon.ru/crm_amo/integration/';
    // if (info.login) url = `${url}domain=${info.domain}`

    return await axios
        .get(url, {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                pin: info.login,

            },
        })
        .then((response) => response?.data)
}

export const saveAmoSettings = async (info) => {
    console.error("saveAmoSettings устарел")
    return
    // if (info?.domain?.length === 0) return

    let url = 'https://api.telezon.ru/crm_amo/integration/';
    // if (info.login) url = `${url}domain=${info.domain}`

    return await axios
        .post(url, {...info?.payload}, {
            headers: {
                Authorization: info.token,
                pin: info.login,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response?.data)
}

export const deleteAmoSettings = async (info) => {
    console.error("deleteAmoSettings устарел")
    return

    if (!info?.login) return
    let url = 'https://api.telezon.ru/crm_amo/integration/';

    return await axios
        .delete(url, {
            headers: {
                Authorization: info.token,
                pin: info.login,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response?.data)
}

export const saveAmoPhone = async (info) => {
    let url = 'https://api.telezon.ru/crm_amo/set_phone/';

    return await axios
        .post(url, {...info?.payload}, {
            headers: {
                Authorization: info.token,
                pin: info.login,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response?.data)
}
