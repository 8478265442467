import React, {useEffect, useState} from 'react'
import { Controller, useForm } from 'react-hook-form'
import s from './Form.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTheme } from '../../../../hooks/useTheme'
import Select from 'react-select'
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'

import {saveContacts, saveSettings} from "../../../../api"
import {timezones} from "../../../../provider/variables";

const schema = yup
    .object({
        organisation: yup.string().required(),
        ems: yup.string().required(),
        phone: yup.string().required(),
    })
    .required()

export const ContactsForm = ({ setActivePopup }) => {
    const theme = useTheme()
    const [currentTimezone, setCurrentTimezone] = useState(timezones[0])
    const [tzMenuIsOpen, openTzMenu] = useState(false)

    useEffect(() => {
        if (!theme.settings.timezone) return

        const tz = timezones.find(v => v.value === theme.settings.timezone)
        if (tz) setCurrentTimezone(tz)
    }, [theme.settings])

    const customStyles = {
        control: () => ({
            display: 'flex',
            textAlign: 'left',
            color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            cursor: 'pointer',
        }),
        placeholder: () => ({
            color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            opacity: '.3',
        }),
        menuList: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            position: 'absolute',
            zIndex: 1000,
            width: '100%',
            borderRadius: '10px',
            top: 'calc(100% + 12px)',
            // overflow: 'hidden',
            background:
                theme.theme === 'light' ? 'var(--night-color)' : 'var(--light-color)',
        }),
        menu: () => ({
            color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        option: () => ({
            background: 'none',
            padding: '5px',
            fontSize: '16px',
            cursor: 'pointer',

            '&:hover': {
                background: '#3a3a3a',
                color: 'var(--light-color)',
            },
        }),
        singleValue: () => ({
            color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--main-font-color)',
        }),
        input: () => ({
            display: 'none',
        }),
    }

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            organisation: "",
            ems: "",
            phone: "",
        }
    })

    const onSubmit = (data) => {
        // Сохраняем контакты
        saveContacts({
            login: theme.userAccount.login,
            token: theme.userAccount.token,
            name: data.organisation,
            emails: data.ems,
            phone: data.phone,
        })
            .then(() => setActivePopup("settingsSuccess"))
            .catch(() => setActivePopup("failSave"))

        saveSettings({
            login: theme.userAccount.login,
            token: theme.userAccount.token,
            timezone: currentTimezone.value,
        })
            .then((r) => console.log("success save timezone", r))
            .catch(e => console.log("error save timezone", e))
    }

    useEffect(() => {
            if (!theme.settings) return

            if (theme.contacts && theme.settings) {
                reset({
                    organisation: theme.contacts.name,
                    ems: theme.contacts.emails,
                    phone: theme.contacts.phone,
                })
            }

        }, [theme.settings, theme.contacts]
    )

    return (
        <>
            <div className={s.header}>
                <div className={s.title}>
                    <DefaultTitle title="Настройки и контакты" />
                </div>
            </div>

            <p className={s.subTitle}>Общая информация</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Название организации</p>
                    <input
                        type="text"
                        placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.organisation
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        {...register('organisation')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Адрес электронной почты</p>
                    <input
                        placeholder={theme.contacts ? theme.contacts["emails"] : ""}
                        defaultValue={theme.contacts ? theme.contacts["emails"] : ""}
                        type="text"
                        className={
                            errors.ems ? `${s.defaultInput} ${s.error}` : s.defaultInput
                        }
                        {...register('ems')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Контактный номер телефона</p>
                    <input
                        placeholder={theme.contacts ? theme.contacts["phone"] : ""}
                        defaultValue={theme.contacts ? theme.contacts["phone"] : ""}
                        type="tel"
                        className={
                            errors.phone ? `${s.defaultInput} ${s.error}` : s.defaultInput
                        }
                        {...register('phone')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Часовой пояс</p>
                    <div
                        className={`${s.defaultInput} ${s.noPadding}`}
                        onClick={() => {
                            openTzMenu(!tzMenuIsOpen)
                        }}
                    >
                        <Controller
                            control={control}
                            values={timezones}
                            name="timezones"
                            instanceId={2}
                            rules={{ required: true }}
                            render={({ field: { onChange } }) => (
                                <Select
                                    onChange={setCurrentTimezone}
                                    options={timezones}
                                    styles={customStyles}
                                    isClearable
                                    isSearchable
                                    hideSelectedOptions
                                    value={currentTimezone}

                                    menuIsOpen={tzMenuIsOpen}
                                />
                            )}
                        />
                    </div>
                </div>

                <button className={[s.button, s.floatR].join(" ")} style={{marginTop: "25px"}}>Сохранить</button>
            </form>
        </>
    )
}
