import React, {useEffect, useState} from 'react'
import s from './Card.module.scss'

const Card = ({data, service, selectedPacket, setSelectedPacket, activeMinutes, deletePacket, activatePacket, activatable=true}) => {
  const [price, setPrice] = useState(0)
  const [label, setLabel] = useState("")
  const [text, setText] = useState("")
  const [isCurrent, setCurrent] = useState(false)

  useEffect(() => {
    if (!data || !data.prices) return

    const price = data.prices.find(elem => elem.minutes === activeMinutes)
    if (price) setPrice(price.price)

    // Определяем, текущая ли услуга
    if (service && service.context && service.context.packet_size && service.usl_name && data.wTitle) {
      const ps = service.context.packet_size
      setCurrent(ps === activeMinutes && service.usl_name.includes(data.wTitle))
    }

  },[data, service, activeMinutes])


  useEffect(() => {
    if (!data) return
    if (data.name) setLabel(data.name)
    if (data.description) setText(data.description)

  }, [data])

  const selectPacket = () => {
    setSelectedPacket(data)
  }


  return (
    <div
      className={`${!isCurrent ? s.card : s.cardCurrent} ${selectedPacket && selectedPacket.id === data.id && s.active}`}
      onClick={selectPacket}
    >
      <div className={s.wrapper}>
        {isCurrent
            ? <p className={s.title}>{label} ({activeMinutes} минут)</p>
            : <p className={s.title}>{label}</p>
        }


        {isCurrent
            ? <div className={s.price}>
                <p className={s.text}>Активный пакет</p>
              </div>
            : <div className={s.price}>
                <p className={s.cost}>{price}</p>
                <p className={s.text}>за 30 дней</p>
              </div>

        }

      </div>
      <div>
        <div className={s.paragraph}>{text}</div>
      </div>

      {/*{activatable &&*/}
      {/*<button className={s.btn} onClick={() => deletePacket(data)} disabled={isCurrent}>Удалить</button>*/}
      {/*}*/}

      <button className={s.btn} onClick={() => activatePacket(data)}>{data.is_active ? "Запретить" : "Разрешить" }</button>

    </div>
  )
}

export default Card
