import s from "./TippyElement.module.scss";
import Tippy from "@tippyjs/react";
import React, {useState} from "react";

export const TippyElement = (props) => {
    const [localTooltip, setLocalOpen] = useState(false)
    const [isHelperOpen, setHelperOpen] = useState(false)

    return(
        <div className={s.elemBlock}>
            <Tippy
                visible={localTooltip}
                animation={'scale'}
                content={
                    <div
                        style={{
                            background: 'var(--background-gradient-st)',
                            color: 'var(--main-font-color)',
                            padding: '12px',
                            borderRadius: '10px',
                            border: '1px solid var(--border-default)',
                            boxShadow: '0 0 20px 0 var(--border-default)',
                        }}
                    >
                        {props.text &&
                        props.text
                        }

                        {props.children &&
                        props.children
                        }

                    </div>
                }
            >
                <p
                    className={s.mark}
                    onClick={() => {
                        if (document.body.clientWidth < 1250) {
                            setLocalOpen(true)
                            setHelperOpen(!isHelperOpen)
                        }
                    }}
                    onMouseOver={() => {
                        setLocalOpen(true)
                    }}
                    onMouseLeave={() => {
                        setLocalOpen(false)
                    }}
                >
                    ?
                </p>
            </Tippy>
        </div>
    )
}

export const FreeTippyElement = (props) => {
    const [localTooltip, setLocalOpen] = useState(false)
    const [isHelperOpen, setHelperOpen] = useState(false)

    return(
        <div className={s.elemBlock}>
            <Tippy
                visible={localTooltip}
                animation={'scale'}
                content={
                    <div
                        style={{
                            background: 'var(--background-gradient-st)',
                            color: 'var(--main-font-color)',
                            padding: '12px',
                            borderRadius: '10px',
                            border: '1px solid var(--border-default)',
                            boxShadow: '0 0 20px 0 var(--border-default)',
                        }}
                    >
                        {props.text && props.text}
                    </div>
                }
            >
                <div
                    style={{

                    }}
                    onClick={() => {
                        if (document.body.clientWidth < 1250) {
                            setLocalOpen(true)
                            setHelperOpen(!isHelperOpen)
                        }
                    }}
                    onMouseOver={() => {
                        setLocalOpen(true)
                    }}
                    onMouseLeave={() => {
                        setLocalOpen(false)
                    }}
                >
                    {props.children && props.children}
                </div>

            </Tippy>

        </div>
    )
}