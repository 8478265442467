import s from "./CallDialog.module.scss";
import ModalMessage from "./ModalMessage";
import React, {useEffect, useState} from "react";
import {C2CInit} from "../../api";
import {useTheme} from "../../hooks/useTheme";

export const CallDialog = (props) => {
    const {userAccount} = useTheme()

    const [srcNumber, setSrcNumber] = useState("")
    const [dstNumber, setDstNumber] = useState("")
    const [callSend, setCallSend] = useState(false)

    useEffect(() => {
        if (props.data.dst) setDstNumber(props.data.dst)
        else setDstNumber("")
        if (props.data.src) setSrcNumber(props.data.src)
        else setSrcNumber("")
        setCallSend(false)

    }, [props.data])

    const sendCallHndlr = () => {
        if (srcNumber.toString().length === 0 || dstNumber.toString().length === 0 )
            return

        setCallSend(true)
        C2CInit({
            login: userAccount.login,
            token: userAccount.token,
            src_num: srcNumber,
            dst_num: dstNumber,
        }).then((r) => {console.log(r); setCallSend(false)})
            .catch((e) => {console.log(e); setCallSend(false)})
    }

    return(
        <ModalMessage
            standartButtonText={"Закрыть"}
            show={props.show}
            hide={props.hide}>

            <div style={{display: "flex"}}>
                <table className={s.callTable}>
                    <tbody>
                    <tr>
                        <td>Внутренний номер</td>
                        <td><input className={s.numberInput} value={srcNumber}
                                   onChange={(e) => setSrcNumber(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td>Номер клиента</td>
                        <td><input className={s.numberInput} disabled={true} readOnly
                                   value={dstNumber}/></td>
                    </tr>

                    </tbody>

                </table>

                { !callSend &&
                <div className={s.bigButton} onClick={sendCallHndlr}>
                    <span>Вызов</span>
                </div>
                }

                { callSend &&
                <div className={s.bigButton} style={{backgroundColor: "green", color: "white"}}>
                    <span>Идёт вызов...</span>
                </div>
                }

                {/*{ callSuccessed === 1 &&*/}
                {/*<div className={s.bigStatusGreen}>Вызов отправлен</div>*/}
                {/*}*/}

                {/*{ callSuccessed === -1 &&*/}
                {/*<div className={s.bigStatusRed}>Ошибка отправки вызова</div>*/}
                {/*}*/}


            </div>
        </ModalMessage>
    )
}