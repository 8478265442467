import React, {useEffect, useRef, useState} from 'react'
import s from './Button.module.scss'
import {useTheme} from "../../hooks/useTheme";
import {CSVLink} from "react-csv";
import {getDashedDate} from "../../provider/dates";
import {CloudUpload, GetApp} from "@mui/icons-material";

const DefaultButton = ({ href, text, clickEvent, style, alter = null }) => {
  return (
      <a className={`${s.button} ${style && style} ${alter ? s.popupHelp : null}`} href={href} onClick={clickEvent} data-title={alter}>
        {text}
      </a>
  )
}

export const DefaultButtonSmall = ({ href, text, clickEvent, color = null, alter = null  }) => {
    const {theme} = useTheme()

    const [useColor, setUseColor] = useState()

    useEffect(() => {
        if (color === "blue") setUseColor(s.blueButton)
        if (color === "green") setUseColor(s.greenButton)
        if (color === "orange") setUseColor(null)
    }, [color])

    return (

        <a className={`${s.button} ${s.smallButton}
            ${useColor && useColor} 
            ${theme === "light" && s.lightButton} ${alter ? s.popupHelp : null}`}
           href={href} onClick={clickEvent} data-title={alter}>
            {text}
        </a>
    )
}

export const SubmitButton = ({ text, clickEvent, color }) => {
    const [useColor, setUseColor] = useState()

    useEffect(() => {
        if (color === "blue") setUseColor(s.blueButton)
        else if (color === "green") setUseColor(s.greenButton)
        else setUseColor(s.orangeButtonColor)

    }, [color])

    return (
        <input type={"submit"} value={text}
               className={`${s.button} ${useColor}`}
               onClick={clickEvent}/>
    )
}

export default DefaultButton


export const DownloadButton = (props) => {
    const [exportData, setExportData] = useState([])
    const [headers, setHeaders] = useState([])


    useEffect(() => {
        setExportData([])
        if (!props?.data?.length === 0 || props?.columns?.length === 0) return

        let keys = []
        for (const header_elem of props?.columns) {
            keys.push(header_elem?.label)
        }
        setHeaders([...keys])

        let data = []
        for (const elem of props.data) {
            let subDat = []
            for (const header_elem of props?.columns) {
                let value = ""
                if (header_elem?.data_type === "dict") {
                    value = elem[header_elem.key][header_elem.dict_key] || ""
                } else if (header_elem?.data_type === "dt") {
                    value = elem[header_elem?.key] || ""
                    if (value.includes("T")) {
                        value = getDashedDate(value)
                    }
                } else {
                    value = elem[header_elem?.key] || ""
                }

                for (const repl_group of header_elem?.repl || []) {
                    if (repl_group?.length === 2 && value.toString().length > 0) {
                        value = value.toString().replace(repl_group[0], repl_group[1])
                    }
                }

                subDat.push(value)
            }
            data.push(subDat)
        }
        setExportData([...data])

        // console.log(data)
    }, [props.data, props.columns])

    return(
        exportData.length > 0 && props.columns ?
            <CSVLink data={exportData}
                     className={s.csvLink}
                     onClick={e => e.stopPropagation()}
                     headers={headers}
                     separator=";"
                     filename={props?.file || "detail.csv"}
                     data-tooltip="Скачать в csv">
                <div
                    className={`${props.alter ? s.popupHelpDwnld : null}`}
                    style={{height: "23"}} data-title={props?.alter ? props?.alter : "Скачать" }>
                    <GetApp className={s.SvgElem}/>
                </div>
            </CSVLink>
            : ""
    )
}

export const UploadButton = ({setFile, alter=null, accept=".mp3,.wav"}) => {
    let inputRef = useRef()

    const readFile = (e) => {
        if (!e?.target?.files?.[0]) return
        // const file = e?.target?.files?.[0]
        // const fileName = file?.name?.replace(/\.[^/.]+$/, "")
        // setFile({file: file, fileName: fileName})
        setFile(e?.target?.files?.[0])
        e.target.value = null;
    }

    return(
        <div className={s.csvLink} onClick={() => {inputRef?.current?.click()}}>
            <div
                className={`${alter ? s.popupHelpDwnld : null}`}
                style={{height: "23"}} data-title={alter ? alter : "Скачать" }>
                <CloudUpload className={s.SvgElem}/>
                <input type="file" id="dataupload" style={{display: "none"}}
                       accept={accept}
                       onChange={readFile}
                       ref={inputRef}/>
            </div>
        </div>
    )
}

export const UploadButtonBig = ({setFile, alter=null, accept=".mp3,.wav"}) => {
    let inputRef = useRef()

    const readFile = (e) => {
        if (!e?.target?.files?.[0]) return
        setFile(e?.target?.files?.[0])
        e.target.value = null;
    }

    return(
        <div className={`${s.csvLink} ${s.csvLinkBig}`} onClick={() => {inputRef?.current?.click()}}>
            <div
                className={`${alter ? s.popupHelpDwnld : null}`}
                style={{height: "50"}} data-title={alter ? alter : "Скачать" }>
                <CloudUpload className={s.SvgElem}/>
                <input type="file" id="dataupload" style={{display: "none"}}
                       accept={accept}
                       onChange={readFile}
                       ref={inputRef}/>
            </div>
        </div>
    )
}

export const UploadButtonLess = ({clickEvent, alter=null}) => {
    return(
        <div className={s.csvLink} onClick={clickEvent}>
            <div
                className={`${alter ? s.popupHelpDwnld : null}`}
                data-title={alter ? alter : "Скачать" }>
                <CloudUpload className={s.SvgElem}/>
            </div>
        </div>
    )
}

export const UploadPreview = (props) => {
    const uploadAction = (data) =>{
        props?.uploadAction(data)
        props.hide()
    }

    return (
        <div style={{
            position: "fixed", zIndex: 1000, inset: 0,
            width: "100%", height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.5)",
            display: props.show ? "block" : "none",
        }} onClick={(v) => props.hide(!props.show)}>
            <div style={{
                position: "fixed",
                display: "flex", flexDirection: "column", alignItems: "center",
                top: "50%", left: "50%", marginRight: "-50%", transform: "translate(-50%, -50%)",
                color: "#555", backgroundColor: "white",
                maxWidth: "800px", padding: "25px",
                textAlign: "center",
                borderBottom: "1px solid #dbdbdb", borderRadius: "10px",
                boxShadow: "unset 0 4px 0 rgb(0 0 0 / 8%)",
            }} onClick={(e) => e.stopPropagation()}>
                <div style={{
                    display: "flex", justifyContent: "space-between",
                    fontSize: "35px", fontWeight: "bold",
                    height: "100%",
                    marginBottom: "20px",
                    color: props.error ? "red": "rgb(85, 85, 85)"
                }}>
                    {props?.mainHeader}
                    <div className={s.buttonsTop}>
                        <div>
                            <DefaultButton text={"╳"} clickEvent={() => props.hide()} alter={"Закрыть окно"}/>
                        </div>
                    </div>
                </div>

                <h4>{props?.helpHeader}</h4>

                <table className={s.formTableM}
                       cellSpacing={0}
                       style={{textAlign: "left", width: "100%", marginBottom: "24px"}}>
                    <thead>
                    <tr>
                        {props?.headers.map(
                            elem => <th key={`header_${elem?.key}`} width={elem?.width}>{elem?.label}</th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {props?.data?.map(
                        row =>
                            <tr>
                                {row?.map(field => <td>{field}</td>)}
                            </tr>
                    )}
                    </tbody>
                </table>
                {/*<span className={s.comment}>*в дательном падеже для озвучивания в IVR: например "счетчика холодной воды"</span>*/}

                <UploadButtonBig setFile={uploadAction}
                                 alter={props?.alter || "Загрузить"}
                                 accept={
                                     "text/csv, " +
                                     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " +
                                     "application/vnd.ms-excel"
                                 }
                />
            </div>

        </div>
    )
}

