import React, {useEffect, useState} from 'react'
import s from "./DateBubble.module.scss"
import './calendar.css'
import {getDate} from "../../../sortFunction";
import {Calendar} from "react-calendar";
import {useTheme} from "../../../hooks/useTheme";
import {TimePicker} from "../../TimePicker/TimePicker";
import {SmallTabs} from "../../Tabs/Tabs";
import {diffDate} from "../../../provider/dates";
import {HelpElemTippy} from "../../HelpElemTippy/HelpElemTippy";


const DateBlock = ({label, valueDate, setValueDate, end=false}) => {
    const {userAccount} = useTheme()
    const [isOpenStart, setIsOpenStart] = useState(false)
    const [minDate, setMinDate] = useState(new Date())
    const [maxDate, setMaxDate] = useState(new Date())

    useEffect(() => {
        let curDate = new Date()
        let min = diffDate(curDate, -3)
        let max = diffDate(curDate, 0, 0, 0, 23, 59, 59)
        setMinDate(min)
        setMaxDate(max)
    }, [userAccount])

    const dateValueChangeHandler = (d) => {
        setValueDate(d)
    }

    return (
        <div className={s.calendars}>
            <p className={s.dateTitle}>{label}</p>
            <p
                className={s.chooseDate}
                onClick={() => setIsOpenStart(!isOpenStart)}
            >
                {getDate(valueDate)}
            </p>
            {isOpenStart && !userAccount.virtual && (
                <div>
                    <div
                        className={s.fullWidth}
                        onClick={() => setIsOpenStart(false)}
                    />
                    <Calendar
                        onChange={(value) => {
                            dateValueChangeHandler(value)
                            setIsOpenStart(false)
                        }}
                        value={valueDate}
                        className={s.calendar}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            )}
        </div>
    )
}

export const DateBubble = ({setStart, setEnd}) => {
    const [valueStart, setValueStart] = useState(new Date())
    const [valueEnd, setValueEnd] = useState(new Date())

    useEffect(() => {

        setStart(valueStart)

    }, [valueStart])

    useEffect(() => {

        setEnd(valueEnd)

    }, [valueEnd])

    useEffect(() => {
        if (valueStart > valueEnd) {
            setValueEnd(valueStart)
            return
        }

        let timeDiff = Math.abs(valueEnd.getTime() - valueStart.getTime());
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays > 366) {
            setValueEnd(diffDate(valueStart, 1))
        }
    }, [valueStart, valueEnd])

    return(
        <div className={s.date}>
            <DateBlock label={"Начальная дата"} valueDate={valueStart} setValueDate={setValueStart}/>
            <DateBlock label={"Конечная дата"} valueDate={valueEnd} setValueDate={setValueEnd}/>
            <div className={s.help}>
                <HelpElemTippy text={"Запрос детализации возможен за последние 3 года в диапазоне не более года"}/>
            </div>
        </div>
    )
}

const DateBlockSmall = ({label, valueDate, setValueDate}) => {
    const {userAccount} = useTheme()
    const [isOpenStart, setIsOpenStart] = useState(false)

    const [minDate, setMinDate] = useState(new Date())
    const [maxDate, setMaxDate] = useState(new Date())

    useEffect(() => {
        let curDate = new Date()
        let min = diffDate(curDate, -3)
        let max = diffDate(curDate, 0, 0, 0, 23, 59, 59)
        setMinDate(min)
        setMaxDate(max)
    }, [userAccount])

    return (
        <div className={s.inlineBlock}>
            <p className={s.dateTitleSmall}>{label}</p>
            <p
                className={s.chooseDate}
                onClick={() => setIsOpenStart(!isOpenStart)}
            >
                {getDate(valueDate)}
            </p>
            {isOpenStart && !userAccount.virtual && (
                <div>
                    <div
                        className={s.fullWidth}
                        onClick={() => setIsOpenStart(false)}
                    />
                    <Calendar
                        onChange={(value) => {
                            setValueDate(value)
                            setIsOpenStart(false)
                        }}
                        value={valueDate}
                        className={s.calendar}

                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            )}
        </div>
    )
}

const TimeBlockSmall = ({setValueTime, valueM, valueH}) => {

    return(
        <div>
            <TimePicker setValue={setValueTime} valueH={valueH} valueM={valueM} />
        </div>
    )

}

const datesFilters = [
    {id: 0, label: "За день", value: "days"},
    {id: 1, label: "За неделю", value: "week"},
    {id: 2, label: "За месяц", value: "month"},
]

export const DateBubbleSmall = (props) => {
    const {userAccount} = useTheme()
    const [valueStart, setValueStart] = useState(new Date())
    const [timeStart, setTimeStart] = useState("00:00")
    const [valueEnd, setValueEnd] = useState(new Date())
    const [timeEnd, setTimeEnd] = useState("23:59")

    useEffect(() => {
        if (valueStart > valueEnd) {
            setValueEnd(valueStart)
            return
        }

        let timeDiff = Math.abs(valueEnd.getTime() - valueStart.getTime());
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays > 366) {
            setValueEnd(diffDate(valueStart, 1))
        }

    }, [valueStart, valueEnd])

    useEffect(() => {
        let start = valueStart
        let h = timeStart.split(":")[0]
        let m = timeStart.split(":")[1]
        h = parseInt(h)
        m = parseInt(m)
        start.setHours(h)
        start.setMinutes(m)
        start.setSeconds(0)

        props.setStart(start)
    }, [valueStart, timeStart])

    useEffect(() => {

        let end = new Date(valueEnd.getFullYear(), valueEnd.getMonth(), valueEnd.getDate())
        let h = parseInt(timeEnd.split(":")[0])
        let m = parseInt(timeEnd.split(":")[1])

        end.setHours(h)
        end.setMinutes(m)
        end.setSeconds(59)

        props.setEnd(end)
    }, [valueEnd, timeEnd])

    const selectPreset = (e) => {
        if (e === "days") {
            setValueStart(new Date())
            setTimeStart("00:00")

            setValueEnd(new Date())
            setTimeEnd("23:59")
        } else if (e === "week") {
            let start = new Date()
            start.setDate(start.getDate() - 7)

            setValueStart(start)
            setTimeStart("00:00")
        } else if (e === "month") {
            let start = new Date()
            start.setMonth(start.getMonth() - 1)

            setValueStart(start)
            setTimeStart("00:00")
        }
    }

    return (
        <div className={`${s.date} ${s.dateSmall}`} style={{minWidth: "480px"}}>
            <div>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    <DateBlockSmall label={"с"} valueDate={valueStart} setValueDate={setValueStart}/>

                    {!userAccount.virtual &&
                    <TimeBlockSmall setValueTime={setTimeStart} blockedMinutes={-30} />
                    }

                    <DateBlockSmall label={"по"} valueDate={valueEnd} setValueDate={setValueEnd}/>

                    {!userAccount.virtual &&
                    <TimeBlockSmall setValueTime={setTimeEnd} valueH={23} valueM={59}/>
                    }
                    <div className={s.help}>
                        <HelpElemTippy text={"Запрос детализации возможен за последние 3 года в диапазоне не более года"}/>
                    </div>
                </div>

                <SmallTabs data={datesFilters} onChange={selectPreset} notSeletable={true}/>
            </div>

        </div>
    )
}