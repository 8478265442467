import React, {useDebugValue, useEffect, useState} from 'react'
import base64 from 'base-64'
import utf8 from "utf8"
import {createBrowserHistory} from "history";
import {useTheme} from "../../../hooks/useTheme";
import s from "../LoginPage/LoginMain/LoginMain.module.scss"
import {Footer} from "../LoginPage/LoginMain/LoginMain";
import {correctNumer, NumberValidationForm} from "./RegisterPageMain";
import {useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getEsiaUrl, signUpApi, validateNumerApi} from "../../../api";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import ModalMessage from "../../../ui/Popup/ModalMessage";
import * as yup from "yup";
import {generatePassword, sleep} from "../../../sortFunction";
import {FreeTippyElement} from "../../../ui/TippyElement/TipyElemet";
import DefaultButton, {DownloadButton} from "../../../ui/Button/Button";
import {smsHistoryHeaders, smsHistoryHeadersAero} from "../../../provider/smsVariables";
import {DogovorData} from "../../../ui/Popup/Popups";
import {PdfViewer} from "../../../ui/Popup/PdfViewer";

const history = createBrowserHistory();

const schema = yup
    .object({
        // "inn": yup.number().required().min(1000000000).max(9999999999),
        "kpp": yup.number(),
        "passport": yup.string().required(),
        "ogrn": yup.number(),
        "kc": yup.number(),
        "rc": yup.number(),
        "bik": yup.number(),
        "bank": yup.string(),
        "email": yup.string().email().required(),
        "phone_number": yup.string().required(),
        "addressStr": yup.string(),
        "OrgName": yup.string().required(),
    })
    .required()

const schemaOrg = yup
    .object({
        "ogrn": yup.number().required(),
        "scope": yup.string().required(),
        "OrgName": yup.string().required(),
    })
    .required()

const RegisterButton = (props) => {
    return(
        <input type={"submit"}
               className={`${s.buttonSubmit} ${s.inputSubmit} ${props.color}`}
               value={props.text}
               {...props}
        />
    )
}

const LoadingButton = (props) => {
    return(
        !props.children ?
            <div className={
                `${s.buttonSubmit} 
             ${props.color}`
            }>
                {props.text && !props.child
                    ? props.text
                    : "Зарегистрироваться"
                }

            </div> :
            <div className={`${s.buttonSubmit} ${props.color}`}>
                {props.children}
            </div>


    )
}

const ClickingButton = (props) => {
    return(
        !props.children ?
            <div className={
                `${s.buttonSubmit} 
             ${props.color}`
            }
                 onClick={props.click}
            >
                {props.text && !props.child
                    ? props.text
                    : "Зарегистрироваться"
                }

            </div> :
            <div className={`${s.buttonSubmit} ${props.color}`}>
                {props.children}
            </div>
    )
}

const ValidateInput = (props) => {
    return(
        <div className={s.validateInput}>
            <FreeTippyElement text={"Введите 4 последние цифры номера телефона, с которого мы вам сейчас позвоним"}>
                <input type={"Text"} onChange={e => props.validate(e.target.value, true)} maxLength={4}/>
            </FreeTippyElement>

        </div>
    )
}

const ValidateStatusButton = (props) => {
    return(
        <div className={`${s.notValidatedIco} ${props.isValidated && s.validatedIco}`}
            onClick={() => {!props.isValidated && props.validate()}}
        >
            {props.isValidated &&
            "ok"
            }

            {!props.isValidated && props.tooManyRequests &&
            "?"
            }

            {!props.isValidated && !props.tooManyRequests &&
            "!"
            }
            <div className={s.helpMess}>
                {props.isValidated && !props.noMore &&
                "Номер подтверждён"
                }

                {!props.isValidated && props.tooManyRequests && !props.noMore &&
                "Слишком много неверных попыток ввода. Запросите новый код"
                }

                {!props.isValidated && !props.tooManyRequests && !props.noMore &&
                "Требуется подтверждение"
                }

                {props.noMore && "Превышено количество попыток. Попробуйте ещё раз через 24 часа"}
            </div>
        </div>
    )
}

const AButton = (props) => {
    return(
        !props.children ?
            <div className={
                `${s.buttonSubmit} 
             ${props.color}`
            }
                 onClick={props.click}
            >
                {props.text && !props.child
                    ? props.text
                    : "Зарегистрироваться"
                }

            </div> :
            <div className={s.buttonSubmit}>
                {props.children}
            </div>


    )
}


const OrgForm = ({data, page, setPage}) => {
    const [searchParams, ] = useSearchParams();
    const [popupTarget, setPopupTarget] = useState("")
    const [nextPage, setNextPage] = useState(0)
    const [url, setUrl] = useState("")
    const [showDogovor, setShowDogovor] = useState(false)
    const [goingToRegister, setGoingToRegister] = useState(false)

    const {register, handleSubmit, reset, formState: { errors },} = useForm({
        resolver: yupResolver(schemaOrg), mode: 'onBlur',
        defaultValues: {
            ogrn: "",
            scope: "",
            OrgName: "",
        }
    })

    useEffect(() => {
        setUrl("")
        let elementNumber = parseInt(page)
        if (isNaN(elementNumber) || !data[elementNumber]) return
        reset({
            ogrn: data[elementNumber].ogrn,
            OrgName: data[elementNumber].shortName,
            scope: data[elementNumber].scope,
        })
        if (data[elementNumber+1]) setNextPage(elementNumber+1)
        else setNextPage(0)

        // console.log(data[elementNumber])
        if (data[elementNumber].active === "false" || data[elementNumber].isLiquidated === "true") {
            return;
        }

        // if (data[elementNumber].admin === "false" && data[elementNumber].chief === "false") {
        //     return;
        // }

        if (!data[elementNumber].scope) return

        let redirect = ""
        if (searchParams.get("redirect")) {
            redirect = searchParams.get("redirect")
        }

        let pass = generatePassword()
        getEsiaUrl({pass: pass, redirect: redirect, scope: data[elementNumber].scope})
            .then((r) => r.url && setUrl(r.url))

    }, [data, page])

    const startRegister = () => {
        setGoingToRegister(true)
        setShowDogovor(false)

        if (url?.length === 0) return
        window.open(url, '_self')
    }

    return(
        <div>
            <form>
                <DefaultTitle title={"Проверьте ваши данные для регистрации"}/>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Название</p>
                    <input type="text" readOnly={true}
                        className={errors.OrgName ? `${s.defaultInput} ${s.error}` : s.defaultInput}
                        {...register('OrgName')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>ОГРН</p>
                    <input type="text" readOnly={true}
                        className={errors.ogrn ? `${s.defaultInput} ${s.error}` : s.defaultInput}
                        {...register('ogrn')}
                    />
                </div>


                {url.length > 0 && !goingToRegister &&
                <ClickingButton color={s.orange} text={"Зарегистрироваться"} click={() => setShowDogovor(true)}/>
                }

                {goingToRegister &&
                <div style={{marginBottom: "10px"}}>
                    <ClickingButton text={"Идёт регистрация..."} color={s.noAvailableB}/>
                </div>
                }

                {!url.length &&
                <div style={{marginBottom: "10px"}}>
                    <ClickingButton text={"Регистрация недоступна"} color={s.noAvailableB}/>
                </div>
                }

                {data?.["1"] &&
                <ClickingButton text={"Следующая организация"} color={s.green} click={() => {setPage(nextPage.toString())}}/>
                }

                <div style={{marginTop: "10px"}}>
                    <ClickingButton text={"Назад"} color={s.green} click={() => {setPage("fiz")}}/>
                </div>

            </form>

            <ModalMessage show={popupTarget === "error"}
                          hide={() => setPopupTarget("")}
                          label={"Ошибка регистрации"}
                          message={"Проверьте правильность введённых данных и повторите попытку"}/>
            <ModalMessage show={popupTarget === "success"}
                          hide={() => {setPopupTarget(""); history.push("/login"); history.go(0)}}
                          label={"Успешно!"}
                          message={"Регистрация прошла успешно. " +
                          "Данные для входа были отправлены на указанный адрес электронной почты"}/>
            <PdfViewer show={showDogovor} hide={() => setShowDogovor(false)} success={startRegister}/>
        </div>
    )
}

const FizForm = ({data}) => {
    const [popupTarget, setPopupTarget] = useState("")
    const [isNumberValidated, setNumberValidated] = useState(false)
    const [isEmailValidated, setEmailValidated] = useState(false)

    const [numberValidationSend, setNumberValidationSend] = useState(false)
    const [emailValidationSend, setEmailValidationSend] = useState(false)
    const [registerAction, setRegisterAction] = useState(false)
    const [error, setError] = useState("")
    const [tooManyRequests, setTooManyRequests] = useState(false)
    const [noMoreRequests, setNoMoreRequests] = useState(false)
    const [timer, setTimer] = useState(0)

    useEffect(() => {console.log(error)}, [error])

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            inn: "",
            email: "",
            phone_number: "",
            OrgName: "",
            passport: ""
        }
    })

    const watchFields = watch(["email", "phone_number"]);

    useEffect(() => {
        if (!data.firstName) return
        let name = data.firstName
        if (data.middleName) {
            name = [name, data.middleName].join(" ")
        }
        if (data.lastName) {
            name = [name, data.lastName].join(" ")
        }

        let email = ""
        let phone = ""
        let passport = ""
        if (data.contacts && data.contacts.length > 0) {
            for (const key of Object.keys(data.contacts)) {
                let e = data.contacts[key]
                if (e.vrfStu !== "VERIFIED") continue

                if (e.type === "EML" && e.value) email = e.value
                if (e.type === "MBT" && e.value) phone = e.value
            }
        }


        if (data?.documents) {
            let passport_docs = Object.values(data.documents)
                .find(e => e.type === "RF_PASSPORT" && e.vrfStu==="VERIFIED")
            if (passport_docs) {
                passport = [passport_docs.series, passport_docs.number].join("")
            }
        }


        reset(
            {
                inn: "",
                passport: passport,
                email: email,
                phone_number: phone,
                OrgName: name,
            }
        )
    }, [data])

    const submitFunc = (form_data) => {
        if (!isNumberValidated) {
            setError("phone_number")
            return
        }
        form_data.phone_number = correctNumer(form_data.phone_number)
        if (form_data.phone_number.length !== 11) {
            setError("phone_number")
            return
        }

        const responseHnd = (r) => {
            setRegisterAction(false)
            if (r?.status) {
                setPopupTarget("success")
                return
            }

            if (!r?.response?.data) return
            let data = JSON.parse(r.response.data)
            if (data?.status === "fail" && data?.error === "already exists") {
                setPopupTarget("alreadyRegistered")
            } else {
                setPopupTarget("error")
            }
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form_data.email)) return
        setRegisterAction(true)
        signUpApi({data: {...form_data, uuid: data.uuid}})
            .then(r => responseHnd(r))
            .catch(r => responseHnd(r))

    }

    useEffect(() => {
        if (error.length === 0) return
        sleep(1000)
            .then(() => setError(""))

    }, [error])

    const validateNumber = (code=null, verify_code=false) => {
        let number = correctNumer(watchFields[1])
        if (number.length !== 11) return
        // console.log("validate number", number, code)

        const errHandler = (e, mainRequest = false) => {
            console.log(e?.response?.data)
            if (e?.response?.data) {
                let error = JSON.parse(e.response.data)
                if (error.error === "already_sent" && error.time) {
                    setTimer(e.response.data.time)
                    setNumberValidationSend(true)
                } else if (error.error === "too_many_requests") {
                    setTooManyRequests(true)
                    setNumberValidationSend(false)

                    console.log(mainRequest)
                    setNoMoreRequests(mainRequest)
                }

            }
            setError("phone_number")
        }

        if (verify_code) {
            if (code.length < 4) return

            let request = {
                phone: number,
                secretCode: code,
            }
            // console.log("send code")
            const setOk = () => {
                setNumberValidated(true)
                setNumberValidationSend(false)
            }
            validateNumerApi(request)
                .then((value) => {
                    value?.status === "success"
                        ? setOk()
                        : errHandler(value)})
                .catch(error => errHandler(error))
        } else {
            validateNumerApi({phone: number})
                .then((value) => value?.status === "success"
                    ? setNumberValidationSend(true)
                    : errHandler(value, true)
                )
                .catch(error => errHandler(error, true))
        }
    }

    const validateEmail = () => {
        console.log("validate email", watchFields[0])
    }

    return(
        <div>
            <form onSubmit={handleSubmit(submitFunc)}>
                <DefaultTitle title={"Проверьте ваши данные для регистрации"}/>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>ФИО</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.OrgName
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        readOnly={true}
                        {...register('OrgName')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Email адресс</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.email
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        {...register('email')}
                    />
                    {/*<ValidateStatusButton isValidated={isEmailValidated} validate={validateEmail}/>*/}
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Номер телефона</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.phone_number || error === "phone_number"
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        readOnly={true}
                        {...register('phone_number')}
                    />
                    { numberValidationSend
                        ? <ValidateInput validate={validateNumber}/>
                        : <ValidateStatusButton isValidated={isNumberValidated}
                                                validate={validateNumber}
                                                noMore={noMoreRequests}
                                                tooManyRequests={tooManyRequests}/>
                    }
                </div>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Паспорт</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.inn
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        readOnly={true}

                        {...register('passport')}
                    />
                </div>
                {/*<div className={s.formBlock}>*/}
                {/*    <p className={s.blockTitle}>ИНН</p>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        // placeholder={theme.contacts ? theme.contacts["name"] : ""}*/}
                {/*        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}*/}
                {/*        className={*/}
                {/*            errors.inn*/}
                {/*                ? `${s.defaultInput} ${s.error}`*/}
                {/*                : s.defaultInput*/}
                {/*        }*/}
                {/*        {...register('inn')}*/}
                {/*    />*/}
                {/*</div>*/}

                <LoadingButton text={"Регистрация физического лица недоступна"} color={s.grey}/>

                {/*{registerAction*/}
                {/*    ? <LoadingButton text={"Идёт регистрация"} color={s.grey}/>*/}
                {/*    : <RegisterButton text={"Регистрация физического лица"} color={s.orange}/>*/}
                {/*}*/}
                {/*<RegisterButton text={"Регистрация физического лица"} color={s.orange}/>*/}
            </form>

            <ModalMessage show={popupTarget === "alreadyRegistered"}
                          hide={() => setPopupTarget("")}
                          label={"Уже зарегистрирован"}
                          message={
                              "Пользователь с этими данными уже сущестует. Воспользуйтесь логином и паролем, " +
                              "или обратитесь в службу поддержки"}/>
            <ModalMessage show={popupTarget === "error"}
                          hide={() => setPopupTarget("")}
                          label={"Ошибка регистрации"}
                          message={"Проверьте правильность введённых данных и повторите попытку"}/>
            <ModalMessage show={popupTarget === "success"}
                          hide={() => {setPopupTarget(""); history.push("/login"); history.go(0)}}
                          label={"Успешно!"}
                          message={"Регистрация прошла успешно. " +
                          "Данные для входа были отправлены на указанный адрес электронной почты"}/>
        </div>
    )
}

const NoOrgsMessage = () => {

    const toMainPage = () => {
        window.open("https://cabinet.telezon.ru", '_self')
    }
    return (
        <div className={s.noOrgMessage}>
            <p>
                В вашем аккаунте на сайте Госуслуг отсутствует информация об организациях.
            </p>

            <p>
                <b>Регистрация в личном кабинете Telezon доступна только для организаций.</b>
            </p>

            <p>
                Для получения дополнительной информации или регистрации Вы можете связаться со специалистами через форму обратной связи или по телефону ниже.
            </p>

            <ClickingButton text={"Назад"} color={s.green} click={toMainPage}/>
        </div>
    )
}


const RegisterPageESIA = (props) => {
    const [searchParams, ] = useSearchParams();
    // const [userData, setUserData] = useState({})
    const [orgs, setOrgs] = useState({})
    const [curPage, setCurPage] = useState("0")

    useEffect(() => {
        if (searchParams.get("error")) {
            let query = ["/login?error", searchParams.get("error")].join("=")
            history.push(query);
            history.go();
        }
        if (!searchParams.get("b64")) return

        const b64 = searchParams.get("b64")
        try {
            let decodedData = base64.decode(b64);
            let text = utf8.decode(decodedData)
            text = text.replaceAll("True", "true")
            text = text.replaceAll("False", "false")
            text = text.replaceAll("'", "\"")
            text = text.replaceAll("<q>", "'")
            let result = JSON.parse(text)
            // console.log(result)
            if (Array.isArray(result?.orgs)) {
                setOrgs(result?.orgs)
                return;
            }

            if (Array.isArray(result?.orgs?.elements)) {
                setOrgs(result?.orgs?.elements)
                return;
            }
            // setUserData(result)
            // console.log(result)

        }
        catch (e) {
            console.log("errror", e)
            console.log("errror", b64)
        }


    }, [searchParams])


    return(
        <div className={s.loginPage}>

            <div className={`${s.mainBlock} ${s.mainBlockRegister}`}>
                <div className={s.logo}/>

                {orgs?.length > 0
                    ?
                    <OrgForm data={orgs} page={curPage} setPage={setCurPage}/>
                    :
                    <NoOrgsMessage />
                }

                <div style={{marginTop: "25px"}}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default RegisterPageESIA
