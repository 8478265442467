import React, {useEffect, useState} from "react"
import s from "./Meters.module.scss"
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import {useTheme} from "../../../../hooks/useTheme";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {metersDefaultValue, metersSchema} from "../schemas";
import {getDateFromStr, normalizeDateDMYHMS, sleep} from "../../../../sortFunction";
import {createMeter, deleteMeter, getMeters, getReaders, updateMeter, uploadMeters} from "../api";
import {abonentsHeaders, exportReadersCols, metersHeaders, readersHeaders, uploadMetersHeaders} from "../variables";
import {WebSitesButton, WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";
import {HelpElem} from "../../../../ui/HelpElem/HelpElem";
import DefaultButton, {
    DownloadButton,
    UploadButtonLess,
    UploadPreview
} from "../../../../ui/Button/Button";
import PaginationBlock from "../../../../ui/PaginationBlock/PaginationBlock";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import Creatable from "react-select/creatable";
import {NoOptionsMessage} from "../../../../ui/react-select-styles";
import {GetApp} from "@mui/icons-material";
import {Player} from "../../../../ui/AudioGenerate/TextAreaAudioBlock";
import {TippyElement} from "../../../../ui/TippyElement/TipyElemet";

export const Meters = (props) => {
    const pageSize = 5
    const [curPage, setCurPage] = useState(1)

    const {userAccount, setActivePopupGlobal} = useTheme()
    const [initialFields, setInitialFields] = useState([])
    const [shakeAction, setShakeAction] = useState(false)
    const [savingAction, setSavingAction] = useState(false)
    const [showUploadPopup, setShowUploadPopup] = useState(false)
    const [filteredRows, setFilteredRows] = useState([])
    const [sortedUp, setSortedUp] = useState(false)
    const [sortedKey, setSortedKey] = useState("")

    const {register, handleSubmit, control, reset, formState: {errors}} = useForm({
        resolver: yupResolver(metersSchema),
        mode: 'onBlur',
        defaultValues: metersDefaultValue
    })

    const { fields, append, remove} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "meters", // unique name for your Field Array
    });

    useEffect(() => {
        if (Object.keys(errors)?.length === 0) return
        setShakeAction(true)
        sleep(1000).then(() => setShakeAction(false))
    }, [errors])

    useEffect(() => {
        // console.log(props?.meters)
        if (props?.meters?.length === 0) {
            reset({
                meters: metersDefaultValue
            })
            setInitialFields(metersDefaultValue)
        } else {
            reset({
                meters: props?.meters.sort((a, b) => a?.priority > b?.priority ? 1 : -1)
            })
            setInitialFields(props?.meters)
        }
    }, [props?.meters])

    const appendVoidRow = () => {
        append(Object.assign({}, ...abonentsHeaders.map((x) => ({[x.key]: ""}))))
    }

    useEffect(() => {
        setFilteredRows(
            fields.slice(
                pageSize * curPage - pageSize,
                pageSize * curPage
            ).map(e => e?.uuid)
        )

    }, [curPage, fields, props?.meters])

    const submitFunc = async (data) => {
        // Ищем удалённые
        let deleted = [...initialFields].filter(e => !data?.meters?.find(ne => ne?.uuid === e?.uuid)).map(e => e?.uuid)
        let actions = []
        for (const row of data?.meters) {
            if (row?.uuid) actions.push({...row, action: "update"})
            else actions.push({...row, action: "create"})
        }

        for (const row of deleted) {
            actions.push({target: row, action: "delete"})
        }

        setSavingAction(true)
        Promise.all(actions.map(
            row => row?.action === "update"
                ? new Promise((resolve, reject) =>
                    updateMeter({payload: row, auth: userAccount})
                        .then(() => resolve("ok"))
                        .catch((e) => reject(e))
                )
                : row?.action === "create"
                    ? new Promise((resolve, reject) =>
                        createMeter({payload: row, auth: userAccount})
                            .then(() => resolve())
                            .catch((e) => reject(e))
                    )
                    : row?.action === "delete"
                        ? new Promise((resolve, reject) =>
                            deleteMeter({uuid: row?.target, auth: userAccount})
                                .then(() => resolve())
                                .catch((e) => reject(e))
                        )
                        : null
            )
        )
            .then(() => {
                setActivePopupGlobal("successSave")
                setSavingAction(false)
            })
            .catch((e) => {
                setActivePopupGlobal("failSave")
                setSavingAction(false)
            })
    }

    const goToLastPage = () => {
        setCurPage(Math.ceil(fields.length / +pageSize))
    }

    const uploadAction = (data) => {
        setSavingAction(true)
        uploadMeters({auth: userAccount, formData: data})
            .then(() => {
                setActivePopupGlobal("successSave")
                setSavingAction(false)
                props.reload()
            })
            .catch(() => {
                setActivePopupGlobal("failSave")
                setSavingAction(false)
            })
    }

    const sortFields = (row) => {
        if (sortedUp) {
            reset({
                meters: [...fields].sort((a, b) => a[row?.key] > b[row?.key] ? 1 : -1)
            })
        } else {
            reset({
                meters: [...fields].sort((a, b) => a[row?.key] < b[row?.key] ? 1 : -1)
            })
        }

        setSortedUp(!sortedUp)
        setSortedKey(row?.key)
    }

    return (
        <div style={{
            position: "fixed", zIndex: 1000, inset: 0,
            width: "100%", height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.5)",
            display: props.show ? "block" : "none",
        }} onClick={(v) => props.hide(!props.show)}>
            <form style={{
                position: "fixed",
                top: "50%", left: "50%", marginRight: "-50%", transform: "translate(-50%, -50%)",
                color: "#555", backgroundColor: "white",
                maxWidth: "1000px", height: "400px", padding: "25px",
                textAlign: "center",
                borderBottom: "1px solid #dbdbdb", borderRadius: "10px",
                boxShadow: "unset 0 4px 0 rgb(0 0 0 / 8%)",
            }} onClick={(e) => e.stopPropagation()}
                  className={s.flexedForm} onSubmit={handleSubmit(submitFunc)} >

                <div style={{
                    display: "flex", justifyContent: "space-between",
                    fontSize: "35px", fontWeight: "bold",
                    marginBottom: "20px",
                    color: props.error ? "red": "rgb(85, 85, 85)"
                }}>
                    Приборы учёта
                    <div className={s.buttonsTop}>
                        <UploadButtonLess clickEvent={() => setShowUploadPopup(true)} alter={"Загрузить"}/>
                        <DownloadButton data={fields} columns={metersHeaders} file={"показания.csv"}
                                        alter={"Скачать список приборов"}/>
                        <div>
                            <DefaultButton text={"╳"} clickEvent={() => props.hide()} alter={"Закрыть окно"}/>
                        </div>
                    </div>
                </div>

                <table className={s.formTableM}
                       style={{textAlign: "left", width: "100%"}}>
                    <thead>
                    <tr>
                        {metersHeaders.map(
                            elem =>
                                <th key={`header_${elem?.key}`} width={elem?.width}
                                    className={`${elem?.key === sortedKey ? sortedUp ? s.sortedUp : s.sortedDown : null}`}

                                    onClick={() => sortFields(elem)}>
                                    {elem?.label}
                                </th>
                        )}
                        <th className={s.noHover}>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((field, index) =>
                        filteredRows.includes(field?.uuid) &&
                        <tr key={index}>
                            {metersHeaders.map(header =>
                                <td key={`${index}_${header?.key}`}>
                                    {!header?.input_type &&
                                    <input style={{
                                        background: "#f0f0f0",
                                        width: header?.width
                                    }}
                                           key={field.id} // important to include key with field's id
                                           className={
                                               errors?.meters?.[index]?.[header?.key] && shakeAction
                                                   ? s.shake : null
                                           }
                                           {...register(`meters.${index}.${header?.key}`)}
                                    />
                                    }

                                    {header?.input_type === "int" &&
                                    <input style={{
                                        background: "#f0f0f0",
                                        width: header?.width
                                    }}
                                           type={"number"} min="0"
                                           key={field.id} // important to include key with field's id
                                           className={
                                               errors?.meters?.[index]?.[header?.key] && shakeAction
                                                   ? s.shake : null
                                           }
                                           {...register(`meters.${index}.${header?.key}`)}
                                    />
                                    }

                                    {header?.input_type === "bool" &&
                                    <Controller
                                        control={control}
                                        values={[true, false]}
                                        name={`${index}.enabled`}
                                        instanceId={2}
                                        rules={{required: true}}
                                        render={({field: {onChange, onBlur},}) => (
                                            <label className={s.flexedCb}>
                                                <input type={"checkbox"}
                                                       className={s.realCheckBox}
                                                       onBlur={onBlur} // notify when input is touched
                                                       {...register(`meters.${index}.${header?.key}`)}
                                                />
                                                <div className={s.fakeCb}/>
                                            </label>

                                        )}
                                    />
                                    }

                                </td>
                            )}

                            <td>
                                <span className={s.action} onClick={() => remove(index)}>Удалить</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <span className={s.comment}>*в дательном падеже для озвучивания в IVR: например "счетчика холодной воды"</span>
                <PaginationBlock curPage={curPage} setCurPage={setCurPage} callData={fields} paginationSize={pageSize}/>

                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "20px", marginTop: "15px"}}>
                        <HelpElem text={"Добавить строку"}>
                            <DefaultButton text={"+"} clickEvent={() => {
                                appendVoidRow()
                                goToLastPage()
                            }}/>
                        </HelpElem>
                    </div>

                    {savingAction
                        ? <WebSitesDivButton text={"Обработка"} color={"noactive"} animated={false}/>
                        : <WebSitesButton text={"Сохранить"}/>
                    }
                </div>
            </form>

            <UploadPreview show={showUploadPopup} hide={setShowUploadPopup}
                           headers={uploadMetersHeaders}
                           data={[["Счётчик холодной воды", "Кухня", "Счётчика холодной воды", "Кухне", "Пример"]]}
                           uploadAction={uploadAction}
                           mainHeader={"Загрузка списка приборов учёта"}
                           helpHeader={"Для загрузки таблицы Excel её поля должны быть в следующей последовательности:"}
            />
        </div>
    )
}

const MeterHeaderFields = ({meters=[], sort}) => {
    return(
        <>
            {meters?.length > 0 && meters.map(meter =>
                <th className={`${s.metersHeader}`} style={{
                    wordBreak: meter?.name.includes(" ") ? "normal" : "break-all"
                }} key={meter?.uuid}>
                    {meter?.name}
                </th>)
            }

            {meters?.length === 0 &&
            <th>Приборы учёта</th>
            }

        </>
    )
}

const MeterValueFields = ({field, meters}) => {

    const getValue = (meter_uuid) => {
        let value_obj = field?.values_list?.find(v => v?.meter === meter_uuid)
        let value = value_obj?.value

        if (value === "None") {
            value = "-"
        }

        return value || "-"
    }

    return(
        meters?.length > 0
            ? meters.map(meter => <td key={meter?.uuid}>{getValue(meter?.uuid)}</td>)
            : <th>Приборы не установлены</th>
    )
}

const AudioButton = ({audio, playAudio}) => {
    const {theme} = useTheme()

    return(
        <div className={s.audioButton}>
            {theme === 'dark' ?
                <div style={{width: "29px", height: "29px"}} onClick={() => playAudio(audio)}>
                    <GlobalSvgSelector id="audio-button"/>
                </div> :
                <div style={{width: "25px", height: "25px"}} onClick={() => playAudio(audio)}>>
                    <GlobalSvgSelector id="audio-dark"/>
                </div>
            }
        </div>
    )
}

const DownloadAudioButton = ({audio}) => {
    return (
        <a href={audio} target={"__blank"} className={s.audioDownload}>
            <GetApp/>
        </a>
    )
}

const NoCr = () => {
    return(
        <div className={s.noCr}>
            <TippyElement>
                Для прослушивания фонограммы необходимо подключить услугу <b>"Запись телефонных разговоров"</b>
                <br/>
                <br/>
                Если же услуга подключена, прослушивание будет доступно после пополнения баланса
            </TippyElement>
        </div>
    )
}

export const MetersStatistic = () => {
    const pageSize = 10
    const [curPage, setCurPage] = useState(1)

    const {userAccount, theme, callrecords} = useTheme()
    const [filteredFields, setFilteredFields] = useState([])
    const [activeSequence, setActiveSequence] = useState([])
    const [exportData, setExportData] = useState([])
    const [exportCols, setExportCols] = useState([])
    const [meters, setMeters] = useState([])

    const [initialFields, setInitialFields] = useState([])
    const [curFilterSequence, setCurFilterSequence] = useState([])
    const [savingAction, setSavingAction] = useState(false)
    const [showMetersPopup, setShowMetersPopup] = useState(false)
    const [sortStatus, setSortStatus] = useState(false)
    const [sortKey, setSortKey] = useState("")
    const [filters, setFilters] = useState({})

    const [audioFile, setAudioFile] = useState("")

    const loadStatistic = () => {
        if (!userAccount?.login) return

        const resetResults = (data) => {
            let d = data?.data?.results || []

            d = d.sort((a, b) => getDateFromStr(a?.created_at) > getDateFromStr(b?.created_at) ? 1 : -1)
            d = d.map(row => ({...row, created_at: normalizeDateDMYHMS(getDateFromStr(row?.created_at))}))
            // d = d.map(row => ({...row, record_url: "https://api.telezon.ru/tts/v1/get/yandex/e071c892-d9c4-461b-8f42-c28f3c6ca6e2.mp3"}))
            setInitialFields(d)
        }
        setSavingAction(true)
        getReaders({...userAccount})
            .then(r => {
                resetResults(r)
                setSavingAction(false)
            })
            .catch(r => {
                console.log(r)
                setSavingAction(false)
            })
    }

    useEffect(() => {
        let fields = [...initialFields]

        if (sortKey === "created_at") {
            if (sortStatus) {
                fields.sort((a, b) =>
                    getDateFromStr(a[sortKey]) > getDateFromStr(b[sortKey]) ? 1 : -1)
            } else {
                fields.sort((a, b) =>
                    getDateFromStr(a[sortKey]) > getDateFromStr(b[sortKey]) ? -1 : 1)
            }
        } else {
            if (sortStatus) {
                fields.sort((a, b) =>
                    a[sortKey] > b[sortKey] ? 1 : -1)
            } else {
                fields.sort((a, b) =>
                    a[sortKey] < b[sortKey] ? 1 : -1)
            }
        }

        // Фильтруем
        for (const key of Object.keys(filters)) {
            let val = filters[key]

            if (val === "Пусто") {
                fields = fields.filter(
                    e => e[key].length === 0)
            }
            else {
                fields = fields.filter(
                    e => e[key] && e[key].toLowerCase().includes(val.toLowerCase()))
            }
        }

        setFilteredFields([...fields])
    }, [initialFields, sortStatus, sortKey, curPage, filters])

    useEffect(() => {
        setActiveSequence([...filteredFields].slice(
            pageSize * curPage - pageSize,
            pageSize * curPage,
        ))
    }, [filteredFields])

    const loadMeters = () => {
        setSavingAction(true)

        const success = (data) => {
            setMeters(data?.data || [])
            setSavingAction(false)
        }

        const fail = (data) => {
            console.log(data?.response?.data)
            setSavingAction(false)
        }
        getMeters({...userAccount})
            .then(r => success(r))
            .catch(e => fail(e))
    }

    useEffect(() => {
        if (!userAccount?.login) return
        loadMeters()
        loadStatistic()
    }, [userAccount])

    const sortData = (key) => {
        setSortStatus(!sortStatus)
        setSortKey(key)
    }

    const getFilters = () => {
        setCurFilterSequence([])

        // const f = [{label: "Нет", value: "Нет", k: "Нет"}]
        let prev = {}
        let names = {}

        for (const field of initialFields) {
            for (const key of Object.keys(field)) {
                if (!prev?.[key]) {
                    prev[key] = []
                    // prev[key] = [{label: "Нет", value: "Нет", k: key}]
                    names[key] = []
                    // names[key] = [{label: "Нет", value: "Нет", k: key}]
                }

                if (!prev[key].includes(field[key])) {
                    prev[key].push(field[key])
                    names[key].push({label: field[key], value: field[key], k: key})
                    names[key] = names[key].sort((a, b) => a?.value > b?.label ? 1 : -1)
                }
            }
        }
        for (const key of Object.keys(names)) {
            names[key] = [{label: "Нет", value: "Нет", k: key}, ...names[key]]
        }
        setCurFilterSequence(names)
    }

    useEffect(() => {
        getFilters()
    }, [initialFields])

    const addFilter = (val) => {
        setCurPage(1)
        let new_f = {...filters}

        if (val?.k && val?.label) {
            if (!Object.keys(new_f).includes(val.k)) {
                new_f[val.k] = []
            }

            if (val.value === "Нет") {
                new_f[val.k] = []
            } else if (new_f[val.k].includes(val.label)) {

            } else {
                new_f[val.k] = val.label
            }

            if (new_f[val.k].length === 0) {
                delete new_f[val.k]
            }
        }

        setFilters({...new_f})
    }

    const filterSelectStyle = {
        control: () => ({
            height: "30px", display: 'flex', textAlign: 'left',
            color: theme === 'light' ? 'white' : 'white'
        }),
        menu: (provided, state) => ({
            ...provided, minWidth: "50px", width: "150px"
        }),
        option: (provided, state) => ({
            ...provided, fontSize: "12px", height: "20px", padding: "5px 0",
            borderBottom: '1px dotted pink',
            backgroundColor: state.isSelected
                ? "rgba(41,165,61,1)"
                : state.isFocused
                    ? "rgba(254,79,26,1)"
                    : "white",
            color: (state.isSelected || state.isFocused)
                ? "white"
                : "black"
        }),
        singleValue: (provided, state) => ({
            ...provided, paddingLeft: "10px",
            fontWeight: state.data && Array.isArray(state.data.label) ? "bold" : "normal",
            color: "white",
        }),
    }

    const freeFilter = (key, e) => {
        addFilter({label: e, value: e, k: key})
        // setFilterValue({label: e, value: e, k: key})
    }

    useEffect(() => {
        let additional_columns = meters.map(m => ({key: m?.uuid, label: m?.name, install_point: m?.install_point}))

        let result = []
        for (const field of filteredFields) {
            for (const val of field?.values_list || []) {
                let value = val?.value
                let serial = val?.serial || ""
                if (!value) value = "-"
                else if (value.toString().toLowerCase() === "none") value = "-"
                if (!serial) serial = "-"
                else if (serial.toString().toLowerCase() === "none") serial = "-"

                let meter = additional_columns.find(e => e?.key === val?.meter)
                result.push({
                    client_id: field?.client_id || "",
                    num: field?.num || "",
                    created_at: field?.created_at || "",
                    serial_number: serial,
                    meter: meter?.label || "",
                    install_point: meter?.install_point || "",
                    value: value || "",
                })
            }
        }
        setExportData([...result])
        setExportCols([...exportReadersCols])
    }, [filteredFields, meters])

    return(
        <div className={s.mainBlock} >
            <DefaultTitle title={"Таблица показаний приборов учёта"} justify={true} />
            <div className={s.title} style={{height: "50px"}}>
                <WebSitesDivButton text={"Приборы учёта"} color={"blue"} clickEvent={() => setShowMetersPopup(true)}/>
                {savingAction
                    ? <WebSitesDivButton text={"Обработка"} color={"noactive"} animated={false}/>
                    : <WebSitesDivButton text={"Обновить"} clickEvent={loadStatistic}/>
                }
            </div>

            {audioFile.length > 0 && <Player file={audioFile}/>}
            <DownloadButton data={exportData} columns={exportCols} file={"показания.csv"}/>

            <div className={s.tableBlock}>
                <table className={s.formTable} style={{textAlign: "left", margin: "24px 0"}}>
                    <thead>
                    <tr>
                        {readersHeaders.map(
                            elem =>
                                <th key={elem?.key} onClick={() => sortData(elem?.key)}>
                                    {elem?.label}

                                    {sortStatus && (sortKey === elem?.key) &&
                                    <span><GlobalSvgSelector id="select-arrow" /></span>
                                    }

                                    {!sortStatus && (sortKey === elem?.key) &&
                                    <span className={s.rotate}><GlobalSvgSelector id="select-arrow" /></span>
                                    }
                                </th>
                        )}

                        <MeterHeaderFields meters={meters} sort={sortData}/>
                        <th width={50}>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={s.tableFilter}>
                        {readersHeaders.map(
                            header => header?.key?.length > 0 ?
                                <td key={`${header?.key}_filter`}>
                                    <Creatable
                                        options={curFilterSequence?.[header?.key] || []}
                                        onCreateOption={(e) => freeFilter(header?.key, e)}
                                        createOptionPosition={"first"}
                                        // formatCreateLabel={(v) => ["Поиск ", v].join(" ")}
                                        formatCreateLabel={(v) => `Поиск ${v}`}

                                        defaultValue={{label: "Нет", value: "Нет", k: header?.key}}
                                        onChange={addFilter}
                                        maxMenuHeight={175}
                                        aria-rowcount={10}
                                        styles={filterSelectStyle}
                                        placeholder={"Выбрать файл"}
                                        components={{NoOptionsMessage}}
                                        noOptionsText={"Нет данных"}
                                    />
                                </td>
                                :
                                <td key={`${header?.key}_filter`}>&nbsp;</td>
                        )}
                    </tr>

                    {/*{fields.filter(row => row?.[searchKey]?.toLowerCase().includes(searchWord.toLowerCase())).map((field, index) => (*/}
                    {activeSequence.map((field) =>
                        <tr key={field?.uuid}>
                            {readersHeaders.map(elem =>
                                <td key={`${field?.uuid}_${elem?.key}`} >
                                    {/*{console.log(field[elem?.key])}*/}
                                    {field[elem?.key].toString()}
                                </td>
                            )}
                            <MeterValueFields field={field} meters={meters}/>

                            {callrecords &&
                            <td>
                                {field?.record_url?.length > 0 &&
                                <AudioButton audio={field?.record_url} playAudio={f => setAudioFile(f)}/>
                                }

                                {field?.record_url?.length > 0 &&
                                <DownloadAudioButton audio={field?.record_url}/>
                                }
                            </td>
                            }

                            {!callrecords &&
                            <td>
                                <NoCr />
                            </td>
                            }

                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

            <PaginationBlock curPage={curPage} setCurPage={setCurPage} callData={filteredFields} paginationSize={pageSize}/>

            <Meters show={showMetersPopup} hide={setShowMetersPopup} meters={meters} reload={loadMeters}/>
        </div>
    )
}
