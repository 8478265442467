import React from 'react';

const ModalMessage = (props) => {
    return (
        <div style={{
            position: "fixed",
            zIndex: 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.5)",
            display: props.show ? "block" : "none",
        }} onClick={(v) => props.hide(!props.show)}>
            <div style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                color: "#555",
                backgroundColor: "white",
                minWidth: "550px",
                minHeight: "50px",
                maxWidth: "700px",
                padding: "25px",
                textAlign: "center",
                borderBottom: "1px solid #dbdbdb",
                borderRadius: "10px",
                boxShadow: "unset 0 4px 0 rgb(0 0 0 / 8%)",
            }} onClick={(e) => e.stopPropagation()}>

                <div style={{
                    fontSize: "35px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                    color: props.error ? "red": "rgb(85, 85, 85)"
                }}>
                    {props.label ? props.label : ""}
                </div>
                {props.message  &&
                <span style={{fontSize: "35px",}}>
                    {props.message}
                </span>}


                {props.children ?
                    <span style={{
                        fontSize: "35px",
                    }}>
                        <div style={{marginTop: "10px"}}>
                            {props.children}
                        </div>

                    </span> : null}
                {!props.noStandartButton &&
                <div className="white-button margin-center w20"
                     style={{marginTop: "10px"}}
                     onClick={(v) => props.hide(!props.show)}>
                    {props.standartButtonText ? props.standartButtonText : "ОК"}
                </div>
                }


            </div>

        </div>

    )
}


export default ModalMessage;
