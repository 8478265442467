export const smsHistoryHeaders = [
    {key: "created_at", label: "Дата,время", data_type: "dt"},
    {key: "number", label: "Номер", data_type: "text"},
    {key: "provider", label: "Оператор", data_type: "dict", dict_key: "descp"},
    {key: "status", label: "Статус", data_type: "dict", dict_key: "descp"},
    {key: "text", label: "Текст", data_type: "text"},
]

export const smsHistoryHeadersAero = [
    {key: "date_created", label: "Дата, время", data_type: "dt"},
    {key: "number", label: "Номер", data_type: "text"},
    {key: "status", label: "Статус", data_type: "text"},
    {key: "cost", label: "Стоимость", data_type: "text", repl: [[".", ","]]},
    {key: "text", label: "Текст", data_type: "text"},
]