import axios from "axios";

// const serviceUrl = "http://127.0.0.1:8003/api/v1/"
const serviceUrl = "https://backend.telezon.ru:8001/services/api/v1/"


export const getPinsPacket = async (params) => {
    let url = `${serviceUrl}packages/${params?.login}/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${params.login} ${params.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.data)
}