import s from "./CollapseB.module.scss"
import React from "react";

export const CollapseB = ({expanded, changeStatus}) => {

    return (
        expanded
        ?<div className={s.showBlockB} onClick={() => changeStatus(!expanded)}>&#11141; свернуть &#11141;</div>
        :<div className={s.showBlockB} onClick={() => changeStatus(!expanded)}>&#11143; развернуть &#11143;</div>
    )
}