import React from 'react'
import SvgPicture from '../../../../../ui/SvgPicture/SvgPicture'
import s from './SvgDiagram.module.scss'

const SvgDiagram = ({ packet, currency }) => {
  return (
    <div className={s.w250}>
      <div className={s.svgPicture}>
        <p>{100 -((+(packet?.package_used || 0) / +(packet?.package_size || 0)) * 100).toFixed(2)}%</p>
        <SvgPicture
          stPercent={100 - Math.round((+(packet?.package_used || 0) / +(packet?.package_size || 0)) * 100)}
        />
      </div>
      <div className={s.info}>
        <div className={s.criteria}>
          <p>Потрачено</p>
          <span>{packet?.package_used} {currency || "руб."}</span>
        </div>
        <div className={s.criteria}>
          <p>Осталось</p>
          <span>{packet?.package_size - packet?.package_used} {currency || "руб."}</span>
        </div>
      </div>
    </div>
  )
}

export default SvgDiagram
