import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import s from './Documents.module.scss'

const Documents = ({ documents, activePage, docsInOnePage }) => {
    const [activeSequence, setActiveSequence] = useState([])

    useEffect(() => {
        setActiveSequence(
            documents.sort((a, b) => {
                if (a?.priority > b?.priority) return 1
                if (a?.priority < b?.priority) return -1

                if (a?.id > b?.id) return 1
                if (a?.id < b?.id) return -1
            })
            .slice(
                activePage * docsInOnePage - docsInOnePage,
                activePage * docsInOnePage,
            ))
    }, [documents, activePage, docsInOnePage])
    return (
        <div>
            {activeSequence.map(doc =>
                <p key={doc.id}>
                    {doc?.link?.length > 0 && doc?.extern_link?.length === 0 &&
                    <Link className={s.link} target="_blank" to={'/documents/' + doc.link} download>
                        {doc.label}
                    </Link>
                    }

                    {doc?.extern_link?.length > 0 && doc?.link?.length === 0 &&
                    <a href={doc.extern_link} target="_blank" className={s.link} >{doc.label}</a>
                    }
                </p>
            )}
        </div>
    )
}

export default Documents
