import {useTheme} from "../../../../hooks/useTheme";
import s from "./abonentsMetersPopup.module.scss";
import DefaultButton from "../../../../ui/Button/Button";
import {clientMetersHeaderDwnld, uploadsTypes} from "../variables";
import {LoadingDots} from "../../../../ui/Loading/Loading";
import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {clientsMetersSchema} from "../schemas";
import {getDateFromStr, normalizeDateDMYHMS} from "../../../../sortFunction";
import PaginationBlock from "../../../../ui/PaginationBlock/PaginationBlock";


export const UploadsPopup = (props) => {
    const pageSize = 5

    const [filteredRows, setFilteredRows] = useState([])
    const [curPage, setCurPage] = useState(1)

    const {register, handleSubmit, control, reset, setValue, watch, formState: {errors}} = useForm({
        resolver: yupResolver(clientsMetersSchema),
        mode: 'onBlur'
    })

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "data", // unique name for your Field Array
    });

    useEffect(() => {
        setFilteredRows(
            fields.slice(
                pageSize * curPage - pageSize,
                pageSize * curPage
            ).map(e => e?.id)
        )

    }, [curPage, fields])

    useEffect(() => {
        if (props?.data) {
            let data = props?.data?.sort((a, b) => getDateFromStr(a?.task_date) < getDateFromStr(b?.task_date) ? 1 : -1)
            reset({"data": data})
        } else {
            reset({"data": []})
        }

    }, [props?.data])

    return (
        <div style={{
            position: "fixed", zIndex: 1000, inset: 0,
            width: "100%", height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.5)",
            display: props.show ? "block" : "none",
        }} onClick={(v) => props.hide()}>
            <div style={{
                position: "fixed",
                display: "flex", flexDirection: "column", alignItems: "center",
                top: "50%", left: "50%", marginRight: "-50%", transform: "translate(-50%, -50%)",
                color: "#555", backgroundColor: "white",
                maxWidth: "800px", padding: "25px",
                textAlign: "center",
                borderBottom: "1px solid #dbdbdb", borderRadius: "10px",
                boxShadow: "unset 0 4px 0 rgb(0 0 0 / 8%)",
            }} onClick={(e) => e.stopPropagation()}>
                <div style={{
                    display: "flex", justifyContent: "space-between",
                    fontSize: "16pt", fontWeight: "bold",
                    height: "100%", width: "100%",
                    marginBottom: "20px",
                    color: props.error ? "red": "rgb(85, 85, 85)"
                }}>
                    История загрузок списков на сервер
                    <div className={s.buttonsTop}>
                        {props?.loading && <LoadingDots />}

                        {!props?.loading &&
                        <div>
                            <DefaultButton text={"↺"} clickEvent={props.update} alter={"Обновить"} />
                        </div>
                        }

                        <div>
                            <DefaultButton text={"╳"} clickEvent={() => props.hide()} alter={"Закрыть окно"}/>
                        </div>
                    </div>
                </div>

                {fields?.length > 0 &&
                <table className={`${s.formTableM} ${s.td_padding_10}`}>
                    <thead>
                        <tr>
                            <td>Дата</td>
                            <td>Тип</td>
                            <td>Выполнена</td>
                        </tr>
                    </thead>
                    <tbody>
                    {fields.map((field, index) =>
                        filteredRows.includes(field?.id) &&
                        <tr key={field?.id} >
                            <td>{normalizeDateDMYHMS(getDateFromStr(field?.task_date))}</td>
                            <td>{uploadsTypes?.[field?.upload_type] || "Неизвестный тип"}</td>
                            <td>{field?.uploaded ? "Да" : "Нет"}</td>
                        </tr>
                    )}
                    </tbody>
                    <PaginationBlock curPage={curPage} setCurPage={setCurPage} callData={fields} paginationSize={pageSize}/>
                </table>
                }
            </div>
        </div>
    )
}