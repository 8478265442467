import React from 'react'
import s from './UserInfo.module.scss'
import SliderInfo from './Slider/Slider'
import UserCash from './UserCash/Cash'
import { useTheme } from '../../../hooks/useTheme'
import SmallSlider from './SmallSlider/SmallSlider'

const UserInfo = ({ popup, setPopup }) => {
    const theme = useTheme()

    return (
        <>
            {theme.balance && (
                <div className={`${theme.designType === "minimal" ? s.minimalDesign : s.mainBlock}`}>
                    <div className={`${theme.designType === "minimal" ? s.balanceSmall : s.slider}`}>
                        {theme.designType !== "minimal" &&
                        <div className={s.sliderTitle}>
                            <p className={s.sliderParagraph}>Ваш баланс:</p>
                            <button
                                className={s.btn}
                                onClick={() => {
                                    setPopup("payments")
                                }}
                            >
                                Пополнить+
                            </button>
                        </div>
                        }

                        {theme.designType === "minimal" ?
                            <div className={s.sliderMain}>
                                <div className={s.sliderFullW}>
                                    <SliderInfo/>
                                </div>
                                <button
                                    className={`${s.btn} ${s.btnH}`}
                                    onClick={() => {
                                        setPopup("payments")
                                    }}
                                >
                                    +
                                </button>
                            </div>
                            :
                            <SliderInfo/>

                        }
                    </div>
                    {theme.designType !== "minimal" &&
                    <div className={s.cash}>
                        <SmallSlider />
                    </div>
                    }
                </div>
            )}
        </>
    )

}

export default UserInfo
