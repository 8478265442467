import React, {useEffect, useState} from 'react'
import {useTheme} from '../../../../../../hooks/useTheme'
import s from './TelezonSecurity.module.scss'
import Tippy from '@tippyjs/react'
import 'tippy.js/animations/scale.css'
import CheckBox from "../../../../../../ui/CheckBox/CheckBox";


export const Security = ({antifraud, changeAntifraud}) => {
    const {helpText, isHelperOpen} = useTheme()
    const [localTooltip, setLocalTooltip] = useState(false)
    const [fraudStatus, setFraudStatus] = useState(antifraud)

    useEffect(() => {
        setFraudStatus(antifraud)
    }, [antifraud])


    return(
        <div className={s.telezonSecurity}>
            {/*<AnimateCheckbox />*/}
            <CheckBox checked={fraudStatus} chStatus={changeAntifraud}/>
            <p className={s.text}>Telezon Security</p>

            <Tippy
                visible={localTooltip}
                animation={'scale'}
                content={
                    <div
                        style={{
                            background: 'var(--background-gradient-st)',
                            color: 'var(--main-font-color)',
                            padding: '12px',
                            borderRadius: '10px',
                            border: '1px solid var(--border-default)',
                            boxShadow: '0 0 20px 0 var(--border-default)',
                        }}
                    >
                        <b>Telezon Security</b> – уникальная услуга от Телезон, которая надежно защитит IP-оборудование от несанкционированного подключения и риска потери существенных сумм.
                        <br/><br/>
                        В связи с внедрением новых SIP-технологий и устройств на сетях связи РФ, число взломов даже сертифицированного клиентского оборудования резко возросло. Прописанные нами алгоритмы определяют мошенников на ранней стадии взлома и блокируют незаконный вывод денежных средств. Таким образом, позволяя вам не стать жертвой несанкционированного подключения.

                    </div>
                }
            >
                <p
                    className={s.mark}
                    onClick={() => {
                        if (document.body.clientWidth < 1250) {
                            setLocalTooltip(true)
                            isHelperOpen(!helpText)
                        }
                    }}
                    onMouseOver={() => {
                        setLocalTooltip(true)
                    }}
                    onMouseLeave={() => {
                        setLocalTooltip(false)
                    }}
                >
                    ?
                </p>
            </Tippy>
        </div>
    )
}


