import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../../../hooks/useTheme'
import s from './TableRow.module.scss'
import {normalizeDateDMHMS} from "../../../../../sortFunction";
import {changeCallRecordStatus, changeFeedbackStatus} from "../../../../../api";
import {incomingCallsHeaders} from "../../../../../provider/variables";
import ReactSelect from "react-select";
import Creatable, { useCreatable } from 'react-select/creatable';
import GlobalSvgSelector from "../../../../GlobalSvgSelector/GlobalSvgSelector";
import SmsSenderPopup from "../../../../../ui/Popup/smsSenderPopup";


// https://react-select.com/styles
const filterSelectStyle = {
    control: () => ({
        height: "30px",
        display: 'flex',
        textAlign: 'left',
    }),
    menu: (provided, state) => ({
        ...provided,
        minWidth: "150px",
        width: "100%"
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        backgroundColor: state.isSelected
            ? "rgba(41,165,61,1)"
            : state.isFocused
                ? "rgba(254,79,26,1)"
                : "white",
        color: (state.isSelected || state.isFocused)
            ? "white"
            : "black"
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontWeight: state.data && Array.isArray(state.data.label) ? "bold" : "normal"
    }),
}

const TableRow = ({ rawData, setActiveComment, isActive, sendCall, changeStatus, crIsActive, changeMenu }) => {
    const {ivrData} = useTheme()
    const [status, setStatus] = useState(!!rawData["status"])
    const [showSmsSender, setShowSmsSender] = useState(false)
    const [showTargetSelect, setShowTargetSelect] = useState(false)
    const [ivrMenuList, setIvrMenuList] = useState([]) // список доступных меню
    const [data, setData] = useState(rawData)
    const uuidRe = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

    useEffect(() => {
        setStatus(rawData.status)
        setData(rawData)
    }, [rawData, rawData.status])

    const openMailAgent = () => {
        if (!data.email) return

        let url = 'mailto:' + data.email;
        window.open(url, 'Mailer');
    }

    useEffect(() => {
        if (!ivrData || !ivrData.data || !ivrData.data || ivrMenuList.length > 0) return
        const menuData = ivrData.data.filter(e => e.name === "Голосовое меню")
        if (menuData.length === 0) return
        // menuData.map((e, index) => console.log(e.objects))

        let elements = []
        for (const e of menuData) {
            for (const object of e.objects) {
                if (!object.descr) continue
                elements.push({id:object.number, value: object.name, label: object.descr})
            }
        }
        setIvrMenuList([...elements])
    }, [ivrData])

    const selectNewMenuTarget = (e) => {
        setShowTargetSelect(false)
        setData({...data, subject: e.label})
        changeMenu(data.id, e.id)
    }

  return (
      <>
          {/*{console.log(data)}*/}
          <tr className={s.tableRow}>
              {/*<td style={{width: "35px"}}>{data["id"]}</td>*/}
              {data &&
                  data.callTransfer
                      ? <td style={{width: "150px"}} className={s.ivrTheme}
                            onClick={() => setShowTargetSelect(true)}
                            onMouseLeave={() => setShowTargetSelect(false)}
                      >
                          {(!showTargetSelect || ivrMenuList.length === 0) &&
                          <span>
                            {data.subject} {data.callMenu}

                              {data.callTransfer.length > 0 && data.callMenu &&
                              <div className={s.callPath} onClick={() => {
                                  isActive && setActiveComment(data["id"])
                              }}>
                                  {data["callTransfer"].join(" -> ")}
                              </div>
                              }
                          </span>
                          }

                          {showTargetSelect && ivrMenuList.length > 0 &&
                          <ReactSelect
                              options={ivrMenuList}
                              onChange={selectNewMenuTarget}
                              maxMenuHeight={175}
                              aria-rowcount={10}
                              styles={filterSelectStyle}
                              menuIsOpen={showTargetSelect}
                          />
                          }

                      </td>
                      :
                      <td style={{width: "150px", padding: "5px 5px"}} className={s.ivrTheme}>
                          <b>Запрос сайта</b>: {data["subject"]}
                      </td>
              }
              <td style={{width: "150px"}}>{normalizeDateDMHMS(data["date"].replace("T", " "))}</td>
              <td style={{width: "120px"}}>
                  {crIsActive
                      ? <a href={"/calllog?page=cr&dst_num="+data["phone"]} >{data["phone"]}</a>
                      : data["phone"]
                  }
              </td>


              {/*<td style={{width: "100px"}}>{t["name"]}</td>*/}
              {/*<td style={{width: "150px"}}>{t["email"]}</td>*/}
              <td style={{minWidth: "250px"}}>{data["message"] ? data["message"] : ""}</td>
              <td style={{width: "250px"}} onClick={() => {
                  isActive && setActiveComment(data["id"])
              }}
                  className={s.commentButton}>
                  {data["comment"] ? data["comment"] : ""}

                  <div className={s.tippy} onClick={() => setActiveComment(data["id"])} >
                      Щёлкните мышкой, чтобы оставить комментарий
                  </div>
              </td>
              <td style={{width: "100px"}} className={s.hoverButton}
                  onClick={() => changeStatus(data["id"], !status)}>
                  {uuidRe.exec(data["id"].toString())
                      ? status ? "Не обработан" : "Обработан"
                      : status ? "Обработан" : "Не обработан"
                  }

              </td>
              <td className={s.reCallButton} >
                  {data.phone &&
                  <div style={{width: "25px", height: "25px"}} onClick={() => sendCall(data["phone"])}
                       className={s.bWithTt}
                  >
                      <GlobalSvgSelector id="call-ico" />
                      <div className={s.toolTip}
                          style={{
                              background: 'var(--background-gradient-st)',
                              color: 'var(--main-font-color)',
                              padding: '12px',
                              borderRadius: '10px',
                              border: '1px solid var(--border-default)',
                              boxShadow: '0 0 20px 0 var(--border-default)',
                              position: "absolute",
                              display: "none",
                          }}
                      >
                          Позвонить
                      </div>
                  </div>
                  }

                  {data.phone &&
                  <div style={{width: "25px", height: "25px"}} onClick={() => setShowSmsSender(true)}
                       className={s.bWithTt}
                  >
                      <GlobalSvgSelector id="sms-ico"/>

                      <div className={s.toolTip}
                           style={{
                               background: 'var(--background-gradient-st)',
                               color: 'var(--main-font-color)',
                               padding: '12px',
                               borderRadius: '10px',
                               border: '1px solid var(--border-default)',
                               boxShadow: '0 0 20px 0 var(--border-default)',
                               position: "absolute",
                               display: "none",
                           }}
                      >
                          Отправить SMS-сообщение
                      </div>
                  </div>
                  }

                  <SmsSenderPopup show={showSmsSender} number={data["phone"]} hide={() => setShowSmsSender(false)}/>

                  {data.email && data.email.length > 0 &&
                  <div onClick={() => window.open('mailto:' + data.email, 'Mailer')}
                     style={{width: "25px", height: "25px"}} className={s.bWithTt} >
                      <GlobalSvgSelector id="mail-ico"/>

                      <div className={s.toolTip}
                           style={{
                               background: 'var(--background-gradient-st)',
                               color: 'var(--main-font-color)',
                               padding: '12px',
                               borderRadius: '10px',
                               border: '1px solid var(--border-default)',
                               boxShadow: '0 0 20px 0 var(--border-default)',
                               position: "absolute",
                               display: "none",
                           }}
                      >
                          Отправить email
                      </div>
                  </div>
                  }

              </td>
          </tr>


      </>

  )

}

const TableRows = (
    {
        data, setActiveComment,isActive,
        sendCall,getFilters,addFilter, changeMenu,
        curFilterSequence, changeStatus }) => {
    const theme = useTheme()
    const [crIsActive, setCrActive] = useState(false)
    // const [filterValue, setFilterValue] = useState({label: "Нет", value: "Нет", k: ""})

    // https://react-select.com/styles
    const filterSelectStyle = {
        control: () => ({
            height: "30px",
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        menu: (provided, state) => ({
            ...provided,
            minWidth: "150px"
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            backgroundColor: state.isSelected
                ? "rgba(41,165,61,1)"
                : state.isFocused
                    ? "rgba(254,79,26,1)"
                    : "white",
            color: (state.isSelected || state.isFocused)
                ? "white"
                : "black"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: state.data && Array.isArray(state.data.label) ? "bold" : "normal"
        }),
    }

    const checkService = () => {
        if (!theme.services || !theme.services.active_usl) return

        const serv_name = "Запись телефонных разговоров"
        let serv = theme.services.active_usl.filter(
            u => u.usl_name && u.usl_name.includes(serv_name) && u.usl_active)

        return serv.length > 0
    }

    useEffect(() => {
        if (!theme.services || !theme.services.active_usl) return

        setCrActive(checkService())

    }, [theme.services])

    const freeFilter = (key, e) => {
        addFilter({label: e, value: e, k: key})
        // setFilterValue({label: e, value: e, k: key})
    }

  return (
    <>
        <tr className={s.tableFilter}>
            {Object.keys(incomingCallsHeaders).map(
                key => key !== "" ?
                    <td onClick={() => getFilters(key)} key={key+"_filter"}>
                        {/*<ReactSelect*/}
                        {/*    options={curFilterSequence}*/}
                        {/*    defaultValue={{label: "Нет", value: "Нет", k: key}}*/}
                        {/*    onChange={addFilter}*/}
                        {/*    maxMenuHeight={175}*/}
                        {/*    aria-rowcount={10}*/}
                        {/*    styles={filterSelectStyle}*/}
                        {/*    // menuIsOpen={isOpenSelect2}*/}
                        {/*/>*/}
                        <Creatable
                            options={curFilterSequence}
                            onCreateOption={(e) => freeFilter(key, e)}
                            createOptionPosition={"first"}
                            formatCreateLabel={(v) => ["Поиск ", v].join(" ")}

                            defaultValue={{label: "Нет", value: "Нет", k: key}}
                            onChange={addFilter}
                            maxMenuHeight={175}
                            aria-rowcount={10}
                            styles={filterSelectStyle}

                        />
                    </td>
                    : <td key={key+"_filter"}>&nbsp;</td>
            )}
        </tr>

      {data.map(t => (
          <TableRow rawData={t} key={t.id} sendCall={sendCall}
                    changeStatus={changeStatus} changeMenu={changeMenu}
                    setActiveComment={setActiveComment} isActive={isActive} crIsActive={crIsActive}/>
        ))}
        {/*{data.map(t => t.id === 1848 && console.log(t))}*/}

    </>
  )
}

export default TableRows
