import Slider from 'rc-slider'
import React, {useEffect, useState} from 'react'
import {useTheme} from '../../../../../../hooks/useTheme'
import s from './MinutesSlider.module.scss'
import './rcSlider.css'
import {minutesMarks} from "../../../../../../provider/variables";
import ModalMessage from "../../../../../../ui/Popup/ModalMessage";

const MinutesSlider = ({setMinutesPrice, setActiveMinutes, activeMinutes}) => {
  const {theme, services, packetsVoipNumers} = useTheme()
  const [stateMinutes, setStateMinutes] = useState(5)
  const [minPacket, setMinPacket] = useState(1)
  const [popupTarget, setPopupTarget] = useState("")

  useEffect(() => {
    setMinutesPrice(0)
  }, [])


  const handleChange = e => {
    if (!minutesMarks[e]) return
    setActiveMinutes(parseInt(minutesMarks[e].label))
  }

  useEffect(() => {
    if (!services || !services.active_usl) return

    let usls = services.active_usl.filter(u => packetsVoipNumers.includes(u.usl_id))
    let today = new Date();

    let packetSize = 0
    for (const u of usls) {
      let dt = new Date(u.date);


      if (dt.getFullYear() !== today.getFullYear() ||
          dt.getMonth() !== today.getMonth()) continue

      packetSize = u.context.packet_size > packetSize ? u.context.packet_size : packetSize
    }

    const p = Object.keys(minutesMarks).filter(m => parseInt(minutesMarks[m].label) === packetSize)
    if (p.length === 0) return
    setMinPacket(parseInt(p[0]))

  }, [activeMinutes, services])

  useEffect(() => {
    Object.values(minutesMarks)
        .map((elem, key) =>
            activeMinutes.toString() === elem.label.toString() && setStateMinutes(key + 1))

  }, [activeMinutes])

  return (
    <div className={s.wrapper}>
      <div className={s.slider}>
        {theme === 'dark' ? (
          <Slider value={stateMinutes}
              onChange={e => handleChange(e)} max={12} min={1} step={1} marks={minutesMarks} />
        ) : (
          <Slider
            className="dark-mode"
            onChange={e => handleChange(e)}
            value={stateMinutes}
            max={12}
            min={1}
            step={1}
            marks={minutesMarks}
          />
        )}
        <p>Выберите количество минут в пакете</p>
      </div>

    </div>
  )
}

export default MinutesSlider
