import s from "./CRMIntegrationsPage.module.scss";
import React from "react";
import {Controller} from "react-hook-form";


export const InputLine = ({errors, register, name, title, dataType="text", redOnly=false, value=null}) => {
    return(
        <div className={s.formBlock}>
            <p className={s.blockTitle}>{title}</p>
            {value &&
            <input
                type={dataType}
                placeholder={title}
                value={value}
                className={
                    errors?.[name]
                        ? `${s.defaultInput} ${s.error}`
                        : s.defaultInput
                }
                {...register(name)}


                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key) && dataType === "numbers") {
                        event.preventDefault();
                    }
                }}
                readOnly={redOnly}
            />
            }

            {!value &&
            <input
                type={dataType}
                placeholder={title}
                className={
                    errors?.[name]
                        ? `${s.defaultInput} ${s.error}`
                        : s.defaultInput
                }
                {...register(name)}


                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key) && dataType === "numbers") {
                        event.preventDefault();
                    }
                }}
                readOnly={redOnly}
            />
            }

        </div>
    )
}

export const ExtInputLine = ({errors, register, name, title, dataType="text", hidden=false}) => {
    return(
        hidden
            ?
            <input
                type="hidden"
                placeholder={title}
                // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                className={
                    errors?.[name]
                        ? `${s.defaultInput} ${s.error}`
                        : s.defaultInput
                }
                {...register(name)}

                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key) && dataType === "numbers") {
                        event.preventDefault();
                    }
                }}
            />
            :
            <div className={s.formBlock}>
                <p className={s.blockTitle}>{title}</p>
                <input
                    type="text"
                    placeholder={title}
                    // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                    className={
                        errors?.[name]
                            ? `${s.defaultInput} ${s.error}`
                            : s.defaultInput
                    }
                    {...register(name)}

                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key) && dataType === "numbers") {
                            event.preventDefault();
                        }
                    }}
                />
            </div>
    )
}

export const CheckboxLine = ({errors, register, name, title, control}) => {
    return(
        <div className={s.formBlock}>
            <p className={s.blockTitle}>{title}</p>
            <Controller
                control={control}
                values={[true, false]}
                name={name}
                instanceId={2}
                rules={{required: true}}
                render={({
                             field: {onChange, onBlur},
                         }) => (
                    <label>
                        <input type={"checkbox"}
                               className={s.realCheckBox}
                               onBlur={onBlur} // notify when input is touched
                               defaultChecked={false}
                               onChange={onChange}
                               {...register(name)}
                        />
                        <div className={s.fakeCb}/>
                    </label>

                )}
            />
        </div>
    )
}

export const LabelLine = ({errors, register, name, title, dataType="text", hidden=false, default_value=""}) => {
    return(
        <div className={s.formBlock}>
            <p className={s.blockTitle}>{title}</p>
            <textarea
                placeholder={title}
                readOnly={true}
                // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                className={`${s.textArea}`}
                {...register(name)}
            />
        </div>
    )
}
