import React, {useState} from 'react'
import { useTheme } from '../../../../../../hooks/useTheme'
import s from './Setting.module.scss'
import {changeParam} from "../../../../../../api";
import {HelpElemTippy} from "../../../../../../ui/HelpElemTippy/HelpElemTippy";
import CheckBox from "../../../../../../ui/CheckBox/CheckBox";

const Setting = ({ info, usl }) => {
    const { userAccount } = useTheme()
    const [checkbox, setCheckbox] = useState(info.value)

    const activateParam = () => {
        if (!usl.id_set_drive) return
        changeParam({
            login: userAccount["login"],
            token: userAccount["token"],
            id_set_drive: usl.id_set_drive,
            param_id: info["param_id"],
            new_val: !info["value"]
        }).then( res => {
            !res["error"] ? setCheckbox(!info["value"]) : console.log(res["error"])
            info["value"] = !res["error"] ? !info["value"] : info["value"]
        })
    }

    return (
        <div className={s.wrapper}>
            <p>{info.param_name}</p>

            {info.description && info.description.length > 0 &&
            <HelpElemTippy text={info.description}/>
            }

            <div className={s.checkboxWrapper}>
                <CheckBox styleParams="inline"
                          checked={checkbox}
                          chStatus={activateParam}/>
            </div>
        </div>
    )
}

export default Setting
