import React, {useEffect, useState} from 'react'
import { Controller, useForm } from 'react-hook-form'
import s from './Form.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTheme } from '../../../../hooks/useTheme'
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'

import CheckBox from "../../../../ui/CheckBox/CheckBox";
import {smsServices, timezones} from "../../../../provider/variables";
import Select from "react-select";
import {NoOptionsMessage, useSelectStyles} from "../../../../ui/react-select-styles";
import {getSmsSettings, saveSmsAeroSettings, saveSmsSettings} from "../../../../api-sms";
import {sleep} from "../../../../sortFunction";
import SmsSenderPopup from "../../../../ui/Popup/smsSenderPopup";
import {setActiveSmsService} from "../../../../api";
import {WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";

const schema = yup
    .object({
        smsActive: yup.bool().required(),
        smsEmail: yup.string().required(),
        smsApiKey: yup.string().required(),
        smsSign: yup.string().required(),
    })
    .required()

const telezonSchema = yup
    .object({
        pin: yup.string().required(),
        is_active: yup.bool().required(),
    })
    .required()


const AeroSettings = () => {
    const theme = useTheme()
    const {setActivePopupGlobal} = useTheme()
    const [smsSettings, setSmsSettings] = useState({})
    const [isSmsActive, setSmsActive] = useState(false)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        if (!theme.smsSettings || !theme.smsSettings.api_key) return
        setSmsSettings({...theme.smsSettings})
        setSmsActive(theme.smsSettings.is_active)
    }, [theme.smsSettings])

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            smsActive: isSmsActive,
            smsEmail: "",
            smsApiKey: "",
            smsSign: "",
        }
    })

    useEffect(() => {
        if (Object.keys(errors)?.length === 0) return

        setShowErrors(true)
        sleep(2000).then(() => setShowErrors(false))
    }, [errors])

    const onSubmit = (data) => {
        // Сохраняем настроки смс
        saveSmsAeroSettings({...theme.userAccount,
            apiKey: data.smsApiKey,
            sign: data.smsSign,
            email: data.smsEmail,
            isActive: isSmsActive,
        })
            .then(() => {
                setActiveSmsService({...theme.userAccount, service: "telezon"})
                    .then(() => setActivePopupGlobal("successSave"))
                    .catch((r) => {
                        setActivePopupGlobal("failSave")
                        console.log(r)
                    })
            })
            .catch(() => setActivePopupGlobal("failSave"))
    }

    useEffect(() => {
            if (!smsSettings) return

            if (smsSettings) {
                reset({
                    smsActive: isSmsActive,
                    smsEmail: smsSettings.email,
                    smsApiKey: smsSettings.api_key,
                    smsSign: smsSettings.sign,
                })
            }

        }, [smsSettings]
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.formBlock}>
                <p className={s.blockTitle} style={{fontWeight: "bold"}}>Активность настроек</p>
                <div style={{display: "inline-block"}}>
                    <Controller
                        control={control}
                        values={[true, false]}
                        name="smsActive"
                        rules={{required: true}}
                        render={({field: {onChange}}) => (
                            <CheckBox styleParams="inline"
                                      checked={isSmsActive}
                                      chStatus={(r) => {onChange(r); setSmsActive(r)}}
                            />
                        )}
                    />
                </div>
            </div>

            <div className={s.formBlock}>
                <p className={s.blockTitle}>Адрес электронной почты</p>
                <input
                    type="text"
                    className={
                        (errors.smsEmail && showErrors)
                            ? `${s.defaultInput} ${s.error} ${s.shake}` : s.defaultInput
                    }
                    {...register('smsEmail')}
                />
            </div>

            <div className={s.formBlock}>
                <p className={s.blockTitle}>Секретный ключ</p>
                <input
                    type="password"
                    className={
                        (errors.smsApiKey && showErrors)
                            ? `${s.defaultInput} ${s.error} ${s.shake}` : s.defaultInput
                    }
                    {...register('smsApiKey')}
                />
            </div>

            <div className={s.formBlock}>
                <p className={s.blockTitle}>Подпись отправителя</p>
                <input
                    type="text"
                    className={
                        (errors.smsSign && showErrors)
                            ? `${s.defaultInput} ${s.error} ${s.shake}` : s.defaultInput
                    }
                    {...register('smsSign')}
                />
            </div>

            <div className={s.historyButton} onClick={() => setActivePopupGlobal("smsHistory")}>Посмотреть историю SMS-сообщений</div>

            <button className={[s.button, s.floatR].join(" ")} style={{marginTop: "25px"}}>Сохранить</button>
        </form>
    )
}


const TelezonService = () => {
    const theme = useTheme()
    const {setActivePopupGlobal} = useTheme()
    const [dialogs, setDialog] = useState("")
    const [smsData, setSmsData] = useState({})
    const [isSmsActive, setSmsActive] = useState(false)
    const [showErrors, setShowErrors] = useState(false)

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(telezonSchema),
        mode: 'onBlur',
        defaultValues: {
            pin: 0,
            is_active: false,
        }
    })

    useEffect(() => {
        if (Object.keys(errors)?.length === 0) return

        setShowErrors(true)
        sleep(2000).then(() => setShowErrors(false))
    }, [errors])

    const loadSettings = () => {
        const parseError = (e) => {
            if (e?.response?.status === 404) {

            }
            console.log(e?.response?.status)
            // setActivePopupGlobal("failSave")
        }

        getSmsSettings({...theme.userAccount})
            .then(r => {
                reset(r?.data)
                setSmsActive(!!r?.data?.is_active)
                setSmsData(r?.data)
            })
            .catch(r => parseError(r))
    }

    useEffect(() => {
        if (!theme.userAccount?.login || !theme.userAccount?.token) return
        loadSettings()

    }, [theme.userAccount, theme.vatsNames])

    const onSubmit = (data) => {
        data.domain = theme?.vatsDomain?.domain || `${data.domain}.sip.telezon.ru`
        saveSmsSettings({...theme.userAccount, payload: data})
            .then(() => {
                setActiveSmsService({...theme.userAccount, service: "telezon"})
                    .then(() => {
                        setActivePopupGlobal("successSave")
                        loadSettings()
                    })
                    .catch((r) => {
                        setActivePopupGlobal("failSave")
                        console.log(r)
                    })
            })
            .catch((r) => {
                setActivePopupGlobal("failSave")
                console.log(r)
            })

    }

    const turnOn = () => {
        let data = {...smsData}
        data.is_active = true
        onSubmit(data)
    }

    const turnOff = () => {
        let data = {...smsData}
        data.is_active = false
        onSubmit(data)
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            {isSmsActive &&
            <div className={`${s.serviceStatus} ${s.activeService}`}>
                <span>
                    Сервис активен
                </span>
                <WebSitesDivButton text={"Отключить"} clickEvent={turnOff}/>
            </div>
            }

            {!isSmsActive &&
            <div className={`${s.serviceStatus} ${s.noActiveService}`}>
                <span>
                    Сервис неактивен
                </span>
                <WebSitesDivButton text={"Включить"} color={"green"} clickEvent={turnOn}/>
            </div>
            }

            <div className={s.historyButton} onClick={() => setActivePopupGlobal("smsHistory")}>Посмотреть историю SMS-сообщений</div>
            {/*<div className={s.historyButton} onClick={() => setDialog("sms")}>Открыть форму отправки сообщения</div>*/}

            {/*<button className={[s.button, s.floatR].join(" ")} style={{marginTop: "25px"}}>Сохранить</button>*/}
            {/*<SmsSenderPopup show={dialogs === "sms"} hide={() => setDialog("")} number={"79232912883"} />*/}
        </form>
    )
}

export const SmsSettingsForm = () => {
    const theme = useTheme()
    const [activeService, setActiveService] = useState({})
    const [openedSelect, setOpenedSelect] = useState("")
    const [currentService, setCurrentService] = useState("")


    useEffect(() => {
        if (theme.smsSettings?.service?.length === 0) return
        if (currentService === theme.smsSettings?.service) return

        if (smsServices.find(e => e?.value === theme.smsSettings?.service)) {
            setActiveService(smsServices.find(e => e?.value === theme.smsSettings?.service))
            setCurrentService(theme.smsSettings?.service)
        } else {
            setActiveService(smsServices.find(e => e?.value === "telezon"))
            setCurrentService("telezon")
        }
    }, [theme.smsSettings])

    return (
        <>
            <div className={s.header}>
                <div className={s.title}>
                    <DefaultTitle title="Настройки SMS-рассылок" />
                </div>
            </div>

            <div>
                <p className={s.blockTitle}>Сервис SMS-рассылок</p>
                <div
                    className={`${s.noPadding}`}
                    onClick={() => {
                        setOpenedSelect(openedSelect === "serviceSelect" ? "" : "serviceSelect")
                    }}
                >
                    <Select
                        onChange={setActiveService}
                        options={smsServices}
                        styles={useSelectStyles}
                        isClearable
                        isSearchable
                        hideSelectedOptions
                        value={activeService}

                        menuIsOpen={openedSelect === "serviceSelect"}
                    />
                </div>
            </div>

            {activeService?.value === "aero" &&
            <AeroSettings />
            }

            {activeService?.value === "telezon" &&
            <TelezonService />
            }
        </>
    )
}
