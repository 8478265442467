import s from "../CRMIntegrationsPage.module.scss";
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import React, {useEffect, useState} from "react";
import {useTheme} from "../../../../hooks/useTheme";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {amoSchema, amoTemplate} from "../schemas";
import {NoOptionsMessage, useSelectStyles} from "../../../../ui/react-select-styles";
import Select from "react-select";
import {InputLine} from "../FormElements";
import {WebSitesButton, WebSitesDivButton} from "../../../../ui/Button/WebSitesButton";
import {getFrontWcDomain} from "../../../../api";
import {saveAmoPhone, saveCrmIntegrations} from "../api";
import {LoadingDots} from "../../../../ui/Loading/Loading";
import Pagination from "../../DocumentPage/Pagination/Pagination";



export const AMOCrmIntegration = ({integration, reload, bubbled=true}) => {
    const {userAccount, setActivePopupGlobal, vatsNames, vatsDomain} = useTheme()
    const [showSettings, setShowSettings] = useState(false)
    const [currentDomain, setCurrentDomain] = useState()
    const [correctFsDomain, setCorrectFsDomain] = useState("")
    const [openedMenu, setOpenedMenu] = useState({})
    const [requestSent, setRequestSent] = useState(false)
    const [saveAction, setSaveAction] = useState(false)

    const [relationships, setRelationships] = useState([])

    const pageSize = 5
    const [pages, setPages] = useState(1) // количество страниц
    const [activePage, setActivePage] = useState(1) // активная страница
    const [activeSequence, setActiveSequence] = useState([])

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(amoSchema),
        mode: 'onBlur',
        // defaultValues: amoTemplate
    })

    useEffect(() => {
        if (integration?.integration?.id) {
            reset(integration)
        } else {
            reset(amoTemplate)
        }

        if (integration?.integration?.fs_domain) {
            let selectedVats = vatsNames.find(e => integration?.integration?.fs_domain.startsWith(e.value))
            setCurrentDomain(selectedVats)
        }
        setRelationships(integration?.integration?.relationships || [])
    }, [integration])

    useEffect(() => {
        if (currentDomain) return
        if (integration?.integration?.fs_domain) {
            let selectedVats = vatsNames.find(e => integration?.integration?.fs_domain.startsWith(e.value))
            setCurrentDomain(selectedVats)
            return;
        }
        if (vatsNames?.length > 0) {
            setCurrentDomain(vatsNames[0])
        }
    }, [vatsNames, integration])

    useEffect(() => {

        if (!currentDomain?.label) return
        if (integration?.fs_domain?.startsWith(currentDomain?.label) && correctFsDomain?.length === 0) return

        setRequestSent(true)
        getFrontWcDomain({...userAccount, domain: currentDomain.value})
            .then(response => {
                setCorrectFsDomain(response?.domain || "")
                setRequestSent(false)
            })
            .catch(r => console.log(r?.response?.status))
    }, [currentDomain])

    const submitForm = (formData) => {
        setSaveAction(true)
        formData["pin"] = userAccount?.login
        formData["domain"] = correctFsDomain
        formData["integration"]["type"] = "amo"

        saveCrmIntegrations({auth: userAccount, ...vatsDomain, data: formData})
            .then((r) => {
                reload()
                setActivePopupGlobal("successSave")
                setSaveAction(false)
            })
            .catch(e => {
                setActivePopupGlobal("oups")
                setSaveAction(false)
                console.log(e)
            })
    }

    const createIntegration = () => {}

    useEffect(() => {
        // console.log(errors)
    }, [errors])

    useEffect(() => {
        setPages(1)
        if (relationships?.length === 0) return
        setPages(Math.ceil(relationships.length / pageSize))
    }, [relationships])

    useEffect(() => {
        let seq = relationships.slice(
            pageSize * activePage - pageSize, // from
            pageSize * activePage // to
        ).map(e => e?.user_id)
        setActiveSequence([...seq])
    }, [relationships, activePage, pages, pageSize])

    return(
        <>
            <form className={`${bubbled ? s.bubble : s.noBlubble} ${showSettings && s.bubbleAnimation} margin-b-24`}
                  style={{width: "70%"}}
                  onClick={() => setOpenedMenu("")}
                  onSubmit={handleSubmit(submitForm)}
            >
                <div className={`${s.fixedBubble}`}>
                    <div className={s.title}>
                        <DefaultTitle title="Интеграция с amoCRM" justify={true}/>
                    </div>

                    {vatsNames?.length > 1 &&
                    <div style={{width: "250px", marginLeft: "24px"}}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenedMenu(openedMenu === "domainSelect" ? "" : "domainSelect")
                        }}
                    >
                        <label>Выберите ВАТС:</label>
                        <div style={{display: "flex", alignItems: "center", minWidth: "250px",}}>
                            <Select
                                onChange={setCurrentDomain}
                                options={vatsNames}
                                styles={useSelectStyles}
                                isClearable
                                isSearchable
                                hideSelectedOptions
                                value={currentDomain}
                                placeholder={"Выбрать ВАТС"}
                                components={{NoOptionsMessage}}
                                noOptionsText={"Ватс отсутствует"}

                                menuIsOpen={openedMenu === "domainSelect"}
                            />
                            {requestSent &&
                            <div style={{margin: "0px 10px"}}>
                                <LoadingDots/>
                            </div>
                            }
                        </div>

                    </div>
                    }
                    <div className={s.formFlex}>
                        {correctFsDomain?.length > 0
                            ? <InputLine errors={errors} register={register} name={"integration.fs_domain"} title={"Домен ВАТС"}
                                         redOnly={true}
                                         value={correctFsDomain}/>
                            : <InputLine errors={errors} register={register} name={"integration.fs_domain"} title={"Домен ВАТС"}
                                         redOnly={true}/>
                        }

                        <InputLine errors={errors} register={register} name={"integration.domain"} title={"Домен CRM"}/>
                        <InputLine errors={errors} register={register} name={"integration.external_num"} title={"Внешний номер"}/>
                        <InputLine errors={errors} register={register} name={"integration.default_num"} title={"Номер по умолчанию"}/>

                        {relationships?.length > 0 &&
                        <WebSitesDivButton text={"Настройка учетных записей"} color={"blue"}
                                           clickEvent={() => setShowSettings(!showSettings)}/>
                        }

                        {!saveAction &&
                        <WebSitesButton text={"Сохранить настройки"}/>
                        }

                        {/*{(!saveAction && relationships?.length === 0) &&*/}
                        {/*    <WebSitesButton text={"Применить интеграцию"} clickEvent={createIntegration}/>*/}
                        {/*}*/}

                        {saveAction &&
                        <WebSitesDivButton text={"Сохраняю"} color={"noactive"} animated={false}/>
                        }

                    </div>
                </div>

                {(showSettings && relationships?.length > 0) &&
                <div className={s.amoSettings}>
                    <table className={`${s.amoTable}`}>
                        <thead>
                        <tr>
                            <th>ID пользователя AMO</th>
                            <th>Учетная запись АМО</th>
                            <th>Внутренний номер</th>
                        </tr>
                        </thead>
                        <tbody>
                        {relationships && relationships.map((elem, index) =>
                            activeSequence?.includes(elem.user_id) &&
                            <tr key={elem.user_id}>
                                <td>
                                    <input type="text" name={'user_id'}
                                           readOnly={true}
                                           className={s.tableInput} style={{width: "150px", maxWidth: "500px"}}
                                           {...register(`integration.relationships[${index}].user_id`)}
                                    />
                                </td>
                                <td>
                                    <input type="text" name={'name'}
                                           className={s.tableInput} style={{width: "500px", maxWidth: "500px"}}
                                           {...register(`integration.relationships[${index}].name`)}
                                    />
                                </td>
                                <td>
                                    <input type="text" name={'inner_phone'}
                                           className={s.tableInput} style={{width: "150px", maxWidth: "150px"}}
                                           {...register(`integration.relationships[${index}].inner_phone`)}
                                    />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <Pagination activePage={activePage} setActivePage={setActivePage} pages={pages}/>

                    {!saveAction &&
                    <WebSitesButton text={"Сохранить настройки"}/>
                    }

                    {saveAction &&
                    <WebSitesDivButton text={"Сохраняю"} color={"noactive"} animated={false}/>
                    }
                </div>
                }

            </form>
        </>
    )
}
