import React, {useEffect, useState} from 'react'
import s from './NumberTypes.module.scss'
import {useTheme} from "../../../../../../../../hooks/useTheme";

const NumberTypes = ({ activeSort, setActiveSort }) => {
  const {numbers} = useTheme()
  const [types, setTypes] = useState([])

  // const types = [
  //   { id: 1, name: 'Городские' },
  //   { id: 2, name: '8800' },
  //   //{ id: 3, name: '8800 Горячие' },
  // ]

  useEffect(() => {
    if (!numbers || numbers.length === 0) return

    numbers.sort((a, b) => a.NUMER > b.NUMER ? 1 : -1)

    let groups = []
    numbers.map(number =>
        number.NUMER.toString().slice(0, 3) === "800"
        && !groups.includes('8800')
            ? groups.push('8800')
            :
        number.NUMER.toString().slice(0, 3) === "391"
        && !groups.includes('Городские')
            ? groups.push('Городские')
            : null
    )

    setTypes([...groups.map((elem, index) => ({id: index + 1, name: elem}))])
    groups.map((elem, index) => elem === "Городские" && setActiveSort({id: index + 1, name: elem}))
  }, [numbers])

  const handleClick = (type) => {
    setActiveSort(type)
  }

  return (
    <div className={s.types}>
      {types.map((type) => (
        <p
          onClick={() => handleClick(type)}
          className={`${s.type} ${activeSort.id === type.id && s.active}`}
          key={type.id}
        >
          {type.name}
        </p>
      ))}
    </div>
  )
}

export default NumberTypes
