import React, {useEffect, useState} from 'react'
import s from './MainBlock.module.scss'
import {useTheme} from "../../../../hooks/useTheme";
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import OutgoingCall from "../../NumersShop/MainBlock/OutgoingCall/OutgoingCall";
import Numbers from "../../NumersShop/MainBlock/Numbers/Numbers";
import ConfirmationPopup from "../../../../ui/Popup/ConfirmationPopup/ConfirmationPopup";
import ModalMessage from "../../../../ui/Popup/ModalMessage";
import {activateCbc, addNumber, addVats, addVoip, byUslRequest, getCredit} from "../../../../api";
import {cards, prices} from "../../../../provider/variables";
import StatusPopup from "../../../../ui/Popup/StatusPopup/StatusPopup";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

const MainBlock = ({ activeBurger, setActiveBurger, url, setActivePopup}) => {
    const [minutesPrice, setMinutesPrice] = useState(0) // цена зависящая от кол-ва минут
    const [activePacket, setActivePacket] = useState({id: 0, price: 0}) // действующий пакет
    const [packetsPrice, setPacketPrice] = useState(0) // стоимость выбранного пакета межгорода
    const [activeMinutes, setActiveMinutes] = useState(
        localStorage.getItem("activeMinutes") ?
            parseInt(localStorage.getItem("activeMinutes")) : 1500) // активное кол-во минут

    const [activeNumbers, setActiveNumbers] = useState([]) // сами номера
    const [numberPrice, setNumberPrice] = useState(0)

    const [popupStatuses, setPopupStatuses] = useState([])
    const [popupTarget, setPopupTarget] = useState("greetings")

    const [popupStatus, setPopupStatus] = useState(true)
    const [byRelations, setByRelations] = useState([])
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showPayStatus, setShowPayStatus] = useState(false)
    const [numbersPoolLoaded, setNumbersPoolLoaded] = useState(false)

    const {cbcServices,
        selectIsOpen, setSelectIsOpen, isHelperOpen, userAccount,
        helpText, numbers, loadNumbersPool, numbersShopPrice, setNumersShopPrice,
        setShopPhonePrice, setShopVoipPrice, balance, services, phoneNumers
    } = useTheme()

    const handleClick = () => {
        if (helpText) isHelperOpen(false)
        if (selectIsOpen) setSelectIsOpen(false)
    }



    const checkService = (name, val, type="vats") => {
        if (!services || !services.active_usl) return false

        if (type === "vats") {
            const serv_name = "Виртуальная IP-ATC <"+val+">"
            let serv = services.active_usl.filter(u =>
                u.usl_name === serv_name && u.usl_active)

            return serv.length > 0
        } else if (type === "voip") {
            const serv_name = cards[name].wTitle+val
            let serv = services.active_usl.filter(u => u.usl_name === serv_name && u.usl_active)

            return serv.length > 0
        }

        // let usls = services.active_usl.filter(u => packetsVoipNumers.includes(u.usl_id))
    }

    const removeLocalNumber = (number_str, all=false) => {
        // Получаем номера из локального хранилища
        let cnt = 0;
        let saved_numbers = []
        if (localStorage.getItem("selectedNumersCount") &&
            localStorage.getItem("selectedNumersCount") > 0) {
            cnt = localStorage.getItem("selectedNumersCount");
            for (let i = 0; i < cnt; i++) {
                saved_numbers.push(localStorage.getItem("selectedNumer"+i.toString()))
            }
        }

        if (all) {
            // Удаляем все
            localStorage.setItem("selectedNumersCount", '0')
            for (let i = 0; i < cnt; i++) {
                localStorage.removeItem("selectedNumer"+i.toString())
            }

            setActiveNumbers([])
        } else {
            saved_numbers = saved_numbers.filter(number => number !== number_str)
            localStorage.setItem("selectedNumersCount", saved_numbers.length.toString())
            saved_numbers.map((num, index) =>
                localStorage.setItem("selectedNumer"+index, num.toString()))

            setActiveNumbers([...activeNumbers.filter(number => number.NUMER !== number_str)])
        }
    }

    useEffect(() => {
        setNumersShopPrice(numberPrice + packetsPrice + prices.cbc)
        setShopPhonePrice(numberPrice)
        setShopVoipPrice(packetsPrice + prices.cbc)
    }, [numberPrice, packetsPrice])

    useEffect(() => {
        if (numbers.length > 0 || numbersPoolLoaded) return

        loadNumbersPool()

        setNumbersPoolLoaded(true)
    }, [numbers])

    useEffect(() => {
        let numbersPrice = 0

        if (phoneNumers.length === 0) {
            activeNumbers.map((elem, id) => {
                numbersPrice += (id === 0 && elem.CATEGORY === "Без категории") ? 100 : elem["PRICE"] + elem["AP"]
            })

        } else {
            activeNumbers.map(num => numbersPrice += num.AP + num.PRICE)
        }

        setNumberPrice(numbersPrice)

    }, [activeNumbers])

    const resultByRequest = (name, val, error) => {
        let n = byRelations
        n.push([name, val > 0])
        setByRelations([...n])

        if (error && error.response && error.response.data) {
            if (error.response.data.includes("no money")) {
                setPopupStatus(true)
                setPopupTarget("balance")
                return
            }
        }

        if (error) {
            if (error.includes("no money")) {
                setPopupStatus(true)
                setPopupTarget("balance")
                return
            } else {
                setPopupStatus(true)
                setPopupTarget("server_error")
                return
            }
        }

        if (val <= 0) {
            setPopupStatus(true)
            setPopupTarget("server_error")
            return
        }
    }

    const getVatsNumber = () => {
        if (activeNumbers.length > 0) {
            return activeNumbers[0].NUMER ? activeNumbers[0].NUMER : ""
        }
    }

    const byAtsHndlr = (confirmed = false) => {
        //if (rangeBar === 0) return false
        // количество сотрудников
        const workers = 3
        if (checkService("vats", workers, "vats")) return

        const vatsResultResponse = (isd) => {
            console.log(isd)
        }

        let req = userAccount
        req.workers = workers
        req.target_number = getVatsNumber()
        req.cr = false

        addVats(req)
            .then(r => {
                r && r.status && r.id_set_drive
                    ? vatsResultResponse(r.id_set_drive)
                    : vatsResultResponse(0)
            })
            .catch(r => {vatsResultResponse(0);})
    }

    const byNumbersHndlr = (confirmed = false) => {
        if (activeNumbers.length === 0) return

        let numbers  = []
        let statuses = popupStatuses //.filter(elem => elem.title !== "Телефонные номера")

        for (const [index, numer] of Object.entries(activeNumbers) ) {
            numbers.push({
                name: numer.NUMER,
                status: "relations",
                price: (parseInt(index) === 0 && numer.CATEGORY === "Без категории") ? 100 : numer["PRICE"] + numer["AP"],
                rmFunction: {target: "Телефонные номера", value: numer.NUMER}
            })
        }

        if (!confirmed) {
            statuses.push({title: "Телефонные номера", elements: numbers})
            setPopupStatuses([...statuses])
        }

        let req = userAccount
        req.numbers = numbers.map(n => n.name)

        const parseResponse = response => {
            if (response.result) {
                for (const elem of response.result) {
                    if (elem.number && elem.isd && elem.isd > 0) {
                        resultByRequest(elem.number.toString(),  true)
                    } else if (elem.number && elem.isd) {
                        resultByRequest(elem.number.toString(),  false, elem.isd)
                    } else if (elem.number) {
                        resultByRequest(elem.number.toString(),  false, elem.isd)
                    }
                }
            }
            setPopupStatus(true)
            setPopupTarget("success")
            removeLocalNumber("", true)
        }

        if (confirmed) {
            addNumber(req)
                .then((r) => parseResponse(r))
                .catch((e) => parseResponse(e)) // Сообщение об ошибке
        }
    }

    const byCbcHndlr = (confirmed = false) => {
        if (confirmed) {
            activateCbc({
                    login: userAccount["login"],
                    token: userAccount["token"],
                    status: cbcServices.filter(
                        e => e["numer"] === (['@', balance["login"], "_cbc"].join(""))).length === 0
                }
            ).then(r => r && r.activated
                ? resultByRequest("cbc", 1)
                : resultByRequest("cbc", 0))
            // ).then(r => {
            //     r && r.status && r.id_set_drive
            //         ? voipResultResponse(r.id_set_drive)
            //         : resultByRequest("voip", 0)
            // })
            //     .catch(() => resultByRequest("voip", 0))
        }
    }

    const byVoipHandler = (confirmed = false) => {
        if (!activePacket || activePacket.id < 0) return

        let packet = cards[activePacket.id]

        let request = userAccount
        request.packet_id = packet.id
        request.packet_size = activeMinutes

        if (checkService(packet.id, activeMinutes, "voip", services))
            return

        let statuses = popupStatuses
        const tarif_name = "Тариф "+packet.title+" ("+activeMinutes+" минут)."

        if (!confirmed)
            statuses.push({
                title: tarif_name,
                elements: {
                    name: "voip",
                    status: "relations",
                    price: packetsPrice,
                    rmFunction: {target: tarif_name, value: null}
                }})

        if (!confirmed)
            setPopupStatuses([...statuses])

        const voipResultResponse = (isd) => {
            resultByRequest("voip", isd > 0)
        }

        if (confirmed)
            addVoip(request)
                .then(r => {
                    r && r.status && r.id_set_drive
                        ? voipResultResponse(r.id_set_drive)
                        : resultByRequest("voip", 0)
                })
                .catch(() => resultByRequest("voip", 0))

    }

    const byHandler = () => {
        if (numberPrice <= 0 && phoneNumers.length === 0) {
            setPopupTarget("noNumbersSelected")
            setPopupStatus(true)
            return
        }

        if (numbersShopPrice <= 0) return
        let balanceValue = balance && balance.balance_v ? balance.balance_v : 0

        if (numbersShopPrice <= balanceValue) { // Обработка, если баланса достаточно
            byNumbersHndlr(false)
            byCbcHndlr(false)
            byVoipHandler(false)

            setShowConfirmation(true)
        } else if (balance.max_credit && balance.max_credit > 0) {
            setPopupStatus(true)
            setPopupTarget("credit")
        } else { // обработка, если баланса недостаточно
            setPopupStatus(true)
            setPopupTarget("balance")
        }
    }

    const rmElem = (e) => {
        if (!e.target) return

        let statuses = popupStatuses
        let target_statuses = statuses.filter(elem => elem.title === e.target)[0]
        let no_target_statuses = statuses.filter(elem => elem.title !== e.target)

        if (e.target === "Телефонные номера" && e.value) {
            removeLocalNumber(e.value.toString())
            target_statuses.elements = target_statuses.elements.filter(elem => elem.name !== e.value)
            //setPopupStatuses([target_statuses, ...no_target_statuses])
        }
    }

    const clearStatuses = () => {
        setShowPayStatus(false)
        setShowConfirmation(false)
        setPopupStatuses([])
        setByRelations([])
        // reloadData()
        // updateNumbersPool()
    }

    const byExecute = () => {
        const hnd = () => {
            setShowPayStatus(true)
            setShowConfirmation(false)

            byNumbersHndlr(true)
            byVoipHandler(true)
            byAtsHndlr(true)
            byCbcHndlr(true)

        }

        const noBalanceHnd = () => {
            setPopupStatus(true)
            setPopupTarget("balance")
        }

        // Пробуем проверсти списание средств
        let request = userAccount
        request.summ = numbersShopPrice
        byUslRequest(request)
            .then(r => r.result
                ? hnd()
                : noBalanceHnd()
            )
            .catch(() => noBalanceHnd())

    }

    const reloadPage = () => {
        history.push("/autocalling")
        history.go(0)
    }

    const setCredit = () => {

        getCredit({
            login: userAccount.login,
            token: userAccount.token,
        })
            .then(e => e.status ? setPopupTarget("successCredit") : setPopupTarget("failCredit"))
    }

  return (
    <div>
        <div className={cbcServices && cbcServices.length > 0 ? s.vatsFrame : s.flexPage}>
            <div className={`${selectIsOpen ? s.active : ''} ${helpText ? s.active : ''}`}
                 onClick={handleClick}>

                <DefaultTitle title={"Подключение услуги «Сервис голосовых уведомлений»"} />

                <Numbers
                    setActiveNumbers={setActiveNumbers}
                    activeNumbers={activeNumbers}
                    removeLocalNumber={removeLocalNumber}
                />

                <div className={s.delimiter}/>
                <div className={s.header}>
                    <DefaultTitle title="Выбор пакета минут" />
                    <p>
                        <a href="https://lp.telezon.ru/mezhgorod">Подробно о безграничной телефонии</a>
                    </p>
                </div>
                <OutgoingCall
                    minutesPrice={minutesPrice}
                    setMinutesPrice={setMinutesPrice}
                    setActivePacket={setActivePacket}
                    activePacket={activePacket}
                    setPacketPrice={setPacketPrice}
                    packetPrice={packetsPrice}
                    activeMinutes={activeMinutes}
                    setActiveMinutes={setActiveMinutes}
                />

                <div>
                    <div className={s.footer}>
                        <p className={s.result}>
                            Итого: {numbersShopPrice}
                            <span className="ruble"> ₽</span>
                        </p>
                        <span className={s.button} onClick={byHandler}>Купить</span>
                    </div>
                </div>

            </div>


            <ConfirmationPopup data={popupStatuses} label={"Подтверждение заказа"}
                               relations={byRelations} rmFunc={rmElem}
                               status={showConfirmation} hide={clearStatuses} success={byExecute}/>

            <StatusPopup data={popupStatuses} label={"Статус заказа"}
                         relations={byRelations}
                         message={
                             popupStatus && popupTarget === "balance"
                                 ? "Не удалось приобрести услуги из-за нехватки средств на счёте «Межгород»"
                                 :
                                 popupStatus && popupTarget === "server_error"
                                     ? "Возникла ошибка на сервере"
                                     :
                                     popupStatus && popupTarget === "success"
                                         ? null
                                         :
                                         popupStatus && popupTarget
                                             ? popupTarget
                                             : null
                         }
                         status={showPayStatus} hide={reloadPage}/>

            { popupStatus && popupTarget === "balance" &&
            <ModalMessage message={"Не удалось приобрести услуги из-за нехватки средств на счёте"}
                          label={"Недостаточно средств"}
                          show={popupStatus}
                          hide={() => {setPopupStatus(false); setActivePopup("payments shop")}}/>
            }

            {popupStatus && popupTarget === "greetings" && (!cbcServices || !cbcServices.length) &&
            <ModalMessage message={"Уважаемый абонент, в данный момент услуга Сервис голосовых уведомлений не подключена"}
                          label={"Услуга не подключена"}
                          show={popupStatus}
                          hide={() => {
                              setPopupStatus(false)
                          }}>
                <div style={{fontSize: "22px"}}>
                    Для того, чтобы услуга работала, необходимо выбрать номер, с которого будет происходить голосовая рассылка, и
                    пакет минут
                </div>

            </ModalMessage>
            }

            { popupStatus && popupTarget === "noNumbersSelected" &&
            <ModalMessage message={"Для использования услуг необходимо выбрать хотябы один номер"}
                          label={"Внимание!"}
                          show={popupStatus}
                          hide={() => {setPopupStatus(false); setActivePopup("")}}/>
            }

            { popupStatus && popupTarget === "credit" &&
            <ModalMessage label={"Недостаточно средств"}
                          standartButtonText={"Пополнить баланс"}
                          show={popupStatus}
                          hide={() => {setPopupStatus(false); setActivePopup("payments")}}>
                Для приобретения выбранных услуг необходимо пополнить счёт,
                либо воспользоваться заёмными средствами нашей компании.
                <div>
                    <div className="white-button margin-center w20"
                         style={{marginTop: "10px"}}
                         onClick={setCredit}>
                        Взять кредит ({balance.max_credit} руб.)
                    </div>
                </div>
            </ModalMessage>
            }

            { popupStatus && popupTarget === "failCredit" &&
            <ModalMessage message={"Не удалось установить кредит или кредитный лимит уже установлен"}
                          label={"Ошибка при выполнении операции"}
                          show={popupStatus}
                          hide={() => setPopupStatus(false)}/>
            }

            { popupStatus && popupTarget === "successCredit" &&
            <ModalMessage message={"Поздравляем! Был успешно установлен кредитный лимит, теперь вы можете совершать покупку"}
                          label={"Успешно!"}
                          show={popupStatus}
                          hide={() => {setPopupStatus(false); history.push("/autocalling"); history.go(0)}}/>
            }

        </div>
    </div>
  )
}

export default MainBlock
