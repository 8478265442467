import React, {useEffect, useState} from "react";
import {ResponsiveBarCanvas} from "@nivo/bar";
import s from "./Charts.module.scss"
import {useTheme} from "../../../../../hooks/useTheme";

export const barTemplate = {
    "status": "Пропущенный",
    "target": "lost",
    "current": 72,
    "currentColor": "hsl(179, 70%, 50%)",
    "last": 69,
    "lastColor": "hsl(79, 70%, 50%)",
}

const Tooltip = (elem, keys) => {
    const [labels, setLabels] = useState({})

    useEffect(() => {
        if (keys.length === 0) return

        let r = {}
        keys.map(e => r[e.key] = e.name)
        setLabels(r)

    }, [keys])

    return(
        <div className={`${s.tooltipElem} ${s.pad}`}>
            {elem.indexValue} ({labels[elem.id] ?  labels[elem.id] : "Не определено"}) : {elem.value}
        </div>
    )
}

const defaultKeys = [{key: "current", name: "Текущий период"}, {key: "last", name: "Прошлый период"}]


export const BarChart = ({data, clickEvent, keys={defaultKeys}}) => {
    return(
        <div style={{width: "650px", height: "300px", marginLeft: ""}}>
            <ResponsiveBarCanvas
                data={data}
                onClick={clickEvent && clickEvent}
                keys={keys}
                indexBy="status"
                margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                pixelRatio={1}
                padding={0.15}
                innerPadding={0}
                minValue="auto"
                maxValue="auto"
                groupMode="grouped"
                layout="vertical"
                reverse={false}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set2' }}
                colorBy="id"
                borderWidth={0}
                borderRadius={0}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36
                }}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Статус',
                    legendPosition: 'middle',
                    legendOffset: 36
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Количество',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                enableGridX={true}
                enableGridY={false}
                enableLabel={true}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                isInteractive={true}
                tooltip={(e) => Tooltip(e, keys)}
                legends={[
                ]}
            />
        </div>
    )
}

export const HistogramChart = (
    {data, clickEvent, keys={defaultKeys}, label=""}, axis={l: "Сотрудники", b: "Кол-во"}) => {
    const {theme} = useTheme()

    const canvasTheme = {
        axis: {
            textColor: '#eee',
            fontSize: '14px',
            tickColor: '#eee',
        },
        grid: {
            stroke: '#888',
            strokeWidth: 1
        },
    }

    return(
        <div style={{
            width: "850px",
            height: data.length > 10 ? (data.length * 40).toString() + "px" : "380px",
            marginLeft: ""}}>
            {label.length > 0 &&
            <div style={theme === 'light' ? {color: 'var(--light-color)'} : {}}
                className={s.chartLabel}>{label}</div>
            }

            <ResponsiveBarCanvas
                theme={canvasTheme}
                data={data}
                onClick={clickEvent && clickEvent}
                keys={keys.map(e => e.key)}
                indexBy="status"
                margin={{ top: 50, right: 20, bottom: 50, left: 150 }}
                pixelRatio={1}
                padding={0.15}
                innerPadding={0}
                minValue={0}
                maxValue="auto"
                groupMode="stacked"
                layout="horizontal"
                reverse={false}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set2' }}
                colorBy="id"
                borderWidth={0}
                borderRadius={0}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={{
                    tickSize: 5,
                    tickPadding: -30,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36
                }}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: axis.b,
                    legendPosition: 'middle',
                    legendOffset: 36
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 25,
                    tickRotation: 0,
                    legend: axis.l,
                    legendPosition: 'middle',
                    // format: (value) => `$${value}`
                }}
                enableGridX={true}
                enableGridY={false}
                enableLabel={true}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                isInteractive={true}
                tooltip={(e) => Tooltip(e, keys)}
                legends={[
                ]}
            />
        </div>
    )
}