import React, { useState } from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import MainBlock from './MainBlock/MainBlock'
import s from './NumersShop.module.scss'
import {useTheme} from "../../../hooks/useTheme";

const NumersShop = ({setActivePopup}) => {
    const {designType} = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    return (
        <div className={`${s.mainPart} ${designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                <MainBlock
                    activeBurger={activeBurger}
                    setActiveBurger={setActiveBurger}
                    setActivePopup={setActivePopup}
                />
            </div>
        </div>
    )
}

export default NumersShop
