import React from 'react'
import SwiperCore from 'swiper'
import {Autoplay} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import logo from '../../../../img/marketing/ops.jpg'
import s from './SmallSlider.module.scss'
import 'swiper/css'

const SmallSlider = (props) => {
  SwiperCore.use([Autoplay])

  return (
    <div className={s.display}>
      {/*<a href={"https://lp.telezon.ru/pocg"} className={s.reclam}/>*/}
      <Swiper slidesPerView={1} spaceBetween={12} autoplay={{ delay: 5000 }}>
        <SwiperSlide className={s.swiperSlide}>
          <a href="https://lp.telezon.ru/pocg" target={"__blank"}>
            <img src={logo} alt="" className={s.image} />
          </a>
        </SwiperSlide>

        {/*<SwiperSlide className={s.swiperSlide}>*/}
        {/*  <a href="/#">*/}
        {/*    <img src={logo} alt="" className={s.image} />*/}
        {/*  </a>*/}
        {/*</SwiperSlide>*/}

        {/*<SwiperSlide className={s.swiperSlide}>*/}
        {/*  <a href="/#">*/}
        {/*    <img src={logo} alt="" className={s.image} />*/}
        {/*  </a>*/}
        {/*</SwiperSlide>*/}

        {/*<SwiperSlide className={s.swiperSlide}>*/}
        {/*  <a href="/#">*/}
        {/*    <img src={logo} alt="" className={s.image} />*/}
        {/*  </a>*/}
        {/*</SwiperSlide>*/}

        {/*<SwiperSlide className={s.swiperSlide}>*/}
        {/*  <a href="/#">*/}
        {/*    <img src={logo} alt="" className={s.image} />*/}
        {/*  </a>*/}
        {/*</SwiperSlide>*/}

        <div slot="container-end" className="paginationBullets"></div>
      </Swiper>
    </div>
  )
}

export default SmallSlider
