import React, {useEffect, useState} from "react";
import s from "./Charts.module.scss"
import {PieChart, pieTemplate} from "./PieChart";
import {BarChart, barTemplate, HistogramChart} from "./BarChart";
import GlobalSvgSelector from "../../../../GlobalSvgSelector/GlobalSvgSelector";
import ReactSelect from "react-select";
import {useTheme} from "../../../../../hooks/useTheme";
import {DropDownList} from "../../../../../ui/DropDownList/DropDownList";

// https://nivo.rocks/components
const main_types = [
    {id: 0, label: "Потерянные вызовы", value: "calls"},
    {id: 1, label: "Где был потерян вызов", value: "types"},
]

const charts_types = [
    {id: 0, label: "Диаграмма кольцевая", value: "pie"},
    {id: 1, label: "Гистограмма горизонтальная", value: "gisth"},
    {id: 2, label: "Гистограмма вертикальная", value: "gistv"},
]

export const OrangeSelect = (props) => {
    const {theme} = useTheme()
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const filterSelectStyle = {
        container: (provided) => ({
            ...provided,
            width: 0,
            position: "absolute",
            marginLeft: -20,
            marginTop: 5,

        }),
        menu: (provided, state) => ({
            ...provided,
            minWidth: "200px"
        }),
        control: () => ({
            height: "0",
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        option: (provided, state) => ({
            ...provided,
            color: (state.isSelected || state.isFocused) ? "white" : "black",
            width: "200px",
            borderBottom: '1px dotted pink',
            backgroundColor: state.isSelected
                ? "rgba(41,165,61,1)"
                : state.isFocused
                    ? "rgba(254,79,26,1)"
                    : "white",
        }),
        singleValue: (provided) => ({
            ...provided,
            height: 0,
            width: 0,
            fontSize: 0,
        }),
        dropdownIndicator: (provided, state) => ({
            display: "none"
        }),
    }

    return(
        <div className={s.subSelector}
             onMouseLeave={() => setMenuIsOpen(false)}
             onMouseOver={() => setMenuIsOpen(true)}>
            <GlobalSvgSelector id={"graphic-selector"} />
            <span className={s.dropDownString}>&#9660;</span>
            <span>
                <ReactSelect
                    options={props.data}
                    defaultValue={{label: "Нет", value: "Нет", k: "no"}}
                    onChange={(e) => props.changeValue(e)}
                    styles={filterSelectStyle}
                    maxMenuHeight={175}
                    aria-rowcount={10}
                    menuIsOpen={menuIsOpen}
                />
            </span>

        </div>
    )
}


export const LostClientsChart = (props) => {
    const [currentData, setCurrentData] = useState([])
    const [lastData, setLastData] = useState([])
    const [barData, setBarData] = useState([])
    const [chartType, setChartType] = useState("pie")
    const [blockCharts, setBlockCharts] = useState("calls")



    useEffect(() => {
        if (!props.data) return

        prepareData(props.data, blockCharts)
    }, [props.data, chartType, blockCharts])

    const generatePie = (rawData) => {
        let resultData = []
        for (const key of Object.keys(rawData)) {
            let elem = rawData[key]

            let template_elem = {...pieTemplate}
            template_elem.id = elem.name
            template_elem.target = key
            template_elem.label = elem.name
            template_elem.value = elem.count
            template_elem.toCkick = elem.count

            if (elem.count > 0)
                resultData.push(template_elem)
        }
        resultData = resultData.sort((a, b) => a.label.length > b.label.length ? 1 : -1)

        return resultData
    }

    const clickEvent = (elem) => {

        if (!props.clickEvent) return
        if (!elem.data) return
        if (!elem.data.target) return

        const value = elem.data.id ? elem.data.id : elem.status ? elem.status : null
        const target = blockCharts === "calls" ? "lost_status" : blockCharts === "types" ? "dst_type" : null
        if (!value || !target) return
        props.clickEvent({label: value, value: value, k: target})
    }

    const generateBar = (rawData, target) => {
        if (!rawData[target]) return []
        if (!rawData[target].current) return []
        if (!rawData[target].last) return []

        let resultData = []
        for (let key of Object.keys(rawData[target].current)) {
            let current_elem = rawData[target].current[key]
            let last_elem = rawData[target].last[key]

            let template_elem = {...barTemplate}
            template_elem.id = current_elem.name
            template_elem.status = current_elem.name
            template_elem.target = key
            template_elem.current = current_elem.count
            template_elem.last = last_elem.count



            if (template_elem.current > 0 || template_elem.last > 0)
                resultData.push(template_elem)

        }
        // console.log(resultData)
        return resultData
    }

    const prepareData = (rawData, target) => {
        if (!rawData[target]) return
        if (!rawData[target].current) return
        if (!rawData[target].last) return

        if (chartType === "pie") {
            setCurrentData(generatePie(rawData[target].current))
            setLastData(generatePie(rawData[target].last))
        }
        else if (chartType === "gistv" || chartType === "gisth") {
            setBarData(generateBar(rawData, target))
        }
    }

    return(
        <div>
            <div className={s.selector}>
                {main_types.map(
                    type_elem =>
                        <div onClick={() => setBlockCharts(type_elem.value)} key={type_elem.id}
                             className={type_elem.value === blockCharts ? s.selected : s.noSelected}>
                            {type_elem.label}
                        </div>
                )}

                {/*<OrangeSelect data={charts_types} changeValue={(e) => setChartType(e.value)}/>*/}
            </div>

            {chartType === "pie" &&
            <div className={`${s.charts} ${s.chartsSize}`}>
                {currentData.length > 0 &&
                <PieChart data={currentData} label={"Текущий период"}
                          clickEvent={clickEvent}
                          show_legend={false} w={"600px"}/>
                }

                {lastData.length > 0 &&
                <PieChart data={lastData} label={"Прошлый период"} show_legend={true} w={"600px"}/>
                }
            </div>
            }

            {chartType === "gistv" &&
            <div className={s.charts}>
                {barData.length > 0 &&
                <BarChart data={barData} clickEvent={clickEvent}/>
                }
            </div>
            }

            {chartType === "gisth" &&
            <div className={s.charts}>
                {barData.length > 0 &&
                <HistogramChart data={barData} clickEvent={clickEvent}/>
                }
            </div>
            }
        </div>
    )
}