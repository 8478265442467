import React, {useEffect, useState} from 'react'
import s from './FeedbackMain.module.scss'
import {useTheme} from "../../../../hooks/useTheme";
import TableRows from "./TableRow/TableRow";
import ModalMessage from "../../../../ui/Popup/ModalMessage";
import {C2CInit, callRecordElementsEdit, changeFeedbackComment} from "../../../../api";
import {incomingCallsHeaders, uuidRe} from "../../../../provider/variables";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";


const FeedbackMain = (
    {
        data, sortData, changeComment, changeStatus,
        isActive, sortStatus, sortKey,
        addFilter, getFilters,
        setCurFilterSequence, curFilterSequence
    }) => {
    const {userAccount, settings} = useTheme()



    const [activeComment, setActiveComment] = useState("")
    const [comment, setComment] = useState("")
    const [showCommentDialog, setShowCommentDialog] = useState(false)
    const [showCallingDialog, setShowCallingDialog] = useState(false)
    const [callSend, setCallSend] = useState(false)

    const [targetNumber, setTargetNumber] = useState("")
    const [srcNumber, setSrcNumber] = useState("")
    const [editingObject, setEditingObject] = useState({})

    useEffect(() => {
        if (activeComment < 0) return

        let elem = []

        // Проверйем, похож ли на uuid
        elem = data.filter(e => e.id === activeComment)

        if (elem.length > 0) {
            if (elem[0].comment.length > 0) {
                setComment(elem[0].comment)
                setShowCommentDialog(true)
            } else {
                setComment("")
                setShowCommentDialog(true)
            }
        }

    }, [activeComment])

    useEffect(() => {
        if (!settings || !settings.default_number) return

        setSrcNumber(settings.default_number)
    },[settings])



    const chCommentText = (event) => {
        setComment(event.target.value)
    }

    const chSrcPhoneNumer = (event) => {
        setSrcNumber(event.target.value)
    }



    const saveComment = () => {
        // Проверяем на uuid
        // const re = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
        if (uuidRe.exec(activeComment)) {
            // отправляем в UCDR
            callRecordElementsEdit({
                login: userAccount.login,
                token: userAccount.token,
                id: activeComment,
                comment: comment?.length > 0 ? comment : "none"
            }).then(r => changeComment(activeComment, comment))
            // }).then(r => console.log(r))

        } else {
            changeFeedbackComment({
                login: userAccount.login,
                token: userAccount.token,
                id: activeComment,
                comment: comment === "none" ? "" : comment
            }).then(r => changeComment(activeComment, comment))
            // }).then(r => console.log(r))
        }

        setActiveComment("")
        setComment("")
        setShowCommentDialog(false)
    }

    const sendCall = (number) => {
        if (number.toString().length === 0)
            return

        setTargetNumber(number)
        setShowCallingDialog(true)
    }

    const sendCallHndlr = () => {
        if (srcNumber.toString().length === 0 || targetNumber.toString().length === 0 )
            return

        setCallSend(true)
        C2CInit({
            login: userAccount.login,
            token: userAccount.token,
            src_num: srcNumber,
            dst_num: targetNumber,
        }).then(r => clearStatuses(r))

    }

    const clearStatuses = (r) => {
        setShowCallingDialog(false)
        setCallSend(false)
    }

    const changeMenu = (id, number) => {
        if (!id || !number) return

        if (uuidRe.exec(id)) {
            // отправляем в UCDR
            callRecordElementsEdit({
                login: userAccount.login,
                token: userAccount.token,
                id: id,
                dstReal: number
            }).then(r => console.log(r))
            // }).then(r => console.log(r))
        }
    }


  return (
    <div>
        <table className={isActive ? s.table : s.tableInactive}>
            <thead>
                <tr className={s.tableHead}>
                    {Object.keys(incomingCallsHeaders).map(
                        key =>
                            <th key={key} onClick={() => sortData(key)} >
                                {incomingCallsHeaders[key].name}

                                {sortStatus && sortKey === key &&
                                <span>
                                    <GlobalSvgSelector id="select-arrow" />
                                </span>
                                }

                                {!sortStatus && sortKey === key &&
                                <span className={s.rotate}>
                                    <GlobalSvgSelector id="select-arrow" />
                                </span>
                                }
                            </th>
                    )}
                    {/*<th style={{width: "39px"}}>№</th>*/}
                    <th>Реакция</th>

                </tr>
            </thead>
            <tbody className={s.tbody}>
            <TableRows data={data} setActiveComment={setActiveComment} changeMenu={changeMenu}
                       sendCall={sendCall}
                       getFilters={getFilters}
                       addFilter={addFilter}
                       curFilterSequence={curFilterSequence}
                       setCurFilterSequence={setCurFilterSequence}
                       isActive={isActive} changeStatus={changeStatus}/>
            </tbody>

        </table>

        { showCommentDialog &&
        <ModalMessage label={"Комментарий"}
                      standartButtonText={"Отменить"}
                      show={showCommentDialog}
                      hide={() => {
                          setShowCommentDialog(false)
                          setActiveComment("")
                      }}>

            <div>
                <textarea className={s.commentBlock}
                          onChange={chCommentText}
                          value={comment === "none" ? "" : comment}/>
                <div className="white-button margin-center w20"
                     style={{marginTop: "10px"}} onClick={saveComment}>
                    Сохранить
                </div>
            </div>
        </ModalMessage>
        }

        { showCallingDialog &&
        <ModalMessage
                      standartButtonText={"Отменить"}
                      show={showCallingDialog}
                      hide={() => clearStatuses()}>

            <div style={{display: "flex"}}>
                <table className={s.callTable}>
                    <tbody>
                    <tr>
                        <td>Внутренний номер</td>
                        <td><input className={s.numberInput} defaultValue={srcNumber}
                                   onChange={chSrcPhoneNumer} /></td>
                    </tr>
                    <tr>
                        <td>Номер клиента</td>
                        <td><input className={s.numberInput} disabled={true} readOnly
                                   value={targetNumber}/></td>
                    </tr>

                    </tbody>

                </table>

                { !callSend &&
                <div className={s.bigButton}
                     style={{marginTop: "10px"}} onClick={sendCallHndlr}>
                    <span>Вызов</span>
                </div>
                }

                { callSend &&
                <div className={s.bigStatusGreen}>Идёт вызов</div>
                }

                {/*{ callSuccessed === 1 &&*/}
                {/*<div className={s.bigStatusGreen}>Вызов отправлен</div>*/}
                {/*}*/}

                {/*{ callSuccessed === -1 &&*/}
                {/*<div className={s.bigStatusRed}>Ошибка отправки вызова</div>*/}
                {/*}*/}


            </div>
        </ModalMessage>

        }


    </div>
  )
}

export default FeedbackMain
