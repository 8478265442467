import React, {useState} from 'react'
import s from './PopupChangePass.module.scss'
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useTheme } from '../../hooks/useTheme'
import {yupResolver} from "@hookform/resolvers/yup";
import {RelatedAuth} from "../../api";
import {createBrowserHistory} from "history";
import {sleep} from "../../sortFunction";


const history = createBrowserHistory();


const schema = yup
    .object({
        pin: yup.string().required("Pin is required"),
        loginASR: yup.string().required("Login is required"),
        passwordASR: yup.string().min(6).max(32).required("Password is required"),
    })


const PopupChangeUser = ({ popup, setPopup, message}) => {
    const theme = useTheme()

    const [errorElem, setErrorElem] = useState("")
    const [errorMes, setErrorMes] = useState("")

    function clearErrors() {
        setErrorElem("")
        setErrorMes("")
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            pin: "",
            loginASR: "",
            passwordASR: "",
        }
    })

    const auth = (response) => {
        if (response.login && response.token) {
            theme.tokenLoginFunc(response)

            sleep(2000)
                .then(() => {
                    history.push("/settings")
                    history.go(0)
                })
        }
    }


    const onSubmit = (e) => {
        setPopup("")
        // Пробуем авторизоваться
        RelatedAuth({
            login: theme.userAccount.login,
            token: theme.userAccount.token,
            pin: e.pin,
            loginASR: e.loginASR,
            passwordASR: e.passwordASR,
        }).then(r => auth(r))
    }

  return (
    <div className={popup === "change_user" ? `${s.popup} ${s.active}` : s.popup}>
      <div className={s.blur} onClick={() => setPopup("")}/>
      <div className={s.popupWrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
              <p className={s.subTitle}>Выберите пользователя   </p>

              <div className={s.formBlock}>
                  {
                      errorElem === "pin" ?
                          <div className={s.errorMes}>{errorMes}</div> : null
                  }

                  <p className={s.blockTitle}>Пин абонента</p>
                  <input
                      type="text"
                      onClick={errorElem === "pin" ? clearErrors : null}
                      className={
                          errors.pin ? `${s.defaultInput} ${s.error}` : s.defaultInput
                      }
                      {...register('pin')}
                  />

              </div>
              <div className={s.formBlock}>
                  {
                      errorElem === "login" ?
                          <div className={s.errorMes}>{errorMes}</div> : null
                  }

                  <p className={s.blockTitle}>Логин АСР</p>
                  <input
                      type="text"
                      onClick={errorElem === "loginASR" ? clearErrors : null}
                      className={
                          errors.loginASR ? `${s.defaultInput} ${s.error}` : s.defaultInput
                      }
                      {...register('loginASR')}
                  />

              </div>
              <div className={s.formBlock}>
                  {
                      errorElem === "passwordASR" ?
                          <div className={s.errorMes}>{errorMes}</div> : null
                  }
                  <p className={s.blockTitle}>Пароль АСР</p>
                  <input
                      type="password"
                      onClick={errorElem === "passwordASR" ? clearErrors : null}
                      className={
                          errors.passwordASR ? `${s.defaultInput} ${s.error}` : s.defaultInput
                      }
                      {...register('passwordASR')}
                  />
              </div>
              {
                  errorElem === "incorrectNewPass" ?
                      <div className={s.mainErrorMes}>
                          Пароль должен быть длиной от 8 до 20 символов и состоять из, по крайней мере,
                          одной цифры, одной заглавной буквы, одной строчной буквы и одного символа _$!
                      </div> : null
              }
              <button className={s.button}>Войти</button>
          </form>

          {message &&
          <div className={s.AddMessage}>
              {message}
          </div>
          }
      </div>
    </div>
  )
}

export default PopupChangeUser
