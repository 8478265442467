import React, {useCallback, useRef} from 'react'
import { useState, useEffect } from "react";
import s from './AudioPlayer.module.scss'
import {PauseCircleFilled, PlayCircleFilled} from "@mui/icons-material";
import moment from "moment";
import {getAudioFilePath} from "../../sortFunction";
import {LoadingDots} from "../Loading/Loading";


function useAudioPlayer() {
    const [duration, setDuration] = useState();
    const [curTime, setCurTime] = useState();
    const [playing, setPlaying] = useState(false);
    const [clickedTime, setClickedTime] = useState();

    useEffect(() => {
        const audio = document.getElementById("audio");
        if (!audio) return

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurTime(audio.currentTime);
        }

        const setAudioTime = () => setCurTime(audio.currentTime);

        // DOM listeners: update React state on DOM events
        audio.addEventListener("loadeddata", setAudioData);
        audio.addEventListener("timeupdate", setAudioTime);

        // React state listeners: update DOM on React state changes
        playing ? audio.play() : audio.pause();

        if (clickedTime && clickedTime !== curTime) {
            audio.currentTime = clickedTime;
            setClickedTime(null);
        }

        // effect cleanup
        return () => {
            audio.removeEventListener("loadeddata", setAudioData);
            audio.removeEventListener("timeupdate", setAudioTime);
        }
    });

    const startPlay = () => {
        setPlaying(true)

    }

    return {
        curTime,
        duration,
        playing,
        setPlaying,
        startPlay,
        setClickedTime
    }
}

function Play(props) {
    const { handleClick } = props;

    return (
        <div>
            <button className={s.player__button} onClick={() => handleClick()}>
                <PlayCircleFilled />
            </button>
        </div>

    );
}

function Pause(props) {
    const { handleClick } = props;

    return (
        <div>
            <button className={s.player__button} onClick={() => handleClick()}>
                <PauseCircleFilled />
            </button>
        </div>

    );
}

function Bar(props) {
    const { duration, curTime, onTimeUpdate } = props;

    const curPercentage = (curTime / duration) * 100;


    function formatDuration(duration) {
        return moment
            .duration(duration, "seconds")
            .format("mm:ss", { trim: false });
    }

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.querySelector("#bar__progress");
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = eMove => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener("mousemove", updateTimeOnMove);

        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    return (
        <div className={s.bar}>
            <span className={s.barTime}>{formatDuration(curTime)}</span>
            <div
                className={s.bar__progress}
                id={"bar__progress"}
                style={{
                    background: `linear-gradient(to right, #fe4f1a ${curPercentage}%, #e5e5e5 0)`
                }}
                onMouseDown={e => handleTimeDrag(e)}
            >
                <span className={s.bar__progress__knob} style={{ left: `${curPercentage - 2}%` }}/>
            </div>
            <span className={s.barTime}>{formatDuration(duration)}</span>
        </div>
    );
}

function Song(props) {
    const { datetime, numer_a, numer_b } = props;

    return (
        <div className={s.song}>
            <div className={s.songTitle}>{numer_a} => {numer_b}</div>
            <h2 className={s.songArtist}>{datetime}</h2>
        </div>
    )
}

export const AudioPlayerOld = ({ src, data, playStatus, setPlayStatus, vr=true }) => {
    const { curTime, duration, playing, setPlaying, startPlay, setClickedTime } = useAudioPlayer();
    const [fileName, setFilename] = useState("")

    useEffect(() => {
        if (playStatus) startPlay()
        if (!playStatus) setPlaying(false)
    }, [playStatus])

    useEffect(() => {

        const audioElem = document.querySelector("#audio");
        if (!audioElem) return

        setPlaying(false)
        if (vr) {
            const filePath = getAudioFilePath(src)
            setFilename(filePath)
        } else {
            setFilename(src)
        }

        audioElem.load()

    }, [src])

    useEffect(() => {
        startPlay()

    }, [fileName])

    useEffect(() => {
        if (curTime === duration && playing) {
            setPlaying(false);
            setPlayStatus(false)
        }
    }, [curTime])

    return (
        <div className={s.player}>
            <audio id="audio" >
                <source src={fileName} type="audio/mpeg"/>
                Your browser does not support the <code>audio</code> element.
            </audio>
            {data &&
            <Song datetime={data["datetime"]} numer_a={data["numerA"]} numer_b={data["numerB"]}  />
            }


            {
                duration ?
                    <div className={s.controls}>
                        {playing ?
                            <Pause handleClick={() => setPlaying(false)} fileName={fileName}/> :
                            <Play handleClick={() => startPlay()} fileName={fileName}/>
                        }

                        <Bar curTime={curTime} duration={duration}
                             onTimeUpdate={(time) => setClickedTime(time)}/>
                    </div>
                    : null
            }

        </div>
    );
}

// export const AudioPlayerMini = ({ src, data, playStatus, setPlayStatus, vr=true }) => {
//     const [audioFile, setAudioFile] = useState("")
//
//     const [duration, setDuration] = useState(0)
//     const [currentTime, setCurrentTime] = useState(0)
//     const [isPlaying, setIsPlaying] = useState(false)
//     const [showLoader, setShowLoader] = useState(true)
//
//     const audioRef = useRef()
//     const progressBarRef = useRef();
//     const playAnimationRef = useRef();
//
//
//     useEffect(() => {
//         if (vr && src?.length > 0) {
//             const filePath = getAudioFilePath(src)
//             setAudioFile(filePath)
//         } else if (src?.length > 0) {
//             setAudioFile(src)
//         } else {
//             setAudioFile("")
//         }
//         setShowLoader(true)
//     }, [src])
//
//     useEffect(() => {
//         setDuration(0)
//
//     }, [audioFile])
//
//     useEffect(() => {
//         if (!progressBarRef.current) return
//         progressBarRef.current.max = duration;
//     }, [duration, progressBarRef.current])
//
//     const onLoadedMetadata = () => {
//         setCurrentTime(0)
//         setDuration(audioRef.current.duration)
//         setIsPlaying(true)
//     }
//
//     useEffect(() => {
//         if (!progressBarRef.current) return
//
//         const dur = audioRef?.current?.duration || 0;
//
//         progressBarRef.current.value = currentTime;
//         progressBarRef.current.style.setProperty(
//             '--range-progress',
//             `${(progressBarRef.current.value / dur) * 100}%`
//         );
//
//     }, [currentTime, progressBarRef.current])
//
//     const repeat = useCallback(() => {
//         if (!audioRef.current) return
//         setCurrentTime(audioRef.current.currentTime);
//         playAnimationRef.current = requestAnimationFrame(repeat);
//     }, [audioRef, progressBarRef, duration, setCurrentTime])
//
//
//     useEffect(() => {
//         if (!audioRef.current) return
//
//         if (isPlaying) {
//             audioRef.current.play();
//             playAnimationRef.current = requestAnimationFrame(repeat);
//         }
//         else {
//             audioRef.current.pause()
//             cancelAnimationFrame(playAnimationRef.current);
//         }
//     }, [isPlaying, audioRef, repeat])
//
//     useEffect(() => {
//         if (currentTime === duration) setIsPlaying(false)
//     }, [duration, currentTime])
//
//
//     return(
//         audioFile?.length > 0 &&
//         <div className={s.player}>
//             <audio src={audioFile}
//                    ref={audioRef}
//                    onLoadedMetadata={onLoadedMetadata}
//             />
//             {data &&
//                 <Song datetime={data?.datetime} numer_a={data?.numerA} numer_b={data?.numerB}  />
//             }
//
//             <div className={s.controls}>
//                 <ProgressBar curTime={currentTime} duration={duration}
//                              progressBarRef={progressBarRef} audioRef={audioRef}/>
//             </div>
//         </div>
//     )
// }

const ProgressBar = ({duration, curTime, audioRef}) => {
    const [curPercent, setCurPercent] = useState(0)

    useEffect(() => {
        if (!curTime || ! duration) return
        setCurPercent((curTime / duration) * 100)
    }, [duration, curTime])

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.querySelector("#bar__progress");
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        audioRef.current.currentTime = calcClickedTime(e);

        const updateTimeOnMove = eMove => {
            audioRef.current.currentTime = calcClickedTime(eMove);
        };

        document.addEventListener("mousemove", updateTimeOnMove);
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
            const minutes = Math.floor(time / 60);
            const formatMinutes =
                minutes < 10 ? `0${minutes}` : `${minutes}`;
            const seconds = Math.floor(time % 60);
            const formatSeconds =
                seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    return(
        <div className={s.bar}>
            <span className={s.barTime}>{formatTime(curTime)}</span>
            {/*<input type="range"*/}
            {/*       ref={progressBarRef}*/}
            {/*       defaultValue="0"*/}
            {/*       onChange={handleProgressChange}*/}
            {/*/>*/}
            <div
                className={s.bar__progress}
                id={"bar__progress"}
                style={{
                    background: `linear-gradient(to right, #fe4f1a ${curPercent}%, #e5e5e5 0)`
                }}
                onMouseDown={e => handleTimeDrag(e)}
            >
                <span className={s.bar__progress__knob} style={{ left: `${curPercent - 2}%` }}/>
            </div>
            <span className={s.barTime}>{formatTime(duration)}</span>
        </div>
    )
}

export const AudioPlayer = ({ src, data, playStatus, setPlayStatus, vr=true }) => {
    const [audioFile, setAudioFile] = useState("https://vr.telezon.ru/audio/538097f0-47a3-4ea7-942b-7672ce8facf5.mp3")

    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const audioRef = useRef()
    const progressBarRef = useRef();
    const playAnimationRef = useRef();


    useEffect(() => {
        if (vr && src?.length > 0) {
            const filePath = getAudioFilePath(src)
            setAudioFile(filePath)
        } else if (src?.length > 0) {
            setAudioFile(src)
        } else {
            setAudioFile("")
        }
    }, [src])

    useEffect(() => {
        setDuration(0)
        setShowLoader(true)
    }, [audioFile])

    useEffect(() => {
        if (!progressBarRef.current) return
        progressBarRef.current.max = duration;
    }, [duration, progressBarRef.current])

    const onLoadedMetadata = () => {
        setCurrentTime(0)
        setDuration(audioRef.current.duration)
        setIsPlaying(true)
        setShowLoader(false)
    }

    useEffect(() => {
        if (!progressBarRef.current) return

        const dur = audioRef?.current?.duration || 0;

        progressBarRef.current.value = currentTime;
        progressBarRef.current.style.setProperty(
            '--range-progress',
            `${(progressBarRef.current.value / dur) * 100}%`
        );

    }, [currentTime, progressBarRef.current])

    const repeat = useCallback(() => {
        if (!audioRef.current) return
        setCurrentTime(audioRef.current.currentTime);
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [audioRef, progressBarRef, duration, setCurrentTime])


    useEffect(() => {
        if (!audioRef.current) return

        if (isPlaying) {
            audioRef.current.play();
            playAnimationRef.current = requestAnimationFrame(repeat);
        }
        else {
            audioRef.current.pause()
            cancelAnimationFrame(playAnimationRef.current);
        }
    }, [isPlaying, audioRef, repeat])

    useEffect(() => {
        if (currentTime === duration) setIsPlaying(false)
    }, [duration, currentTime])


    return(
        audioFile?.length > 0 &&
        <div className={s.player}>
            <audio src={audioFile}
                   ref={audioRef}
                   onLoadedMetadata={onLoadedMetadata}
            />
            {data &&
                <Song datetime={data?.datetime} numer_a={data?.numerA} numer_b={data?.numerB}  />
            }

            <div className={s.controls}>
                {showLoader &&
                    <div style={{height: "60px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <LoadingDots />
                    </div>
                }

                {!showLoader &&
                    <div style={{height: "60px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {isPlaying
                            ? <Pause handleClick={() => setIsPlaying(!isPlaying)} />
                            : <Play handleClick={() => setIsPlaying(!isPlaying)} />
                        }
                    </div>
                }


                <ProgressBar curTime={currentTime} duration={duration}
                             progressBarRef={progressBarRef} audioRef={audioRef}/>
            </div>
        </div>
    )
}


export const AudioPlayerMini = ({ src, data, playStatus, setPlayStatus, vr=true, stopEvent }) => {
    const [audioFile, setAudioFile] = useState("")

    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const audioRef = useRef()
    const progressBarRef = useRef();
    const playAnimationRef = useRef();


    useEffect(() => {
        if (vr && src?.length > 0) {
            const filePath = getAudioFilePath(src)
            setAudioFile(filePath)
        } else if (src?.length > 0) {
            setAudioFile(src)
        } else {
            setAudioFile("")
        }
    }, [src])

    useEffect(() => {
        setDuration(0)
        setShowLoader(true)
    }, [audioFile])

    useEffect(() => {
        if (!progressBarRef.current) return
        progressBarRef.current.max = duration;
    }, [duration, progressBarRef.current])

    const onLoadedMetadata = () => {
        setCurrentTime(0)
        setDuration(audioRef.current.duration)
        setIsPlaying(true)
        setShowLoader(false)
    }

    useEffect(() => {
        if (!progressBarRef.current) return

        const dur = audioRef?.current?.duration || 0;

        progressBarRef.current.value = currentTime;
        progressBarRef.current.style.setProperty(
            '--range-progress',
            `${(progressBarRef.current.value / dur) * 100}%`
        );

    }, [currentTime, progressBarRef.current])

    const repeat = useCallback(() => {
        if (!audioRef.current) return
        setCurrentTime(audioRef.current.currentTime);
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [audioRef, progressBarRef, duration, setCurrentTime])


    useEffect(() => {
        if (!audioRef.current) return

        if (isPlaying) {
            audioRef.current.play();
            playAnimationRef.current = requestAnimationFrame(repeat);
        }
        else {
            audioRef.current.pause()
            cancelAnimationFrame(playAnimationRef.current);
        }
    }, [isPlaying, audioRef, repeat])

    useEffect(() => {
        if (currentTime === duration) setIsPlaying(false)
    }, [duration, currentTime])

    useEffect(() => {
        if (playStatus !== isPlaying) setIsPlaying(playStatus)
    }, [playStatus, isPlaying])

    return(
        audioFile?.length > 0 &&
        <div className={s.player}>
            <audio src={audioFile}
                   ref={audioRef}
                   onLoadedMetadata={onLoadedMetadata}
            />

            {showLoader && <LoadingDots/> }
            <div className={s.controls}>
                <ProgressBar curTime={currentTime} duration={duration}
                             progressBarRef={progressBarRef} audioRef={audioRef}/>
            </div>
        </div>
    )
}
