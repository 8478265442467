import React, {useEffect, useState} from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './SettingsPage.module.scss'
import {useTheme} from "../../../hooks/useTheme";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import {ContactsForm} from "./Form/ContactsForm";
import {SecurityForm} from "./Form/SecurityForm";
import {MailingsForm} from "./Form/MailingsForm";
import {IncommingCalsForm} from "./Form/IncommingCallsForm";
import {SmsSettingsForm} from "./Form/SmsSettingsForm";
import {servicesId} from "../../../provider/variables";

const SettingsPage = (props) => {
    const [activeBurger, setActiveBurger] = useState(false)
    const [incomingCalls, setIncomingCalls] = useState(false)
    const [smsActive, setSmsActive] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        if (theme.additionalServices.length === 0) return

        setIncomingCalls(
            theme.additionalServices.filter(
                e => servicesId.incomingCalls.includes(e?.usl_id) && e?.usl_active)
        )
        setSmsActive(
            theme.additionalServices.filter(
                e => servicesId.smsServices.includes(e?.usl_id) && e?.usl_active)
        )
    }, [theme.additionalServices])

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    return (
        theme.designType === "minimal" ?
            <div className={`${s.mainPart} ${s.minimalDesign}`}>
                <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />

                {theme.settings &&
                <div className={s.flex}>
                    <div className={`${s.bubble} ${s.fixedBubble}`} >
                        <ContactsForm setActivePopup={props.setActivePopup}/>
                    </div>
                    <div className={`${s.bubble} ${s.fixedBubble}`} >
                        <MailingsForm setActivePopup={props.setActivePopup}/>
                    </div>

                    {incomingCalls &&
                    <div className={`${s.bubble} ${s.fixedBubble}`} >
                        <IncommingCalsForm setActivePopup={props.setActivePopup}/>
                    </div>
                    }

                    {incomingCalls &&
                    <div className={`${s.bubble} ${s.fixedBubble}`}>
                        <SmsSettingsForm setActivePopup={props.setActivePopup}/>
                    </div>
                    }

                    <div className={`${s.bubble} ${s.fixedBubble}`} >
                        <SecurityForm setActivePopup={props.setActivePopup}/>
                    </div>
                </div>
                }

                {!theme.settings &&
                <div className={s.flex}>
                    <div className={`${s.bubble} ${s.fixedBubble}`} >
                        <DefaultTitle title="Настройки недоступны"/>
                    </div>
                </div>
                }
            </div>
            :
            <div className={`${s.mainPart}`}>
                <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />

                <div className={`${s.flexColumn} ${s.bigDesign}`}>
                    <ContactsForm setActivePopup={props.setActivePopup}/>
                    <MailingsForm setActivePopup={props.setActivePopup}/>

                    {incomingCalls &&
                    <IncommingCalsForm setActivePopup={props.setActivePopup}/>
                    }

                    {(incomingCalls || smsActive) &&
                    <SmsSettingsForm setActivePopup={props.setActivePopup}/>
                    }

                    <SecurityForm setActivePopup={props.setActivePopup}/>


                    {!theme.settings &&
                    <DefaultTitle title="Настройки недоступны"/>
                    }
                </div>
            </div>
    )
}

export default SettingsPage
