import React, {useEffect, useState} from 'react'
import Sidebar from "../../../ui/Sidebar/Sidebar";
import s from "./ZkhIpuPage.module.scss"
import {useTheme} from "../../../hooks/useTheme";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import {Tabs} from "../../../ui/Tabs/Tabs";
import {tabs} from "./variables";
import {MetersStatistic} from "./Meters/Meters";
import {Settings} from "./Settings/Settings";
import {Abonents} from "./Abonents/Abonents";
import {getSettings} from "./api";

export const ZkhIpuPage = () => {
    const theme = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [activeTab, setActiveTab] = useState(tabs[0])
    const [settingsData, setSettingsData] = useState({})

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    const loadSettings = () => {
        getSettings({...theme.userAccount})
            .then(r => {
                r?.data?.[0] && (setSettingsData(r?.data?.[0]))
            })
            .catch(r => console.log(r))
    }

    useEffect(() => {
        if (!theme.userAccount?.login) return

        loadSettings()

    }, [theme.userAccount])

    return(
        <div className={`${s.mainPart} ${theme.designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            <div className={s.flex}>
                <DefaultTitle title={"Управление показаниями индивидуальных приборов учёта"} justify={true}>
                    <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} noMargin={true} />
                </DefaultTitle>

                {activeTab?.section === "meters" &&
                <MetersStatistic />
                }

                {activeTab?.section === "settings" &&
                <Settings settingsData={settingsData} reload={loadSettings}/>
                }

                {activeTab?.section === "abonents" &&
                <Abonents />
                }
            </div>
        </div>
    )
}