import React, {useEffect} from 'react'
import { useTheme } from '../../../../../hooks/useTheme'
import Setting from './Setting/Setting'
import s from './Settings.module.scss'

const Settings = ({usl}) => {
  return (
    <div className={s.settings}>
      <p className={s.title}>Настройки</p>

        {usl.params &&
        usl.params .map(
            (param) => (
                <Setting info={param} key={param.id} usl={usl}/>
            ),
        )}
    </div>
  )
}

export default Settings
