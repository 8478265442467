import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import s from './Menu.module.scss'
import {useTheme} from "../../../hooks/useTheme";

const Menu = ({ items }) => {
  const {userAccount} = useTheme()

  return (
    <>
      {items.map((item) => (
          (item.showVirtual || (!userAccount.virtual && !userAccount.fusion_auth)) && // Убираем для виртуалов
          <li className={s.link} key={item.text}>
            <Link to={item.href} className={s.color}>
              {item.text}
            </Link>
          </li>
      ))}
    </>
  )
}

export default Menu
