import React, {useEffect, useState} from 'react'
import Sidebar from '../../../ui/Sidebar/Sidebar'
import s from './IncomingCalls.module.scss'
import {useTheme} from "../../../hooks/useTheme";
import {changeCallRecordStatus, changeFeedbackStatus, getCallRecords, getFeedbackData} from "../../../api";
import FeedbackMain from "./FeedbackMain/FeedbackMain";
import PaginationBlock from "../../../ui/PaginationBlock/PaginationBlock";
import HeadBubles from "./HeadBlock/HeadBubles";
import {
    addDayToDate,
    convertDateToUcdr, convertDateToUcdrFull,
    getDateFromStr,
    normalizeDateDMY, sleep
} from "../../../sortFunction";
import Loading from "../../../ui/Loading/Loading";
import {NewFeatures} from "../../../ui/Popup/Popups";
import {uuidRe, incomingCallsHeaders, virtualUsersCrMinutesDif} from "../../../provider/variables";


const IncomingCalls = () => {
    const {userAccount, savedIvrData, designType} = useTheme()
    const [activeBurger, setActiveBurger] = useState(false)
    const [startLoadData, setStartLoadData] = useState(false)

    const [feedbackData, setFeedbackData] = useState([])
    const [rawCrData, setRawCrData] = useState([])
    const [crData, setCrData] = useState([])
    const [callData, setCallData] = useState([])

    const [activeRows, setActiveRows] = useState([])
    const [filteredRows, setFilteredRows] = useState([])
    const [sortStatus, setSortStatus] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [sortKey, setSortKey] = useState("date")
    const [curPage, setCurPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [filters, setFilters] = useState({})
    const [curFilterSequence, setCurFilterSequence] = useState([])
    const [ivrMenuList, setIvrMenuList] = useState([]) // список выбранных меню

    const [valueStart, setValueStart] = useState(new Date())
    const [valueEnd, setValueEnd] = useState(new Date())
    const [curAts, setAts] = useState()
    const [showFeatures, setShowFeatures] = useState(true)
    const [autoRefresh, changeAutoRefreshStatus] = useState(false)
    const [autoRefreshPeriod, setAutoRefreshPeriod] = useState(60000)
    const [autoRefreshCnt, setAutoRefreshCnt] = useState(0)

    const noRelevant = "Вызов завершился вне релевантного меню"

    const refresh = () => {
        if (!autoRefresh) return
        console.log("autoRefresh")

        loadDataFeedback()
        loadCrData(false)
        sleep(autoRefreshPeriod).then(() => setAutoRefreshCnt(autoRefreshCnt + 1))
    }

    useEffect(() => {
        if (autoRefresh) refresh()
    }, [autoRefresh, autoRefreshPeriod, autoRefreshCnt])

    useEffect(() => {
        if (valueStart > valueEnd) {
            setValueEnd(valueStart)
        }
        loadCrData()
    }, [valueStart, valueEnd, curAts])

    useEffect(() => {
        setFilteredRows([...filterData([...feedbackData, ...crData])])
    }, [filters, feedbackData, crData, ivrMenuList, startLoadData])

    // useEffect(() => {
    //     setCallData([...feedbackData, ...crData])
    // }, [feedbackData, crData])


    useEffect(() => {
        if (!savedIvrData || !savedIvrData.sniff_nums) return
        setIvrMenuList(savedIvrData.sniff_nums.split(", "))

    }, [savedIvrData])

    useEffect(() => {
        // Сортируем
        if (sortStatus) {
            if (sortKey === "date")
                filteredRows.sort((a, b) =>
                    getDateFromStr(a[sortKey]) > getDateFromStr(b[sortKey]) ? 1 : -1)
            else
                filteredRows.sort((a, b) =>
                    a[sortKey] > b[sortKey] ? 1 : -1)
        } else {
            if (sortKey === "date")
                filteredRows.sort((a, b) =>
                    getDateFromStr(a[sortKey]) < getDateFromStr(b[sortKey]) ? 1 : -1)
            else
                filteredRows.sort((a, b) =>
                    a[sortKey] < b[sortKey] ? 1 : -1)
        }
        // пагинируем
        setActiveRows([...filteredRows.slice(
            pageSize * curPage - pageSize,
            pageSize * curPage,
        )])
    }, [filteredRows, sortKey, curPage, pageSize, sortStatus])



    const loadDataFeedback = () => {
        if (feedbackData.length !== 0) return

        const parseFeedbackData = (r) => {
            setFeedbackData([...r])
        }
        getFeedbackData({
            login: userAccount.login,
            token: userAccount.token,
        }).then(r => parseFeedbackData(r))
    }

    const changeStatus = (id, status) => {
        const updateCrRows = () => {
            for (const elem of crData) {
                elem.status = elem.id === id ? elem.status = status : elem.status;
            }

            setCrData([...crData])
        }

        const updateFbRows = () => {
            for (const elem of feedbackData) {
                elem.status = elem.id === id ? elem.status = status : elem.status;
            }
            setFeedbackData([...feedbackData])
        }

        const re = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
        if (re.exec(id.toString())) {
            changeCallRecordStatus({
                login: userAccount.login,
                token: userAccount.token,
                id: id,
                status: !status
            }).then(r => updateCrRows(r))

        } else {
            changeFeedbackStatus({
                login: userAccount.login,
                token: userAccount.token,
                id: id,
                status: status
            }).then(r => updateFbRows(r))
        }
    }

    const parseCrData = (data) => {
        if (!data || !data.results) return

        // console.log(ivrMenuList)

        let parsedData = []
        for (const elem of data.results) {
            let relevant = true
            // console.log(elem)

            let transfer_history = elem?.transfer_history || {}
            let relevantMenus = Object.keys(transfer_history).map(
                transferElem => ivrMenuList?.includes(transferElem)
            )
            // let relevantMenus = []
            // for (const transferElem of Object.keys(transfer_history)) {
            //     if (ivrMenuList.includes(transferElem))
            //         relevantMenus.push(transferElem)
            // }

            if (relevantMenus?.length === 0) relevant = false

            let call_history = []
            let last_menu = ""

            if (relevant) {
                let history = Object.keys(transfer_history).map(
                    key => ({...transfer_history[key], number: key})
                )
                transfer_history = history.sort(
                    (a, b) => a?.date > b?.date ? 1 : -1)
                call_history = transfer_history.filter(e => e?.descr).map(e => e?.descr)

                for (const e of transfer_history)
                    if (ivrMenuList.includes(e.number)) last_menu = e.descr
            }

            if (elem?.transfer_history?.[elem.dst_real]) {
                const real_name = elem?.transfer_history?.[elem.dst_real]?.descr || ""
                last_menu = real_name
                // call_history.push(["Изменено на", real_name].join(" "))
                call_history.push(`Изменено на ${real_name}`)
            }

            parsedData.push({
                message_id: elem?.uuid,
                id: elem?.uuid,
                phone: elem?.src_num,
                email: "",
                subject: last_menu?.length > 0 ? last_menu : noRelevant,
                name: "",
                message: "",
                status: !elem?.is_processed,
                comment: elem?.comment || "",
                date: elem?.call_start_stamp,
                callTransfer: call_history,
                callMenu: last_menu?.length > 0
            })
        }

        // setCrData([...parsedData])
        setCrData([...parsedData])
        setShowLoader(false)
    }

    useEffect(() => {parseCrData(rawCrData)},[ivrMenuList, rawCrData])

    const loadCrData = (showLoader=true) => {
        if (!valueStart || !valueEnd || !curAts || startLoadData) return;

        let requestPage = "all"
        let urlContext = ""

        // Даты
        let date_str = "?"
        if (valueStart === valueEnd) {
        } else {
            if (userAccount.virtual) {
                let start = new Date()
                date_str += "date_range_after="+convertDateToUcdrFull(start, virtualUsersCrMinutesDif)
            } else {
                date_str += "date_range_after="+convertDateToUcdr(valueStart)
            }
            date_str += "&date_range_before="+convertDateToUcdr(valueEnd, 1)
        }
        urlContext += date_str
        // Домен
        urlContext += "&domain=" + curAts["label"]
        // urlContext += "&domain=" + 9037
        //
        // Размер страницы
        // if (target === "all") {
        //   urlContext += "&page_size=" + 100
        // } else {
        //   urlContext += "&page_size=" + pageSize
        // }

        // Номер страницы
        urlContext += "&page_size=" + 500
        if (!userAccount.virtual) {
            urlContext += "&page=" + requestPage
        }

        // Доп фильтры
        urlContext += "&direction=inbound"
        urlContext += "&detail_history=true"

        // setRequestStatus(true)
        if (showLoader) setShowLoader(true)
        setStartLoadData(true)
        getCallRecords({
            login: userAccount["login"],
            token: userAccount["token"],
            url: urlContext,
        }).then(result => {
            setRawCrData(result)
            setStartLoadData(false)
        })
            .catch(() => setStartLoadData(false))
    }

    const filterData = (data) => {
        if (data?.length === 0) return []
        let endDate = new Date(valueEnd)
        endDate = addDayToDate(endDate, 1)
        let startDate = valueStart
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)

        let activeSequence = [...data]

        // Фильтрация по дате
        // console.log(startDate, endDate, activeSequence.length)
        // activeSequence.map(e => console.log(e.date, valueStart, getDateFromStr(e.date) >= valueStart))
        activeSequence = activeSequence.filter(
            e => ((getDateFromStr(e.date) >= startDate) && (getDateFromStr(e.date) <= endDate))
        )

        // Фильтрация звонков по неуспешным
        if (!Object.keys(filters).includes("relevant")) {
            activeSequence = activeSequence.filter(e => e.subject !== noRelevant)
        }

        // Фильтрация по полям таблицы
        //const f2 = {status: 'Обработан', comment: 'ЛС'}
        for (const key of Object.keys(filters)) {
            let val = filters[key]

            if (key === "relevant") {
                continue
            }

            if (key === "subject" && val === "Запрос с сайта") {
                activeSequence = activeSequence.filter(
                    e => !uuidRe.exec(e.id))
                continue
            }

            if (key === "date") {
                activeSequence = activeSequence.filter(
                    e => normalizeDateDMY(e[key]).includes(val))
            }
            else if (key === "status") {
                if (val === "Обработан") {
                    activeSequence = activeSequence.filter(
                        e => uuidRe.exec(e.id) ? !e[key] : !!e[key])
                } else {
                    activeSequence = activeSequence.filter(
                        e => uuidRe.exec(e.id) ? !!e[key] : !e[key])
                }

            } else if (val === "Пусто") {
                activeSequence = activeSequence.filter(
                    e => e[key].length === 0)
            }
            else {
                activeSequence = activeSequence.filter(
                    e => e[key] && e[key].toLowerCase().includes(val.toLowerCase()))
            }
        }
        return activeSequence
    }

    useEffect(() => {
        loadDataFeedback()
    }, [userAccount])

    const sortData = (key) => {
        setCallData([...callData])
        setSortStatus(!sortStatus)
        setSortKey(key)
    }

    const getFilters = (e) => {
        setCurFilterSequence([])

        let f = [{label: "Нет", value: "Нет", k: e}]
        let prev = []
        let names = {}
        for (const [, elem] of Object.entries(filteredRows)) {
            let label = elem[e]
            let val = elem[e]

            if (e === "date") {
                label = normalizeDateDMY(label)
                val = normalizeDateDMY(val)
            }

            if (e === "subject" && !uuidRe.exec(elem.id.toString())) {
                label = "Запрос с сайта"
                val = "Запрос с сайта"
            }

            if (val.length === 0) {
                label = "Пусто"
                val = "Пусто"
            }

            if (e === 'status') {
                label = label ? "Обработан" : "Не обработан"
                val = val ? "Обработан" : "Не обработан"
            }

            if (!prev.includes(val)) prev.push(val)
            names[val] = label
        }
        prev.sort()

        setCurFilterSequence(
            [...f, ...prev.map(elem => ({label: elem, value: names[elem], k: e}))])

    }

    const addFilter = (val) => {
        setCurPage(1)

        let new_f = filters

        // Фильтрация по слайдеру "Фильтровать неуспешные"
        if (!Object.keys(new_f).includes(val)) {
            if (val === "relevant") {
                new_f[val] = true
            }
        } else {
            if (val === "relevant") {
                delete new_f[val]
            }
        }

        // фильтры таблицы
        if (val.k && val.label) {
            if (!Object.keys(new_f).includes(val.k)) {
                new_f[val.k] = []
            }

            if (val.value === "Нет") {
                new_f[val.k] = []
            } else if (new_f[val.k].includes(val.label)) {

            } else {
                new_f[val.k] = val.label
            }

            if (new_f[val.k].length === 0) {
                delete new_f[val.k]
            }
        }

        setFilters({...new_f})
    }

    const changeComment = (id, text) => {
        const re = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
        if (re.exec(id.toString())) {
            for (const elem of crData) {
                elem.comment = elem.id === id && text !== "none" ? text : elem.comment;
            }

            setCrData([...crData])
        } else {
            for (const elem of feedbackData) {
                elem.comment = elem.id === id && text !== "none" ? text : elem.comment;
            }
            setFeedbackData([...feedbackData])
        }
    }

  return (
      <div className={`${s.mainPart} ${designType === "minimal" && s.minimalDesign}`}>
        <Sidebar activeBurger={activeBurger} handleMenu={() => setActiveBurger(!activeBurger)} />
        {/*<NewFeatures show={showFeatures} hide={() => setShowFeatures(false)} page={"incoming-calls"}/>*/}
        <div className={s.flex}>
            <HeadBubles valueStart={valueStart} setValueStart={setValueStart}
                        valueEnd={valueEnd} setValueEnd={setValueEnd} curAts={curAts} filters={filters}
                        setFilter={addFilter} setPageSize={setPageSize} setAts={setAts} rowsCount={filteredRows.length}
                        curPage={curPage} setCurPage={setCurPage} filteredRows={filteredRows} pageSize={pageSize}
                        autoRefresh={autoRefresh} changeAutoRefreshStatus={changeAutoRefreshStatus}
                        setAutoRefreshPeriod={setAutoRefreshPeriod}
                        autoRefreshPeriod={autoRefreshPeriod}
            />

            {showLoader &&
            <Loading />
            }

            {Object.keys(filters).length > 0 &&
            <div className={s.filterListBox}>
                <div>Фильтры:</div>
                <div className={s.filterListBoxElem}>
                    {Object.keys(filters).map(key =>
                        key === "relevant"
                            ?
                            <div key={"f-elem-"+key}
                                 onClick={() => addFilter({label: 'Нет', value: 'Нет', k: key})} >
                                Все вызовы
                            </div>
                            :
                            <div key={"f-elem-"+key}
                                 onClick={() => addFilter({label: 'Нет', value: 'Нет', k: key})} >
                                {incomingCallsHeaders[key].name}: {filters[key]}
                            </div>
                    )}
                </div>
            </div>
            }


            <FeedbackMain curPage={curPage} setCurPage={setCurPage} sortStatus={sortStatus} sortKey={sortKey} addFilter={addFilter}
                          changeStatus={changeStatus}
                          getFilters={getFilters} curFilterSequence={curFilterSequence} setCurFilterSequence={setCurFilterSequence}
                          data={activeRows} sortData={sortData} changeComment={changeComment} isActive={!showLoader}/>

            <PaginationBlock curPage={curPage} setCurPage={setCurPage} callData={filteredRows} paginationSize={pageSize}/>

        </div>

    </div>
  )
}

export default IncomingCalls
