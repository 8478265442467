import React, {useEffect, useState} from 'react'
import {useTheme} from '../../../../../../hooks/useTheme'
import s from './CallingRecords.module.scss'
import Tippy from '@tippyjs/react'
import 'tippy.js/animations/scale.css'
import CheckBox from "../../../../../../ui/CheckBox/CheckBox";

const CallingRecords = ({crStatus, setCrStatus}) => {
    const [localTooltip, setLocalOpen] = useState(false)
    const [status, setStatus] = useState(crStatus)
    const {helpText, isHelperOpen, crIsActive, theme} = useTheme()

    useEffect(() => {
        setStatus(crStatus)
    }, [crStatus])
    //
    useEffect(() => {
        setStatus(crIsActive)
    }, [crIsActive])

    // useEffect(() => {console.log(status)}, [status])

    useEffect(() => {
        if (!helpText) {
            setLocalOpen(false)
        }
    }, [helpText])

    const physicalChangeStatus = (p) => {
        setCrStatus(p)
    }

    return (
        <div className={s.flex}>
            <CheckBox checked={status} chStatus={physicalChangeStatus}/>
          <p className={s.paragraph} style={theme==='light' ? {color: 'var(--main-font-color)'} : {}}>Запись разговоров</p>
          <Tippy
            visible={localTooltip}
            animation={'scale'}
            content={
              <div
                style={{
                  background: 'var(--background-gradient-st)',
                  color: 'var(--main-font-color)',
                  padding: '12px',
                  borderRadius: '10px',
                  border: '1px solid var(--border-default)',
                  boxShadow: '0 0 20px 0 var(--border-default)',
                }}
              >
                  <b>Запись разговоров</b> – удобный сервис для мониторинга звонков и повышения качества
                  обслуживания клиентов по телефону.
                  <br/><br/>
                  Аналитика общения персонала с клиентами уменьшит число коммуникативных ошибок и поможет
                  наращивать продажи. Вы сможете всегда знать, о чем,
                  когда и с кем сотрудники разговаривают по телефону. Находиться при этом в офисе не обязательно.

              </div>
            }
          >
            <p
              className={s.mark}
              style={theme==='light' ? {color: 'var(--main-font-color)'} : {}}
              onClick={() => {
                if (document.body.clientWidth < 1250) {
                  setLocalOpen(true)
                  isHelperOpen(!helpText)
                }
              }}
              onMouseOver={() => {
                setLocalOpen(true)
              }}
              onMouseLeave={() => {
                setLocalOpen(false)
              }}
            >
              ?
            </p>
          </Tippy>
        </div>
      )
}

export default CallingRecords
