import React from 'react'
import SvgPicture from '../../../../../ui/SvgPicture/SvgPicture'
import s from './SvgDiagram.module.scss'

const SvgDiagram = ({ packet }) => {
  return (
    <div className={s.w250}>
      <div className={s.svgPicture}>
        <p>{((+packet.used / +packet.exists) * 100).toFixed(2)}%</p>
        <SvgPicture
          stPercent={Math.round((+packet.used / +packet.exists) * 100)}
        />
      </div>
      <div className={s.info}>
        <div className={s.criteria}>
          <p>Потрачено</p>
          <span>{packet.used} мин.</span>
        </div>
        <div className={s.criteria}>
          <p>Осталось</p>
          <span>{packet.exists - packet.used} мин.</span>
        </div>
      </div>
    </div>
  )
}

export default SvgDiagram
