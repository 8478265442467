import React, {useEffect, useState} from 'react'
import s from './HeadBubles.module.scss'
import {useTheme} from "../../../../hooks/useTheme";
import {Calendar} from "react-calendar";
import {getDate} from "../../../../sortFunction";
import ReactSelect from "react-select";
import CheckBox from "../../../../ui/CheckBox/CheckBox";
import PaginationBlock from "../../../../ui/PaginationBlock/PaginationBlock";


const HeadBubles = (
    {
        valueStart, setValueStart, valueEnd, setValueEnd, setFilter,
        setPageSize, setAts, curAts, filters, rowsCount,
        curPage, setCurPage, filteredRows, pageSize,
        autoRefresh, changeAutoRefreshStatus, setAutoRefreshPeriod, autoRefreshPeriod
}) => {
    const {vatsNames, theme, userAccount} = useTheme()
    const [isOpenStart, setIsOpenStart] = useState(false)
    const [isOpenEnd, setIsOpenEnd] = useState(false)

    const [isOpenFilters, setIsOpenFilters] = useState(false)
    const [isOpenAts, setIsOpenAts] = useState(false)
    const [isOpenPagination, setIsOpenPagination] = useState(false)

    const changeAtsSelect = () => {setIsOpenAts(!isOpenAts)}
    const changeSelectPagination = () => {setIsOpenPagination(!isOpenPagination)}


    // const filters = [
    //     { value: 0, label: 'Все' },
    //     { value: 1, label: 'Выполненные' },
    //     { value: 2, label: 'Невыполненные' },
    // ]

    const setPagination = (v) => {
        setPageSize(v.value)
    }

    useEffect(() => {
        if(vatsNames.length === 0) return
        setAts(vatsNames[0])

    }, [vatsNames])

    const customStyles = {
        control: () => ({
            display: 'flex',
            textAlign: 'left',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        placeholder: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            padding: '5px',
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            maxWidth: '100px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            opacity: '.3',
        }),
        menuList: () => ({
            textAlign: 'left',
            borderRadius: '5px',
            position: 'absolute',
            zIndex: '15',
            left: '-5px',
            width: '100%',
            marginTop: '5px',
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            background: theme !== 'light' ? '#fff' : 'var(--night-color)',
        }),
        menu: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        option: () => ({
            background: 'none',
            padding: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            borderRadius: '5px',

            '&:hover': {
                background: '#3a3a3a',
                color: 'var(--light-color)',
            },
        }),
        singleValue: () => ({
            color: theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
        }),
        input: () => ({
            display: 'none',
        }),
    }

    const changeAutoRefreshPeriod = (event) => {
        let t = event.target.value
        if (t <= 0) t = 1
        setAutoRefreshPeriod(t * 60000)
    }

  return (
      <>
          <div className={s.flex}>
              <div className={s.date}>
                  <div className={s.calendars}>
                      <p className={s.dateTitle}>Начальная дата:</p>
                      <p
                          className={s.chooseDate}
                          onClick={() => setIsOpenStart(!isOpenStart)}
                      >
                          {getDate(valueStart)}
                      </p>
                      {isOpenStart && !userAccount.virtual && (
                          <div>
                              <div
                                  className={s.fullWidth}
                                  onClick={() => setIsOpenStart(false)}
                              />
                              <Calendar
                                  onChange={(value) => {
                                      setValueStart(value)
                                      setIsOpenStart(false)
                                  }}
                                  value={valueStart}
                                  className={s.calendar}
                              />
                          </div>
                      )}
                  </div>
                  <div className={s.calendars}>
                      <p className={s.dateTitle}>Конечная дата:</p>
                      <p
                          className={s.chooseDate}
                          onClick={() => setIsOpenEnd(!isOpenEnd)}
                      >
                          {getDate(valueEnd)}
                      </p>
                      {isOpenEnd && !userAccount.virtual && (
                          <div>
                              <div
                                  className={s.fullWidth}
                                  onClick={() => setIsOpenEnd(false)}
                              />
                              <Calendar
                                  onChange={(value) => {
                                      setValueEnd(value)
                                      setIsOpenEnd(false)
                                  }}
                                  value={valueEnd}
                                  className={s.calendar}
                              />
                          </div>
                      )}
                  </div>
              </div>

              <div className={s.filterSettings}>
                  {/*<div style={{width: "150px"}}>*/}
                  {/*    <p className={s.dateTitle}>Статус заявки</p>*/}
                  {/*    {document.body.clientWidth > 992 ? (*/}
                  {/*        <div onClick={changeSelectFilters} style={{cursor: "pointer"}}>*/}
                  {/*            <ReactSelect*/}
                  {/*                placeholder="Устройство"*/}
                  {/*                defaultValue={filters[0]}*/}
                  {/*                options={filters}*/}
                  {/*                onChange={setFilter}*/}
                  {/*                // styles={customStyles}*/}
                  {/*                menuIsOpen={isOpenFilters}*/}
                  {/*            />*/}
                  {/*        </div>*/}
                  {/*    ) : (*/}
                  {/*        <div style={{cursor: "pointer"}}>*/}
                  {/*            <ReactSelect*/}
                  {/*                placeholder="Устройство"*/}
                  {/*                defaultValue={filters[0]}*/}
                  {/*                options={filters}*/}
                  {/*                onChange={setFilter}*/}
                  {/*                // styles={customStyles}*/}
                  {/*            />*/}
                  {/*        </div>*/}
                  {/*    )}*/}
                  {/*</div>*/}
                  <div>
                      <p className={s.dateTitle}>Выберите устройство</p>
                      {document.body.clientWidth > 992 ? (
                          <div onClick={changeAtsSelect} className={s.cursor}>
                              <ReactSelect
                                  placeholder="Устройство"
                                  value={curAts}
                                  options={vatsNames}
                                  onChange={setAts}
                                  styles={customStyles}
                                  menuIsOpen={isOpenAts}
                              />
                          </div>
                      ) : (
                          <div className={s.cursor}>
                              <ReactSelect
                                  placeholder="Устройство"
                                  value={curAts}
                                  options={vatsNames}
                                  onChange={setAts}
                                  styles={customStyles}
                              />
                          </div>
                      )}

                  </div>

                  <div>
                      <p className={s.dateTitle}>Размер страницы</p>
                      {document.body.clientWidth > 992 ? (
                          <div onClick={changeSelectPagination} style={{cursor: "pointer"}}>
                              <ReactSelect
                                  placeholder="Кол-во эл. на странице"
                                  defaultValue={{ value: 10, label: '10' }}
                                  options={[
                                      { value: 10, label: '10' },
                                      { value: 20, label: '20' },
                                      { value: 50, label: '50' },
                                      { value: 100, label: '100' },
                                      { value: 150, label: '150' },
                                      { value: 200, label: '200' },
                                  ]}
                                  onChange={setPagination}
                                  styles={customStyles}
                                  menuIsOpen={isOpenPagination}
                              />
                          </div>
                      ) : (
                          <div style={{cursor: "pointer"}}>
                              <ReactSelect
                                  placeholder="Кол-во эл. на странице"
                                  defaultValue={{ value: 10, label: '10' }}
                                  options={[
                                      { value: 10, label: '10' },
                                      { value: 20, label: '20' },
                                      { value: 50, label: '50' },
                                      { value: 100, label: '100' },
                                      { value: 150, label: '150' },
                                      { value: 200, label: '200' },
                                  ]}
                                  onChange={setPagination}
                                  styles={customStyles}
                              />
                          </div>
                      )}
                  </div>

              </div>



          </div>
          <div className={s.flex} >
              <div className={s.filterSettings}>
                  <div className={s.filterElem}>
                      <div className={s.flexSimple} >
                          <p style={{height: "26px !important", padding: "0 10px"}}>
                              Показывать все звонки
                          </p>
                          <div style={{height: "24px !important"}}>
                              <CheckBox styleParams="inline fl-r"
                                        status={Object.keys(filters).includes("relevant")}
                                        chStatus={() => setFilter("relevant")}/>
                          </div>

                      </div>

                      <div className={s.flexSimple} >
                          <p style={{height: "26px !important", padding: "0 10px"}}>
                              Автообновление
                          </p>
                          <div style={{height: "24px !important"}}>
                              <CheckBox styleParams="inline fl-r"
                                        status={autoRefresh}
                                        chStatus={() => changeAutoRefreshStatus(!autoRefresh)}/>
                          </div>

                      </div>

                      { autoRefresh &&
                      <div className={s.flexSimple} >
                          <p style={{height: "26px !important", padding: "0 10px"}}>
                              Период (минуты)
                          </p>
                          <div style={{height: "24px !important"}}>
                              <input type={"decimal"}
                                     value={autoRefreshPeriod / 60000}
                                     onChange={changeAutoRefreshPeriod}/>
                          </div>

                      </div>

                      }

                  </div>



              </div>
              <div >
                  <div className={s.counterBlock}>Всего запросов: {rowsCount}</div>
                  <PaginationBlock curPage={curPage} setCurPage={setCurPage}
                                   callData={filteredRows} paginationSize={pageSize}/>
              </div>


              {/**/}
          </div>
      </>

  )
}

export default HeadBubles
