import {useTheme} from "../hooks/useTheme";
import {components} from "react-select";
import React from "react";

export const useSelectStyles = () => {
    const theme = useTheme()
    return (
        {
            control: () => ({
                display: 'flex',
                textAlign: 'left',
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
                cursor: 'pointer',
            }),
            placeholder: () => ({
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
                opacity: '.3',
            }),
            menuList: (provided, state) => ({
                ...provided,
                textAlign: 'left',
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
                position: 'absolute',
                zIndex: 1000,
                width: '100%',
                borderRadius: '10px',
                top: 'calc(100% + 12px)',
                // overflow: 'hidden',
                background:
                    theme.theme === 'light' ? 'var(--night-color)' : 'var(--light-color)',
            }),
            menu: () => ({
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            }),
            option: () => ({
                background: 'none',
                padding: '5px',
                fontSize: '16px',
                cursor: 'pointer',

                '&:hover': {
                    background: '#3a3a3a',
                    color: 'var(--light-color)',
                },
            }),
            singleValue: () => ({
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--main-font-color)',
            }),
            input: () => ({
                display: 'none',
            }),
        }
    )
}

export const useFormSelectStyles = () => {
    const theme = useTheme()
    return (
        {
            control: () => ({
                display: 'flex',
                borderStyle: "dotted",
                textAlign: 'left',
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
                cursor: 'pointer',
            }),
            placeholder: () => ({
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
                opacity: '.3',
            }),
            menuList: (provided, state) => ({
                ...provided,
                textAlign: 'left',
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
                position: 'absolute',
                zIndex: 1000,
                width: '100%',
                borderRadius: '10px',
                top: 'calc(100% + 12px)',
                // overflow: 'hidden',
                background: theme.theme === 'light' ? 'var(--night-color)' : 'var(--light-color)',
            }),
            menu: () => ({
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--night-color)',
            }),
            option: () => ({
                background: 'none',
                padding: '5px',
                fontSize: '16px',
                cursor: 'pointer',

                '&:hover': {
                    background: '#3a3a3a',
                    color: 'var(--light-color)',
                },
            }),
            singleValue: () => ({
                color: theme.theme === 'light' ? 'var(--light-color)' : 'var(--main-font-color)',
            }),
            input: () => ({
                display: 'none',
            }),
        }
    )
}

export const NoOptionsMessage = props => {
    const defaultText = "No options"
    return (
        <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">{props?.selectProps?.noOptionsText || defaultText}</span>
        </components.NoOptionsMessage>
    );
};
