import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import DefaultTitle from '../../DefaultTitle/DefaultTitle'
import s from './SettingsBar.module.scss'
import {useTheme} from "../../../hooks/useTheme";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import {GlobalGifSelector} from "../../../components/GlobalGifSelector/GlobalGifSelector";
import {MenuTooltips} from "../../../provider/variables";
import {DefaultButtonSmall} from "../../Button/Button";
import {MenuTooltip} from "../../MenuTooltip/MenuTooltip";

const ToolTip = ({text}) => {
    return(
        <div className={s.toolTip}
             style={{
                 background: 'var(--background-gradient-st)',
                 color: 'var(--main-font-color)',
                 padding: '12px',
                 borderRadius: '10px',
                 border: '1px solid var(--border-default)',
                 boxShadow: '0 0 20px 0 var(--border-default)',
                 position: "absolute",
                 zIndex: "999",
                 display: "none",
                 cursor: "none"
             }}
        >
            {text}
        </div>
    )
}

export const GifMenuElem = ({id, width}) => {
    const [staticImg, setStaticImg] = useState(true)

    return (
        staticImg
            ?
            <span onMouseOver={() => setStaticImg(!staticImg)}
                  style={{width: "35px", height: "35px", content: ""}}>
                <GlobalSvgSelector id={id} width={width ? width : ""}/>
            </span>
            :
            <span onMouseOut={() => setStaticImg(!staticImg)}>
                <GlobalGifSelector id={id} width={width ? width : ""}/>
            </span>

    )
}

const SettingsBar = ({ settings }) => {
    const location = useLocation()
    const {voipNumers, phoneNumers, userAccount, theme, designType, setDesignType} = useTheme()
    const [menu, setMenu] = useState([])
    const [tooltipsList, setTooltipsList] = useState([])

    useEffect(() => {
        let m = []
        let ttpsList = []
        for (const elem of settings) {
            if (elem.service === "voip") {
                if (!voipNumers || voipNumers.length === 0) continue
            }

            if ((userAccount.virtual || userAccount.fusion_auth) && !elem.showVirtual) {
                // Прячем от виртуалов
                continue
            }

            if (elem.service === "cl") {
                if (phoneNumers.length === 0 && voipNumers.length === 0) continue
            }

            m.push(elem)

            let mt = MenuTooltips.find(
                ttpElem => ttpElem.service === elem.gifName && ttpElem.show && !localStorage.getItem(ttpElem.key))
            if (mt) ttpsList.push(mt)
        }

        setMenu([...m])
        setTooltipsList(ttpsList.sort((a, b) => a.id < b.id ? 1 : -1))

    }, [settings, voipNumers])

    const changeDesign = () => {
        setDesignType(designType === 'normal' ? 'minimal' : 'normal')
    }

    const nextTooltip = () => {
        let ttps = [...tooltipsList]
        let elem = ttps.pop()
        localStorage.setItem(elem.key, "true")
        setTooltipsList(ttps)
    }

    return (
        <div className={`${s.menuBar} ${designType === 'minimal' ? s.smallBubble : ""}`}>
            {designType === 'normal' ?
                <div className={s.title}>
                    <DefaultTitle title="Настройки"/>
                    <div className={`${s.toSmallDesignButton} ${s.withTooltip}`} onClick={changeDesign}>

                        {theme !== 'light' ? (
                            <GifMenuElem id={"arrow-left"} width={"20px"}/>
                        ) : (
                            <GifMenuElem id={"arrow-left"} width={"20px"}/>
                        )}
                        <ToolTip text={"Широкий дизайн"}/>


                    </div>
                </div>
                :
                <div className={s.title}>
                    <div className={`${s.toSmallDesignButton} ${s.withTooltip}`} onClick={changeDesign}>
                        {theme !== 'light' ? (
                            <GifMenuElem id={"arrow-right"}/>
                        ) : (
                            <GifMenuElem id={"arrow-right"}/>
                        )}
                        <ToolTip text={"Узкий дизайн"}/>
                    </div>
                </div>
            }

            {menu.map((m) => (
                <p
                    key={m.id}
                    className={
                        location.pathname === m.href || location.pathname === `${m.href}/`
                            ? `${s.href} ${s.active}`
                            : s.href
                    }
                >
                    {designType === 'normal'
                        ?
                        <Link to={m.href}>{m.title}</Link>
                        :
                        <div className={`${s.inline}`}>
                            <Link to={m.href} className={`${s.withTooltip}`} style={{width: "35px", height: "35px"}}>
                                <GifMenuElem id={m.gifName}/>
                                <ToolTip text={m.title} />
                            </Link>
                            {MenuTooltips.find(elem => elem.service === m.gifName && elem.show) &&
                            tooltipsList.length > 0 && tooltipsList[tooltipsList.length - 1].service === m.gifName &&
                            <MenuTooltip elem={MenuTooltips.find(elem => elem.service === m.gifName && elem.show)} hide={nextTooltip}/>
                            }

                        </div>

                    }
                </p>
            ))}
        </div>
    )
}

export default SettingsBar
