import React, {useEffect, useState} from "react";
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import s from "../CRMIntegrationsPage.module.scss"
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {WebSitesButton} from "../../../../ui/Button/WebSitesButton";
import {useTheme} from "../../../../hooks/useTheme";
import {saveCrmIntegrations} from "../api";
import {megaplanSchema} from "../schemas";
import {CheckboxLine, ExtInputLine} from "../FormElements";



export const MegaplanIntegration = ({integration, reload, bubbled=true}) => {
    const [integrationIsEnabled, setIntegrationIsEnabled] = useState(false)
    const [integrationData, setIntegrationData] = useState({})
    const {userAccount, setActivePopupGlobal, vatsDomain} = useTheme()

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(megaplanSchema),
        mode: 'onBlur',
        defaultValues: {
            domain: vatsDomain?.domain,
            integration: {
                domain: "",
                default_phone: "",
                smart_forwarding: false,
                api_key: "",
            }
        }})

    useEffect(() => {
        if (!integration?.integration) return
        setIntegrationData(integration)
    }, [integration])

    useEffect(() => {
        // if (integrationData?.integration?.type !== "mega") return

        reset({...integrationData})
        setIntegrationIsEnabled(integrationData?.integration?.active)
    }, [integrationData])

    const submitForm = (formData) => {
        const formatedData = {
            domain: formData.domain,
            integration: {
                type : "mega",
                domain: formData?.integration?.domain || "",
                default_phone: formData?.integration?.default_phone || 0,
                api_key: formData?.integration?.api_key || "",
                smart_forwarding: formData?.integration?.smart_forwarding || false,
            }
        }

        saveCrmIntegrations({auth: userAccount, ...vatsDomain, data: formatedData})
            .then((e) => {setActivePopupGlobal("successSave"); reload(); console.log(e)})
            .catch((r) => {console.log(r); setActivePopupGlobal("failSave")})
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0) return

        console.log(errors)
    }, [errors])

    return(
        <div className={`${bubbled ? s.bubble : s.noBlubble} margin-b-24`}
             style={{width: "70%"}}
        >
            <div className={`${s.fixedBubble}`}>
                <div className={s.title}>
                    <DefaultTitle title="Интеграция с Мегаплан" justify={true}/>
                </div>

                <form onSubmit={handleSubmit(submitForm)} className={s.formFlex}>
                    <ExtInputLine errors={errors} register={register} name={"integration.domain"}
                               title={"Домен ЦРМ"}/>
                    <ExtInputLine errors={errors} register={register} name={"integration.default_phone"}
                               dataType={"numbers"} title={"Номер по умолчанию"}/>

                    <ExtInputLine errors={errors} register={register} name={"integration.api_key"}
                               title={"Ключ API"}/>
                    <CheckboxLine errors={errors} register={register}
                                  name={"integration.smart_forwarding"} title={"Умная переадресация"} control={control}/>
                    <WebSitesButton text={
                        integration?.integration?.type === "mega"
                            ? "Сохранить настройки"
                            : "Применить интеграцию"
                    }/>
                </form>
            </div>
        </div>
    )
}
