import React, {useEffect, useState} from 'react'
import s from './NumberAssortiment.module.scss'
import 'tippy.js/animations/scale.css'
import {useTheme} from '../../../../../../../../hooks/useTheme'

const NumberAssortiment = ({search, searchNumbers, numbers, addActiveNumber}) => {
  const {helpText, updateNumbersPool} = useTheme()
  const [localTooltip, setLocalTooltip] = useState(false)


  useEffect(() => {
    if (!helpText) {
      setLocalTooltip(false)
    }
  }, [helpText])

  return (
    <div className={s.flex}>
      <div className={s.window}>
        <div className={s.wrapper}>
          {!search
            ? numbers.map(number => (
                <p key={number.NUMER} className={s.number}>
                  <span className={s.plus} onClick={() => addActiveNumber(number.NUMER)}>
                    +
                  </span>
                  <span className={s.paragraph} onDoubleClick={() => addActiveNumber(number.NUMER)}>
                    {number.NUMER}
                  </span>
                </p>
              ))
            : searchNumbers.map(number => (
                <p key={number.NUMER} className={s.number}>
                  <span className={s.plus} onClick={() => addActiveNumber(number.NUMER)}>
                    +
                  </span>
                  <span className={s.paragraph} onDoubleClick={() => addActiveNumber(number.NUMER)}>
                    {number.NUMER}
                  </span>
                </p>
              ))}
        </div>
        <button className={s.reload} onClick={updateNumbersPool}>Обновить список</button>
      </div>

    </div>
  )
}

export default NumberAssortiment
