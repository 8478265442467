import React from 'react'
import { useTheme } from '../../../hooks/useTheme'
import s from './MobileMenu.module.scss'
import { Link } from 'react-router-dom'
import GlobalSvgSelector from '../../GlobalSvgSelector/GlobalSvgSelector'
import MenuBar from '../../../ui/MenuBlock/MenuBar/MenuBar'
import SettingsBar from '../../../ui/MenuBlock/SettingsBar/SettingsBar'

const MobileMenu = ({ items, handleMenu, activeMenu, changeTheme }) => {
  const { theme, menu, settingsMenu, logoutFunc } = useTheme()

  return (
    <div className={activeMenu ? `${s.fullWidth} ${s.act}` : s.fullWidth}>
      <div
        className={activeMenu ? `${s.popupMenu} ${s.active}` : s.popupMenu}
        onClick={(e) => e.stopPropagation()}
      >
        {items.map((item) => (
          <p className={s.link} key={item.text}>
            <Link to={item.href} onClick={handleMenu} className={s.color}>
              {item.text}
            </Link>
          </p>
        ))}
        <Link to="#" className={s.help} onClick={logoutFunc}>
          Выход
        </Link>
        <button
          className={theme !== 'dark' ? `${s.darkCross} ${s.cross}` : s.cross}
          onClick={handleMenu}
        >
          {theme !== 'light' ? (
            <GlobalSvgSelector id="cross" />
          ) : (
            <GlobalSvgSelector id="cross-dark" />
          )}
        </button>
        <div className={s.features}>
          <p
            onClick={changeTheme}
            className={theme !== 'dark' ? s.darkTheme : ''}
          >
            {theme !== 'light' ? (
              <GlobalSvgSelector id="theme-switcher" />
            ) : (
              <GlobalSvgSelector id="theme-switcher-dark" />
            )}
          </p>
          <a
            href="/#"
            className={theme !== 'dark' ? s.darkTheme : ''}
            onClick={handleMenu}
          >
            {theme !== 'light' ? (
              <GlobalSvgSelector id="profile" />
            ) : (
              <GlobalSvgSelector id="profile-dark" />
            )}
          </a>
          <p
            className={theme !== 'dark' ? s.darkTheme : ''}
            onClick={handleMenu}
          >
            {theme !== 'light' ? (
              <GlobalSvgSelector id="notifications" />
            ) : (
              <GlobalSvgSelector id="notifications-dark" />
            )}
          </p>
        </div>
        <div onClick={handleMenu}>
          <MenuBar menu={menu} />
          <SettingsBar settings={settingsMenu} />
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
