import React, {useEffect, useState} from 'react'
import ModalMessage from "../ModalMessage";
import s from './StatusPopup.module.scss'
import {useTheme} from "../../../hooks/useTheme";


export const SrcStatus = ({status, value = null, relations}) => {
    const [relStatus, setRelStatus] = useState(0)

    useEffect(() => {
        if (relations.length > 0) {
            for (const rel of relations) {
                if (rel.includes(value) && rel.includes(true))
                    setRelStatus(1)
                else if (rel.includes(value) && rel.includes(false))
                    setRelStatus(-1)
            }
        }
    }, [relations])

    if (status === 1 || relStatus === 1) return (<div className={s.successStatus}>Выполнено</div>)
    if (status === -1 || relStatus === -1) return (<div className={s.failStatus}>Возникла ошибка</div>)
    if (status === "wait") return (<div className={s.failStatus}>asd</div>)


    return  <div className={s.spinnerMain}>
                <div style={{margin: "0", width: "16px"}} className={["loading-spinner", s.spinner].join(" ")} />
            </div>
}

const StatusPopup= ({label, data, status, hide, relations, message}) => {

  return (
      <ModalMessage
          label={label}
          show={status}
          hide={() => hide(false)}>
          {message &&
          <div className={s.errorMessage}>
              {message}
          </div>

          }
          {data.map((elem, index) =>
              elem.elements.length
              ?
                  <div key={elem.title} className={s.elemGroup}>
                      <div className={s.label}>{elem.title}</div>
                      {elem.elements.length &&
                      elem.elements.map(e =>
                          <div className={s.elem} key={e.name}>
                              <div className={s.elemTitle}>{e.name}</div>
                              <div className={s.elemStatus}>
                                  <SrcStatus status={elem.elements.status}
                                             value={e.name}
                                             relations={relations}/>
                              </div>
                          </div>
                      )
                      }
                  </div>
              :
                  <div key={index} className={s.elemGroup}>
                      <div className={s.labelInline}>{elem.title}</div>
                      <div className={s.elemStatus}>
                          <SrcStatus status={elem.elements.status}
                                     value={elem.elements.name} relations={relations}/>
                      </div>

                  </div>

          )}

      </ModalMessage>
  )
}

export default StatusPopup
