export const helpMasterVideoLinks = {
    "interfaceVideo": {url: "", title: "Обзор интерфейса"},
    "paymentsVideo": {url: "", title: "Пополнение счёта"},
    "showExtensionsVideo": {url: "https://youtu.be/gEX6SzLgKDU", title: "Как посмотреть подключенные номера"},
    "addExtensionsVideo": {url: "https://youtu.be/7sa7DCBCldY", title: "Как подключить новые номера"},
    "removeExtensionsVideo": {url: "https://youtu.be/nwjWD-dFzEk", title: "Как отключить номера"},
    "vatsExtensionsConfVideo": {url: "https://youtu.be/xZg6EFrb-Iw", title: "Шаг 1. Настройте внутренние номера сотрудников"},
    "vatsAddExtensionsToGroupsVideo": {url: "https://youtu.be/UH_M38HpXA0", title: "Шаг 2. Объедините внутренние номера сотрудников в группы"},
    "vatsAddAudioVideo": {url: "https://youtu.be/GLnYGIc_mUg", title: "Шаг 3. Подготовьте и загрузите аудиофайлы"},
    "vatsIVRConfVideo": {url: "https://youtu.be/JzrnF-Cy7kE", title: "Шаг 4. Настройте голосовое меню"},
    "vatsRasprVizovConfVideo": {url: "https://youtu.be/IuSq7e3pHaM", title: "Шаг 5. Настройте распределение вызовов по времени"},
    "vatsRoutingConfVideo": {url: "https://youtu.be/rdeN7VP4fFE", title: "Шаг 6. Настройте маршрутизацию городских номеров или номеров 8800"},
    "vatsPhonesConfVideo": {url: "", title: "Шаг 7. Настройте VOIP устройства"},
    "vatsAddNumbersVideo": {url: "https://youtu.be/90aK09zq7uA", title: "Добавление внутренних номеров для сотрудников"},
    "vatsCallRecordsVideo": {url: "https://youtu.be/YuuV8FI5N4s", title: "Запись разговоров"},
    "vatsVamZvonilyVideo": {url: "https://youtu.be/s4y_fZlj2e8", title: "Сервис \"Вам звонили\""},
    "vatsCrmVideo": {url: "", title: "Интеграция с CRM"},
    "vatsIvrScenariosVideo": {url: "", title: "Разработка сценариев голосового меню"},
    "vatsVoiceVideo": {url: "", title: "Профессиональная озвучка голосового меню"},
    "vatsInstallIndividualVideo": {url: "", title: "Настройка ВАТС по индивидуальному заданию"},
    "outgoingVideo": {url: "https://youtu.be/3F5y4GNFNg8", title: "Пакеты исходящей связи"},
    "vatsSFWindowsVideo": {url: "https://youtu.be/gP-rEx2i8Vk", title: "Настройка софтфона для Windows"},
    "vatsSFMacVideo": {url: "", title: "Настройка софтфона для MacOS"},
    "vatsSFAndroidVideo": {url: "https://youtu.be/x412h6NNKEA", title: "Настройка софтфона для Android"},
    "vatsSFIOSVideo": {url: "https://youtu.be/Kbhs9j2b5oI", title: "Настройка софтфона для IOS"},
    "vatsBLackListVideo": {url: "https://youtu.be/0cxiyIx1eq8", title: "Настройка черного списка"},
    "vatsTTSGenerateVideo": {url: "", title: "Генерация аудиофайлов с помощью TTS"},
    "vatsCallCenterVideo": {url: "", title: "Настройка коллцентра"},
    "vatsConferenceCallVideo": {url: "", title: "Настройка конференц связи"},
}

export const breadCrumbSectionsHelper = {
    services: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам"},
    ],
    interfaceVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Обзор интерфейса"},
    ],
    paymentsVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Пополнение счёта"},
    ],
    cityNumbers: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Городские номера и 8800"},
    ],
    showExtensionsVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Городские номера и 8800", page: "cityNumbers"},
        {name: "Как посмотреть подключенные номера"},
    ],
    addExtensionsVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Городские номера и 8800", page: "cityNumbers"},
        {name: "Как подключить новые номера"},
    ],
    removeExtensionsVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Городские номера и 8800", page: "cityNumbers"},
        {name: "Как отключить номера"},
    ],
    vats: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", },
    ],
    vatsInstall: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно"},
    ],
    vatsExtensionsConfVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Шаг 1. Настройте внутренние номера сотрудников"},
    ],
    vatsAddExtensionsToGroupsVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Шаг 2. Объедините внутренние номера сотрудников в группы"},
    ],
    vatsAddAudioVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Шаг 3. Подготовьте и загрузите аудиофайлы"},
    ],
    vatsIVRConfVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Шаг 4. Настройте голосовое меню"},
    ],
    vatsRasprVizovConfVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Шаг 5. Настройте распределение вызовов по времени"},
    ],
    vatsRoutingConfVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Шаг 6. Настройте маршрутизацию городских номеров или номеров 8800"},
    ],
    vatsVoipConf: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Шаг 7. Настройте VOIP устройства"},
    ],
    vatsSFWindowsVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Настройка VOIP устройств", page: "vatsVoipConf"},
        {name: "Настройка софтфона для Windows"},
    ],
    vatsSFMacVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Настройка VOIP устройств", page: "vatsVoipConf"},
        {name: "Настройка софтфона для MacOS"},
    ],
    vatsSFAndroidVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Настройка VOIP устройств", page: "vatsVoipConf"},
        {name: "Настройка софтфона для Android"},
    ],
    vatsSFIOSVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настроить ВАТС по шагам самостоятельно", page: "vatsInstall"},
        {name: "Настройка VOIP устройств", page: "vatsVoipConf"},
        {name: "Настройка софтфона для iOS"},
    ],
    vatsAddNumbersVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Добавление внутренних номеров для сотрудников"},
    ],
    vatsCallRecordsVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Запись разговоров"},
    ],
    vatsVamZvonilyVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Сервис Вам звонили"},
    ],
    vatsCrmVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Интеграция с CRM"},
    ],
    vatsIvrScenariosVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Разработка сценариев голосового меню"},
    ],
    vatsVoiceVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Профессиональная озвучка голосового меню"},
    ],
    vatsInstallIndividualVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настройка ВАТС по индивидуальному заданию"},
    ],
    vatsBLackListVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настройка черного списка"},
    ],
    vatsTTSGenerateVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Генерация аудиофайлов с помощью TTS"},
    ],
    vatsConferenceCallVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Виртуальная АТС", page: "vats"},
        {name: "Настройка конференц связи"},
    ],
    outgoingVideo: [
        {name: "Главное меню", page: "main"},
        {name: "Помощь по услугам", page: "services"},
        {name: "Пакеты исходящей связи"},
    ],
}