import React, {useEffect, useState} from 'react'
import DefaultTitle from '../../../../ui/DefaultTitle/DefaultTitle'
import s from './MainBlock.module.scss'
import {useTheme} from '../../../../hooks/useTheme'

import VirtualATS from './VirtualATS/VirtualATS'
import Numbers from './Numbers/Numbers'
import OutgoingCall from './OutgoingCall/OutgoingCall'
import ModalMessage from "../../../../ui/Popup/ModalMessage";
import {
    addNumber, addVats, addVoip, byUslRequest, changeService, sendEmail, suspendService
} from "../../../../api";
import {cards, marks, prices} from "../../../../provider/variables";

import StatusPopup from "../../../../ui/Popup/StatusPopup/StatusPopup";
import ConfirmationPopup from "../../../../ui/Popup/ConfirmationPopup/ConfirmationPopup";
import {createBrowserHistory} from "history";


const history = createBrowserHistory();

const MainBlock = ({setActivePopup}) => {
    const {selectIsOpen, setSelectIsOpen, helpText, balance, userAccount, crIsActive,
        services, setShopPhonePrice, setShopVoipPrice, shopVoipPrice, phoneNumers, packetsVoipNumers,
        isHelperOpen, setNumersShopPrice, numbersShopPrice, numbers, loadNumbersPool} = useTheme()

    const [activeNumbers, setActiveNumbers] = useState([]) // сами номера
    const [rangeBar, setRangeBar] = useState(
        localStorage.getItem("vatsRangeBar") ?
            parseInt(localStorage.getItem("vatsRangeBar")) : 5) // Позиция ВАТС

    const [crStatus, setCrStatus] = useState(false)
    const [fraudStatus, setFraudStatus] = useState(false)

    const [activeMinutes, setActiveMinutes] = useState(
        localStorage.getItem("activeMinutes") ?
            parseInt(localStorage.getItem("activeMinutes")) : 1500) // активное кол-во минут

    const [installingStatus, setInstallingStatus] = useState(
        localStorage.getItem("installingStatus") ?
            parseInt(localStorage.getItem("installingStatus"))  > 0 : false // Профессиональная настройка ВАТС
    )

    const [securityPrice, setSecurityPrice] = useState(0) // цена за антифрод
    const [vatsPrice, setVatsPrice] = useState(0) // Плата за количество сотрудников АТС
    const [crPrice, setCrPrice] = useState(0) // Плата за запись разговоров

    const [numberPrice, setNumberPrice] = useState(0)
    const [minutesPrice, setMinutesPrice] = useState(0) // цена зависящая от кол-ва минут
    const [activePacket, setActivePacket] = useState({id: 0, price: 0}) // действующий пакет

    const [packetsPrice, setPacketPrice] = useState(0) // стоимость выбранного пакета межгорода
    const [atsSetDrive, setAtsSetDrive] = useState(0) // isd устройства ATS в базе
    const [mainSetDrive, setMainSetDrive] = useState(0) // isd устройства ГУ в базе

    const [popupStatus, setPopupStatus] = useState(false)
    const [showPayStatus, setShowPayStatus] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [popupTarget, setPopupTarget] = useState("")

    const [popupStatuses, setPopupStatuses] = useState([])
    const [byRelations, setByRelations] = useState([])
    const [title, setTitle] = useState("Приобретение номеров и услуг")
    const [numbersPoolLoaded, setNumbersPoolLoaded] = useState(false)

    const get_id_set_usl = (usl) => {
        if (!services || !services.active_usl) return []

        let serv_name = ""
        if (usl === "cr") {
            serv_name = "Запись телефонных разговоров_безлимитный_ВАТС"
        } else if (usl === "AF") {
            serv_name = "Телезон_Секьюрити"
        } else {
            return []
        }

        // services.active_usl.map(u => u.usl_name && console.log(serv_name, u.usl_name.includes(serv_name)))
        let serv = services.active_usl.filter(u => u.usl_name && u.usl_name.includes(serv_name) && u.usl_active)

        if (serv.length === 0) return []
        else return serv.map(e => e.id_set_usl)

    }

    useEffect(() => {
        if (!services || !services.active_usl || !packetsVoipNumers) return

        const s = services.active_usl.find(usl => usl.usl_id && packetsVoipNumers.includes(usl.usl_id) && usl.usl_active)
        if (s && s.id_set_drive) {
            setMainSetDrive(s.id_set_drive)
        }


        // console.log(services.active_usl)
    }, [services, packetsVoipNumers])

    const checkService = (name, val, type="vats") => {
        if (!services || !services.active_usl) return false

        if (type === "vats") {
            const serv_name = "Виртуальная IP-ATC <"+val+">"
            let serv = services.active_usl.filter(u =>
                u.usl_name === serv_name && u.usl_active)

            if (serv.length > 0)  {
                setAtsSetDrive(serv[0].id_set_drive)
            }
            // serv = serv.filter(u => u)
            return serv.length > 0
        } else if (type === "voip") {
            const serv_name = cards[name].wTitle+val
            let serv = services.active_usl.filter(
                u => u.usl_name === serv_name && u.usl_active && u.context && u.context.packet_size === activeMinutes)

            if (serv.length > 0)  {
                setMainSetDrive(serv[0].id_set_drive)
            }

            return serv.length > 0
        } else if (type === "cr") {
            const serv_name = "Запись телефонных разговоров_безлимитный_ВАТС"
            let serv = services.active_usl.filter(u => u.usl_name === serv_name && u.usl_active)

            return serv.length > 0
        } else if (type === "antifraud") {
            const serv_name = "Телезон_Секьюрити_5"
            let serv = services.active_usl.filter(u => u.usl_name === serv_name && u.usl_active)
            return serv.length > 0
        }

        // let usls = services.active_usl.filter(u => packetsVoipNumers.includes(u.usl_id))
    }

    useEffect(() => {
        setNumersShopPrice(numberPrice + securityPrice + vatsPrice + crPrice + packetsPrice)

        setShopPhonePrice(numberPrice + vatsPrice);
        setShopVoipPrice(packetsPrice + crPrice + securityPrice);
    }, [numberPrice, vatsPrice, packetsPrice, crStatus, securityPrice])

    useEffect(() => {
        let numbersPrice = 0
        activeNumbers.map(num => numbersPrice += num.AP + num.PRICE)
        setNumberPrice(numbersPrice)

    }, [activeNumbers])

    useEffect(() => {
        // console.log("useEffect atsSetDrive", crStatus, atsSetDrive, confirmed)
        if (crStatus && atsSetDrive && confirmed) {
            byCallRecordsHndlr()
        }

        if (installingStatus && atsSetDrive && confirmed) {

            let mes = "Абонент "+userAccount.login+" запросил профессиональную настройку ВАТС.\n\n"
            mes += "Просьба связаться с абонентом для её выполнения. Услуга не оплачена."

            sendEmail({
                login: userAccount.login,
                token: userAccount.token,
                page: "Покупка номеров",
                text: mes,
                target: ["dks@telezon.ru", "sd@telezon.ru", "eka@telezon.ru"]
            }).then(response => console.log(response))
        }

    }, [atsSetDrive, confirmed])

    useEffect(() => {
        if (fraudStatus && mainSetDrive && confirmed) {
            byTelezonSecurity()
        }
    }, [mainSetDrive, confirmed, fraudStatus])

    const byCallRecordsHndlr = () => {
        if (!crStatus || !atsSetDrive) return
        if (checkService("cr", 0, "cr", services))
            return

        let req = userAccount
        req.idSetDrive = atsSetDrive
        req.serviceName = "callRecords"
        changeService(req)
            .then(() => resultByRequest("cr", true))
            .catch(() => resultByRequest("cr", false))
    }

    const byTelezonSecurity = () => {
        console.log("byTelezonSecurity", fraudStatus, mainSetDrive)
        if (!fraudStatus || !mainSetDrive) return
        if (checkService("antifraud", 0, "antifraud", services))
            return

        let req = userAccount
        req.idSetDrive = mainSetDrive
        req.serviceName = "antifarud"
        changeService(req)
            .then(() => resultByRequest("antifraud", true))
            .catch(() => resultByRequest("antifraud", false))
    }

    const getVatsNumber = () => {
        if (activeNumbers.length > 0) {
            return activeNumbers[0].NUMER ? activeNumbers[0].NUMER : ""
        }
    }

    const byAtsHndlr = (confirmed = false) => {
        let statuses = popupStatuses

        // console.log(crStatus, crIsActive)
        if (!confirmed)
            if (crStatus && !crIsActive) {
                statuses.push({
                    title: "Запись телефонных разговоров",
                    elements: {
                        name: "cr",
                        status: "relations",
                        price: crPrice,
                        rmFunction: {target: "Запись телефонных разговоров", value: true}
                    }})
            }

        //if (rangeBar === 0) return false
        // количество сотрудников
        const workers = marks[rangeBar] && marks[rangeBar].label ? parseInt(marks[rangeBar].label): 0
        if (checkService("vats", workers, "vats")) return

        if (!confirmed && (vatsPrice > 0 || workers === 3))
            statuses.push(
                {
                    title: "Виртуальная АТС ("+workers.toString()+" сотрудников)",
                    elements: {
                        name: "vats",
                        status: "relations",
                        price: vatsPrice,
                        rmFunction: {target: "Виртуальная АТС ("+rangeBar.toString()+" сотрудников)", value: null}
                    }
                })

        if (!confirmed)
            setPopupStatuses([...statuses])

        const vatsResultResponse = (isd) => {
            setAtsSetDrive(isd)
            resultByRequest("vats", isd > 0)
        }

        setAtsSetDrive(0)
        let req = userAccount
        req.workers = workers
        req.target_number = getVatsNumber()
        req.cr = crStatus

        if (confirmed)
            addVats(req)
                .then(r => {
                    r && r.status && r.id_set_drive
                        ? vatsResultResponse(r.id_set_drive)
                        : vatsResultResponse(0)
                })
                .catch(r => {vatsResultResponse(0); setError(r.response.data)})

    }

    const setError = (val) => {
        if (!JSON.parse(val)) return

        val = JSON.parse(val);
        if (!val.error) return

        let text = "";
        switch (val.error) {
            case "need number":
                text = "Необходимо выбрать номер телефона";
                break
            case "no money":
                text = "Не удалось приобрести услуги из-за нехватки средств на счёте «Межгород»";
                break
            default:
                text = "Возникла ошибка на сервере"
        }

        //setPopupTarget(text)
    }

    const byVoipHandler = (confirmed = false) => {
        if (!activePacket || !activePacket.packet || activePacket.packet.id < 0) return

        let request = {...userAccount, ...activePacket}

        let statuses = popupStatuses
        console.log(confirmed, fraudStatus, checkService("antifraud", 0, "antifraud"))
        if (!confirmed)
            if (fraudStatus && !checkService("antifraud", 0, "antifraud")) {
                statuses.push({
                    title: "Telezon Security",
                    elements: {
                        name: "antifraud",
                        status: "relations",
                        price: securityPrice,
                        rmFunction: {target: "Telezon Security", value: true}
                    }})
            }

        if (checkService(activePacket.packet.id, activePacket.packet_size, "voip"))
            return

        const tarif_name = "Тариф "+activePacket.packet.name+" ("+activePacket.packet_size+" минут)."

        if (!confirmed)
            statuses.push({
                title: tarif_name,
                elements: {
                    name: "voip",
                    status: "relations",
                    price: packetsPrice,
                    rmFunction: {target: tarif_name, value: null}
                }})

        if (!confirmed)
            setPopupStatuses([...statuses])

        const voipResultResponse = (isd) => {
            setMainSetDrive(isd)
            resultByRequest("voip", isd > 0)
        }

        if (confirmed)
            addVoip(request)
                .then(r => {
                    r && r.status && r.id_set_drive
                        ? voipResultResponse(r.id_set_drive)
                        : resultByRequest("voip", 0)
                })
                .catch(() => resultByRequest("voip", 0))

    }

    const removeLocalNumber = (number_str, all=false) => {
        // Получаем номера из локального хранилища
        let cnt = 0;
        let saved_numbers = []
        if (localStorage.getItem("selectedNumersCount") &&
            localStorage.getItem("selectedNumersCount") > 0) {
            cnt = localStorage.getItem("selectedNumersCount");
            for (let i = 0; i < cnt; i++) {
                saved_numbers.push(localStorage.getItem("selectedNumer"+i.toString()))
            }
        }

        if (all) {
            // Удаляем все
            localStorage.setItem("selectedNumersCount", '0')
            for (let i = 0; i < cnt; i++) {
                localStorage.removeItem("selectedNumer"+i.toString())
            }

            setActiveNumbers([])
        } else {
            saved_numbers = saved_numbers.filter(number => number !== number_str)
            localStorage.setItem("selectedNumersCount", saved_numbers.length.toString())
            saved_numbers.map((num, index) => localStorage.setItem("selectedNumer"+index, num.toString()))

            setActiveNumbers([...activeNumbers.filter(number => number.NUMER !== number_str)])
        }
    }

    const resultByRequest = (name, val, error) => {
        let n = byRelations
        n.push([name, val > 0])
        setByRelations([...n])

        if (error && error.response && error.response.data) {
            if (error.response.data.includes("no money")) {
                //setPopupStatus(true)
                //setPopupTarget("balance")
                return
            }
        }

        if (error) {
            if (error.includes("no money")) {
                //setPopupStatus(true)
                //setPopupTarget("balance")
                return
            } else {
                //setPopupStatus(true)
                //setPopupTarget("server_error")
                return
            }
        }

        if (val <= 0) {
            //setPopupStatus(true)
            //setPopupTarget("server_error")
            return
        }
    }

    const rmElem = (e) => {
        if (!e.target) return

        let statuses = popupStatuses
        let target_statuses = statuses.filter(elem => elem.title === e.target)[0]
        let no_target_statuses = statuses.filter(elem => elem.title !== e.target)

        if (e.target === "Телефонные номера" && e.value) {
            removeLocalNumber(e.value.toString())
            target_statuses.elements = target_statuses.elements.filter(elem => elem.name !== e.value)
            return
            //setPopupStatuses([target_statuses, ...no_target_statuses])
        } else if (e.target.includes("Запись телефонных разговоров")) {
            changeCallRecordsStatus(0,false)
        } else if (e.target.includes("Telezon Security")) {
            changeAntifraudStatus(0, false)
        }
        setPopupStatuses([...no_target_statuses])

    }

    const byNumberHndlr = (confirmed = false) => {
        // console.log("byNumberHndlr", byNumberHndlr.length, confirmed)
        if (activeNumbers.length === 0) return

        let numbers  = []
        let statuses = popupStatuses //.filter(elem => elem.title !== "Телефонные номера")

        for (const numer of activeNumbers) {
            numbers.push({
                name: numer.NUMER,
                status: "relations",
                price: numer.AP + numer.PRICE,
                rmFunction: {target: "Телефонные номера", value: numer.NUMER}
            })
        }

        if (!confirmed) {
            statuses.push({title: "Телефонные номера", elements: numbers})
            setPopupStatuses([...statuses])
        }

        let req = userAccount
        req.numbers = numbers.map(n => n.name)

        const parseResponse = response => {
            if (response.result) {
                for (const elem of response.result) {
                    if (elem.number && elem.isd && elem.isd > 0) {
                        resultByRequest(elem.number.toString(),  true)
                    } else if (elem.number && elem.isd) {
                        resultByRequest(elem.number.toString(),  false, elem.isd)
                    } else if (elem.number) {
                        resultByRequest(elem.number.toString(),  false, elem.isd)
                    }
                }
            }
            //setPopupStatus(true)
            //setPopupTarget("success")
            removeLocalNumber("", true)
        }

        if (confirmed) {
            addNumber(req)
                .then((r) => parseResponse(r))
                .catch((e) => parseResponse(e)) // Сообщение об ошибке
        }

        //setActiveNumbers([...[]])

        //removeLocalNumber("", true)

    }

    const byExecute = () => {
        const hnd = () => {
            setConfirmed(true)
            setPopupTarget("byServices")
            //setShowConfirmation(false)

            byNumberHndlr(true)
            byAtsHndlr(true)
            byVoipHandler(true)

        }

        const noBalanceHnd = () => {
            setActivePopup("noBalance")
            clearStatuses()
        }

        // Пробуем проверсти списание средств
        let request = userAccount
        request.summ = numbersShopPrice
        byUslRequest(request)
            .then(r => r.result
                ? hnd()
                : noBalanceHnd()
            )
            .catch(() => noBalanceHnd())
    }

    const byHandler = () => {
        if (numberPrice <= 0 && phoneNumers.length === 0) {
            setActivePopup("noNumbersSelected")
            return
        }

        const hnd = () => {
            if (activeNumbers.length > 0) byNumberHndlr(false) // Покупаем номер
            byAtsHndlr(false)
            byVoipHandler(false)


            setPopupTarget("confirmation")
        }

        console.log(balance)

        let balanceValue = balance && balance.balance_v ? balance.balance_v : 0
        if (numbersShopPrice <= balanceValue) { // Обработка, если баланса достаточно
            hnd()
        } else if (balance.max_credit && balance.max_credit > 0) {
            setActivePopup("noBalanceWCredit")
        } else { // обработка, если баланса недостаточно
            setActivePopup("noBalance")
        }
    }

    const handleClick = () => {
        if (helpText) isHelperOpen(false)
        if (selectIsOpen) setSelectIsOpen(false)
    }

    const clearStatuses = () => {
        setPopupTarget("")
        setPopupStatuses([])
        setByRelations([])
    }

    useEffect(() => {
        if (checkService("antifraud", 0, "antifraud", services)) {
            setFraudStatus(true)
        } else {
            setFraudStatus(false)
        }
    }, [services])

    useEffect(() => {
        if (crIsActive) setCrStatus(true)
        else setCrStatus(false)

    }, [crIsActive])

    const changeCallRecordsStatus = (val) => {
        localStorage.setItem("callRecordsStatus", val ? "1" : "0")

        if (val) {
            let p = prices.callRecords;
            p = p.filter(elem => rangeBar >= elem["from"] && rangeBar <= elem["to"])
            if (p.length > 0) {
                setCrPrice(p[0]["price"])
            }
            setCrStatus(val)
        } else if (crIsActive) {
            setPopupTarget("killServiceCr")
            //setPopupStatus(true)
        } else {
            setCrPrice(0)
            setCrStatus(val)
        }
    }

    const changeAntifraudStatus = (val) => {
        localStorage.setItem("fraudStatus", val ? "1" : "0")

        if (val) {
            let p = prices.antifraud;

            p = p.filter(elem => rangeBar >= elem["from"] && rangeBar <= elem["to"])
            if (p.length > 0) {
                setSecurityPrice(p[0]["price"])
                setFraudStatus(val)
            }
        } else if (checkService("antifraud", 0, "antifraud", services) && !val) {
            setPopupTarget("killServiceAF")
        } else {
            setSecurityPrice(0)
            setFraudStatus(val)
        }
    }

    useEffect(() => {
        if (numbers.length > 0 || numbersPoolLoaded) return

        loadNumbersPool()

        setNumbersPoolLoaded(true)
    }, [numbers])

    useEffect(() => {
        if (numbersShopPrice > 0)
            setTitle([
                    "Подключение услуг",
                    "(Итого: ", +numbersShopPrice.toString()+" руб.)"
                ].join(" ")
            )
        else {
            setTitle("Подключение услуг")
        }
    }, [numbersShopPrice])

    const killService = (service_type) => {
        const isu = get_id_set_usl(service_type)
        if (isu.length === 0) return

        suspendService({
            login: userAccount.login,
            token: userAccount.token,
            id_set_usl: isu[0]
        }).then((e) => {window.location.reload(); console.log(e)})
            .catch((e) => console.log(e))

        //setPopupTarget("")
    }

    // useEffect(() => {console.log(activePacket)}, [activePacket])

    return (
        <div>
            <div className={`${s.width} ${selectIsOpen ? s.active : ''} ${helpText ? s.active : ''}`}
                 onClick={handleClick}
            />

            <DefaultTitle computeResult={numbersShopPrice} title={title} />

            <Numbers
                numberPrice={numberPrice}
                setActiveNumbers={setActiveNumbers}
                activeNumbers={activeNumbers}
                removeLocalNumber={removeLocalNumber}
            />

            <div className={s.virtual}>

                <VirtualATS
                    rangeBar={rangeBar}
                    setRangeBar={setRangeBar}

                    securityPrice={securityPrice}
                    setSecurityPrice={setSecurityPrice}
                    vatsPrice={vatsPrice}
                    setVatsPrice={setVatsPrice}
                    crPrice={crPrice}
                    setCrPrice={setCrPrice}

                    crStatus={crStatus}
                    setCrStatus={setCrStatus}
                    fraudStatus={fraudStatus}
                    setFraudStatus={setFraudStatus}
                    installingStatus={installingStatus}
                    setInstallingStatus={setInstallingStatus}
                    changeCallRecordsStatus={changeCallRecordsStatus}
                    changeAntifraudStatus={changeAntifraudStatus}
                />
            </div>
            <div>
                <div className={s.header}>
                    <DefaultTitle title="Покупка пакетов исходящей связи" />
                    <p>
                        <a href="https://lp.telezon.ru/mezhgorod">Подробно о безграничной телефонии</a>
                    </p>
                </div>
                <OutgoingCall
                    minutesPrice={minutesPrice}
                    setMinutesPrice={setMinutesPrice}
                    setActivePacket={setActivePacket}
                    activePacket={activePacket}
                    setPacketPrice={setPacketPrice}
                    packetPrice={packetsPrice}
                    activeMinutes={activeMinutes}
                    setActiveMinutes={setActiveMinutes}
                />
            </div>
            <div>
                <div className={s.footer}>
                    <p className={s.result}>
                        Итого: {numbersShopPrice}
                        <span className="ruble"> ₽</span>
                    </p>
                    <span className={s.button} onClick={byHandler}>Купить</span>
                </div>
            </div>

            <ConfirmationPopup data={popupStatuses} label={"Подтверждение заказа"}
                               rmFunc={rmElem}
                               status={popupTarget === "confirmation"} hide={clearStatuses} success={byExecute}/>
            <StatusPopup data={popupStatuses} label={"Статус заказа"}
                         relations={byRelations}
                         message={
                             popupStatus && popupTarget === "balance"
                                 ? "Не удалось приобрести услуги из-за нехватки средств на счёте «Межгород»"
                                 :
                                 popupStatus && popupTarget === "server_error"
                                     ? "Возникла ошибка на сервере"
                                     :
                                     popupStatus && popupTarget === "success"
                                         ? null
                                         :
                                         popupStatus && popupTarget
                                             ? popupTarget
                                             : null
                         }
                // status={true} hide={reloadPage}/>
                         status={popupTarget === "byServices"} hide={() => window.location.reload()}/>


            <ModalMessage message={
                popupTarget === "killServiceCr"
                    ?"Вы действительно хотите отключить услугу Записи телефонных разговоров?"
                    :"Вы действительно хотите отключить услугу Telezon Security?"
            }
                          label={"Внимание"}
                          show={popupTarget === "killServiceCr" || popupTarget === "killServiceAF"}
                          hide={() => {setPopupStatus(false); setActivePopup("");
                              popupTarget === "killServiceCr" ? killService("cr") : killService("AF")
                          }}>
                <div>
                    <div className="white-button margin-center w20"
                         style={{marginTop: "10px"}}
                         onClick={() => {setPopupStatus(false); setActivePopup("")}}>
                        Отмена
                    </div>
                </div>
            </ModalMessage>
        </div>
    )
}

export default MainBlock
