import React, {useEffect, useState} from "react";
import DefaultTitle from "../../../../ui/DefaultTitle/DefaultTitle";
import s from "../CRMIntegrationsPage.module.scss"
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {WebSitesButton} from "../../../../ui/Button/WebSitesButton";
import {useTheme} from "../../../../hooks/useTheme";
import {saveCrmIntegrations} from "../api";
import {s2Schema} from "../schemas";
import {CheckboxLine, ExtInputLine, LabelLine} from "../FormElements";


export const S2Integration = ({integration, reload, bubbled=true}) => {
    const [integrationData, setIntegrationData] = useState({})
    const {userAccount, setActivePopupGlobal, vatsDomain} = useTheme()

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(s2Schema),
        mode: 'onBlur',
        defaultValues: {
            domain: vatsDomain?.domain,
            integration: {
                event_domain: "",
                default_phone: "",
                smart_forwarding: false,
                instance_uuid: "",
                api_key: "",
            }
        }})

    useEffect(() => {
        if (!integration?.integration) return
        setIntegrationData(integration)
    }, [integration])

    useEffect(() => {
        // if (integrationData?.integration?.type !== "s2") return
        let new_data = integrationData
        if (integrationData?.integration?.instance_uuid) {
            new_data.integration.call_url = "https://api.telezon.ru/imanager/s2/event/" + integrationData?.integration?.instance_uuid
        }

        reset({...new_data})
        // if (integrationData?.integration?.type === "s2") {
        //     setIntegrationIsEnabled(integrationData?.integration?.active)
        // } else {
        //     setIntegrationIsEnabled(false)
        // }
    }, [integrationData])

    const saveIntegration = (data) => {
        const formatedData = {
            domain: data.domain,
            integration: {
                type : "s2",
                event_domain: data?.integration?.event_domain || "",
                default_phone: data?.integration?.default_phone || 0,
                api_key: data?.integration?.api_key || "",
                smart_forwarding: data?.integration?.smart_forwarding || false,
            }
        }

        saveCrmIntegrations({auth: userAccount, ...vatsDomain, data: formatedData})
            .then(() => {setActivePopupGlobal("successSave"); reload()})
            .catch((r) => {console.log(r); setActivePopupGlobal("failSave")})
    }

    const submitForm = (formData) => {
        // if (formData?.integration?.type !== "s2") {
        //     deleteCrmIntegrations({auth: userAccount, ...vatsDomain})
        //         .then(() => saveIntegration(formData))
        //         .catch((r) => {console.log(r.response.data); setActivePopupGlobal("failSave")})
        // } else {
        //     saveIntegration(formData)
        // }
        saveIntegration(formData)

    }

    useEffect(() => {
        if (Object.keys(errors).length === 0) return

        console.log(errors)
    }, [errors])

    return(
        <div className={`${bubbled ? s.bubble : s.noBlubble} margin-b-24`}
             style={{width: "70%"}}
        >
            <div className={`${s.fixedBubble} `}>
                <div className={s.title}>
                    <DefaultTitle title="Интеграция с S2" justify={true} />
                </div>

                <form onSubmit={handleSubmit(submitForm)} className={s.formFlex}>
                    <ExtInputLine errors={errors} register={register} name={"integration.event_domain"}
                               title={"Домен для уведомлений"}/>
                    <ExtInputLine errors={errors} register={register} name={"integration.default_phone"}
                               dataType={"numbers"} title={"Номер по умолчанию"}/>
                    <ExtInputLine errors={errors} register={register} name={"integration.instance_uuid"}
                               hidden={true}/>
                    <ExtInputLine errors={errors} register={register} name={"integration.type"}
                               hidden={true} default_value={"s2"}/>
                    <ExtInputLine errors={errors} register={register} name={"integration.active"}
                               hidden={true} default_value={"false"}/>

                    <ExtInputLine errors={errors} register={register} name={"integration.api_key"}
                               title={"Ключ API"}/>
                    <CheckboxLine errors={errors} register={register}
                                  name={"integration.smart_forwarding"} title={"Умная переадресация"} control={control}/>
                    <LabelLine errors={errors} register={register} title={"URL заказа звонка"}
                               name={"integration.call_url"}/>
                    <WebSitesButton text={
                        integration?.integration?.type === "s2"
                            ? "Сохранить настройки"
                            : "Применить интеграцию"
                    }/>
                </form>
            </div>
        </div>
    )
}
