import React, {useEffect, useRef, useState} from 'react'
import InputMask from 'react-input-mask';
import s from "../LoginPage/LoginMain/LoginMain.module.scss"

import {signUpApi, validateNumerApi} from "../../../api";
import ModalMessage from "../../../ui/Popup/ModalMessage";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import {createBrowserHistory} from "history";
import {Footer} from "../LoginPage/LoginMain/LoginMain";


const history = createBrowserHistory();

const schema = yup
    .object({
        "inn": yup.number().required().min(1000000000).max(9999999999),
        "kpp": yup.number(),
        "ogrn": yup.number(),
        "kc": yup.number(),
        "rc": yup.number(),
        "bik": yup.number(),
        "bank": yup.string(),
        "email": yup.string().email().required(),
        "phone_number": yup.string().required(),
        "addressStr": yup.string(),
        "OrgName": yup.string().required(),
    })
    .required()

const numberValidateSchema = yup
    .object({
        "number": yup.string().required(),
        "code": yup.string(),
    }).required()

const RegisterButton = (props) => {
    return(
        <input type={"submit"}
               className={`${s.buttonSubmit} ${s.inputSubmit} ${props.color}`}
               value={props.text}
               {...props}
        />
    )
}

export const correctNumer = (numer) => {
    if (numer.length === 0) return ""

    // Удаляем лишние символы
    let wrongSymbols = ['+', '(', ')', '-']
    for (const [, symb] of wrongSymbols.entries()) {
        while(numer.includes(symb)) {
            numer = numer.replace(symb, '')
        }
    }

    if (numer.length !== 11) return ""
    return numer
}

export const NumberValidationForm = (props) => {
    const [timerStyle, setTimerStyle] = useState('timer invisible');
    const [timer, setTimer] = useState(0);
    const [popupTarget, setPopupTarget] = useState("");
    const [sendRequest, setSendRequest] = useState(false);

    const phoneRef = useRef()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(numberValidateSchema),
        mode: 'onBlur',
        defaultValues: {
            number: "",
            secretCode: "",
        }
    })



    const sendCall = (e) => {
        let numer = correctNumer(e.number)
        if (numer.length !== 11) return

        const responseHnd = (response) => {
            if (response.response && response.response.status && response.response.status === 429) {
                let time = JSON.parse(response.response.data)["time"];
                setTimer(time);
                setTimerStyle('timer');
            } else if (response.status && response.status === "ok") {
                setPopupTarget("request")
                setSendRequest(true)
            }
        }

        validateNumerApi({phone: numer})
            .then((value) => responseHnd(value))
            .catch(error => console.log(error.response.status))

    }

    const validateCode = (e) => {
        let numer = correctNumer(e.number)
        if (numer.length !== 11) return

        const responseHnd = (response) => {
            if (response && response.status && response.status === "ok") {
                props.setPhone(numer)
            } else {
                setPopupTarget("incorrectCode")
            }
        }

        let request = {
            phone: numer
        }

        if (e.secretCode.length > 0) {
            let code = e.secretCode

            while (code.includes("_")) {
                code = code.replace("_", "")
            }

            if (code.length === 4) request.secretCode = code
        }

        validateNumerApi({...request})
            .then((value) => responseHnd(value))
            .catch(error => console.log(error.response.status))
    }

    return(
        <div >
            <DefaultTitle title={"Введите номер для регистрации"}/>
            <form onSubmit={handleSubmit(sendCall)} className={s.registerForms}>
                <div className={timerStyle}>{timer} сек</div>
                <Controller
                    control={control}
                    name="number"
                    rules={{required: true}}
                    render={({field: {onChange}}) => (
                        <InputMask type="text" name="phone" mask="+7(999)-999-99-99"
                                   placeholder="Номер телефона"
                                   ref={phoneRef}
                                   onChange={(e) => onChange(e.target.value)}
                                   className={
                                       errors.number ? `normal error` : "normal"
                                   }
                        />
                    )} />

                <RegisterButton text={"Запросить код"} color={s.orange}/>
            </form>

            {sendRequest &&
            <form onSubmit={handleSubmit(validateCode)} className={s.registerForms}>
                <Controller
                    control={control}
                    name="secretCode"
                    rules={{required: true}}
                    render={({field: {onChange}}) => (
                        <InputMask type="text" name="secretCode" mask="9999"
                                   placeholder="Последние 4 цифры номера"
                                   ref={phoneRef}
                                   onChange={(e) => onChange(e.target.value)}
                                   className={
                                       errors.number ? `normal error` : "normal"
                                   }
                        />
                    )}/>


                <RegisterButton text={"Проверить код"} color={s.orange}/>
            </form>
            }
            <ModalMessage show={popupTarget === "request"}
                          hide={() => setPopupTarget("")}
                          label={"Запрос отправлен"}
                          message={"Мы вам сейчас позвоним. Необходимо будет ввести последние 4 цифры номера телефона."}/>

            <ModalMessage show={popupTarget === "incorrectCode"}
                          hide={() => setPopupTarget("")}
                          label={"Ошибка"}
                          message={"Неправильный код."}/>
        </div>

    )
}


const FizRegisterForm = (props) => {
    const [popupTarget, setPopupTarget] = useState("")
    const [isProcessed, setProcessed] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            inn: "",
            email: "",
            phone_number: "",
            OrgName: "",
        }
    })

    useEffect(() => {
        reset({"phone_number": props.number})
    }, [props.number])

    const submitFunc = (data) => {
        data.phone_number = correctNumer(data.phone_number)
        if (data.phone_number.length !== 11) return
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) return

        setProcessed(true)
        signUpApi({data: data})
            .then(r => {
                setPopupTarget("success")
                setProcessed(false)
            })
            .catch(r => {
                setPopupTarget("error")
                setProcessed(false)
            })
    }

    return(
        <div>
            <form onSubmit={handleSubmit(submitFunc)}>
                <DefaultTitle title={"Введите ваши данные для регистрации"}/>

                <div className={s.formBlock}>
                    <p className={s.blockTitle}>ФИО</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.OrgName
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        {...register('OrgName')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Email адресс</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.email
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        {...register('email')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>Номер телефона</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.phone_number
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        readOnly={true}
                        {...register('phone_number')}
                    />
                </div>
                <div className={s.formBlock}>
                    <p className={s.blockTitle}>ИНН</p>
                    <input
                        type="text"
                        // placeholder={theme.contacts ? theme.contacts["name"] : ""}
                        // defaultValue={theme.contacts ? theme.contacts["name"] : ""}
                        className={
                            errors.inn
                                ? `${s.defaultInput} ${s.error}`
                                : s.defaultInput
                        }
                        {...register('inn')}
                    />
                </div>

                {isProcessed
                    ? <RegisterButton text={"Регистрирую, ожидайте..."} onClick={(e) => e.preventDefault()} color={s.orange}/>
                    : <RegisterButton text={"Регистрация"} color={s.orange}/>

                }

            </form>

            <ModalMessage show={popupTarget === "error"}
                          hide={() => setPopupTarget("")}
                          label={"Ошибка регистрации"}
                          message={"Проверьте правильность введённых данных и повторите попытку"}/>
            <ModalMessage show={popupTarget === "success"}
                          hide={() => {setPopupTarget(""); history.push("/login"); history.go(0)}}
                          label={"Успешно!"}
                          message={"Регистрация прошла успешно. " +
                          "Данные для входа были отправлены на указанный адрес электронной почты"}/>
        </div>
    )
}

const RegisterPageMain = () => {
    const [phone, setPhone] = useState('');

    return(
        <div className={s.loginPage}>
            <div className={`${s.mainBlock} ${s.mainBlockRegister}`}>
                <div className={s.logo}/>

                {phone.length === 0 &&
                <NumberValidationForm setPhone={setPhone}/>
                }

                {phone.length === 11 &&
                <FizRegisterForm number={phone} />
                }

                <RegisterButton text={"Назад"} onClick={() => {
                    history.push("/login");
                    history.go(0);
                }} color={s.green}/>

                <div style={{marginTop: "25px"}}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default RegisterPageMain;
