import React from 'react'
import Slider from 'rc-slider'
import s from './RangeSlider.module.scss'
import { useTheme } from '../../../../../../hooks/useTheme'
import './rcslider.css'
import {marks} from "../../../../../../provider/variables";

const RangeSlider = ({ setRangeBar, rangeBar }) => {
  const { theme } = useTheme()

  return (
    <div className={s.wrapper}>
      <div className={s.slider}>
        {theme === 'dark' ? (
          <Slider
            onChange={(e) => setRangeBar(e)}
            defaultValue={rangeBar}
            max={60}
            min={0}
            step={5}
            marks={marks}
          />
        ) : (
          <Slider
            className="dark-mode"
            onChange={(e) => setRangeBar(e)}
            defaultValue={rangeBar}
            max={60}
            min={0}
            step={5}
            marks={marks}
          />
        )}
        <p>Количество сотрудников</p>
      </div>
    </div>
  )
}

export default RangeSlider
