import React, {useEffect, useState} from "react";
import s from "./TimePicker.module.scss"

export const TimePicker = (props) => {
    const [hours, setHours] = useState(props.valueH ? props.valueH : 0)
    const [minutes, setMinutes] = useState(props.valueM ? props.valueM : 0)

    const [hoursText, setHoursText] = useState("00")
    const [minutesText, setMinutesText] = useState("00")
    const [showInput, setShowInput] = useState(false)

    const addHours = () => {
        if (hours < 23)
            setHours(hours + 1)
        else
            setHours(0)
    }

    const subHours = () => {

        if (hours > 0)
            setHours(hours - 1)
        else
            setHours(23)
    }

    const addMinutes = () => {
        if (minutes < 59)
            setMinutes(minutes + 1)
        else
            setMinutes(0)
    }

    const subMinutes = () => {
        if (minutes > 0)
            setMinutes(minutes - 1)
        else
            setMinutes(59)
    }

    useEffect(() => {
        let val = "00:00"
        if (hours.toString().length === 1) {
            val = ["0", hours.toString()].join("")
        } else {
            val = hours.toString()
        }

        setHoursText(val)
    },[hours])

    useEffect(() => {
        let val = "00:00"
        if (minutes.toString().length === 1) {
            val = ["0", minutes.toString()].join("")
        } else {
            val = minutes.toString()
        }

        setMinutesText(val)
    },[minutes])

    useEffect(() => {
        props.setValue([hoursText, minutesText].join(":"))

    }, [hoursText, minutesText])

    const parseDateTimeInput = (e) => {
        if (e.key !== "Enter") return

        const val = e.target.value

        if (!val.includes(":")) return

        let h = val.split(":")[0]
        let m = val.split(":")[1]
        setHours(parseInt(h) > 59 ? 0 : parseInt(h))
        setMinutes(parseInt(m) > 59 ? 0 : parseInt(m))
        setShowInput("")

    }

    return(
        <div onClick={() => setShowInput(false)}>
            <div className={`${s.picker} ${props.style}`}
                 onClick={(e) => e.stopPropagation()}>
                {showInput &&
                <input type={"text"} className={s.inputText}
                       defaultValue={[hoursText, minutesText].join(":")}
                       onKeyPress={parseDateTimeInput}
                    // onMouseLeave={() => setShowInput("")}
                />
                }

                <div>
                    <div className={s.udButton} onClick={addHours}>
                        <span>^</span>
                    </div>
                    <div className={s.valueItem}
                         onClick={() => setShowInput(true)}>{hoursText}</div>
                    <div className={`${s.udButton} ${s.rotate}`} onClick={subHours}>
                        <span>^</span>
                    </div>
                </div>

                <span>:</span>

                <div>
                    <div className={s.udButton} onClick={addMinutes}>
                        <span>^</span>
                    </div>
                    <div className={s.valueItem}
                         onClick={() => setShowInput(true)}>{minutesText}</div>
                    <div className={`${s.udButton} ${s.rotate}`} onClick={subMinutes}>
                        <span>^</span>
                    </div>
                </div>
            </div>

        </div>

    )
}