import React from 'react'
import s from './Finances.module.scss'
import {useTheme} from "../../../../../hooks/useTheme";
import {getDashedDate} from "../../../../../provider/dates";

const Finances = ({ balance, packet }) => {
    const {currentVoipPacket} = useTheme()

    return (
        <div className={s.finances}>
            <p className={s.title}>Финансы</p>
            <p className={s.text}>
                Баланс для звонков вне пакета:{' '}
                <span>
                    {balance.balance_v.toFixed(2)} <b className={s.ruble}>₽</b>
                </span>
            </p>
            <p className={s.text}>
                Действует с: <span>{getDashedDate(packet?.package_start, true)}</span>
            </p>
            <p className={s.text}>
                Активен до: <span>{getDashedDate(packet?.package_end, true)}</span>
            </p>
        </div>
    )
}

export default Finances
