import React, {useEffect, useState} from "react";
import s from "./MasterHelpButton.module.scss"
import {ContactSupport, Label} from "@mui/icons-material";
import {sleep} from "../../sortFunction";
import {VideoPlayer} from "../VideoPlayer/VideoPlayer";
import {helpMasterVideoLinks, breadCrumbSectionsHelper} from "./variables";
import {BreadCrumb} from "../TremasovaDesign/BreadCrumb/BreadCrumb";


export const MasterHelpButton = ({clickEvent}) => {
    return(
        <div className={`${s.masterHelpButton}`} onClick={() => clickEvent()}>
            <ContactSupport fontSize="large" color="action"/>
        </div>
    )
}

const MainMenu = ({setSection}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Выберите категорию</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("interfaceVideo")}>
                <span>Обзор интерфейса</span>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("paymentsVideo")}>
                <span>Пополнение счёта</span>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("services")}>
                <span>Помощь по услугам</span>
            </div>
        </div>
    )
}

const ServicesMenu = ({setSection, breadCrumbElements}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>

            <div className={s.menuTitle}>
                <div>Помощь по услугам</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            {breadCrumbElements?.length > 0 &&
            <BreadCrumb elements={breadCrumbElements} goTo={(page) => changeSection(page)}/>
            }

            <div className={s.textMenu} onClick={() => changeSection("cityNumbers")}>
                <p>Городские номера и 8800</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vats")}>
                <p>Виртуальная АТС</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("outgoingVideo")}>
                <p>Пакеты исходящей связи</p>
            </div>

            {/*<div className={`${s.textMenu} ${s.menuItemButton}`} onClick={() => changeSection("main")}>*/}
            {/*    <p>Назад</p>*/}
            {/*</div>*/}
        </div>
    )
}

const VatsMenuOne = ({setSection, breadCrumbElements}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Виртуальная АТС</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            {breadCrumbElements?.length > 0 &&
            <BreadCrumb elements={breadCrumbElements} goTo={(page) => changeSection(page)}/>
            }

            <div className={s.textMenu} onClick={() => changeSection("vatsInstall")}>
                <p>Настроить ВАТС по шагам самостоятельно</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsAddNumbersVideo")}>
                <p>Добавление внутренних номеров сотрудников</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsCallRecordsVideo")}>
                <p>Запись разговоров</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsVamZvonilyVideo")}>
                <p>Сервис Вам звонили</p>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsCrmVideo")}>
                <p>Интеграция с CRM</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsIvrScenariosVideo")}>
                <p>Разработка сценариев голосового меню</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsVoiceVideo")}>
                <p>Профессиональная озвучка голосового меню</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsInstallIndividualVideo")}>
                <p>Настройка ВАТС по индивидуальному заданию</p>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsBLackListVideo")}>
                <p>Настройка черного списка</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsTTSGenerateVideo")}>
                <p>Генерация аудиофайлов с помощью TTS</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsCallCenterVideo")}>
                <p>Настройка коллцентра</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsConferenceCallVideo")}>
                <p>Настройка конференц связи</p>
            </div>

            {/*<div className={s.flexRowButtons}>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("services")}>*/}
            {/*        <p>Назад</p>*/}
            {/*    </div>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("main")}>*/}
            {/*        <p>Главное меню</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}


const CityNumbersAnd8800 = ({setSection, breadCrumbElements}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Городские номера и 8800</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            {breadCrumbElements &&
            <BreadCrumb elements={breadCrumbElements} goTo={(page) => changeSection(page)}/>
            }

            <div className={s.textMenu} onClick={() => changeSection("showExtensionsVideo")}>
                <p>Как посмотреть подключенные номера</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("addExtensionsVideo")}>
                <p>Как подключить новые номера</p>
            </div>
                <div className={s.textMenu} onClick={() => changeSection("removeExtensionsVideo")}>
                <p>Как отключить номера</p>
            </div>

            {/*<div className={s.flexRowButtons}>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("services")}>*/}
            {/*        <p>Назад</p>*/}
            {/*    </div>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("main")}>*/}
            {/*        <p>Главное меню</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

const VatsInstallOne = ({setSection, breadCrumbElements}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Настроить ВАТС по шагам самостоятельно</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>

            </div>

            {breadCrumbElements &&
            <BreadCrumb elements={breadCrumbElements} goTo={(page) => changeSection(page)}/>
            }



            <div className={s.textMenu} onClick={() => changeSection("vatsExtensionsConfVideo")}>
                <p>Шаг 1. Настройте внутренние номера сотрудников</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsAddExtensionsToGroupsVideo")}>
                <p>Шаг 2. Объедините внутренние номера сотрудников в группы</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsAddAudioVideo")}>
                <p>Шаг 3. Подготовьте и загрузите аудиофайлы</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsIVRConfVideo")}>
                <p>Шаг 4. Настройте голосовое меню</p>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsRasprVizovConfVideo")}>
                <p>Шаг 5. Настройте распределение вызовов по времени</p>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsRoutingConfVideo")}>
                <p>Шаг 6. Настройте маршрутизацию городских номеров или номеров 8800</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsVoipConf")}>
                <p>Шаг 7. Настройте VOIP устройства</p>
            </div>

            {/*<div className={s.flexRowButtons}>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("vats")}>*/}
            {/*        <p>Назад</p>*/}
            {/*    </div>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("main")}>*/}
            {/*        <p>Главное меню</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

const VatsInstallTwo = ({setSection}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Настроить ВАТС по шагам самостоятельно</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsRasprVizovConfVideo")}>
                <p>Настройка распределения вызовов по времени</p>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsRoutingConfVideo")}>
                <p>Настройка маршрутизации городских номеров или номеров 8800</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsVoipConf")}>
                <p>Настройка VOIP устройств</p>
            </div>

            <div className={s.flexRowButtons}>
                <div className={s.menuItem} onClick={() => changeSection("vatsInstall")}>
                    <p>Назад</p>
                </div>
                <div className={s.menuItem} onClick={() => changeSection("main")}>
                    <p>Главное меню</p>
                </div>
            </div>
        </div>
    )
}

const VatsVoipConf = ({setSection, breadCrumbElements}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Настройка VOIP устройств</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            {breadCrumbElements &&
            <BreadCrumb elements={breadCrumbElements} goTo={(page) => changeSection(page)}/>
            }

            <div className={s.textMenu} onClick={() => changeSection("vatsSFWindowsVideo")}>
                <p>Настройка софтфона для Windows</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsSFMacVideo")}>
                <p>Настройка софтфона для MacOS</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsSFAndroidVideo")}>
                <p>Настройка софтфона для Android</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsSFIOSVideo")}>
                <p>Настройка софтфона для iOS</p>
            </div>


            {/*<div className={s.flexRowButtons}>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("vatsInstall")}>*/}
            {/*        <p>Назад</p>*/}
            {/*    </div>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("main")}>*/}
            {/*        <p>Главное меню</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

const VatsMenuTwo = ({setSection}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Виртуальная АТС</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsCrmVideo")}>
                <p>Интеграция с CRM</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsIvrScenariosVideo")}>
                <p>Разработка сценариев голосового меню</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsVoiceVideo")}>
                <p>Профессиональная озвучка голосового меню</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsInstallIndividualVideo")}>
                <p>Настройка ВАТС по индивидуальному заданию</p>
            </div>

            <div className={s.menuItem} onClick={() => changeSection("vatsThree")}>
                <p>Ещё</p>
            </div>

            <div className={s.flexRowButtons}>
                <div className={s.menuItem} onClick={() => changeSection("vats")}>
                    <p>Назад</p>
                </div>
                <div className={s.menuItem} onClick={() => changeSection("main")}>
                    <p>Главное меню</p>
                </div>
            </div>
        </div>
    )
}

const VatsMenuThree = ({setSection}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return(
        <div className={`${s.mainMenu} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
             onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>Виртуальная АТС</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            <div className={s.textMenu} onClick={() => changeSection("vatsBLackListVideo")}>
                <p>Настройка черного списка</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsTTSGenerateVideo")}>
                <p>Генерация аудиофайлов с помощью TTS</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsCallCenterVideo")}>
                <p>Настройка коллцентра</p>
            </div>
            <div className={s.textMenu} onClick={() => changeSection("vatsConferenceCallVideo")}>
                <p>Настройка конференц связи</p>
            </div>

            <div className={s.flexRowButtons}>
                <div className={s.menuItem} onClick={() => changeSection("vatsTwo")}>
                    <p>Назад</p>
                </div>
                <div className={s.menuItem} onClick={() => changeSection("main")}>
                    <p>Главное меню</p>
                </div>
            </div>
        </div>
    )
}

const VideoSection = ({setSection, section, breadCrumbElements}) => {
    const [animation, setAnimation] = useState(false)

    const changeSection = (p) => {
        setAnimation(true)
        sleep(1000)
            .then(() => setSection(p))
    }

    return (
        <div className={`${s.mainMenu} ${s.videoBlock} ${!animation ? s.incomeAnimation : s.outcomeAnimation}`}
            onClick={(e) => e.stopPropagation()}>
            <div className={s.menuTitle}>
                <div>{helpMasterVideoLinks?.[section]?.title}</div>
                <div className={s.closeButton} onClick={() => changeSection("hide")}>×</div>
            </div>

            {breadCrumbElements &&
            <BreadCrumb elements={breadCrumbElements} goTo={(page) => changeSection(page)}/>
            }

            <div style={{marginTop: "10px"}}>
                <VideoPlayer uri={helpMasterVideoLinks?.[section]?.url}/>
            </div>


            {/*<div className={s.flexRowButtons}>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("back")}>*/}
            {/*        <p>Назад</p>*/}
            {/*    </div>*/}
            {/*    <div className={s.menuItem} onClick={() => changeSection("main")}>*/}
            {/*        <p>Главное меню</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export const MasterHelp = ({show, hide}) => {
    const allowSections = [
        "main", "services", "vats", "vatsTwo", "cityNumbers",
        "vatsInstall", "vatsInstallTwo", "vatsVoipConf", "vatsThree"
    ]

    const [section, setSection] = useState("main")
    const [sectionHistory, setSectionHistory] = useState([])
    const [hideAnimation, setHideAnimation] = useState(false)
    const [breadCrumbElements, setBreadCrumbElements] = useState([])

    const changeSection = (new_section) => {
        if (sectionHistory[sectionHistory.length - 1] !== section
            && new_section !== "back"
            && new_section.includes("Video"))

            setSectionHistory([...sectionHistory, section])
        setSection(new_section)
    }

    useEffect(() => {
        console.log(sectionHistory)
    }, [sectionHistory])

    useEffect(() => {
        if (section === "hide") {
            hideAction()
            return;
        }
        if (section === "back") {
            if (sectionHistory.length > 1) setSection(sectionHistory[sectionHistory.length - 1])
            else setSection("main")
            return
        }

        if (sectionHistory.length > 0
            && (!allowSections.includes(section)
            && !helpMasterVideoLinks?.[section])) {

            setSection(sectionHistory[sectionHistory.length - 1])
        } else if (!allowSections.includes(section) && !sectionHistory.length > 0) {
            setSection("main")
        }

        if (breadCrumbSectionsHelper[section]) {
            setBreadCrumbElements(breadCrumbSectionsHelper[section])
        } else {
            setBreadCrumbElements([])
        }
    }, [section])

    const hideAction = () => {
        setHideAnimation(true)
        sleep(600)
            .then(() => hide())
    }

    return(
        <div style={{
            position: "fixed",
            zIndex: 100000,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height:"100%",
            backgroundColor: "rgba(99, 99, 100, 0.8)",
            display: show ? "block" : "none",
        }} onClick={hideAction} className={hideAnimation ? s.hideAnimation : null}>
            {section === "main" &&
            <MainMenu setSection={changeSection} />
            }

            {section === "services" &&
            <ServicesMenu setSection={changeSection} breadCrumbElements={breadCrumbElements}/>
            }
            {section === "vats" &&
            <VatsMenuOne setSection={changeSection} breadCrumbElements={breadCrumbElements}/>
            }
            {/*{section === "vatsTwo" &&*/}
            {/*<VatsMenuTwo setSection={changeSection}/>*/}
            {/*}*/}
            {/*{section === "vatsThree" &&*/}
            {/*<VatsMenuThree setSection={changeSection}/>*/}
            {/*}*/}
            {section === "cityNumbers" &&
            <CityNumbersAnd8800 setSection={changeSection} breadCrumbElements={breadCrumbElements}/>
            }
            {section === "vatsInstall" &&
            <VatsInstallOne setSection={changeSection} breadCrumbElements={breadCrumbElements}/>
            }
            {/*{section === "vatsInstallTwo" &&*/}
            {/*<VatsInstallTwo setSection={changeSection}/>*/}
            {/*}*/}

            {section === "vatsVoipConf" &&
            <VatsVoipConf setSection={changeSection} breadCrumbElements={breadCrumbElements}/>
            }

            {section.includes("Video") &&
            <VideoSection setSection={changeSection} section={section} breadCrumbElements={breadCrumbElements}/>
            }
        </div>
    )
}
