import React from 'react'
import s from './Cities.module.scss'

const ActiveCities = ({ activeCities, handleClick }) => {
  return (
    <>
      {activeCities.map((city) => (
        <p key={city.id} onClick={() => handleClick(city.id)}>
          <span className={s.minus} onClick={() => handleClick(city.id)}>
            -
          </span>
          {city.name}
        </p>
      ))}
    </>
  )
}

export default ActiveCities
