import s from "./CRMIntegrationsPage.module.scss";
import Sidebar from "../../../ui/Sidebar/Sidebar";
import React, {useEffect, useState} from "react";
import {useTheme} from "../../../hooks/useTheme";
import {BitrixIntegration} from "./BitrixIntegration/BitrixIntegration";
import {getAmoSettings, getCrmIntegrations} from "./api";
import {S2Integration} from "./S2Integration/S2Integration";
import {MegaplanIntegration} from "./MegaplanIntegration/MegaplanIntegration";
import DefaultTitle from "../../../ui/DefaultTitle/DefaultTitle";
import {SelectionButton} from "../../../ui/TremasovaDesign/Buttons/SelectionButton";
import {WebSitesDivButton} from "../../../ui/Button/WebSitesButton";
import {AMOCrmIntegration} from "./AMOCrmIntegration/AMOCrmIntegration";


const IntegrationSelector = ({integrationsList, selectedIntegration, bubbled=true, amoSettings,
                                 setSelectedIntegration, integration}) => {
    const [isSelectNewIntegration, setSelectNewIntegration] = useState(false)

    useEffect(() => {
        if (isSelectNewIntegration) return

        if (integration?.integration?.type?.length > 0) {
            setSelectedIntegration(integration.integration.type)
        }

    }, [isSelectNewIntegration])

    const deleteIntegration = () => {}

    return(
        <div className={`${bubbled ? s.bubble : s.noBlubble} flex-center flex-column margin-b-24`}
             style={{
                 width: "25.5%"
             }}
        >
            <div className={s.title}>
                <DefaultTitle title="Активная интеграция" justify={true}/>
            </div>
            {integrationsList.map(
                integr => ((integration?.integration?.type === integr.name) || (integr.name === "amo" && amoSettings?.id)) &&
                    <SelectionButton text={integr.title} key={integr.name}
                                           typpyText={
                                               integration?.integration?.type === integr.name
                                                   ? "Активная интеграция"
                                                   : "Выберите для настройки"
                                           }
                                           inactive={integration?.integration?.type === integr.name}
                                           selected={selectedIntegration === integr.name}
                                           onClick={() => setSelectedIntegration(integr.name)}/>
            )}

            <WebSitesDivButton clickEvent={() => setSelectNewIntegration(!isSelectNewIntegration)}
                               text={"Изменить интеграцию"}/>
            {/*<WebSitesDivButton clickEvent={deleteIntegration}*/}
            {/*                   text={"Отключить интеграцию"} color={"blue"}/>*/}

            {isSelectNewIntegration &&
            <div>
                <DefaultTitle title="Выберите новую интеграцию" justify={true}/>
                {integrationsList.map(
                    integr => integration?.integration?.type !== integr.name &&
                        <SelectionButton text={integr.title}
                                         typpyText={
                                             integration?.integration?.type === integr.name
                                                 ? "Активная интеграция"
                                                 : "Выберите для настройки"
                                         }
                                         inactive={integration?.integration?.type === integr.name}
                                         selected={selectedIntegration === integr.name}
                                         onClick={() => setSelectedIntegration(integr.name)}/>
                )}
            </div>
            }

            <div className={s.remark}>
                Одновременно может быть подключена только одна интеграция CRM.
                <br/><br/>
                Для выбора интеграции необходимо внести настройки и нажать "Применить интеграцию".
            </div>
        </div>
    )
}


const NoIntegrationSelector = ({integrationsList, selectedIntegration, bubbled=true,
                                 setSelectedIntegration, integration}) => {

    return(
        <div className={`${bubbled ? s.bubble : s.noBlubble} flex-center flex-column margin-b-24`}
             style={{
                 width: "25.5%"
             }}
        >
            <div className={s.title}>
                <DefaultTitle title="Выберите интеграцию:" justify={true}/>
            </div>

            {integrationsList.map(
                integr =>
                    <SelectionButton text={integr.title} key={integr.name}
                                     typpyText={
                                         integration?.integration?.type === integr.name
                                             ? "Активная интеграция"
                                             : "Выберите для настройки"
                                     }
                                     inactive={integration?.integration?.type === integr.name}
                                     selected={selectedIntegration === integr.name}
                                     onClick={() => setSelectedIntegration(integr.name)}/>
            )}

            <div className={s.remark}>
                Одновременно может быть подключена только одна интеграция CRM.
                <br/><br/>
                    Для выбора интеграции необходимо внести настройки и нажать "Применить интеграцию".
            </div>
        </div>
    )
}


const MinimalDesign = ({integration, reload}) => {
    let integrations = [
        {
            name: "bitrix",
            title: "Интеграция Bitrix24",
            elem: <BitrixIntegration integration={integration} key={"bitrix"} reload={reload}/>
        },
        {
            name: "s2",
            title: "Интеграция S2",
            elem: <S2Integration integration={integration} key={"s2"} reload={reload}/>
        },
        {
            name: "mega",
            title: "Интеграция Megaplan",
            elem: <MegaplanIntegration integration={integration} key={"megaplan"} reload={reload}/>
        },
        {
            name: "amo",
            title: "Интеграция amoCRM",
            elem: <AMOCrmIntegration integration={integration} key={"amo"} reload={reload}/>
        },

    ]

    const [selectedIntegration, setSelectedIntegration] = useState("bitrix")

    useEffect(() => {
        if (integration?.integration?.type?.length > 0 && !integration?.integration?.is_active) {
            setSelectedIntegration(integration.integration.type)
        } else if (integration?.integration?.is_active) {
            setSelectedIntegration("amo")
        }
    }, [integration])

    return(
        <div>
            <div className={s.flex}>
                {/*{console.log(integration?.integration?.type, amoSettings?.is_active)}*/}
                {(integration?.integration?.type?.length > 0 || integration?.integration?.is_active) &&
                <IntegrationSelector integrationsList={integrations}
                                     integration={integration}
                                     amoSettings={integration?.integration}
                                     selectedIntegration={selectedIntegration}
                                     setSelectedIntegration={setSelectedIntegration}
                />
                }

                {(!integration?.integration?.type?.length > 0 && !integration?.integration?.is_active) &&
                <NoIntegrationSelector integrationsList={integrations}
                                     integration={integration}
                                     selectedIntegration={selectedIntegration}
                                     setSelectedIntegration={setSelectedIntegration}
                />
                }

                {selectedIntegration?.length > 0 && integrations.map(
                    integr => integr.name === selectedIntegration && integr.elem
                )}
            </div>
        </div>
    )
}

const BigDesign = ({integration, reload}) => {
    let integrations = [
        {
            name: "bitrix",
            title: "Интеграция Bitrix24",
            elem: <BitrixIntegration integration={integration} key={"bitrix"} reload={reload}/>
        },
        {
            name: "s2",
            title: "Интеграция S2",
            elem: <S2Integration integration={integration} key={"s2"} reload={reload}/>
        },
        {
            name: "mega",
            title: "Интеграция Megaplan",
            elem: <MegaplanIntegration integration={integration} key={"megaplan"} reload={reload}/>
        },
        {
            name: "amo",
            title: "Интеграция amoCRM",
            elem: <AMOCrmIntegration integration={integration?.integration} key={"amo"} reload={reload}/>
        },

    ]

    const [selectedIntegration, setSelectedIntegration] = useState("bitrix")

    useEffect(() => {
        if (integration?.integration?.type?.length > 0 && !integration?.integration?.is_active) {
            setSelectedIntegration(integration.integration.type)
        } else if (integration?.integration?.is_active) {
            setSelectedIntegration("amo")
        }
    }, [integration])

    return(
        <div className={s.flex} style={{marginLeft: "24px"}}>
            <IntegrationSelector integrationsList={integrations}
                                 integration={integration}
                                 selectedIntegration={selectedIntegration}
                                 setSelectedIntegration={setSelectedIntegration}
                                 bubbled={false}
            />

            {selectedIntegration?.length > 0 && integrations.map(
                integr => integr.name === selectedIntegration && integr.elem
            )}
        </div>
    )
}

export const CRMIntegrationsPage = () => {
    const [activeBurger, setActiveBurger] = useState(false)
    const {designType, userAccount, vatsDomain, vatsNames} = useTheme()

    const [activeIntegration, setActiveIntegration] = useState({})

    const handleMenu = () => {
        setActiveBurger(!activeBurger)
    }

    const parseIntegrations = (data) => {
        if (!data?.integration) return
        setActiveIntegration(data)
    }

    const loadIntegration = () => {
        if (!userAccount?.login) return
        getCrmIntegrations({auth: userAccount, ...vatsDomain})
            .then((response) => parseIntegrations(response))
            .catch((response) => console.log(response))
    }

    useEffect(() => {
        if (!userAccount?.login) return
        if (!vatsDomain.domain) return
        loadIntegration()
    }, [userAccount, vatsDomain])

    return(
        <div className={`${s.mainPart} ${designType === "minimal" && s.minimalDesign}`}>
            <Sidebar activeBurger={activeBurger} handleMenu={handleMenu} />
            {designType === "minimal"
                ? <MinimalDesign integration={activeIntegration} reload={loadIntegration} />
                : <BigDesign integration={activeIntegration} reload={loadIntegration} />
            }
        </div>
    )
}
