import axios from 'axios'
import {authServer, backendUrl, paymentServer, thisUrl} from "./provider/variables";


export const userBalance = async (info) => {
    let url = [backendUrl, "8000/user/balanses/"].join(':');

  return await axios
    .post(url, {
      auth: {
        login: info.login,
        name: 'Token',
        token: info.token,
      },
    })
    .then((response) => JSON.parse(response.data))
}

export const userSettings = async (info) => {
    let url = [backendUrl, "8000/settings/all/"].join(':');
    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const getUserManagers = async (info) => {
    let url = [backendUrl, "8000/settings/managers/"].join(':');

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const RelatedAuth = async (info) => {
    let url = [backendUrl, "8000/user/sign-in-relate/"].join(':');

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                pin: info.pin,
                login: info.loginASR,
                password: info.passwordASR,
            }


        })
        .then((response) => JSON.parse(response.data))
}

export const userContacts = async (info) => {
    let url = [backendUrl, "8000/user/contacts/"].join(':');

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const saveContacts = async (info) => {
    let url = [backendUrl, "8000/user/contacts/save/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                'name': info.name,
                'emails': info.emails,
                'phone': info.phone,
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const saveMailingPlans = async (info) => {
    let url = [backendUrl, "8000/mailings/save/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                "mailPlans": info.mailPlans
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const saveSettings = async (info) => {
    let url = [backendUrl, "8000/settings/save/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                //'mail_p': info.mail_p,
                'mail_v': info.mail_v,
                'mail_i': info.mail_i,
                //'mail_s': info.mail_s,
                'mailings_active': info.mailings_active,
                'timezone': info.timezone,
                'antifraud': info.antifraud,
                'default_number': info.default_number,
                'flag': info.flag
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const changePasswd = async (info) => {
    let url = [backendUrl, "8001/auth/pswch/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            password: info.password
        })
        .then((response) => JSON.parse(response.data))
}

export const changeOldPasswd = async (info) => {
    let url = [backendUrl, "8000/user/passwOld/"].join(':');

    return await axios
        .patch(url, {
            login: info["login"],
            oldPassword: info["oldPassword"],

            password: info["password"],
            type: info["type"]
        })
        .then((response) => JSON.parse(response.data))
}



export const servicesAll = async (info) => {
    let url = [backendUrl, "8000/services/all/"].join(':');

  return await axios
    .post(url, {
      auth: {
        login: info.login,
        name: 'Token',
        token: info.token,
      },
    })
    .then((response) => JSON.parse(response.data))
}

export const documentsAll = async (info) => {
    let url = [backendUrl, "8000/documents/all/"].join(':');

  return await axios
      .get(url, {
          headers: {
              Authorization: `Token ${info.login} ${info.token}`,
              'Content-Type': 'application/json',
          },
      })
    .then((response) => response.data)
}

export const servicesAvailable = async (info) => {
    let url = [backendUrl, "8000/services/available/"].join(':');

  return await axios
    .post(url, {
      auth: {
        login: info.login,
        name: 'Token',
        token: info.token,
      },
    })
    .then((response) => JSON.parse(response.data))
}

export const getPacket = async (info) => {
    let url = [backendUrl, "8000/services/packet/"].join(':');

  return await axios
    .post(url, {
      auth: {
        login: info.login,
        name: 'Token',
        token: info.token,
      },
    })
    .then((response) => JSON.parse(response.data))
}

export const getMailings = async (info) => {
    let url = [backendUrl, "8000/mailings/"].join(':');

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const userAuth = async (info) => {
    // let url = [backendUrl, "8000/user/sign-in/"].join(':');
    let url = [authServer, "8001/auth/sign-in/"].join(':');

    return await axios
        .post(url, {
            login: info["login"],
            password: info["password"],
            domen: '',
        })
        .then((response) => response.data)
        .then((response) => JSON.parse(response))
}

export const ttsGetLink = async (info) => {
    // let url = [backendUrl, "8000/user/sign-in/"].join(':');
    let url = "http://10.248.0.173:8600/v1/yandex/generate/"

    const headers = {
        "headers": {
            'Content-Type': "application/json"
        }

    }

    return await axios
        .post(url, {
            text: "Удобные интерфейсы для решения <[задач]>.",
            voice: "filipp",
            emotion: 'neutral',
            speed: '1.1',
        }, headers)
        .then((response) => response.data)
}


export const innerNumbersAuth = async (info) => {
    // let url = [backendUrl, "8001/auth/fusion/"].join(':');
    let url = [authServer, "8001/auth/fusion/"].join(':');

    return await axios
        .post(url, {
            login: info.login,
            password: info.password,
        })
        .then((response) => response.data)
        .then((response) => JSON.parse(response))
}

export const userUpdateToken = async (info) => {
    // let url = [backendUrl, "8000/user/upd/"].join(':');
    let url = [authServer, "8001/auth/token-update/"].join(':');

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
        })
        .then((response) => response.data)
        .then((response) => JSON.parse(response))
        .catch((err) => ({error: err.response.status}))
}

export const activateCbc = async (info) => {
    let url = [backendUrl, "8000/services/add/cbc/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": {
                "activate": info.status
            }
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}



export const addVats = async (info) => {
    let url = [backendUrl, "8000/services/add/vats/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": {
                "workers": info.workers,
                "target_number": info.target_number,
                "cr_status": info.cr
            }
        })
        .then((response) => JSON.parse(response.data))
}

export const changeDriveParam = async (info) => {
    let url = [backendUrl, "8000/services/add/"].join(':');
    // console.log(info.data)

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": info.data
        })
        .then((response) => JSON.parse(response.data))
}

export const changeParam = async (info) => {
    let url = [backendUrl, "8000/services/add/"].join(':');
    // let url = [backendUrl, "8000/services/add/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                "action": "ch_param",
                "id_set_drive": info["id_set_drive"],
                "id": info["param_id"],
                "param_value": info["new_val"]
            }
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const suspendService = async (info) => {
    let url = [backendUrl, "8000/services/delete/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                "id_set_usl": info.id_set_usl,
            }
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const suspendDrive = async (info) => {
    let url = [backendUrl, "8000/services/delete-drive/"].join(':');
    if (!info.isd) return

    url += info.isd.toString() + "/"

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const addCallRecords = async (info) => {
    let url = [backendUrl, "8000/services/add/cr/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": {
                "id_set_drive": info.idSetDrive
            }
        })
        .then((response) => JSON.parse(response.data))
}

export const addVoip = async (info) => {
    let url = [backendUrl, "8000/services/add/voip/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": {
                "packet": info.packet,
                "packet_size": info.packet_size
            }
        })
        .then((response) => JSON.parse(response.data))
}

export const addAntifraud = async (info) => {
    let url = [backendUrl, "8000/services/add/ts/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": {
                "id_set_drive": info.idSetDrive
            }
        })
        .then((response) => JSON.parse(response.data))
}

export const changeService = async (info) => {
    let url = [backendUrl, "8000/services/change/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": {
                "id_set_drive": info.idSetDrive,
                "service_name": info.serviceName,
            }
        })
        .then((response) => JSON.parse(response.data))
}

export const byUslRequest = async (info) => {
    let url = [backendUrl, "8000/shop/numers/pay/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            "data": {
                "target": "voip",
                "summ": info.summ,
                "services": info.services
            }
        })
        .then((response) => JSON.parse(response.data))
}

export const addService = async (info) => {
    let url = [backendUrl, "8000/services/add/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                "action": "ch_service",
                "id": info["id"],
                "id_set_drive": info["id_set_drive"],
                "id_set_usl": info["id_set_usl"]
            }
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const getCallRecords = async (info) => {
    let url = [backendUrl, "8000/cr/"].join(':');
    url += info["url"]
    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const getCallRecordsLost = async (info) => {
    let url = [backendUrl, "8000/cr/lost/"].join(':');
    url += info["url"]

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const findCallRecordsByNumber = async (info) => {
    let url = `${backendUrl}:8000/cr/find/${info?.urlContext}/`
    // let url = `http://127.0.0.1:8000/cr/find/${info?.urlContext}    `

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const getLostCallsStatistics = async (info) => {
    let url = [backendUrl, "8000/cr/lostcalls/"].join(':');
    url += info["url"]

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const getUcdrStatistics = async (info) => {
    let url = [backendUrl, "8000/cr/statistics/"].join(':');
    url += info["url"]

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
}


export const getDetails = async (info) => {
    // console.log(info)
    if (!info["src"]) return
    let url = [backendUrl, "8000/detail/<pin>/<src>/"].join(':');
    url = url.replace("<pin>", info.login)
    url = url.replace("<src>", info.src)

    let start = ["start", info.start].join("=")
    let end = ["end", info.end].join("=")
    let devices = ["numbers", info.ats.join(",")].join("=")
    let headers = {
        Authorization: `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
        "Pragma": [start, end, devices].join(";")
    }

    return await axios
        .get(url, {headers: headers})
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const getFaqHelps = async (info) => {
    // let url = ["http://127.0.0.1", "8000/faq/all/"].join(':');
    let url = [backendUrl, "8000/faq/all/"].join(':');

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const addFaqHelps = async (info) => {
    // let url = ["http://127.0.0.1", "8000/faq/add/"].join(':');
    let url = [backendUrl, "8000/faq/add/"].join(':');

    return await axios
        .post(url, {
            "label": info.label,
            "pseudo": info.pseudo,
            "page": info.page,
            "text": info.text,
            "base64": info.base64
        },
            {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const deleteFaqHelps = async (info) => {
    if (!info.id) return
    // let url = ["http://127.0.0.1", "8000/faq/delete/"+info.id+"/"].join(':');
    let url = [backendUrl, "8000/faq/delete/"+info.id+"/"].join(':');

    return await axios
        .delete(url,
            {
                headers: {
                    Authorization: `Token ${info.login} ${info.token}`,
                    'Content-Type': 'application/json',
                },
            })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const updateFaqHelps = async (info) => {
    if (!info.id) return
    // let url = ["http://127.0.0.1", "8000/faq/update/"+info.id+"/"].join(':');
    let url = [backendUrl, "8000/faq/update/"+info.id+"/"].join(':');

    return await axios
        .put(url, {
                "label": info.label,
                "pseudo": info.pseudo,
                "page": info.page,
                "text": info.text,
                "base64": info.base64
            },
            {
                headers: {
                    Authorization: `Token ${info.login} ${info.token}`,
                    'Content-Type': 'application/json',
                },
            })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const getFrontWcAuth = async (info) => {
    let url = [backendUrl, "8000/cr/frontwcAuth/"].join(':');

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const getNumbersPool = async (info) => {
    let url = [backendUrl, '8000/shop/numers/pool/'].join(':')

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const getProperties = async (info) => {
    let url = [backendUrl, '8000/services/property/'].join(':')

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const isVirtual = async (info) => {
    let url = [backendUrl, '8000/user/property/'].join(':')

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const newNumbersPool = async (info) => {
    let url = [backendUrl, '8000/shop/numers/update/'].join(':')

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const getFeedbackData = async (info) => {
    // let url = ["https://backend.telezon.ru", '/feedback/mail/get/'].join(':')
    let url = "https://backend.telezon.ru/feedback/mail/get/";
    const headers = {
        'Authorization': `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
    }

    return await axios
        .get(url, {
            headers: headers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const changeFeedbackStatus = async (info) => {
    // let url = ["https://backend.telezon.ru", '8001/feedback/mail/status/change/'].join(':')
    let url = "https://backend.telezon.ru/feedback/mail/status/change/";
    const headers = {
        'Authorization': `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
    }

    return await axios
        .patch(url, {
            id: info.id,
            status: info.status,
        },{
            headers: headers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const callRecordElementsEdit = async (info) => {
    let url = "https://backend.telezon.ru/feedback/"
    // let url = "http://127.0.0.1"
    let requests = {}
    if (info?.comment) {
        // url = [url, 'ucdr/comment/change/'].join(':')
        url = `${url}ucdr/comment/change/`
        requests = {
            comment: info.comment === "none" ? "" : info.comment
        }
    }
    else if (info?.dstReal) {
        // url = [url, '8001/feedback/ucdr/menutarget/change/'].join(':')
        url = `${url}ucdr/menutarget/change/`
        requests = {
            dst_real: info.dstReal
        }
    } else {
        return
    }

    const headers = {
        'Authorization': `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
    }

    return await axios
        .patch(url, {
            id: info.id,
            ...requests
        },{
            headers: headers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}


export const C2CInit = async (info) => {
    // let url = ["https://backend.telezon.ru", '8001/feedback/ucdr/c2c/init/'].join(':')
    let url = "https://backend.telezon.ru/feedback/ucdr/c2c/init/";
    const headers = {
        'Authorization': `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
    }

    return await axios
        .post(url, {
            src_num: info.src_num,
            dst_num: info.dst_num,
        },{
            headers: headers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const changeCallRecordStatus = async (info) => {
    // let url = ["https://backend.telezon.ru", '8001/feedback/ucdr/status/change/'].join(':')
    let url = "https://backend.telezon.ru/feedback/ucdr/status/change/";
    const headers = {
        'Authorization': `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
    }

    return await axios
        .patch(url, {
            id: info.id,
            status: info.status,
        },{
            headers: headers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const changeFeedbackComment = async (info) => {
    // let url = ["https://backend.telezon.ru", '8001/feedback/mail/comment/change/'].join(':')
    let url = "https://backend.telezon.ru/feedback/mail/comment/change/";
    const headers = {
        'Authorization': `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
    }

    return await axios
        .patch(url, {
            id: info.id,
            comment: info.comment,
        },{
            headers: headers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const addNumber = async (info) => {
    // let url = [backendUrl, '8000/shop/numers/by/'].join(':')
    let url = `${backendUrl}:8000/shop/numers/by/`

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            numbers: info.numbers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const getDdosStatistics = async (info) => {
    // let url = [backendUrl, '8000/shop/numers/by/'].join(':')
    let url = `https://backend.telezon.ru:8005/ttk/get/?ipaddr=${info.ip}`

    const headers = {
        'Authorization': `Token ${info.login} ${info.token}`,
        'Content-Type': 'application/json',
    }

    return await axios
        .get(url, {
            headers: headers
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const getFrontWcDomain = async (info) => {
    let url = `${backendUrl}:8000/cr/domain/`;
    if (info.domain) url = `${url}?domain=${info.domain}`
    else url = `${url}?login=${info.login}`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const getEsiaUrl = async (info) => {
    // let scope =  "";//"openid%20fullname%20id_doc%20birthplace"//%20contacts%20gender%20birthplace";

    let url = [backendUrl, "8001/auth_esia/?format=json"].join(':');
    let redirect_link = [thisUrl, "register/esia/"].join('/');
    //let url = ["https://cabinet.telezon.ru", "8001/auth_esia/?format=json"].join(':');
    if (info.scope) {
        // scope = info.scope.join("+")
        url = [url, ["scope", info.scope].join("=")].join("&")
        redirect_link = [thisUrl, "login/"].join('/');
    }

    if (info.redirect.length > 0) {
        redirect_link = [redirect_link, ["referer", info.redirect].join("=")].join("?")
    }
    redirect_link = encodeURIComponent(redirect_link)
    url = [url, ["redirect_uri", redirect_link].join("=")].join("&")
    url = [url, ["pass", info["pass"]].join("=")].join("&")

    return await axios
        .get(url)
        .then((response) => JSON.parse(response.data))
        // .catch((err) => ({status: err.response.status}))
}

export const getSberUrl = async (info) => {
    let redirect_link = [thisUrl, "login"].join('/');
    if (info.redirect.length > 0) {
        redirect_link = [redirect_link, ["referer", info.redirect].join("=")].join("?")
    }

    let url = [backendUrl, "8001/sber_auth/"].join(':');
    url = [url, ["redirect_link", encodeURIComponent(redirect_link)].join("=")].join("?")
    url = [url, ["pass", info["pass"]].join("=")].join("&")

    return await axios
        .get(url)
        .then((response) => JSON.parse(response.data))
        // .catch((err) => ({error: err.response.status}))
}

export const getIvrData = async (info) => {
    let url = [backendUrl, "8000/cr/domain/ivr/"].join(':');

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return JSON.parse(response.data)
        })
}

export const saveIvrData = async (info) => {
    let url = [backendUrl, "8000/cr/domain/ivr/save/"].join(':');

    return await axios
        .patch(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                numbers: info.numbers,
                redirectNumber: info.redirectNumber,
            }
        })
        .then((response) => JSON.parse(response.data))
        .catch(error => error)
}

export const getSavedIvrData = async (info) => {
    let url = [backendUrl, "8000/cr/domain/ivr/saved/"].join(':');

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
        .catch(error => error)
}

export const getSmsSettings = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/user/";

    return await axios
        .get(url, {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                PIN: info.login,
            },
        })
        .then((response) => response.data)
        .catch(error => error.data)
}

export const addSmsTemplate = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/template/";

    return await axios
        .post(url, {
                "name": info.name,
                "text": info.text,
            },
            {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
        .catch(error => error.data)
}

export const changeTemplate = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/template/"+info.id+"/";

    return await axios
        .put(url, {
                "name": info.name,
                "text": info.text,
            },
            {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
        .catch(error => error.data)
}

export const getSmsBalance = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/balance/"

    return await axios
        .get(url, {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                PIN: info.login,
            },
        }, )
        .then((response) => response.data)
        .catch(error => error.data)
}

export const checkSmsStatus = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/sms/?sms_id="+info.id;

    return await axios
        .get(url, {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
        .catch(error => error.data)
}

export const sendSms = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/sms/"

    return await axios
        .post(url, {
                "number": info.number,
                "text": info.text,
            },
            {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
        .catch(error => error.data)
}


export const deleteTemplateSms = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/template/"+info.id+"/";

    return await axios
        .delete(url, {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
        .catch(error => error.data)
}

export const saveSmsSettings = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/user/";

    return await axios
        .put(url, {
                "email": info.email,
                "api_key": info.apiKey,
                "sign": info.sign,
                "is_active": info.isActive
        },
            {
            headers: {
                Authorization: info.token,
                'Content-Type': 'application/json',
                PIN: info.login,
            },
        }, )
        .then((response) => response.data)
        .catch(error => error.data)
}

export const getCredit = async (info) => {
    if (!info.login || !info.token) return

    let url = [backendUrl, "8000/user/setCredit/"].join(':');

    return await axios
        .patch(url, {
                auth: {
                    login: info.login,
                    name: 'Token',
                    token: info.token,
                }})
        .then((response) => JSON.parse(response.data))
        .catch((err) => ({error: err.response.status}))
}

export const validateNumerApi = async (info) => {
    if (!info.phone) return

    let url = ["8000/user/verify", info.phone, ""].join("/")
    url = [backendUrl, url].join(':');

    if (info.secretCode) {
        return await axios
            .patch(url, {"secret-code": info.secretCode})
            .then((response) => JSON.parse(response.data))
            .catch(error => error)
    } else {
        return await axios
            .get(url)
            .then((response) => JSON.parse(response.data))
            .catch(error => error)
    }
}

export const validateNumerApiOld = async (info) => {
    if (!info.phone) return

    let url = [backendUrl, "8000/user/verify/"].join(':');

    let request = {
        phone: info.phone,
    }

    if (info.secretCode && info.secretCode.length > 0) {
        request["secret-code"] = info.secretCode
    }

    return await axios
        .post(url, request)
        .then((response) => JSON.parse(response.data))
        .catch(error => error)
}

export const signUpApi = async (info) => {
    let url = [backendUrl, "8001/auth/sign-up/"].join(':');
    // let url = ["http://10.248.0.208", "7999/auth/sign-up/"].join(':');

    return await axios
        .post(url, {
            ...info.data
        })
        .then((response) => JSON.parse(response.data))
        .catch((response) => response)
}

// export const signFizApi = async (info) => {
//     let url = [backendUrl, "8001/auth_esia/registerFiz"].join(':');
//
//     return await axios
//         .post(url, {
//             ...info.data
//         })
//         .then((response) => JSON.parse(response.data))
//     // .catch(error => error)
// }

export const sendEmail = async (info) => {
    let url = `${backendUrl}:8000/mailings/mail/`
    // let url = [backendUrl, "8000/mailings/mail/"].join(':');

    let request = {
        auth: {},
        data: {
            type: info.page,
            text: info.text
        }
    }

    if (info.login && info.login.length > 0 && info.token && info.token.length > 0) {
        request["auth"]["login"] = info.login
        request["auth"]["token"] = info.token
        request["auth"]["name"] = 'Token'
    }

    if (info.contact && info.contact.length > 0) {
        request["data"]["contact"] = info.email
    }

    if (info.target && info.target.length > 0) {
        request["data"]["target"] = info.target
    }

    return await axios
        .post(url, request)
        .then((response) => JSON.parse(response.data))
        .catch(error => error)
}

export const    getPayUrlApi = async (info) => {
    let url = [paymentServer, "8443/pay/"].join(':');
    console.log("Данные запроса:", {
        service: info.service,
        amount: info.amount,
        payService: info.payService,
        returnUrl: info.returnUrl,
        failUrl: info.failUrl,
        shopAmounts: info.shopAmounts
    });
    try {
        // Логирование перед отправкой запроса
        console.log('Starting request to:', url);
        console.log('Request data:', {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                service: info.service,
                amount: info.amount,
                payService: info.payService,
                returnUrl: info.returnUrl,
                failUrl: info.failUrl,
                shopAmounts: info.shopAmounts
            }
        });

        const response = await axios.post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            data: {
                service: info.service,
                amount: info.amount,
                payService: info.payService,
                returnUrl: info.returnUrl,
                failUrl: info.failUrl,
                shopAmounts: info.shopAmounts
            }
        });

        // Логирование успешного ответа
        console.log('Response status:', response.status);
        console.log('Response data:', response.data);

        return JSON.parse(response.data);

    } catch (error) {
        // Логирование ошибок
        console.error('Request error:', error);

        if (error.response) {
            // Ошибка с ответом от сервера
            console.error('Error status:', error.response.status);
            console.error('Error data:', error.response.data);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            // Запрос был сделан, но ответа не было
            console.error('No response received:', error.request);
        } else {
            // Ошибка при настройке запроса
            console.error('Error in request setup:', error.message);
        }

        console.error('Error config:', error.config);

        return error;
    }
}

export const setPayStatus = async (info) => {
    let url = [paymentServer, "8443/pay/status/"].join(':');

    return await axios
        .post(url, info)
        .then((response) => response)
}

export const getVZTemplates = async (info) => {
    // let url = ["http://127.0.0.1", "8000/faq/all/"].join(':');
    let url = [backendUrl, "8000/lost_calls/all/"].join(':');

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const saveVZTemplate = async (info) => {
    // let url = ["http://127.0.0.1", "8000/faq/all/"].join(':');
    let url = [backendUrl, "8000/lost_calls/add/"].join(':');

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            ...info
        })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const updateVZTemplate = async (info) => {
    // let url = ["http://127.0.0.1", "8000/faq/all/"].join(':');
    let url = [backendUrl, "8000/lost_calls/update/"+info.id+"/"].join(':');

    return await axios
        .put(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            ...info,
        })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const deleteVZTemplate = async (info) => {
    // let url = ["http://127.0.0.1", "8000/faq/all/"].join(':');
    let url = [backendUrl, "8000/lost_calls/delete/"+info.id+"/"].join(':');
    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.data ? response.data : []
        })

}

export const generateTTs = async (info) => {
    let url = 'https://api.telezon.ru/tts/v1/yandex/generate/';

    return await axios
        .post(url, {
            text: info.text,
            voice: "zahar",
            emotion: "good",
            speed: "1.0",
        })
        .then(response => response.data)

}

export const getVZExtensions = async (info) => {
    // let url = [backendUrl, "8000/lost_calls/extensions/get/"].join(':');
    let url = `${backendUrl}:8000/lost_calls/extensions/get/`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.data ? JSON.parse(response.data) : []
        })

}

export const updateVZExtensions = async (info) => {
    // let url = [backendUrl, "8000/lost_calls/extensions/save/"].join(':');
    let url = `${backendUrl}:8000/lost_calls/extensions/save/`

    return await axios
        .put(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            ...info,
        })
        .then((response) => {
            return response.data ? response.data : []
        })

}


export const getVoipPrices = async (info) => {
    let url = [backendUrl, "8000/services/voip_prices/"].join(':');
    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.data)
}

export const voipCards = async (info) => {
    let url = [backendUrl, "8000/services/unlvoip/"].join(':');

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.data)
}

export const saveVoipCard = async (info) => {
    let url = [backendUrl, "8000/services/unlvoip/add/"].join(':');

    return await axios
        .post(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            ...info
        })
        .then((response) => response.data)
}

export const deleteVoipCard = async (info) => {
    let url = [backendUrl, "8000/services/unlvoip/delete/"+info.id+"/"].join(':');

    return await axios
        .delete(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.data)
}

export const updateVoipCard = async (info) => {
    let url = [backendUrl, "8000/services/unlvoip/update/"+info.id+"/"].join(':');

    return await axios
        .put(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            ...info
        })
        .then((response) => response.data)
}


export const getVamZvoniliSettings = async (info) => {
    let url = `${backendUrl}:8000/lost_calls/settings/?`
    if (info.domain) url = `${url}domain=${info.domain}`

    return await axios
        .get(url, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const saveVamZvoniliSettings = async (info) => {
    // const backendUrl = "http://127.0.0.1"
    let url = `${backendUrl}:8000/lost_calls/settings/?`
    if (info.domain) url = `${url}?domain=${info.domain}`

    return await axios
        .put(url, {
            auth: {
                login: info.login,
                name: 'Token',
                token: info.token,
            },
            ...info
        })
        .then((response) => JSON.parse(response.data))
}

export const byServices = async (info) => {
    let url = [backendUrl, "8003/services/by/"].join(':');

    return await axios
        .post(url, {
            uuid: info.buyUuid,
        }, {
            headers: {
                Authorization: `Token ${info.auth.login} ${info.auth.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const setActiveSmsService = async (info) => {
    let url = `${backendUrl}:8000/settings/${info.login}/sms/`

    return await axios
        .patch(url, {
            "service": info?.service || "telezon"
        }, {
            headers: {
                Authorization: `Token ${info.login} ${info.token}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => JSON.parse(response.data))
}

export const sendSmsAero = async (info) => {
    let url = "https://api.telezon.ru/sms/aero/sms/"

    return await axios
        .post(url, {
                "number": info.number,
                "text": info.text,
            },
            {
                headers: {
                    Authorization: info.token,
                    'Content-Type': 'application/json',
                    PIN: info.login,
                },
            }, )
        .then((response) => response.data)
        .catch(error => error.data)
}
